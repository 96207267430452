import { request } from 'utils/request'

export async function getAllServices() {
  return request.get('/api/os-services')
}

export async function getCarServices(carId) {
  return request.get(`/api/os-car-services/${carId}`)
}

export async function addCarServices(data) {
  return request.post(`/api/os-car-services`, {
    ...data,
  })
}

export async function deleteCarServices(data) {
  return request.delete(`/api/os-car-services`, {
    data: { ...data },
  })
}
