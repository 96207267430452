export const optGroups = [
  {
    id: 13,
    name: 'Безопасность',
  },
  {
    id: 15,
    name: 'Интерьер',
  },
  {
    id: 16,
    name: 'Комфорт',
  },
  {
    id: 19161,
    name: 'Мультимедиа',
  },
  {
    id: 17,
    name: 'Экстерьер',
  },
]
