import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import SelectDefault from 'components/Fields/Select'
import Input from 'components/Fields/Input'
import Table from 'components/Table'
import { Notifications } from 'components/Notifications'
import { ModalAction } from 'components/ModalAction'
import Button from 'components/Button'

import {
  getActiveOrders,
  getCompletedOrders,
  getOrdersCount,
} from 'store/orders/selectors'
import { getAllCars } from 'store/cars/selectors'
import { loadOrdersThunk } from 'store/orders/thunks'
import { loadAllCars } from 'store/cars/thunks'

import { statusesRoot } from './constats'

import { getManagers, setNewManager } from 'api/managers'
import { setCarInShowroom } from 'api/cars'
import { getDealerData } from 'api/dealers'

import { getValuesWithSelect } from 'utils/getValuesWithSelect'
import { getCookie } from 'utils/cookie'
import { insertUrlParam } from 'utils/insertUrlParam'

import style from './styles.module.scss'
import clsx from 'clsx'

export default function Tables({ dealerId, selectedDate, setActualStatus }) {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const currentStatus = query.get('status')
  const dispatch = useDispatch()

  const [isSent, setIsSent] = useState(false)
  const [managers, setManagers] = useState([])
  const [currentOrderId, setCurrentOrderId] = useState([])
  const [isOpenNotifications, setIsOpenNotifications] = useState({
    isOpen: false,
    currentTitle: '',
  })
  const [isShowModal, setIsShowModal] = useState(false)
  const [isFlag, setIsFlag] = useState(true)

  const { activeOrders, completedOrders, ordersAmount, cars } = useSelector(
    (state) => ({
      activeOrders: getActiveOrders(state),
      completedOrders: getCompletedOrders(state),
      ordersAmount: getOrdersCount(state),
      cars: getAllCars(state),
    })
  )

  const correctStatus = (queryStatus) => {
    if (queryStatus == null) {
      return 2
    } else {
      if (queryStatus >= 0 && queryStatus < 4) {
        return parseInt(queryStatus)
      } else {
        return 2
      }
    }
  }

  const {
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      status: correctStatus(currentStatus),
    },
  })

  const checkData =
    (Array.isArray(ordersAmount) && ordersAmount?.length > 0) ||
    (Array.isArray(cars) && cars?.length > 0) ||
    (Array.isArray(managers) && managers?.length > 0)

  const watchStatus = watch('status')
  const watchManager = watch('manager')
  const watchSearch = watch('search') || ''
  const watchSearchModels = watch('searchModels') || ''

  const CarsForPlacing = useMemo(
    () =>
      Array.isArray(cars) && checkData
        ? cars?.filter((el) => {
            return el.showroom !== 'in'
          })
        : [],
    [cars]
  )
  const CarsPlaced = useMemo(
    () =>
      Array.isArray(cars) && checkData
        ? cars?.filter((el) => {
            return el.showroom === 'in'
          })
        : [],
    [cars]
  )
  const CarsWithBadge = useMemo(
    () =>
      Array.isArray(cars) && checkData
        ? cars?.filter((el) => {
            return el.in_bage === true
          })
        : [],
    [cars]
  )
  const CarsWithNoBadge = useMemo(
    () =>
      Array.isArray(cars) && checkData
        ? cars?.filter((el) => {
            return el.in_bage === false
          })
        : [],
    [cars]
  )

  const withBadgeModelsList = useMemo(
    () =>
      CarsWithBadge.reduce(
        (acc, el) => {
          if (
            acc.some((e) => e.label === el.family_name + ' ' + el.model_name)
          ) {
            return [...acc]
          }

          const elem = {
            label: el.family_name + ' ' + el.model_name,
            value: el.family_name + ' ' + el.model_name,
          }

          return [...acc, elem]
        },
        [{ value: 0, label: 'Все модели' }]
      ),
    [CarsWithBadge]
  )

  const withNoBadgeModelsList = useMemo(
    () =>
      CarsWithNoBadge.reduce(
        (acc, el) => {
          if (
            acc.some((e) => e.label === el.family_name + ' ' + el.model_name)
          ) {
            return [...acc]
          }

          const elem = {
            label: el.family_name + ' ' + el.model_name,
            value: el.family_name + ' ' + el.model_name,
          }

          return [...acc, elem]
        },
        [{ value: 0, label: 'Все модели' }]
      ),
    [CarsWithNoBadge]
  )

  //add object field path to add search by this field

  const searchFieldsOrder = [
    'family_slug',
    'id',
    'client_info.name',
    'model_name',
    'model_slug',
    'manager.fullname',
    'vin',
  ]
  const searchFieldsCar = [
    ['family_name', 'model_name'],
    'car_id',
    'modification.title',
    'vin',
  ]

  useEffect(() => {
    setValue('search', '')
  }, [watchStatus])

  useEffect(() => {
    async function fetchManagers() {
      try {
        const res = await getManagers()
        setManagers(res.data)
      } catch (e) {
        console.warn(e)
      }
    }

    fetchManagers()
  }, [])

  useEffect(() => {
    async function fetchDealer() {
      try {
        const res = await getDealerData(dealerId, { showroom: false })
      } catch (e) {
        console.warn(e)
      }
    }

    if (dealerId) {
      fetchDealer()
    }
  }, [dealerId])

  const filteredManagers = getValuesWithSelect(managers, ['id', 'name'])

  const setManagerForOrder = async () => {
    try {
      await setNewManager(currentOrderId, {
        manager_id: watchManager,
      })
      setIsSent((prev) => !prev)
      setIsShowModal(false)
    } catch (e) {
      console.warn(e)
    }
  }

  async function deleteCarFromShowroom(carId) {
    try {
      await setCarInShowroom(carId, { showroom: false })
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isTakeOff' })
      setIsSent((prev) => !prev)
    } catch {
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isError' })
    }
  }

  async function addCarToShowroom(carId) {
    try {
      await setCarInShowroom(carId, { showroom: true })
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isPosted' })
      setIsSent((prev) => !prev)
    } catch {
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isError' })
    }
  }

  function openModal(id) {
    setIsShowModal(true)
    setCurrentOrderId(id)
  }

  function delayedRequest() {
    setTimeout(() => {
      setIsFlag((prev) => !prev)
    }, 300000)
  }

  useEffect(() => {
    delayedRequest()
  }, [isFlag])

  useEffect(() => {
    if (isFlag) {
      dispatch(loadOrdersThunk())
      dispatch(loadAllCars())
    }
  }, [isSent, dealerId, selectedDate, isFlag])

  useEffect(() => {
    insertUrlParam('status', watchStatus)
    setActualStatus(watchStatus)
  }, [watchStatus])

  return (
    <>
      <Notifications
        {...isOpenNotifications}
        setIsOpenNotifications={setIsOpenNotifications}
      />

      {isShowModal && (
        <ModalAction
          account
          withoutTitle
          description="Выберите менеджера на данный заказ"
          handleClose={() => setIsShowModal(false)}
        >
          <div className={style.modal}>
            <SelectDefault
              label="Менеджер"
              name="manager"
              control={control}
              options={filteredManagers}
              error={errors['manager']}
            />
            <Button.Outlined onClick={setManagerForOrder}>
              Назначить
            </Button.Outlined>
          </div>
        </ModalAction>
      )}

      <div className={style.userInfo}>
        <div className={style.selectType}>
          <SelectDefault
            name="status"
            control={control}
            orders
            bold
            hiddenLabel
            isSearchable={false}
            options={statusesRoot}
            // bage
          />
        </div>
        <div className={style.search}>
          <Input
            label="Что найти?"
            name="search"
            control={control}
            error={errors['search']}
          />
        </div>
      </div>
      {watchStatus === 4 && (
        <div className={clsx(style.actionsInfo, style.actionsInfoSpaceBetween)}>
          <SelectDefault
            name="searchModels"
            control={control}
            orders
            bold
            hiddenLabel
            isSearchable={false}
            options={withBadgeModelsList}
            className={style.searchModelField}
          />
        </div>
      )}

      {watchStatus === 0 && (
        <Table.ActiveOrdersChief
          data={activeOrders}
          openModal={openModal}
          watchSearch={watchSearch}
          searchFieldsOrder={searchFieldsOrder}
          watchStatus={watchStatus}
        />
      )}
      {watchStatus === 1 && (
        <Table.CompletedOrdersChief
          data={completedOrders}
          watchSearch={watchSearch}
          searchFieldsOrder={searchFieldsOrder}
          watchStatus={watchStatus}
        />
      )}
      {watchStatus === 2 && (
        <Table.CarsForPlacingChief
          data={CarsForPlacing}
          addCar={addCarToShowroom}
          watchSearch={watchSearch}
          searchFieldsCar={searchFieldsCar}
          watchStatus={watchStatus}
        />
      )}
      {watchStatus === 3 && (
        <Table.CarsPlacedChief
          data={CarsPlaced}
          deleteCar={deleteCarFromShowroom}
          watchSearch={watchSearch}
          searchFieldsCar={searchFieldsCar}
          watchStatus={watchStatus}
        />
      )}
      {watchStatus === 4 && (
        <Table.CarsForBadgeChief
          data={CarsWithBadge}
          noBadgeCars={CarsWithNoBadge}
          deleteCar={deleteCarFromShowroom}
          watchSearch={watchSearch}
          searchFieldsCar={searchFieldsCar}
          watchStatus={watchStatus}
          watchSearchModels={watchSearchModels}
          withNoBadgeModelsList={withNoBadgeModelsList}
        />
      )}
    </>
  )
}
