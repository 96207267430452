export const IconForward = ({ width, height, color }) => {
  return (
    <svg
      width={width || 25}
      height={height || 25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12" stroke={color || '#E37639'} />
      <path
        d="M9.2998 12.5L16.2998 12.5M16.2998 12.5L12.7998 9M16.2998 12.5L12.7998 16"
        stroke={color || '#E37639'}
      />
    </svg>
  )
}
