import { useEffect, useState } from 'react'
import { SORT_DIRECTION } from '../constants/loadingStatus'
import { sortTableData } from '../utils/sortTableData'

/**
 * Sorting hook for tables
 * @param {[any]} data - data array
 * @param {[{id: number, path: string, type?: string}]} config - data array
 * @returns {[[], number, string, Function, Function]} - [sorted array, handleSortedIndex, handleSortDirection]
 */
export const useSort = (data, config) => {
  const [sortedData, setSortedData] = useState([...data])
  const [sortHandlers, setSortHandlers] = useState(null)
  const [sortedIndex, setSortedIndex] = useState(0)
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.DESC)

  const filteredByDirectSale = data.filter(({ type }) => type === 'direct_sale')
  const filteredWDirectSale = data.filter(({ type }) => type !== 'direct_sale')
  const currentData = !!filteredByDirectSale.length
    ? [filteredByDirectSale, filteredWDirectSale]
    : [data]

  const handleSortedIndex = (i) => setSortedIndex(i)
  const handleSortDirection = (direction) => setSortDirection(direction)

  useEffect(() => {
    if (!!data.length && sortedIndex !== undefined && sortDirection) {
      const handlers = currentData.reduce((acc, item, i) => {
        let res = config.reduce(
          (acc, el) => ({
            ...acc,
            [el.id]: (sortDirection) =>
              sortTableData(item, sortDirection, el.path, el.type),
          }),
          {}
        )
        return { ...acc, [i]: res }
      }, {})

      setSortHandlers(handlers)
    }
  }, [data])

  useEffect(() => {
    if (sortHandlers) {
      const data = currentData.reduce((acc, el, i) => {
        let res = !!sortHandlers[i][sortedIndex]
          ? sortHandlers[i][sortedIndex](sortDirection)
          : el

        return !!res.length ? [...acc, ...res] : acc
      }, [])

      setSortedData([...data])
    }
  }, [sortedIndex, sortDirection, sortHandlers])

  return [
    sortedData,
    sortedIndex,
    sortDirection,
    handleSortedIndex,
    handleSortDirection,
  ]
}
