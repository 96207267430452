import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import Calendar from 'containers/TableSupervisor/Calendar'

import Icon from 'components/Icon'

import { getDateReportFromLs, removeDateReportToLs } from 'utils/localStorage'

import { getDealersById } from 'store/dealers/selectors'

import style from '../styles.module.scss'
import {
  getAmountCarForPlaceByDealer,
  getAmountCarPlacedByDealer,
} from 'api/reports'
import { getTokenFromLs } from 'utils/token'
import { getUserRole } from 'store/auth/selectors'
import { ROLE } from 'constants/role'

export default function Dealer({ currentDealer, isMobile }) {
  const [isOpenCalendar, setIsOpenCalendar] = useState(false)
  const [selectedDate, setSelectedDate] = useState(
    getDateReportFromLs('date_report_dealer_') || null
  )
  const [amountCarsData, setAmountCarsData] = useState({
    forPlace: '',
    placed: '',
  })
  const [isReady, setIsReady] = useState(false)

  const { dealers, role } = useSelector((state) => ({
    dealers: getDealersById(state),
    role: getUserRole(state),
  }))

  const dealer = useMemo(
    () => dealers.filter(({ dealerCode }) => dealerCode == currentDealer),
    [dealers]
  )
  const dealerName = dealer[0]?.dealerName

  const token = getTokenFromLs('t_kn')

  const handleOpenCalendar = () => {
    setIsOpenCalendar(true)
  }

  const handleResetDate = (e) => {
    e.stopPropagation()
    let link = document.getElementById('allOrdersByDealer')
    let persKpLink = document.getElementById('persKPByDate')
    let corpKpLink = document.getElementById('corpKPByDate')

    role !== ROLE.manager && link.removeAttribute('href')
    persKpLink.removeAttribute('href')
    corpKpLink.removeAttribute('href')

    removeDateReportToLs('date_report_dealer_')
    setSelectedDate(null)
    setIsOpenCalendar(false)
  }

  const handleSetReport = () => {
    if (selectedDate === null) {
      handleOpenCalendar()
    }
  }

  async function fetchAmountCars() {
    try {
      const amountCarsForPlace = await getAmountCarForPlaceByDealer(
        dealer[0].dealerCode
      )
      const amountCarsPlaced = await getAmountCarPlacedByDealer(
        dealer[0].dealerCode
      )

      setAmountCarsData({
        forPlace: amountCarsForPlace.data.success,
        placed: amountCarsPlaced.data.success,
      })
      setIsReady(true)
    } catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    if (selectedDate !== null) {
      let ordersLink = document.getElementById('allOrdersByDealer')
      let persKpLink = document.getElementById('persKPByDate')
      let corpKpLink = document.getElementById('corpKPByDate')

      role !== ROLE.manager &&
        ordersLink.setAttribute(
          'href',
          `https://v6.lada.ru/api/os-reports/orders/${selectedDate.startDate}/date-from/${selectedDate.endDate}/date-to/${currentDealer}/filial-code`
        )
      persKpLink.setAttribute(
        'href',
        `https://v6.lada.ru/api/offer-reports/pers/type/${selectedDate.startDate}/date-from/${selectedDate.endDate}/date-to/${token}`
      )
      corpKpLink.setAttribute(
        'href',
        `https://v6.lada.ru/api/offer-reports/corp/type/${selectedDate.startDate}/date-from/${selectedDate.endDate}/date-to/${token}`
      )
    }
  }, [selectedDate])

  useEffect(() => {
    if (dealer !== undefined && !isReady) {
      fetchAmountCars()
    }
  }, [dealer])

  return (
    <div>
      <div className={style.block}>
        {isOpenCalendar && (
          <Calendar
            setSelectedDate={setSelectedDate}
            setIsOpenCalendar={setIsOpenCalendar}
            reportType="date_report_dealer_"
            isMobile={isMobile}
          />
        )}
        <h3>Отчет по {dealerName}</h3>
        <div className={style.orders}>
          <div className={style.wrapperCalendar} onClick={handleOpenCalendar}>
            {selectedDate !== null ? (
              <div className={style.selectedDate}>
                <div className={style.icon}>
                  <Icon.Calendar />
                  <span>
                    {'c ' +
                      selectedDate.startDate +
                      ' по ' +
                      selectedDate.endDate}
                  </span>
                </div>
                <div
                  className={style.resetBtn}
                  onClick={(e) => handleResetDate(e)}
                >
                  <Icon.Close />
                </div>
              </div>
            ) : (
              <div className={style.input}>
                {selectedDate === null && (
                  <div className={style.emtyDate}>
                    <Icon.Calendar />
                    <span>
                      Выберите дату для формирования отчета по{' '}
                      {role !== ROLE.manager ? 'заказам' : 'КП'}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
          {role !== ROLE.manager && (
            <>
              <a
                onClick={(e) => handleSetReport(e)}
                target="_blank"
                className={style.item}
                id="allOrdersByDealer"
              >
                <span>
                  <Icon.DownloadReports />
                </span>
                <div className={style.content}>
                  <span>
                    {selectedDate !== null
                      ? `Заказы (с ${selectedDate.startDate} по ${selectedDate.endDate})`
                      : 'Заказы'}
                  </span>
                  <span>CSV</span>
                </div>
              </a>
            </>
          )}
        </div>

        {role !== ROLE.manager && <div className={style.line}></div>}

        <div className={style.cars}>
          {role !== ROLE.manager && (
            <>
              <a
                href={`https://v6.lada.ru/api/os-reports/for-showroom/${currentDealer}/filial-code`}
                target="_blank"
                className={style.item}
                rel="noreferrer"
              >
                <span>
                  <Icon.DownloadReports />
                </span>
                <div className={style.content}>
                  <p>
                    Автомобили для размещения{' '}
                    <span className={style.amount}>
                      {amountCarsData.forPlace}
                    </span>
                  </p>
                  <span>CSV</span>
                </div>
              </a>
              <a
                href={`https://v6.lada.ru/api/os-reports/in-showroom/${currentDealer}/filial-code`}
                target="_blank"
                className={style.item}
                rel="noreferrer"
              >
                <span>
                  <Icon.DownloadReports />
                </span>
                <div className={style.content}>
                  <p>
                    Автомобили на витрине{' '}
                    <span className={style.amount}>
                      {amountCarsData.placed}
                    </span>
                  </p>
                  <span>CSV</span>
                </div>
              </a>
            </>
          )}
          <>
            <a
              onClick={(e) => handleSetReport(e)}
              id="persKPByDate"
              target="_blank"
              className={style.item}
              rel="noreferrer"
            >
              <span>
                <Icon.DownloadReports />
              </span>
              <div className={style.content}>
                <p>
                  {selectedDate !== null
                    ? `Персональные КП (с ${selectedDate.startDate} по ${selectedDate.endDate})`
                    : 'Персональные КП '}
                </p>
                <span>CSV</span>
              </div>
            </a>
            <a
              onClick={(e) => handleSetReport(e)}
              id="corpKPByDate"
              target="_blank"
              className={style.item}
              rel="noreferrer"
            >
              <span>
                <Icon.DownloadReports />
              </span>
              <div className={style.content}>
                <p>
                  {selectedDate !== null
                    ? `Корпоративные КП (с ${selectedDate.startDate} по ${selectedDate.endDate})`
                    : 'Корпоративные КП '}
                </p>
                <span>CSV</span>
              </div>
            </a>
          </>
        </div>
      </div>
    </div>
  )
}
