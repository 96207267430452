import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'

import Input from 'components/Fields/Input'
import SelectDefault from 'components/Fields/Select'
import { Container } from 'components/Container'
import Icon from 'components/Icon'
import Button from 'components/Button'
import {
  ModalActionDelete,
  ModalActionSave,
  ModalWarning,
} from 'containers/Order/Modals'

import { getFormattedPrice, getPrice } from 'utils/getPrice'
import { getValuesWithSelect } from 'utils/getValuesWithSelect'
import {
  checkAccessoriesAvailable,
  percentCalculation,
} from '../../NewCar/utils'

import { colors } from 'constants/inlineColorConfig'
import {
  addCarServices,
  deleteCarServices,
  getAllServices,
  getCarServices,
} from 'api/services'

import style from './styles.module.scss'

export const Services = ({
  background,
  carId,
  data,
  isInputsActive = true,
  refetchOrderInfo,
  access,
  totalCarPricePercent,
  totalCarPrice,
  initialCarPrice,
  isOrder = false,
}) => {
  const [isShowModalDelete, setIsShowModalDelete] = useState(false)
  const [isShowModalSave, setIsShowModalSave] = useState(false)
  const [isShowModalWarning, setIsShowModalWarning] = useState(false)

  const [isSent, setIsSent] = useState(false)
  const [carServices, setCarServices] = useState(data)
  const [allServices, setAllServices] = useState([])

  const servicesPrice = carServices?.reduce((acc, { price }) => acc + price, 0)
  const [servicesPercent, setServicesPercent] = useState(
    percentCalculation(servicesPrice, initialCarPrice)
  )

  useEffect(() => {
    setServicesPercent(percentCalculation(servicesPrice, initialCarPrice))
  }, [servicesPrice])

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm()

  useEffect(() => {
    const fetchAllServices = async () => {
      try {
        const res = await getAllServices()
        const filteredServices = res.data.filter(
          (el) => !carServices.some((it) => Object.values(it).includes(el.id))
        )
        setAllServices(filteredServices)
      } catch (e) {
        console.warn(e)
      }
    }

    fetchAllServices()
  }, [carServices])

  useEffect(() => {
    const fetchCarServices = async () => {
      try {
        const res = await getCarServices(carId)
        setCarServices(res.data)
        refetchOrderInfo()
      } catch (e) {
        console.warn(e)
      }
    }

    fetchCarServices()
  }, [isSent])

  const addService = async (val) => {
    const fullAvailable = isOrder
      ? false
      : checkAccessoriesAvailable(
          val.price,
          initialCarPrice,
          totalCarPricePercent,
          'isFull'
        )
    const serviceAvailable = isOrder
      ? false
      : checkAccessoriesAvailable(
          val.price,
          initialCarPrice,
          servicesPercent,
          'isServices'
        )

    if ((fullAvailable && serviceAvailable) || isOrder) {
      try {
        await addCarServices({
          car_id: carId,
          service_id: val.service,
          price: val.price,
        })
        setIsSent((prev) => !prev)
        setValue('service', '')
        setValue('price', '')
        refetchOrderInfo()
      } catch (e) {
        console.log(e)
      }
    } else {
      setIsShowModalWarning(true)
    }
  }

  const deleteService = async (id) => {
    try {
      await deleteCarServices({
        car_id: carId,
        service_id: id,
      })
      setIsSent((prev) => !prev)
      refetchOrderInfo()
    } catch (e) {
      console.log(e)
    }
  }

  const formattedServices = getValuesWithSelect(allServices, ['id', 'name'])

  return (
    <div
      className={clsx({
        [style.background]: background,
      })}
    >
      {isShowModalDelete && (
        <ModalActionDelete
          handleCloseModal={() => setIsShowModalDelete(false)}
        />
      )}
      {isShowModalSave && (
        <ModalActionSave handleCloseModal={() => setIsShowModalSave(false)} />
      )}
      {isShowModalWarning && (
        <ModalWarning handleCloseModal={() => setIsShowModalWarning(false)} />
      )}
      <Container className={style.container}>
        <h2 className={style.title}>Дополнительные услуги</h2>
        <div className={style.list}>
          {servicesPrice > 0 && !isOrder && (
            <div className={style.message}>
              <span>
                Внимание! Итоговая стоимость автомобиля с аксессуарами и
                допуслугами должна составлять не более 130% РРЦ
              </span>
            </div>
          )}
          {!!carServices?.length &&
            carServices.map(({ name, price, id }) => (
              <div className={style.item} key={id}>
                <div className={style.itemName}>
                  <span className={style.itemTitle}>{name}</span>
                  {access && (
                    <div onClick={() => deleteService(id)}>
                      <Icon.Close
                        width={16}
                        height={16}
                        color={colors.blackPearl}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <span>{getPrice(price)}</span>
                </div>
              </div>
            ))}
          {servicesPrice > 0 && !isOrder && (
            <div>
              <div className={style.servicesPrice}>
                <span>ИТОГО допуслуг на</span>
                <span>{getPrice(servicesPrice) + ' / ' + servicesPercent}</span>
              </div>

              <div className={style.totalPrice}>
                <span>Текущая Итоговая стоимость автомобиля</span>
                <span>
                  {getPrice(totalCarPrice) +
                    ' / ' +
                    totalCarPricePercent +
                    'РРЦ'}
                </span>
              </div>
            </div>
          )}
        </div>
        <form onSubmit={handleSubmit(addService)}>
          <div className={style.form}>
            <div className={style.select}>
              <SelectDefault
                label="Добавить услугу"
                name="service"
                control={control}
                options={formattedServices}
                error={errors['service']}
                isDisabled={!isInputsActive || !access}
              />
            </div>
            <div className={style.input}>
              <Input
                label="Сумма, ₽"
                name="price"
                control={control}
                error={errors['price']}
                required
                rules={{
                  required: 'Это поле необходимо заполнить',
                  validate: (v) =>
                    v && v >= 0 ? true : 'Введите корректную сумму',
                }}
                disabled={!isInputsActive || !access}
              />
            </div>
            <div className={style.action}>
              <Button.Outlined
                disabled={!isInputsActive || !access}
                type="submit"
              >
                Добавить
              </Button.Outlined>
            </div>
          </div>
        </form>
      </Container>
    </div>
  )
}
