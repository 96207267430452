import { useForm } from 'react-hook-form'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import clsx from 'clsx'

import { Container } from 'components/Container'
import Input from 'components/Fields/Input'
import Button from 'components/Button'
import Switcher from 'components/Switcher'

import { getFormattedPrice } from 'utils/getPrice'
import { CREDIT_TYPES } from 'constants/creditTypes'

import style from './styles.module.scss'
import Checkbox from '../../../components/Fields/Checkbox'

export const getMonthPrice = ({
  price,
  percent,
  period,
  first_payment = 0,
  residual = 0,
}) => {
  const amount = price - first_payment
  const stavka = percent / 100

  if (residual > 0) {
    const result = Math.floor(
      ((stavka / 12) *
        (amount * Math.pow(1 + stavka / 12, period - 1) -
          residual / (1 + stavka / 12))) /
        (Math.pow(1 + stavka / 12, period - 1) - 1)
    )
    return result ? result : 0
  }

  const result = Math.floor(
    ((stavka / 12) *
      (amount * Math.pow(1 + stavka / 12, period) -
        residual / (1 + stavka / 12))) /
      (Math.pow(1 + stavka / 12, period) - 1)
  )
  return result ? result : 0
}

export const getMonthPriceVesta = ({
  price,
  percent,
  period,
  first_payment = 0,
  residual = 0,
  isSaveLife,
}) => {
  const amount = price - first_payment

  const kasko = price * 0.05
  const stavka = percent / 100
  const priceAmount =
    ((amount + kasko) * 0.0015 * period) / (1 - 0.0015 * period)

  const vestaPrice = amount + kasko + Math.trunc(priceAmount)
  const vestaPriceNoneSaveLife = amount + kasko

  if (residual > 0) {
    if (!isSaveLife) {
      const result = Math.floor(
        ((stavka / 12) *
          (vestaPriceNoneSaveLife * Math.pow(1 + stavka / 12, period - 1) -
            residual / (1 + stavka / 12))) /
          (Math.pow(1 + stavka / 12, period - 1) - 1)
      )
      return result ? result : 0
    }

    const result = Math.floor(
      ((stavka / 12) *
        (vestaPrice * Math.pow(1 + stavka / 12, period - 1) -
          residual / (1 + stavka / 12))) /
        (Math.pow(1 + stavka / 12, period - 1) - 1)
    )
    return result ? result : 0
  }
  if (!isSaveLife) {
    const result = Math.floor(
      ((stavka / 12) *
        (vestaPriceNoneSaveLife * Math.pow(1 + stavka / 12, period) -
          residual / (1 + stavka / 12))) /
        (Math.pow(1 + stavka / 12, period) - 1)
    )
    return result ? result : 0
  }

  const result = Math.floor(
    ((stavka / 12) *
      (vestaPrice * Math.pow(1 + stavka / 12, period) -
        residual / (1 + stavka / 12))) /
      (Math.pow(1 + stavka / 12, period) - 1)
  )
  return result ? result : 0
}

export const CreditProgram = ({
  price,
  setCreditData,
  credit_data,
  isCreditError,
  carInfo,
  setIsCreditError,
}) => {
  const [creditType, setCreditType] = useState(0)
  const [monthPayment, setMonthPayment] = useState(null)
  const [creditPrice, setCreditPrice] = useState(null)
  const [creditFirstPayment, setCreditFirstPayment] = useState(null)
  const [percentageRate, setPercentageRate] = useState(null)
  const [residual, setResidual] = useState(null)
  const [loanPeriod, setLoanPeriod] = useState(null)
  const [isChangeFirsPayment, setIsChangeFirsPayment] = useState(true)
  const [isSaveLife, setIsSaveLife] = useState(false)

  const isVesta = carInfo.family_slug === 'vesta'
  const isCreditTypeDef = creditType === CREDIT_TYPES.default

  const formattedRate = credit_data.loan_rate
    ? Number(credit_data.loan_rate) < 10
      ? '0' +
        String(Number(credit_data.loan_rate).toFixed(2)).replace('.', ',') +
        '%'
      : String(Number(credit_data.loan_rate).toFixed(2)).replace('.', ',') + '%'
    : null

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      loan_amount: creditPrice,
      first_payment_value: credit_data.first_payment,
      loan_rate: formattedRate,
      loan_period: credit_data.loan_period,
      residual: credit_data.residual,
    },
  })

  useEffect(() => {
    if (credit_data.residual) {
      setCreditType(CREDIT_TYPES.residualPayment)
    }

    if (credit_data.month_payment) {
      setMonthPayment(credit_data.month_payment)
      setCreditFirstPayment(credit_data.first_payment)
      setPercentageRate(formattedRate.replace('%', ''))
      Number(credit_data.residual) > 0 &&
        setResidual(Number(credit_data.residual))
      setLoanPeriod(credit_data.loan_period)

      setCreditData(credit_data)
    }
  }, [])

  const resetResults = () => {
    setMonthPayment(null)
    setCreditFirstPayment(null)
    setPercentageRate(null)
    setResidual(null)
    setLoanPeriod(null)
  }

  const handleCreditType = useCallback((id) => {
    setCreditType(id)
    resetResults()
  }, [])

  useEffect(() => {
    setCreditPrice(price)
    setValue('loan_amount', price)
  }, [price])

  const watch_first_payment_value = watch('first_payment_value')
  const watch_first_payment_percent = watch('first_payment_percent')
  const watch_loan_period = watch('loan_period')
  const watch_loan_rate = watch('loan_rate')
  const watch_loan_residual_payment = watch('residual')

  const onSubmit = () => {
    const loanRateFormated = String(watch_loan_rate).replace(',', '.')

    const totalFirstPayment = watch_first_payment_value
      ? Number(watch_first_payment_value)
      : 0

    const loanRateNumber = Number(
      loanRateFormated.substr(0, loanRateFormated.length - 1)
    )

    let currentMonthPayment = 0

    if (isVesta && isCreditTypeDef) {
      currentMonthPayment = getMonthPriceVesta({
        price: creditPrice,
        percent: loanRateNumber,
        period: Number(watch_loan_period),
        first_payment: Number(totalFirstPayment) || 0,
        residual: 0,
        isSaveLife,
      })
    } else {
      switch (creditType) {
        case CREDIT_TYPES.default:
          currentMonthPayment = getMonthPrice({
            price: creditPrice,
            percent: loanRateNumber,
            period: Number(watch_loan_period),
            first_payment: Number(totalFirstPayment) || 0,
            residual: 0,
          })
          break
        case CREDIT_TYPES.residualPayment:
          currentMonthPayment = getMonthPrice({
            price: creditPrice,
            percent: loanRateNumber,
            period: Number(watch_loan_period),
            residual: Number(watch_loan_residual_payment),
            first_payment: Number(totalFirstPayment) || 0,
          })

          break
        default:
      }
    }

    setMonthPayment(currentMonthPayment)

    setCreditFirstPayment(totalFirstPayment)
    setPercentageRate(loanRateNumber)
    Number(watch_loan_residual_payment) > 0 &&
      setResidual(Number(watch_loan_residual_payment))
    setLoanPeriod(watch_loan_period)

    const creditData = {
      loan_amount: creditPrice,
      month_payment: currentMonthPayment,
      first_payment: totalFirstPayment,
      loan_payment: creditPrice - totalFirstPayment,
      loan_rate: loanRateNumber,
      loan_period: watch_loan_period,
      residual: Number(watch_loan_residual_payment),
    }

    setCreditData(creditData)
    setIsCreditError(false)
  }

  useEffect(() => {
    if (isChangeFirsPayment) {
      setIsChangeFirsPayment(false)
      setValue(
        'first_payment_percent',
        Math.round(watch_first_payment_value / (price / 100))
      )
    } else {
      setIsChangeFirsPayment(true)
    }
  }, [watch_first_payment_value])

  useEffect(() => {
    if (useRef.current) {
      if (isChangeFirsPayment) {
        setIsChangeFirsPayment(false)
        setValue(
          'first_payment_value',
          Math.round((price / 100) * watch_first_payment_percent)
        )
      } else {
        setIsChangeFirsPayment(true)
      }
    } else {
      useRef.current = true
    }
  }, [watch_first_payment_percent])

  // console.log(isSaveLife,">>>")

  return (
    <div className={style.background}>
      <Container className={style.container}>
        <div className={style.titleWithButton}>
          <h2 className={style.title}>Кредитная программа</h2>
        </div>
        <div className={style.formBlock}>
          <Switcher
            selections={['Классический кредит', 'Кредит с остаточным платежом']}
            value={creditType}
            setter={handleCreditType}
            style={{ margin: '10px 0' }}
            gray
          />
        </div>
        {isVesta && isCreditTypeDef && (
          <div className={style.checkbox}>
            <div className={style.titleText}>Страхование жизни</div>
            <Checkbox
              onClick={() => setIsSaveLife((prevState) => !prevState)}
              control={control}
              name={'checkSG'}
            />
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={style.form}>
            <div className={clsx(style.row)}>
              <Input
                label="Итоговая стоимость"
                name="loan_amount"
                control={control}
                type="number"
                error={errors['loan_amount']}
                disabled={true}
              />
              <Input
                label="Первый взнос руб"
                name="first_payment_value"
                required
                rules={{ required: 'Это поле обязательно' }}
                onChangeDecorator={(e) =>
                  e.target.value > price ? price : e.target.value
                }
                control={control}
                type="number"
                error={errors['first_payment_value']}
                className={style.input}
              />
              <Input
                label="Первый взнос %"
                name="first_payment_percent"
                required
                rules={{
                  required: 'Это поле обязательно',
                  validate: (v) =>
                    v && Number(v) < 100 ? true : 'Некорректное значение',
                }}
                onChangeDecorator={(e) =>
                  e.target.value > 100 ? 100 : e.target.value
                }
                control={control}
                type="number"
                error={errors['first_payment_percent']}
                className={style.input}
              />
              <Input
                label="Процентная ставка"
                name="loan_rate"
                mask="99,99%"
                required
                rules={{
                  required: 'Это поле обязательно',
                  pattern: {
                    value: /([0-9]{2}),([0-9]{2})%/,
                    message: 'Необходимо заполнить поле',
                  },
                }}
                control={control}
                error={errors['loan_rate']}
              />
              <Input
                label="Срок кредита, мес"
                name="loan_period"
                required
                rules={{ required: 'Это поле обязательно' }}
                control={control}
                type="number"
                error={errors['loan_period']}
              />
              {creditType === CREDIT_TYPES.residualPayment && (
                <Input
                  label="Остаточный платеж"
                  name="residual"
                  required
                  rules={{ required: 'Это поле обязательно' }}
                  control={control}
                  type="number"
                  error={errors['loan_payment']}
                />
              )}
            </div>
          </div>

          <div className={style.creditInfo}>
            <div className={style.creditOption}>
              <span className={style.title}>Итоговая стоимость автомобиля</span>
              {creditPrice && (
                <span className={style.infoMonth}>
                  {getFormattedPrice(creditPrice)} ₽
                </span>
              )}
            </div>

            <div className={style.creditOption}>
              <span className={style.title}>Ежемесячный платеж</span>
              {monthPayment && (
                <span className={style.infoMonth}>
                  {getFormattedPrice(monthPayment)} ₽
                </span>
              )}
            </div>

            {creditType === CREDIT_TYPES.residualPayment && (
              <div className={style.creditOption}>
                <span className={style.title}>Остаточный платеж</span>
                {residual && (
                  <span className={style.info}>
                    {getFormattedPrice(residual)} ₽
                  </span>
                )}
              </div>
            )}

            <div className={style.creditOption}>
              <span className={style.title}>Первый взнос</span>
              {creditFirstPayment && (
                <span className={style.info}>
                  {getFormattedPrice(creditFirstPayment)} ₽
                </span>
              )}
            </div>

            <div className={style.creditOption}>
              <span className={style.title}>Процентная ставка</span>
              {percentageRate && (
                <span className={style.info}>{percentageRate}%*</span>
              )}
            </div>

            <div className={style.creditOption}>
              <span className={style.title}>Срок кредита</span>
              {loanPeriod && (
                <span className={style.info}>{loanPeriod} мес</span>
              )}
            </div>
          </div>

          <div className={style.action}>
            {isCreditError && <p>необходимо заполнить данные по кредиту</p>}
            <Button.Outlined type="submit">Рассчитать</Button.Outlined>
          </div>
        </form>
      </Container>
    </div>
  )
}
