export const IconAttention = ({ width, height, color }) => {
  return (
    <svg
      width={width || '64'}
      height={height || '64'}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="32"
        cy="32"
        r="30.5"
        fill="white"
        stroke="#A6ADB5"
        strokeWidth="3"
      />
      <path
        d="M35 11V26.7557L33.4848 40.2271H30.5152L29 26.7557V11H35ZM29 45.6157H35V52H29V45.6157Z"
        fill="#A6ADB5"
      />
    </svg>
  )
}
