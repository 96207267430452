import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { IconDropDownArrow } from 'components/Icon/IconDropDownArrow'
import Radio from 'components/Fields/Radio'
import Input from 'components/Fields/Input'

import style from './styles.module.scss'

export const AccordionItem = ({
  className,
  title,
  customStyle = {},
  control,
  subtitle,
  currentName,
  errors,
  name,
  items,
  disabled,
  disabledOpen = false,
  isInputsActive = true,
  preSetRate = false,
  creditAvailable = false,
  setValue = () => {},
  defaultPrice = 0,
  kasko = false,
  setIsChange = () => {},
  watch = () => {},
  isChange = false,
  reset = () => {},
}) => {
  const mainProperty = !!items && items.length ? items[0] : null
  const sideProperties =
    !!items && items.length ? items.filter((e, i) => i !== 0) : []

  const [isOpened, setIsOpened] = useState(false)
  const [price, setPrice] = useState('')
  const nameWatch = watch('price_' + name)

  useEffect(() => {
    if (preSetRate !== false) {
      setValue('price_' + name, (defaultPrice * preSetRate).toFixed(0))
    }
  }, [])

  const toggleOpened = () => {
    !disabledOpen && setIsOpened((isOpened) => !isOpened)
  }

  const filterPriceDecorator = (event) => {
    const newVal = event.target.value
    if (!newVal.match(/[A-z]/g)) {
      setPrice(newVal)
      return { ...event, target: { ...event.target, value: newVal } }
    } else {
      return { ...event, target: { ...event.target, value: price } }
    }
  }

  useEffect(() => {
    if (isChange) {
      setIsChange(false)
    }
  }, [nameWatch])

  return (
    <div
      className={clsx(style.containerItem, className, {
        [style.opened]: isOpened,
        [style.disabled]: disabled,
      })}
      style={customStyle}
    >
      <div className={style.header} onClick={toggleOpened}>
        <div className={style.main}>
          <span className={clsx(style.titleWrapper)}>
            <Radio
              customStyle={style.radio}
              checked={currentName === name}
              disabled={disabled}
              value={name}
              control={control}
              name={kasko ? 'kasko_name' : 'name'}
              circle
              onClick={() => {
                currentName === name && reset()
              }}
            />
            <div className={style.titleBlock}>
              <div className={style.title}>{title}</div>
              {creditAvailable && (
                <div className={style.creditTitle}>Подходит для кредита</div>
              )}
            </div>
          </span>
          <span className={style.subtitle}>{subtitle}</span>
          {/*<span*/}
          {/*  className={clsx(style.price, {*/}
          {/*    [style.isActive]: isActive,*/}
          {/*  })}*/}
          {/*>*/}
          {/*  {price} ₽/год*/}
          {/*</span>*/}
          <div style={kasko ? { width: 290 } : { width: 177 }}>
            <Input
              required={currentName === name}
              // rules={
              //   currentName === name ? { min: 1, required: 'Это поле обязательно' } : {}
              // }
              rules={
                currentName === name && {
                  validate: (value) => {
                    return Number(value) > 1 ? true : 'Указана неверная сумма'
                  },
                  required: 'Это поле обязательно',
                }
              }
              error={errors[`price_${name}`]}
              label={'Сумма ₽/год'}
              name={'price_' + name}
              control={control}
              onChangeDecorator={filterPriceDecorator}
              disabled={!isInputsActive || preSetRate !== false}
              className={style.input}
            />
          </div>
          {!disabledOpen && <IconDropDownArrow />}
        </div>
      </div>
      <div className={style.body}>
        {mainProperty && (
          <div className={style.fullRow}>
            <div className={style.fullRowTitle}>{mainProperty.title}</div>
            <div className={style.fullRowText}>
              {mainProperty.description.replace('\\n', '')}
            </div>
          </div>
        )}
        <div className={style.table}>
          {!!sideProperties &&
            sideProperties.map((el, id) => (
              <div key={id}>
                <div className={style.tableTitle}>
                  {el.title.replace('\\n', '')}
                </div>
                <div className={style.tableText}>{el.description}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
