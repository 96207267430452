import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { LOADING_STATUS } from 'constants/loadingStatus'
import { loadCitiesWithId, loadCityById } from './thunks'

export const adapter = createEntityAdapter()

export const cities = createSlice({
  name: 'cities',
  initialState: adapter.getInitialState({
    items: [],
    citiesWithId: [],
    total: 0,
    loadingStatus: LOADING_STATUS.LOADING,
  }),
  reducers: {},
  extraReducers: {
    [loadCitiesWithId.pending]: (state) => {
      state.loadingStatus = LOADING_STATUS.LOADING
    },
    [loadCitiesWithId.rejected]: (state) => {
      state.citiesWithId = []
    },
    [loadCitiesWithId.fulfilled]: (state, { payload }) => {
      state.loadingStatus = LOADING_STATUS.IDLE
      state.citiesWithId = payload.items.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
      )
    },
  },
})

export default cities.reducer
