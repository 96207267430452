import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'

import Input from 'components/Fields/Input'
import { Container } from 'components/Container'
import Icon from 'components/Icon'
import Button from 'components/Button'
import { GroupSelectWithTabs } from 'components/Fields/GroupSelectWithTabs/GroupSelect'
import {
  ModalActionDelete,
  ModalActionSave,
  ModalWarning,
} from 'containers/Order/Modals'

import { addCarAccessories, deleteCarAccessories } from 'api/accessories'

import { getPrice } from 'utils/getPrice'
import {
  checkAccessoriesAvailable,
  percentCalculation,
} from '../../NewCar/utils'

import { colors } from 'constants/inlineColorConfig'

import style from './styles.module.scss'

const formattedCurrentAccessories = (accessories) => {
  const base = accessories.base
  const added = accessories.added

  if (base && added) {
    return [...base, ...added]
  }

  if (base && !added) {
    return [...base]
  }

  if (!base && added) {
    return [...added]
  }
}

/**
 * Cut used accessories from dataList for do not use in adding
 * @param {[]} dataList - full accessories list
 * @param {[]} usedList - current accessories in use
 * @return {[]} - filtered full accessories list
 * @private
 */
const _cutUsedAccessories = (dataList, usedList) =>
  !!usedList.length
    ? [...dataList].reduce(
        (acc, optionGroup) => [
          ...acc,
          {
            ...optionGroup,
            options: [...optionGroup.options].reduce(
              (acc, option) =>
                usedList.find(
                  (usedOption) => String(usedOption.id) === String(option.value)
                )
                  ? acc
                  : [...acc, option],
              []
            ),
          },
        ],
        []
      )
    : dataList

export const Accessories = ({
  data,
  catalog,
  refetchOrderInfo,
  accessories,
  dealer_accessories,
  carId,
  background,
  isInputsActive = true,
  access,
  totalCarPricePercent,
  totalCarPrice,
  initialCarPrice,
  isOrder = false,
}) => {
  const [isShowModalDelete, setIsShowModalDelete] = useState(false)
  const [isShowModalSave, setIsShowModalSave] = useState(false)
  const [isShowModalWarning, setIsShowModalWarning] = useState(false)

  const [groups, setGroups] = useState(null)
  const [allAccessories, setAllAccessories] = useState([])
  const [filteredAccessories, setFilteredAccessories] = useState([])
  const [price, setPrice] = useState(0)
  const [currentAccessories, setCurrentAccessories] = useState(data)
  const [accTabs, setAccTabs] = useState(['Все'])

  const accessoriesList = useMemo(
    () => formattedCurrentAccessories(currentAccessories) || [],
    [currentAccessories]
  )
  const accessoriesPrice = accessoriesList?.reduce(
    (acc, { price }) => acc + price,
    0
  )
  const [accessoriesPercent, setAccessoriesPercent] = useState(
    percentCalculation(accessoriesPrice, initialCarPrice)
  )

  useEffect(() => {
    setAccessoriesPercent(percentCalculation(accessoriesPrice, initialCarPrice))
  }, [accessoriesList])

  useEffect(() => {
    if (Array.isArray(data.base) && data.base.length !== 0) {
      data.base.forEach(
        (item) => (item.name = `${item.name + ' арт. ' + item.id}`)
      )
      setCurrentAccessories(data)
    } else {
      setCurrentAccessories(data)
    }
  }, [data])

  useEffect(() => {
    if (allAccessories.length)
      setFilteredAccessories(
        _cutUsedAccessories(allAccessories, accessoriesList)
      )
  }, [accessoriesList, allAccessories])

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    reset,
    setError,
    clearErrors,
  } = useForm()
  const watchAccessory = watch('accessories')
  const watchPrice = watch('price')

  const addAccessory = async (val) => {
    const acc = [
      ...(Array.isArray(accessories) ? [...accessories] : []),
      ...dealer_accessories,
    ]
    const current = acc.find((el) => el.id === watchAccessory)

    const priceLocal = current.price ? current.price : price.price
    const isDealer = current.is_dealer

    if (isDealer || Number(current.price) >= Number(watchPrice)) {
      const fullAvailable = isOrder
        ? false
        : checkAccessoriesAvailable(
            val.price,
            initialCarPrice,
            totalCarPricePercent,
            'isFull'
          )
      const accAvailable = isOrder
        ? false
        : checkAccessoriesAvailable(
            val.price,
            initialCarPrice,
            accessoriesPercent,
            'isAcc'
          )

      if ((fullAvailable && accAvailable) || isOrder) {
        try {
          await addCarAccessories({
            car_id: carId,
            accessory_id: current.id,
            is_dealer: isDealer,
            price: watchPrice,
            name: current.name,
          })
          // this set from drop old useForm state
          setValue('accessories', '')
          // this drop visual value from select, I don't have time form resolve it now some differently
          reset()
          await refetchOrderInfo()
        } catch (e) {
          console.log(e)
        }
      } else {
        setIsShowModalWarning(true)
      }
    } else {
      setError('price', {
        type: 'price',
        message: `Сумма не должна превышать РРЦ ${current.price}`,
      })
    }
  }

  const deleteAccessory = async (accessoryId) => {
    try {
      await deleteCarAccessories({
        car_id: carId,
        accessory_id: accessoryId,
      })
      await refetchOrderInfo()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (!!catalog) {
      const filteredGroups = catalog.reduce((acc, group) => {
        if (!acc['parent']) acc['parent'] = []
        if (!acc['options']) acc['options'] = []

        if (group.parent_id !== 0) {
          acc['options'].push(group)
        } else {
          acc['parent'].push(group)
        }
        return acc
      }, {})

      setGroups(filteredGroups)
    }
  }, [catalog])

  useEffect(() => {
    if ((accessories || dealer_accessories) && groups) {
      const accessoriesGroups =
        (!!accessories &&
          groups.options.map(({ id, parent_id }) => {
            const options = accessories
              .filter((el) => el.group === id)
              .map((it) => {
                return { value: it.id, label: it.name }
              })

            return { parent_id, options: options }
          })) ||
        []

      const filteredAccessories =
        (accessoriesGroups.length > 0 &&
          groups.parent.map(({ id, name }) => {
            const options = accessoriesGroups
              .filter((el) => el.parent_id === id)
              .reduce((acc, it) => {
                it.options.forEach(
                  (item) =>
                    (item.label = `${item.label + ' арт. ' + item.value}`)
                )
                acc.push(...it.options)

                return acc
              }, [])

            return { label: name, options: options }
          })) ||
        []

      const dealerAccessories =
        (!!dealer_accessories &&
          dealer_accessories.map(({ id, name }) => {
            return { value: id, label: name }
          })) ||
        []

      const mixedAccessories = [
        ...filteredAccessories,
        { label: 'Дилерские аксессуары', options: dealerAccessories },
      ]

      filteredAccessories.length > 0 &&
        setAccTabs((prev) =>
          !prev.includes('LADA Image') ? [...prev, 'LADA Image'] : prev
        )
      dealerAccessories.length > 0 &&
        setAccTabs((prev) =>
          !prev.includes('Дилер') ? [...prev, 'Дилер'] : prev
        )

      mixedAccessories.length > 0 && setAllAccessories(mixedAccessories)
    }
  }, [accessories, dealer_accessories, groups])

  useEffect(() => {
    if (accessories) {
      const acc = [...accessories, ...dealer_accessories]
      const current = acc.find((el) => el.id === watchAccessory)

      if (current && current.price) {
        setPrice(current.price)
        setValue('price', current.price)
      }
      if (current && !current.price) {
        setValue('price', '')
      }
    }
  }, [watchAccessory])

  useEffect(() => {
    if (watchPrice !== 'undefined') {
      clearErrors()
    }
  }, [watchPrice])

  return (
    <div
      className={clsx({
        [style.background]: background,
      })}
    >
      {isShowModalDelete && (
        <ModalActionDelete
          handleCloseModal={() => setIsShowModalDelete(false)}
        />
      )}
      {isShowModalSave && (
        <ModalActionSave handleCloseModal={() => setIsShowModalSave(false)} />
      )}
      {isShowModalWarning && (
        <ModalWarning handleCloseModal={() => setIsShowModalWarning(false)} />
      )}
      <Container className={style.container}>
        <h2 className={style.title}>Аксессуары</h2>
        {accessoriesPrice > 0 && !isOrder && (
          <div className={style.message}>
            <span>
              Внимание! Итоговая стоимость автомобиля с аксессуарами и
              допуслугами должна составлять не более 130% РРЦ
            </span>
          </div>
        )}
        <div className={style.list}>
          {!!accessoriesList.length &&
            accessoriesList.map(({ name, price, id }) => (
              <div className={style.item} key={id}>
                <div className={style.itemName}>
                  <span className={style.itemTitle}>{name}</span>
                  {access && (
                    <div onClick={() => isInputsActive && deleteAccessory(id)}>
                      <Icon.Close
                        width={16}
                        height={16}
                        color={colors.blackPearl}
                      />
                    </div>
                  )}
                </div>
                {price && (
                  <div className={style.itemPrice}>{getPrice(price)}</div>
                )}
              </div>
            ))}
          {accessoriesPrice > 0 && !isOrder && (
            <div>
              <div className={style.accPrice}>
                <span>итого аксессуаров на</span>
                <span>
                  {getPrice(accessoriesPrice) + ' / ' + accessoriesPercent}
                </span>
              </div>

              <div className={style.totalPrice}>
                <span>Текущая Итоговая стоимость автомобиля</span>
                <span>
                  {getPrice(totalCarPrice) +
                    ' / ' +
                    totalCarPricePercent +
                    'РРЦ'}
                </span>
              </div>
            </div>
          )}
        </div>
        <form onSubmit={handleSubmit(addAccessory)}>
          <div className={style.form}>
            <div className={style.select}>
              <GroupSelectWithTabs
                label="Добавить аксессуары"
                name="accessories"
                control={control}
                options={filteredAccessories}
                error={errors['accessories']}
                isDisabled={!isInputsActive || !access}
                className={style.accessories}
                tabs={accTabs}
              />
            </div>
            <div className={style.price}>
              <Input
                label="Сумма, ₽"
                name="price"
                control={control}
                error={errors['price']}
                required
                rules={{
                  required: 'Это поле необходимо заполнить',
                  validate: (v) =>
                    v && v > 0 ? true : 'Введите корректную сумму',
                }}
                disabled={!isInputsActive || !access}
              />
            </div>
            <div className={style.action}>
              <Button.Outlined
                type="submit"
                disabled={!isInputsActive || !access}
              >
                Добавить
              </Button.Outlined>
            </div>
          </div>
        </form>
      </Container>
    </div>
  )
}
