import { IconLocation } from './IconLocation'
import { IconService } from './IconService'
import { IconAccessories } from './IconAccessories'
import { IconParts } from './IconParts'
import { IconHelp } from './IconHelp'
import { IconSupport } from './IconSupport'
import { IconFlatArrowRight } from './IconFlatArrowRight'
import { IconFlatArrowLeft } from './IconFlatArrowLeft'
import { IconArrowRight } from './IconArrowRight'
import { IconDownload } from './IconDownload'
import { IconDownloadCircle } from './IconDownloadCircle'
import { IconCheck } from './IconCheck'
import { IconCalc } from './IconCalc'
import { IconWarranty } from './IconWarranty'
import { IconView360 } from './View360'
import { IconVk } from './IconVk'
import { IconFb } from './IconFb'
import { IconTw } from './IconTw'
import { IconYt } from './IconYt'
import { IconIn } from './IconIn'
import { IconGarant } from './IconGarant'
import { IconWheel } from './IconWheel'
import { IconWarrantyCard } from './IconWarrantyCard'
import { IconServiceBook } from './IconServiceBook'
import { IconRegistrationCertificate } from './IconRegistrationCertificate'
import { IconDriverLicense } from './IconDriverLicense'
import { IconEmail } from './IconEmail'
import { IconForward } from './IconForward'
import { IconClose } from './IconClose'
import { IconGear } from './IconGear'
import { IconRub } from './IconRub'
import { IconArrowDown } from './IconArrowDown'
import { IconScrollDown } from './IconScrollDown'
import { IconMenu } from './IconMenu'
import { IconPlus } from './IconPlus'
import { IconMechanical } from './IconMechanical'
import { IconAutomatic } from './IconAutomatic'
import { IconEngineCircle } from './IconEngineCircle'
import { IconDropDownArrow } from './IconDropDownArrow'
import { IconArrowLeft } from './IconArrowLeft'
import { IconLongArrowLeft } from './IconLongArrowLeft'
import { IconPDF } from './IconPDF'
import { IconYoutube } from './IconYoutube'
import { IconAskDealers } from './IconAskDealers'
import { IconTradeIn } from './IconTradeIn'
import { IconAuto } from './IconAuto'
import { IconCredit } from './IconCredit'
import { IconRatingStar } from './IconRatingStar'
import { IconHelpRating } from './IconHelpRating'
import { IconUpdate } from './IconUpdate'
import { IconAddToCompare } from './IconAddToCompare'
import { IconListOfCompare } from './IconListOfCompare'
import { IconArrowDownWithTail } from './IconArrowDownWithTail'
import { IconFilter } from './IconFilter'
import { IconDealer } from './IconDealer'
import { IconSearch } from './IconSearch'
import { IconTrash } from './IconTrash'
import { IconBenefit } from './IconBenefit'
import { IconCarOfTheYear } from './IconCarOfTheYear'
import { IconTenPercent } from './IconTenPercent'
import { IconFifteenPercent } from './IconFifteenPercent'
import { IconTwentyPercent } from './IconTwentyPercent'
import { IconInstallment } from './IconInstallment'
import { IconFavorite } from './IconFavorite'
import { IconGift } from './IconGift'
import { IconAnalytics } from './IconAnalytics'
import { IconAccount } from './IconAccount'
import { IconAttention } from './IconAttention'
import { IconCalendar } from './IconCalendar'
import { IconDownloadReports } from 'components/Icon/IconDownloadReports'

const Icon = {
  Location: IconLocation,
  ArrowRight: IconArrowRight,
  Service: IconService,
  Accessories: IconAccessories,
  Help: IconHelp,
  Support: IconSupport,
  ArrowFlatRight: IconFlatArrowRight,
  ArrowFlatLeft: IconFlatArrowLeft,
  Download: IconDownload,
  DownloadCircle: IconDownloadCircle,
  Calc: IconCalc,
  Warranty: IconWarranty,
  Parts: IconParts,
  Check: IconCheck,
  View: IconView360,
  Vk: IconVk,
  Facebook: IconFb,
  Twitter: IconTw,
  Youtube: IconYt,
  Instagram: IconIn,
  Email: IconEmail,
  Forward: IconForward,
  Garant: IconGarant,
  Wheel: IconWheel,
  WarrantyCard: IconWarrantyCard,
  ServiceBook: IconServiceBook,
  RegistrationCertificate: IconRegistrationCertificate,
  DriverLicense: IconDriverLicense,
  Close: IconClose,
  Gear: IconGear,
  Rub: IconRub,
  ArrowDown: IconArrowDown,
  ScrollDown: IconScrollDown,
  Menu: IconMenu,
  Plus: IconPlus,
  Mechanical: IconMechanical,
  Automatic: IconAutomatic,
  EngineCircle: IconEngineCircle,
  DropDownArrow: IconDropDownArrow,
  ArrowLeft: IconArrowLeft,
  LongArrowLeft: IconLongArrowLeft,
  PDF: IconPDF,
  Youtubes: IconYoutube,
  AskDealers: IconAskDealers,
  TradeIn: IconTradeIn,
  Auto: IconAuto,
  Credit: IconCredit,
  RatingStar: IconRatingStar,
  HelpRating: IconHelpRating,
  Update: IconUpdate,
  AddToCompare: IconAddToCompare,
  ListOfCompare: IconListOfCompare,
  ArrowDownWithTail: IconArrowDownWithTail,
  Filter: IconFilter,
  Dealer: IconDealer,
  Search: IconSearch,
  Trash: IconTrash,
  MaxBenefit: IconBenefit,
  CarOfTheYear: IconCarOfTheYear,
  TenPercent: IconTenPercent,
  FifteenPercent: IconFifteenPercent,
  TwentyPercent: IconTwentyPercent,
  Installment: IconInstallment,
  Gift: IconGift,
  Favorite: IconFavorite,
  Analytics: IconAnalytics,
  Account: IconAccount,
  Attention: IconAttention,
  Calendar: IconCalendar,
  DownloadReports: IconDownloadReports,
}

export default Icon
