export const IconView360 = ({ width, height, color }) => {
  return (
    <svg
      width={width || 54}
      height={height || 32}
      viewBox="0 0 54 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.7775 7.99295C36.4395 8.00079 36.1766 8.16529 36.1902 8.36039L36.4115 11.5396C36.425 11.7347 36.71 11.8865 37.0479 11.8786C37.3859 11.8708 37.6488 11.7063 37.6352 11.5112L37.4385 8.68524L42.3337 8.57169C42.6716 8.56385 42.9346 8.39934 42.921 8.20425C42.9074 8.00915 42.6224 7.85736 42.2845 7.8652L36.7775 7.99295ZM40.8455 14.4815C40.8455 18.8958 34.6468 22.4743 27.0004 22.4743V23.1814C35.3232 23.1814 42.0702 19.2863 42.0702 14.4815H40.8455ZM27.0004 22.4743C19.354 22.4743 13.1554 18.8958 13.1554 14.4815H11.9306C11.9306 19.2863 18.6776 23.1814 27.0004 23.1814V22.4743ZM36.3868 8.60601C39.1292 10.0669 40.8455 12.1582 40.8455 14.4815H42.0702C42.0702 11.9527 40.2005 9.67548 37.2174 8.08637L36.3868 8.60601ZM13.1554 14.4815C13.1554 12.1582 14.8716 10.0669 17.614 8.60601L16.7835 8.08637C13.8003 9.67548 11.9306 11.9527 11.9306 14.4815H13.1554Z"
        fill={color || 'white'}
      />
    </svg>
  )
}
