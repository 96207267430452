import clsx from 'clsx'

import styles from './styles.module.scss'

export const ButtonFullWidth = ({ children, to, className }) => {
  return (
    <a href={to} className={clsx(className)}>
      <div className={clsx(styles.button, className)}>{children}</div>
    </a>
  )
}
