import clsx from 'clsx'

import Button from 'components/Button'

import styles from './styles.module.scss'

export const Pagination = ({
  steps,
  className,
  setCurrentPage,
  currentPage,
  containerRef = false,
}) => {
  const handleClick = (e) => {
    const number = e.target.getAttribute('data-count-value')

    if (number && Number(currentPage.amount) !== Number(number)) {
      setCurrentPage({ current: 1, amount: number })
    }
  }

  const handleLoadOneMore = () => {
    setCurrentPage((prev) => ({ ...prev, current: currentPage.current + 1 }))
  }

  const scrollToRef = () => {
    containerRef.current.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.pagination}>
        показывать по
        {steps.map((count, i) => (
          <button
            data-count-value={count}
            key={i}
            className={styles.amountItem}
            onClick={handleClick}
          >
            {count}
          </button>
        ))}
        Автомобилей
      </div>
      <div className={styles.actions}>
        {containerRef ? (
          <Button.Outlined onClick={scrollToRef}>К началу</Button.Outlined>
        ) : (
          <Button.Outlined to="#request">К началу</Button.Outlined>
        )}

        <Button.Default onClick={handleLoadOneMore}>
          Показать ещё
        </Button.Default>
      </div>
    </div>
  )
}
