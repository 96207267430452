const getOptions = (obj) => {
  return Object.keys(obj).map((el) => {
    let res = {
      value: el,
      label: obj[el],
    }

    return res
  })
}

export let initialState = {}

let data = {}

export function reducer(state, action) {
  switch (action.type) {
    case 'CHANGE_DATA':
      data = action.payload

      initialState = {
        families: getOptions(action.payload.families),
        models: [],
        engines: [],
        versions: [],
        compls: [],
        colors: [],
      }
      return
    case 'CHANGE_FAMILIES':
      return {
        ...initialState,
        models: getOptions(data.models[action.payload]),
      }
    case 'CHANGE_MODELS':
      return {
        ...state,
        engines: getOptions(
          data.engines[action.payload.family][action.payload.model]
        ),
        versions: [],
        compls: [],
        colors: [],
      }
    case 'CHANGE_ENGINES':
      return {
        ...state,
        versions: getOptions(
          data.versions[action.payload.family][action.payload.model][
            action.payload.engine
          ]
        ),
        compls: [],
        colors: [],
      }
    case 'CHANGE_VERSIONS':
      return {
        ...state,
        compls: getOptions(
          data.compls[action.payload.family][action.payload.model][
            action.payload.engine
          ][action.payload.version]
        ),
        colors: [],
      }
    case 'CHANGE_COMPL':
      return {
        ...state,
        colors: getOptions(data.colors[action.payload.compl]),
      }
    default:
      return state
  }
}
