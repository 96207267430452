import * as ReactDOM from 'react-dom'
import clsx from 'clsx'
import { useSort } from 'hooks/useSort'
import { useSearch } from 'hooks/useSearch'
import { useForm } from 'react-hook-form'
import { useState, useEffect, useRef } from 'react'

import Icon from 'components/Icon'
import Button from 'components/Button'
import { Pagination } from 'components/Pagination'
import Input from 'components/Fields/Input'

import style from './styles.module.scss'
import { Fragment } from 'react'
import SelectDefault from 'components/Fields/Select'

export const AddCarModal = ({
  withNoBadgeModelsList,
  data,
  searchFieldsCar,
  handleClose,
}) => {
  const modalRef = useRef(null)

  const [currentData, setCurrentData] = useState([])
  const [currentPage, setCurrentPage] = useState({ current: 1, amount: 10 })

  const {
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      searchModels: 0,
    },
  })

  const watchSearch = watch('search') || ''
  const watchSearchModels = watch('searchModels')

  const filteredCarsPlacing = useSearch(
    data,
    watchSearch,
    searchFieldsCar,
    300,
    false
  )

  const [sortedData] = useSort(filteredCarsPlacing.filteredData, [])

  useEffect(() => {
    const startIndex = 0
    const endIndex = currentPage.amount * currentPage.current
    const formattedData = sortedData.slice(startIndex, endIndex)

    setCurrentData(
      formattedData.filter((el) =>
        !watchSearchModels
          ? true
          : el.family_name + ' ' + el.model_name === watchSearchModels
      )
    )
  }, [currentPage, sortedData, watchSearchModels])

  return ReactDOM.createPortal(
    <div className={clsx(style.container)}>
      <div className={style.overlay} onClick={handleClose} />
      <div ref={modalRef} className={style.modal}>
        <div className={style.header}>
          <h2 id="modalTitle">выберите автомобиль для бейджа</h2>
          <div className={style.close} onClick={handleClose}>
            <Icon.Close />
          </div>
        </div>
        <div className={style.fieldsBlock}>
          <SelectDefault
            name="searchModels"
            control={control}
            orders
            bold
            hiddenLabel
            isSearchable={false}
            options={withNoBadgeModelsList}
            className={style.searchModelField}
          />
          <div className={style.search}>
            <Input
              label="Что найти?"
              name="search"
              control={control}
              error={errors['search']}
            />
          </div>
        </div>

        <div className={style.body}>
          <table className={clsx(style.table, style.carsPlaced)}>
            <tbody>
              {currentData.map((carInfo) => {
                return (
                  <Fragment key={carInfo.car_id}>
                    <tr className={style.info}>
                      <td>
                        <span className={style.bold}>
                          {carInfo.family_name} {carInfo.model_name}
                        </span>
                        <span className={style.vin}>VIN {carInfo.vin}</span>
                      </td>
                      <td>
                        <span>{carInfo.complectation}</span>
                        <span>{carInfo.modification.title}</span>
                      </td>
                      <td align="right">
                        <Button.Default to={`/carForBadge/${carInfo.car_id}`}>
                          редактировать и распечатать бейдж
                        </Button.Default>
                        {/* <PdfCreator id={carInfo.car_id} /> */}
                      </td>
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          </table>

          {currentData.length === 0 && (
            <div className={style.emptyBlock}>
              <p className={style.emptyMsg}>пока нет автомобилей</p>
            </div>
          )}

          {currentData.length > 0 && (
            <Pagination
              className={style.tableFooter}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              steps={[10, 25, 50]}
              containerRef={modalRef}
            />
          )}
        </div>
      </div>
    </div>,
    document.body
  )
}
