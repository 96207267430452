import { request, requestLada } from 'utils/request'

export async function getCars() {
  return request.get(`/api/os-manager-cars`)
}

export async function getCarById(carId) {
  return request.get(`/api/os-manager-cars/${carId}`)
}

export async function getCarForBageById(carId) {
  return requestLada.get(`/api/os-car-bages/${carId}/car-id`)
}

export async function setCarInShowroom(carId, data) {
  return request.put(`/api/os-manager-cars/${carId}`, {
    ...data,
  })
}

export async function getFullModelRange() {
  return requestLada.get('/api/combinations/full-model-range')
}
