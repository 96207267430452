import { createAsyncThunk } from '@reduxjs/toolkit'

import { getCitiesWithId, getCityById } from 'api/city'

export const loadCitiesWithId = createAsyncThunk(
  'cities/allCitiesWithId',
  async (_, thunkAPI) => {
    const res = await getCitiesWithId()
    if (res.data.error) {
      return { items: [], total: res.data.length }
    }
    return { items: res.data, total: res.data.length }
  }
)

export const loadCityById = createAsyncThunk(
  'cities/cityById',
  async (cityId, _, thunkAPI) => {
    const res = await getCityById(cityId)
    if (res.data.error) {
      return { items: [], total: res.data.length }
    }
    return { items: res.data, total: res.data.length }
  }
)
