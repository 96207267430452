export const IconPlus = ({ width, height, color }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.3" cx="12.5" cy="12.5" r="12" stroke="#D9DCDC" />
      <path
        d="M7.848 11.76V13.04H11.736V16.928H13.016V13.04H16.904V11.76H13.016V7.872H11.736V11.76H7.848Z"
        fill="white"
      />
    </svg>
  )
}
