export const IconListOfCompare = ({ width, height, color }) => {
  return (
    <svg
      width={width || '19'}
      height={height || '17'}
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 16H3" stroke={color || '#848E98'} strokeWidth="1.6" />
      <path d="M19 10H3" stroke={color || '#848E98'} strokeWidth="1.6" />
      <path d="M19 7H8" stroke={color || '#848E98'} strokeWidth="1.6" />
      <path d="M19 13H3" stroke={color || '#848E98'} strokeWidth="1.6" />
      <path d="M19 4L10 4" stroke={color || '#848E98'} strokeWidth="1.6" />
      <path
        d="M8 4L-4.17233e-07 4"
        stroke={color || '#848E98'}
        strokeWidth="1.6"
      />
      <path
        d="M4 8L4 -4.17233e-07"
        stroke={color || '#848E98'}
        strokeWidth="1.6"
      />
    </svg>
  )
}
