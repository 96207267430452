import { getFullModelRange } from 'api/cars'
import { getAllCO } from 'api/corporateOffer'

export async function getAll() {
  try {
    const res = await getAllCO()

    return res.data
  } catch (e) {
    return []
  }
}

export async function getFamiliesData() {
  try {
    const res = await getFullModelRange()

    return res.data
  } catch (e) {
    return []
  }
}
