import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { useDebounce } from 'hooks/useDebounce'

import { updateCreditData } from 'api/credit'
import { getCreditData } from 'api/rnBank'

import Tabs from 'components/Tabs'
import Text from 'components/Text'
import Input from 'components/Fields/Input'
import Ranger from 'components/Fields/Ranger'
import { Container } from 'components/Container'
import { CreditPayment } from './CreditPayment'

import style from './styles.module.scss'
import { getCookie } from 'utils/cookie'

export const CreditCalculator = ({
  car,
  putClass,
  monthlyPayment,
  setMonthlyPayment,
  currentDownpaymentRange,
  currentCreditRate,
  totalAmount,
  setTotalAmount,
  currentOfferYearsRange,
  setCurrentCreditRate,
  setDownpayment,
  downpayment,
  creditPeriodTab,
  setCreditPeriodTab,
  isShowButton,
  setDuration,
  duration,
  insuranceTab,
  setInsuranceTab,
}) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isDisabled, setIsDisabled] = useState(false)
  const debouncedCurrentFirstPay = useDebounce(downpayment, 500)

  const { control, watch, setValue } = useForm({
    defaultValues: {
      price: car.modification.price * 0.5,
      range: downpayment / (car.modification.price / 100),
    },
  })
  const watchRange = watch('range')

  useEffect(() => {
    setValue('price', Math.round((car.modification.price / 100) * watchRange))
    setDownpayment(
      Math.round(Math.round((car.modification.price / 100) * watchRange))
    )
  }, [watchRange])

  const carMonthOld = useMemo(() => {
    const fullYear = new Date().getFullYear()
    const month = new Date().getMonth()

    return (fullYear - car.year) * 12 + month
  }, [])

  useEffect(() => {
    const getNewCreditData = async () => {
      const currentYear = new Date().getFullYear()

      if (!isFirstLoad) {
        const data = {
          Referer: window.location.href,
          IdProductCondition:
            insuranceTab === 1
              ? 'cca314da-c766-4c3f-a021-024c2a7bf5d0'
              : 'cf1b7f2d-3bdf-4fbe-8556-48d48f781044',
          offer: {
            car: {
              carPrice: String(car.modification.price),
              carProductionYear: String(currentYear),
            },
            initialPayment: 0,
            initialPaymentPercent: watchRange,
            residualPayment: 0,
            residualPaymentPercent: 0,
            creditTerm: currentOfferYearsRange[creditPeriodTab],
            additionalServicesInfo: [
              {
                isSelected: false,
                name: 'Lada Finance Защита платежей [Минимальный пакет + СОЗ]',
                packageName: '',
                price: 91585,
                serviceId: 'a5fcf1b5-5f13-4b0c-ad5e-f5abf45c7040',
                upstair: true,
              },
            ],
          },
        }
        const res = await getCreditData(data)

        setCurrentCreditRate(Number(res.data.offer[0].bankRate).toFixed(2))
        setDuration(Number(res.data.offer[0].creditTerm))
        setMonthlyPayment(Math.round(Number(res.data.offer[0].monthlyPayment)))
        setTotalAmount(res.data.offer[0].creditAmountTotal)
      } else {
        setIsFirstLoad(false)
      }
    }

    getNewCreditData()
  }, [debouncedCurrentFirstPay, creditPeriodTab, insuranceTab])

  const setNewCreditData = async () => {
    await updateCreditData({
      order_id: car.order_id,
      bank: 'РН Банк',
      loan_amount: car.modification.price,
      first_payment: downpayment,
      loan_rate: currentCreditRate,
      loan_period: duration,
      loan_payment: totalAmount,
      month_payment: monthlyPayment,
      residual: 0,
      insurance: insuranceTab === 1 ? false : true,
    })

    setIsDisabled(true)
  }

  useEffect(() => {
    setIsDisabled(false)
  }, [creditPeriodTab, downpayment])

  return (
    <>
      <div className={style.creditBlock} id="credit">
        <Container className={style.container}>
          <Text.H3 title={'Кредитный калькулятор'} className={style.title} />
          <div className={style.wrapper}>
            <div className={style.creditProgram}>
              <div className={style.block}>
                <h4>ПЕРВОНАЧАЛЬНЫЙ ВЗНОС</h4>
                <div className={style.initialFee}>
                  <div className={style.result}>
                    <Input control={control} name="price" disabled />
                  </div>
                  <Ranger
                    control={control}
                    name="range"
                    step={5}
                    min={currentDownpaymentRange[0]}
                    max={currentDownpaymentRange[1]}
                    putClass={'initial_fee_' + putClass}
                  />
                </div>
              </div>
              <div className={style.block}>
                <h4>
                  Срок кредита<span className={style.mobile}>, месяцев</span>
                </h4>
                <div className={style.desktop}>
                  <Tabs
                    active={creditPeriodTab}
                    setActive={setCreditPeriodTab}
                    tabs={currentOfferYearsRange.map((tab) => tab + ' мес')}
                    className={style.mobileTabs}
                    putClass={'credit_period_' + putClass}
                    isOrange={true}
                    className={style.tabsColor}
                  />
                </div>
                <div className={style.mobile}>
                  <Tabs
                    active={creditPeriodTab}
                    setActive={setCreditPeriodTab}
                    tabs={currentOfferYearsRange.map((tab) => String(tab))}
                    className={style.mobileTabs}
                    putClass={'credit_period_' + putClass}
                    isOrange={true}
                  />
                </div>
                <div className={style.block}>
                  <h4>СТРАХОВАНИЕ ЖИЗНИ В КРЕДИТ</h4>
                  <div className={style.desktop}>
                    <Tabs
                      active={insuranceTab}
                      setActive={setInsuranceTab}
                      tabs={['Да', 'Нет']}
                      className={style.mobileTabs}
                      putClass={'credit_insurance_' + putClass}
                      isOrange={true}
                      className={style.tabsColor}
                    />
                  </div>
                  <div className={style.mobile}>
                    <Tabs
                      active={insuranceTab}
                      setActive={setInsuranceTab}
                      tabs={['Да', 'Нет']}
                      className={style.mobileTabs}
                      putClass={'credit_insurance_' + putClass}
                      isOrange={true}
                    />
                  </div>
                </div>
              </div>
            </div>

            <CreditPayment
              fullPrice={car.modification.price}
              firstPrice={downpayment}
              monthPrice={monthlyPayment}
              period={currentOfferYearsRange[creditPeriodTab] + ' мес'}
              percent={currentCreditRate}
              isShowButton={isShowButton}
              setNewCreditData={setNewCreditData}
              isDisabled={isDisabled}
            />
          </div>
        </Container>
      </div>
    </>
  )
}
