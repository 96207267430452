import clsx from 'clsx'
import { Controller } from 'react-hook-form'

import styles from './styles.module.scss'

const Checkbox = ({
  name,
  customStyle,
  defaultValue,
  disabled,
  control,
  loading,
  children,
  click,
  bordered,
  isSpecSeries,
  putClass,
  dataName,
  ...attr
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ value = false, onChange }) => (
        <label
          className={clsx({
            [styles.checkbox]: true,
            [customStyle]: customStyle,
            [styles.checkbox_disabled]: disabled,
            [styles.checkbox_bordered]: bordered,
            [styles.checkboxSpec]: isSpecSeries,
            [putClass]: putClass,
          })}
          onClick={(event) => event.stopPropagation()}
        >
          <input
            data-test-name={`${dataName || 'checkbox'}`}
            type="checkbox"
            className={clsx({ ['checkbox_' + putClass]: putClass })}
            name={name}
            checked={value}
            onChange={(e) => {
              onChange(e.target.checked)
              click && click(e)
            }}
            disabled={disabled}
            {...attr}
          />
          <span>{children}</span>
        </label>
      )}
    />
  )
}

export default Checkbox
