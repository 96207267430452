export function getDisabledIds(checked, schema, allValues = []) {
  if (Array.isArray(schema) && schema.length > 1) {
    const isCheckedArray = Array.isArray(checked)
    const multiValue = schema.find((el) => Array.isArray(el))
    let disabled = []

    const values = isCheckedArray
      ? allValues
      : Object.keys(checked).map((el) => +el)

    let checkedIds = []
    if (isCheckedArray) {
      checkedIds = checked
    } else {
      for (let key in checked) {
        if (checked[key]) {
          checkedIds.push(+key)
        }
      }
    }

    values.forEach((el) => {
      if (!checkedIds.includes(el) && checkedIds.length) {
        disabled.push(el)
      }

      if (multiValue) {
        multiValue.forEach((multyId) => {
          if (checkedIds.includes(multyId)) {
            disabled = disabled.filter((id) => !multiValue.includes(id))
          }
        })
      }
    })

    return disabled
  } else {
    return []
  }
}
