export const staticData = [
  {
    id: 0,
    title: 'КАСКО',
    name: 'kasko',
  },
  {
    id: 1,
    title: 'ОСАГО',
    name: 'osago',
  },
  {
    id: 2,
    title: 'ОСАГО + КАСКО',
    name: 'osagoKasko',
  },
]
