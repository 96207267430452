import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Container } from 'components/Container'
import Switch from 'components/Fields/Switch'
import Input from 'components/Fields/Input'
import Button from 'components/Button'

import { getPrice, getFormattedPrice } from 'utils/getPrice'

import { colors } from 'constants/inlineColorConfig'
import style from './styles.module.scss'

export const TradeIn = ({
  car,
  evaluation,
  dealerEvaluation,
  setIsOpen,
  setCurrent,
  setData,
  isInputsActive = true,
  isActiveTradeIn,
  setIsActiveTradeIn,
  removeTradeIn,
  orderId,
  access,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      tradeIn: dealerEvaluation || evaluation,
    },
  })
  const watchRun = watch('run')
  const watchYear = watch('year')
  const [isShouldValidate, setIsShouldValidate] = useState(false)

  useEffect(() => {
    if (dealerEvaluation || evaluation) {
      setIsShouldValidate(true)
    }
  }, [dealerEvaluation, evaluation])

  useEffect(() => {
    if (watchRun && Number(watchRun) > 99999999) {
      let cutedRun = String(watchRun).substr(0, watchRun.length - 1)

      setValue('run', cutedRun)
    }
  }, [watchRun])

  useEffect(() => {
    const currentYear = new Date().getFullYear()

    if (watchYear && Number(watchYear) > currentYear) {
      let validatedYear = currentYear

      setValue('year', validatedYear)
    }
  }, [watchYear])

  const watchTradeIn = watch('tradeIn')

  const isEvaluation =
    dealerEvaluation === undefined && evaluation === undefined

  const handleChange = () => {
    if (isActiveTradeIn) {
      if (!isEvaluation) {
        removeTradeIn(orderId)
      }
      setIsActiveTradeIn(false)
    } else {
      setIsActiveTradeIn(true)
    }
  }

  const handleClick = (val) => {
    setIsActiveTradeIn(watchTradeIn)
    setCurrent('tradeIn')
    setData(val)
    setIsOpen(true)
  }

  useEffect(() => {
    if (!isEvaluation) {
      setIsActiveTradeIn(!isEvaluation)
    }
  }, [])

  useEffect(() => {
    if (car) {
      setValue('brand', car.brand)
      setValue('model', car.model)
      setValue('modification', car.modification)
      setValue('year', car.year)
      setValue('body', car.body)
      setValue('run', car.run)
      setValue('price', dealerEvaluation || evaluation)
    }
  }, [car])

  return (
    <div className={style.background}>
      <Container className={style.container}>
        <div className={style.titleWithButton}>
          <h2 className={style.title}>Программа Трейд-ин</h2>
          <div className={style.switch}>
            <Switch
              id="tradeIn"
              name="tradeIn"
              control={control}
              activeColor={colors.brightOrange}
              disabled={!isInputsActive || !access}
              value={true}
              change={handleChange}
            />
            <div className={style.text}>
              <span>Использовать</span>
              <span>программу</span>
            </div>
          </div>
        </div>

        {isActiveTradeIn && (
          <>
            <form onSubmit={handleSubmit(handleClick)}>
              <div className={style.formCar}>
                <div className={style.rowForm}>
                  <Input
                    label="Марка"
                    name="brand"
                    required={!isShouldValidate}
                    rules={
                      !isShouldValidate && { required: 'Это поле обязательно' }
                    }
                    control={control}
                    error={errors['brand']}
                    disabled={!watchTradeIn || !access || !isInputsActive}
                  />
                  <Input
                    label="Модель"
                    name="model"
                    required={!isShouldValidate}
                    rules={
                      !isShouldValidate && { required: 'Это поле обязательно' }
                    }
                    control={control}
                    error={errors['model']}
                    disabled={!watchTradeIn || !access || !isInputsActive}
                  />
                  <Input
                    label="Модификация"
                    name="modification"
                    required={!isShouldValidate}
                    rules={
                      !isShouldValidate && { required: 'Это поле обязательно' }
                    }
                    control={control}
                    error={errors['modification']}
                    disabled={!watchTradeIn || !access || !isInputsActive}
                  />
                  <Input
                    label="Год выпуска"
                    name="year"
                    control={control}
                    required={!isShouldValidate}
                    rules={
                      !isShouldValidate && { required: 'Это поле обязательно' }
                    }
                    error={errors['year']}
                    type="number"
                    disabled={!watchTradeIn || !access || !isInputsActive}
                  />
                </div>
                <div className={style.rowForm}>
                  <Input
                    label="Тип кузова"
                    name="body"
                    control={control}
                    required={!isShouldValidate}
                    rules={
                      !isShouldValidate && { required: 'Это поле обязательно' }
                    }
                    error={errors['body']}
                    disabled={!watchTradeIn || !access || !isInputsActive}
                  />
                  <Input
                    label="Пробег"
                    name="run"
                    control={control}
                    required={!isShouldValidate}
                    rules={
                      !isShouldValidate && { required: 'Это поле обязательно' }
                    }
                    type="number"
                    error={errors['run']}
                    disabled={!watchTradeIn || !access || !isInputsActive}
                  />
                  <Input
                    label="Оценка стоимости дилером"
                    name="price"
                    control={control}
                    required={!isShouldValidate}
                    rules={
                      !isShouldValidate && { required: 'Это поле обязательно' }
                    }
                    type="number"
                    error={errors['price']}
                    disabled={!watchTradeIn || !access || !isInputsActive}
                  />
                  <div className={style.action}>
                    <Button.Outlined
                      type="submit"
                      disabled={!isInputsActive || !access}
                    >
                      Изменить
                    </Button.Outlined>
                  </div>
                </div>
              </div>
            </form>
            <div className={style.titleBlock}>Автомобиль клиента</div>
            <div className={style.table}>
              <div className={style.row}>
                <div className={style.tableTitle}>Марка</div>
                <div className={style.tableItem}>
                  {car ? car.brand : 'Kia / Mazda'}
                </div>
              </div>
              <div className={style.row}>
                <div className={style.tableTitle}>Год выпуска</div>
                <div className={style.tableItem}>{car ? car.year : 2000}</div>
              </div>
              <div className={style.row}>
                <div className={style.tableTitle}>Модификация</div>
                <div className={style.tableItem}>
                  {car ? car.modification : '1.6 МТ / 1.6 АТ'}
                </div>
              </div>
              <div className={style.row}>
                <div className={style.tableTitle}>Модель</div>
                <div className={style.tableItem}>
                  {car ? car.model : 'Rio / Solaris'}
                </div>
              </div>
              <div className={style.row}>
                <div className={style.tableTitle}>Тип кузова</div>
                <div className={style.tableItem}>
                  {car ? car.body : 'Седан / Хэтчбэк'}
                </div>
              </div>
              <div className={style.row}>
                <div className={style.tableTitle}>Пробег</div>
                <div className={style.tableItem}>
                  {car ? getFormattedPrice(car.run) : 0} км
                </div>
              </div>
            </div>
            {!dealerEvaluation && (
              <div className={style.titleBlock}>
                <span>Предварительная оценка auto.ru</span>
                <span>{evaluation ? getPrice(evaluation) : 0}</span>
              </div>
            )}
            <div className={style.titleBlock}>
              <span>Оценка дилера</span>
              <span>{dealerEvaluation ? getPrice(dealerEvaluation) : 0}</span>
            </div>
          </>
        )}
      </Container>
    </div>
  )
}
