import React from 'react'

import { Container } from 'components/Container'

import engineImg from 'assets/img/pdfImages/engine.svg'
import colorImg from 'assets/img/pdfImages/color.svg'

import style from './styles.module.scss'

export const Header = ({
  color_name,
  model_name,
  complectation,
  modification,
}) => {
  return (
    <Container className={style.container}>
      <p className={style.title}>Новая {model_name}</p>
      <div className={style.headFlex}>
        <div className={style.complBox}>
          <p className={style.complTitle}>КОМПЛЕКТАЦИЯ АВТОМОБИЛЯ</p>
          <p className={style.complName}>{complectation}</p>
        </div>

        <div className={style.engineBox}>
          <div className={style.engineItem}>
            <img src={engineImg} />
            <div>
              <p>двигатель</p>
              <span>{modification.title}</span>
            </div>
          </div>

          <div className={style.engineItem}>
            <img src={colorImg} />
            <div>
              <p>Цвет </p>
              <span>{color_name ? color_name : 'нет данных цвета'}</span>
            </div>
          </div>
        </div>
      </div>

      {/* {features?.length > 0 && (
        <div className={style.featuresTable}>
          {features.map((el) => (
            <div
              style={{ width: 100 / features.length + '%' }}
              className={style.featuresItem}
            >
              {el}
            </div>
          ))}
        </div>
      )}
      {modification?.packages.map((el) => (
        <div
          className={clsx(style.packageItem, {
            [style.packageItemFull]: features?.length === 0,
          })}
        >
          Включен пакет опций: {el.title}
        </div>
      ))} */}
    </Container>
  )
}
