import { createSelector } from '@reduxjs/toolkit'

export const getOrders = (state) => state.orders

export const getOrdersCount = createSelector(
  getOrders,
  (orders) => orders.total
)

export const getAllOrders = createSelector(getOrders, (orders) => orders.items)

export const getActiveOrders = createSelector(
  getOrders,
  (orders) => orders.activeOrders
)

export const getCompletedOrders = createSelector(
  getOrders,
  (orders) => orders.completedOrders
)

export const getOrdersLoadingStatus = createSelector(
  getOrders,
  (orders) => orders.loadingStatus
)
