import clsx from 'clsx'

import style from './styles.module.scss'

export const Container = ({ children, className, onClick }) => {
  return (
    <div className={clsx(style.container, className)} onClick={onClick}>
      {children}
    </div>
  )
}
