import clsx from 'clsx'
import { Controller } from 'react-hook-form'

import { colors } from 'constants/inlineColorConfig'

import styles from './styles.module.scss'

const Switch = ({
  id,
  name,
  value,
  defaultValue,
  control,
  disabled,
  putClass,
  inactiveColor = colors.grayishBlue,
  activeColor = colors.brightOrange,
  change = () => {},
  ...attr
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ value, onChange }) => (
        <div
          className={clsx(styles.toggleSwitch, {
            [putClass]: putClass,
            [styles.disabled]: disabled,
          })}
        >
          <input
            type="checkbox"
            id={id}
            name={name}
            className={styles.toggleSwitchCheckbox}
            checked={value}
            disabled={disabled}
            onChange={(e) => {
              onChange(e.target.checked)
              change(e.target.checked)
            }}
            {...attr}
          />
          <label
            htmlFor={id}
            className={styles.toggleSwitchLabel}
            tabIndex={disabled ? -1 : 1}
            onClick={(event) => event.stopPropagation()}
            style={{ backgroundColor: value ? activeColor : inactiveColor }}
          >
            <span
              className={clsx({
                [styles.switchDot]: true,
                [styles.disabled]: disabled,
              })}
              tabIndex={-1}
            />
          </label>
        </div>
      )}
    />
  )
}

export default Switch
