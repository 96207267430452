export const IconDownloadCircle = ({ width, height, color }) => {
  return (
    <svg
      width={width || 25}
      height={height || 25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12" stroke={color || '#E37639'} />
      <path
        d="M12.8 8V15M12.8 15L16.3 11.5M12.8 15L9.30005 11.5"
        stroke={color || '#E37639'}
      />
      <line
        x1="9.30005"
        y1="16.5"
        x2="16.3"
        y2="16.5"
        stroke={color || '#E37639'}
      />
    </svg>
  )
}
