export const IconIn = ({ width, height, color }) => {
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0767 1.81392C17.5351 1.26664 16.8902 0.832404 16.1795 0.536437C15.4687 0.240469 14.7062 0.0886686 13.9363 0.0898506H5.86446C4.31123 0.0967234 2.82357 0.716792 1.72526 1.81511C0.626941 2.91342 0.00687282 4.40108 0 5.95431V14.0261C0.00687282 15.5793 0.626941 17.067 1.72526 18.1653C2.82357 19.2636 4.31123 19.8837 5.86446 19.8906H13.9363C15.4895 19.8837 16.9772 19.2636 18.0755 18.1653C19.1738 17.067 19.7939 15.5793 19.8007 14.0261V5.95431C19.8034 5.18417 19.6522 4.42125 19.3562 3.71028C19.0601 2.9993 18.6251 2.35459 18.0767 1.81392ZM18.6252 14.0261C18.6269 14.6424 18.5068 15.2529 18.2718 15.8225C18.0368 16.3922 17.6915 16.9098 17.2557 17.3456C16.82 17.7813 16.3024 18.1266 15.7327 18.3617C15.163 18.5967 14.5525 18.7168 13.9363 18.7151H5.86446C4.63003 18.7321 3.43843 18.263 2.54693 17.409C2.12166 16.9691 1.78739 16.4496 1.56326 15.8804C1.33913 15.3111 1.22955 14.7031 1.24081 14.0914V5.95431C1.23729 4.72093 1.72122 3.5361 2.58723 2.65787C3.45323 1.77964 4.63115 1.27913 5.86446 1.26536H13.9363C14.548 1.2541 15.1559 1.36367 15.7252 1.5878C16.2945 1.81193 16.8139 2.14621 17.2538 2.57147C18.1078 3.46297 18.5769 4.65458 18.5599 5.88901L18.6252 14.0261Z"
        fill={color || '#848E98'}
      />
      <path
        d="M9.90001 5.05249C8.92354 5.05249 7.969 5.34205 7.1571 5.88455C6.34519 6.42704 5.71239 7.19812 5.33871 8.10026C4.96503 9.0024 4.86726 9.99509 5.05776 10.9528C5.24826 11.9105 5.71847 12.7902 6.40894 13.4807C7.09941 14.1712 7.97912 14.6414 8.93683 14.8319C9.89454 15.0224 10.8872 14.9246 11.7894 14.5509C12.6915 14.1772 13.4626 13.5444 14.0051 12.7325C14.5476 11.9206 14.8371 10.9661 14.8371 9.98961C14.8337 8.68127 14.3124 7.42749 13.3873 6.50235C12.4621 5.5772 11.2084 5.05594 9.90001 5.05249ZM9.90001 13.7512C9.15604 13.7512 8.42877 13.5306 7.81017 13.1173C7.19158 12.7039 6.70944 12.1165 6.42473 11.4291C6.14003 10.7418 6.06553 9.98544 6.21068 9.25576C6.35582 8.52607 6.71408 7.85582 7.24015 7.32975C7.76622 6.80368 8.43648 6.44542 9.16616 6.30027C9.89584 6.15513 10.6522 6.22962 11.3395 6.51433C12.0269 6.79904 12.6144 7.28117 13.0277 7.89977C13.441 8.51836 13.6616 9.24563 13.6616 9.98961C13.6616 10.9873 13.2653 11.944 12.5599 12.6495C11.8544 13.3549 10.8977 13.7512 9.90001 13.7512Z"
        fill={color || '#848E98'}
      />
      <path
        d="M15.2292 3.69385C15.0381 3.69385 14.8512 3.75053 14.6922 3.85674C14.5333 3.96294 14.4094 4.11389 14.3363 4.2905C14.2631 4.46711 14.244 4.66145 14.2813 4.84893C14.3186 5.03642 14.4106 5.20864 14.5458 5.34381C14.681 5.47898 14.8532 5.57104 15.0407 5.60833C15.2282 5.64562 15.4225 5.62648 15.5991 5.55333C15.7757 5.48017 15.9267 5.35629 16.0329 5.19735C16.1391 5.0384 16.1957 4.85153 16.1957 4.66037C16.1957 4.53345 16.1707 4.40776 16.1222 4.2905C16.0736 4.17324 16.0024 4.06669 15.9127 3.97694C15.8229 3.88719 15.7164 3.81599 15.5991 3.76742C15.4818 3.71885 15.3561 3.69385 15.2292 3.69385Z"
        fill={color || '#848E98'}
      />
    </svg>
  )
}
