import { useEffect, useState } from 'react'

/**
 *
 * @param {any} value - dynamic change value
 * @param delay - delay to return value
 * @returns {any} - value after delay
 */
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [value])

  return debouncedValue
}
