export const IconYoutube = ({ width, height, color }) => {
  return (
    <svg
      width="100"
      height="68"
      viewBox="0 0 100 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M97.9163 11.1124C97.3501 9.09248 96.2471 7.26379 94.7246 5.82069C93.1592 4.3332 91.2405 3.26917 89.1497 2.72902C81.3247 0.66652 49.9746 0.666521 49.9746 0.666521C36.9052 0.517823 23.8389 1.17183 10.8496 2.62485C8.75877 3.20491 6.84366 4.29272 5.27464 5.79152C3.73298 7.27485 2.61631 9.10402 2.03298 11.1082C0.631696 18.6571 -0.0491413 26.3221 -0.000358091 33.9999C-0.0503581 41.6707 0.628809 49.3332 2.03298 56.8915C2.60381 58.8874 3.71631 60.7082 5.26214 62.179C6.80798 63.6499 8.73298 64.7124 10.8496 65.2749C18.7788 67.3332 49.9746 67.3332 49.9746 67.3332C63.0607 67.4821 76.1436 66.8281 89.1497 65.3749C91.2405 64.8347 93.1592 63.7707 94.7246 62.2832C96.2663 60.8124 97.3622 58.9832 97.9122 56.9915C99.35 49.4455 100.049 41.7773 99.9997 34.0957C100.108 26.3815 99.4096 18.6772 97.9163 11.1082V11.1124ZM40.008 48.2665V19.7374L66.0913 34.004L40.008 48.2665Z"
        fill="white"
      />
    </svg>
  )
}
