export const IconCalc = ({ width, height, color }) => {
  return (
    <svg
      width={width || 46}
      height={height || 49}
      viewBox="0 0 46 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.3959 10.7621H7.29865C6.68473 10.7621 6.18481 10.2651 6.18481 9.6512C6.18481 9.03728 6.68473 8.54321 7.29865 8.54321H37.3959C38.0098 8.54321 38.5097 9.0402 38.5097 9.6512C38.5097 10.2651 38.0098 10.7621 37.3959 10.7621Z"
        fill={color || '#E37639'}
      />
      <path
        d="M37.3959 15.4154H7.29865C6.68473 15.4154 6.18481 14.9184 6.18481 14.3045C6.18481 13.6906 6.68473 13.1965 7.29865 13.1965H37.3959C38.0098 13.1965 38.5097 13.6935 38.5097 14.3045C38.5097 14.9184 38.0098 15.4154 37.3959 15.4154Z"
        fill={color || '#E37639'}
      />
      <path
        d="M30.8834 20.0668H7.29964C6.68571 20.0668 6.18872 19.5698 6.18872 18.9559C6.18872 18.342 6.68863 17.8479 7.29964 17.8479H30.8805C31.4944 17.8479 31.9944 18.3449 31.9944 18.9559C31.9973 19.5698 31.4974 20.0668 30.8834 20.0668Z"
        fill={color || '#E37639'}
      />
      <path
        d="M21.8834 25.0668H7.29963C6.68571 25.0668 6.18872 24.5698 6.18872 23.9559C6.18872 23.342 6.68863 22.8479 7.29963 22.8479H21.8805C22.4944 22.8479 22.9944 23.3449 22.9944 23.9559C22.9973 24.5698 22.4974 25.0668 21.8834 25.0668Z"
        fill={color || '#E37639'}
      />
      <path
        d="M26.8834 30.2993H7.29964C6.68571 30.2993 6.18872 29.8023 6.18872 29.1883C6.18872 28.5744 6.68863 28.0804 7.29964 28.0804H26.8805C27.4944 28.0804 27.9944 28.5773 27.9944 29.1883C27.9973 29.8023 27.4974 30.2993 26.8834 30.2993Z"
        fill={color || '#E37639'}
      />
      <path
        d="M18.8834 35.2993H7.29963C6.68571 35.2993 6.18872 34.8023 6.18872 34.1883C6.18872 33.5744 6.68863 33.0804 7.29963 33.0804H18.8805C19.4944 33.0804 19.9944 33.5773 19.9944 34.1883C19.9973 34.8023 19.4974 35.2993 18.8834 35.2993Z"
        fill={color || '#E37639'}
      />
      <path
        d="M11.8834 40.5435H7.29963C6.68571 40.5435 6.18872 40.0465 6.18872 39.4326C6.18872 38.8186 6.68863 38.3246 7.29963 38.3246H11.8805C12.4944 38.3246 12.9944 38.8216 12.9944 39.4326C12.9973 40.0465 12.4974 40.5435 11.8834 40.5435Z"
        fill={color || '#E37639'}
      />
      <path
        d="M20.6238 40.5435H16.04C15.4261 40.5435 14.9291 40.0465 14.9291 39.4326C14.9291 38.8186 15.429 38.3246 16.04 38.3246H20.6209C21.2348 38.3246 21.7347 38.8216 21.7347 39.4326C21.7376 40.0465 21.2377 40.5435 20.6238 40.5435Z"
        fill={color || '#E37639'}
      />
      <path
        d="M4.95944 48.6572H41.0493C43.5109 48.6572 45.5134 46.6547 45.5105 44.1902V5.12425C45.5105 2.65979 43.508 0.657227 41.0464 0.657227H33.3703C32.7567 0.657227 32.2594 1.1546 32.2594 1.76813C32.2594 2.38167 32.7567 2.87904 33.3703 2.87904H41.0493C42.2889 2.87904 43.2975 3.88763 43.2975 5.12425V44.1902C43.2975 45.4268 42.286 46.4354 41.0493 46.4354H4.95944C3.71991 46.4354 2.71132 45.4268 2.71132 44.1902V5.12425C2.71132 3.88763 3.71991 2.87904 4.95944 2.87904H28.2067C28.8202 2.87904 29.3176 2.38167 29.3176 1.76813C29.3176 1.1546 28.8202 0.657227 28.2067 0.657227H4.95944C2.49206 0.657227 0.489502 2.65979 0.489502 5.12425V44.1902C0.489502 46.6547 2.49206 48.6572 4.95944 48.6572Z"
        fill={color || '#E37639'}
      />
    </svg>
  )
}
