import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Container } from 'components/Container'
import Switch from 'components/Fields/Switch'
import Input from 'components/Fields/Input'
import Button from 'components/Button'

import { colors } from 'constants/inlineColorConfig'

import style from './styles.module.scss'

export const TradeIn = ({
  isActiveTradeIn,
  setIsActiveTradeIn,
  tradeinData,
  setTradeinData,
  setTotalPrice = () => {},
  mode = 'create',
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...(mode === 'edit' && Object.keys(tradeinData).length
        ? { ...tradeinData, tradeIn: true }
        : {}),
    },
  })

  const handleChange = () => {
    if (isActiveTradeIn) {
      setIsActiveTradeIn(false)
      setTradeinData({})

      if (!!Object.keys(tradeinData).length)
        setTotalPrice((prev) => prev + Number(tradeinData.cost.valueOf()))
    } else {
      setIsActiveTradeIn(true)

      if (!!Object.keys(tradeinData).length)
        setTotalPrice((prev) => prev - Number(tradeinData.cost.valueOf()))
    }
  }

  const handleClick = (val) => {
    delete val.tradeIn

    setTradeinData({ ...val })
    setTotalPrice((prev) => prev - Number(val.cost.valueOf()))
  }

  return (
    <div className={style.background}>
      <Container className={style.container}>
        <div className={style.titleWithButton}>
          <h2 className={style.title}>Программа Трейд-ин</h2>
          <div className={style.switch}>
            <Switch
              id="tradeIn"
              name="tradeIn"
              control={control}
              activeColor={colors.brightOrange}
              value={true}
              change={handleChange}
            />
            <div className={style.text}>
              <span>Использовать</span>
              <span>программу</span>
            </div>
          </div>
        </div>
        {isActiveTradeIn && (
          <>
            <form onSubmit={handleSubmit(handleClick)}>
              <div className={style.formCar}>
                <div className={style.rowForm}>
                  <Input
                    label="Модель"
                    name="model"
                    required
                    rules={{ required: 'Это поле обязательно' }}
                    control={control}
                    error={errors['model']}
                  />
                  <Input
                    label="КПП"
                    name="kpp"
                    required
                    rules={{ required: 'Это поле обязательно' }}
                    control={control}
                    error={errors['kpp']}
                  />
                  <Input
                    label="Двигатель"
                    name="engine"
                    required
                    rules={{ required: 'Это поле обязательно' }}
                    control={control}
                    error={errors['engine']}
                  />
                  <Input
                    label="Исполнение/версия"
                    name="version"
                    control={control}
                    required
                    rules={{ required: 'Это поле обязательно' }}
                    error={errors['version']}
                    type="text"
                  />
                </div>
                <div className={style.rowForm}>
                  <Input
                    label="Комплектация"
                    name="complectation"
                    control={control}
                    required
                    rules={{ required: 'Это поле обязательно' }}
                    error={errors['complectation']}
                  />
                  <Input
                    label="Цвет"
                    name="color"
                    control={control}
                    required
                    rules={{ required: 'Это поле обязательно' }}
                    type="text"
                    error={errors['color']}
                  />
                  <Input
                    label="Год выпуска"
                    name="year"
                    control={control}
                    required
                    rules={{ required: 'Это поле обязательно' }}
                    type="number"
                    error={errors['year']}
                  />
                  <Input
                    label="Пробег"
                    name="run"
                    control={control}
                    required
                    rules={{ required: 'Это поле обязательно' }}
                    type="number"
                    error={errors['run']}
                  />
                </div>
                <div className={style.rowForm}>
                  <div className={style.halfRow}>
                    <Input
                      label="VIN"
                      name="vin"
                      control={control}
                      required
                      rules={{
                        required: 'Это поле обязательно',
                        validate: (v) =>
                          v &&
                          v.length === 17 &&
                          String(v).match(/^[A-Za-z0-9]*$/)
                            ? true
                            : 'VIN должен состоять из 17 символов (латинские буквы и цифры)',
                      }}
                      type="text"
                      error={errors['vin']}
                    />
                    <Input
                      label="Оценочная стоимость"
                      name="cost"
                      control={control}
                      required
                      rules={{ required: 'Это поле обязательно' }}
                      error={errors['cost']}
                      type="number"
                    />
                  </div>

                  <Button.Outlined className={style.action} type="submit">
                    {tradeinData ? 'Изменить' : 'Сохранить'}
                  </Button.Outlined>
                </div>
              </div>
            </form>
            {tradeinData && (
              <>
                <div className={style.titleBlock}>Сохраненные данные</div>
                <div className={style.table}>
                  <div className={style.row}>
                    <div className={style.tableTitle}>модель</div>
                    <div className={style.tableItem}>{tradeinData.model}</div>
                  </div>
                  <div className={style.row}>
                    <div className={style.tableTitle}>КПП</div>
                    <div className={style.tableItem}>{tradeinData.kpp}</div>
                  </div>
                  <div className={style.row}>
                    <div className={style.tableTitle}>Двигатель</div>
                    <div className={style.tableItem}>{tradeinData.engine}</div>
                  </div>
                  <div className={style.row}>
                    <div className={style.tableTitle}>Исполнение/версия</div>
                    <div className={style.tableItem}>{tradeinData.version}</div>
                  </div>
                  <div className={style.row}>
                    <div className={style.tableTitle}>Комплектация</div>
                    <div className={style.tableItem}>
                      {tradeinData.complectation}
                    </div>
                  </div>
                  <div className={style.row}>
                    <div className={style.tableTitle}>Цвет</div>
                    <div className={style.tableItem}>{tradeinData.color}</div>
                  </div>
                  <div className={style.row}>
                    <div className={style.tableTitle}>Год выпуска</div>
                    <div className={style.tableItem}>{tradeinData.year}</div>
                  </div>
                  <div className={style.row}>
                    <div className={style.tableTitle}>Пробег</div>
                    <div className={style.tableItem}>{tradeinData.run}</div>
                  </div>
                  <div className={style.row}>
                    <div className={style.tableTitle}>VIN</div>
                    <div className={style.tableItem}>{tradeinData.vin}</div>
                  </div>
                  <div className={style.row}>
                    <div className={style.tableTitle}>Оценочная стоимость</div>
                    <div className={style.tableItem}>{tradeinData.cost}</div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Container>
    </div>
  )
}
