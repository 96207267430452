import { createSelector } from '@reduxjs/toolkit'

export const getCities = (state) => state.cities

export const getCitiesWithId = createSelector(
  getCities,
  (cities) => cities.citiesWithId
)

export const getCitiesLoadingStatus = createSelector(
  getCities,
  (dealers) => dealers.loadingStatus
)
