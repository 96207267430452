export const IconMechanical = ({ width, height, color }) => {
  return (
    <svg
      width={width || 34}
      height={height || 34}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" stroke={color || '#E37639'} />
      <path
        d="M12 10C12 10.5987 12 13.9634 12 17.5M12 25C12 22.7297 12 20.1276 12 17.5M17.434 10V17.5M17.434 25V17.5M12 17.5H17.434M17.434 17.5H23.0471V10"
        stroke={color || '#E37639'}
      />
    </svg>
  )
}
