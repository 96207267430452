export const IconScrollDown = ({ width, height, color }) => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.47058 14.1765L11.7941 22.1177L22.1176 14.1765"
        stroke="white"
        strokeWidth="2"
      />
      <path
        opacity="0.5"
        d="M1.47058 7.82349L11.7941 15.7647L22.1176 7.82349"
        stroke="white"
        strokeWidth="2"
      />
      <path
        opacity="0.2"
        d="M1.47046 1.47058L11.794 9.41176L22.1175 1.47058"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  )
}
