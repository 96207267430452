import { useForm } from 'react-hook-form'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'

import { Container } from 'components/Container'
import Input from 'components/Fields/Input'
import Button from 'components/Button'
import Switcher from 'components/Switcher'
import SelectWithInput from 'components/Fields/SelectWithInput'

import { getFormattedPrice } from 'utils/getPrice'
import { CREDIT_TYPES } from 'constants/creditTypes'

import { banksList } from './constants'

import style from './styles.module.scss'

export const getMonthPrice = ({
  price,
  percent,
  period,
  first_payment = 0,
  residual = 0,
}) => {
  const amount = price - first_payment
  const stavka = percent / 100

  if (residual > 0) {
    const result = Math.floor(
      ((stavka / 12) *
        (amount * Math.pow(1 + stavka / 12, period - 1) -
          residual / (1 + stavka / 12))) /
        (Math.pow(1 + stavka / 12, period - 1) - 1)
    )
    return result ? result : 0
  }

  const result = Math.floor(
    ((stavka / 12) *
      (amount * Math.pow(1 + stavka / 12, period) -
        residual / (1 + stavka / 12))) /
      (Math.pow(1 + stavka / 12, period) - 1)
  )
  return result ? result : 0
}

export const FullCreditProgram = ({
  price,
  setCreditData,
  creditData,
  mode = 'create',
}) => {
  const [creditType, setCreditType] = useState(0)
  const [monthPayment, setMonthPayment] = useState(null)
  const [creditPrice, setCreditPrice] = useState(price)
  const [creditFirstPayment, setCreditFirstPayment] = useState(null)
  const [percentageRate, setPercentageRate] = useState(null)
  const [residual, setResidual] = useState(null)
  const [loanPeriod, setLoanPeriod] = useState(null)
  const [isChangeFirsPayment, setIsChangeFirsPayment] = useState(true)
  const [isCreditError, setIsCreditError] = useState(null)

  const formattedRate = creditData.loan_rate
    ? Number(creditData.loan_rate) < 10
      ? '0' +
        String(Number(creditData.loan_rate).toFixed(2)).replace('.', ',') +
        '%'
      : String(Number(creditData.loan_rate).toFixed(2)).replace('.', ',') + '%'
    : null

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      loan_amount: creditPrice,
      ...(mode === 'edit'
        ? {
            bank_name: 'Авто Финас Банк',
            first_payment_value: creditData.first_payment,
            loan_rate: formattedRate,
            loan_period: creditData.loan_period,
            ...(creditData.residual && { residual: creditData.residual }),
          }
        : {}),
    },
  })

  // const getBankList = () => {
  //   let check = banksList.some(({ label }) => label === creditData.bank_name)
  //
  //   if (!check) {
  //     return [
  //       { value: creditData.bank_name, label: creditData.bank_name },
  //       ...banksList,
  //     ]
  //   } else {
  //     return banksList
  //   }
  // }
  //
  // const replacedBankList = getBankList()

  useEffect(() => {
    if (creditData.residual) {
      setCreditType(CREDIT_TYPES.residualPayment)
    }

    if (creditData.month_payment) {
      setMonthPayment(creditData.month_payment)
      setCreditFirstPayment(creditData.first_payment)
      setPercentageRate(formattedRate.replace('%', ''))
      Number(creditData.residual) > 0 &&
        setResidual(Number(creditData.residual))
      setLoanPeriod(creditData.loan_period)

      setCreditData(creditData)
    }
  }, [])

  const resetResults = () => {
    setMonthPayment(null)
    setCreditFirstPayment(null)
    setPercentageRate(null)
    setResidual(null)
    setLoanPeriod(null)
  }

  const handleCreditType = useCallback((id) => {
    setCreditType(id)
    resetResults()
  }, [])

  const watch_bank_name = watch('bank_name')
  const watch_first_payment_value = watch('first_payment_value')
  const watch_first_payment_percent = watch('first_payment_percent')
  const watch_loan_period = watch('loan_period')
  const watch_loan_rate = watch('loan_rate')
  const watch_loan_residual_payment = watch('residual')

  const onSubmit = () => {
    const loanRateFormated = String(watch_loan_rate).replace(',', '.')

    const totalFirstPayment = watch_first_payment_value
      ? Number(watch_first_payment_value)
      : 0

    const loanRateNumber = Number(
      loanRateFormated.substr(0, loanRateFormated.length - 1)
    )

    let currentMonthPayment = 0

    switch (creditType) {
      case CREDIT_TYPES.default:
        currentMonthPayment = getMonthPrice({
          price: creditPrice,
          percent: loanRateNumber,
          period: Number(watch_loan_period),
          first_payment: Number(totalFirstPayment) || 0,
          residual: 0,
        })
        break
      case CREDIT_TYPES.residualPayment:
        currentMonthPayment = getMonthPrice({
          price: creditPrice,
          percent: loanRateNumber,
          period: Number(watch_loan_period),
          residual: Number(watch_loan_residual_payment),
          first_payment: Number(totalFirstPayment) || 0,
        })

        break
      default:
    }

    setMonthPayment(currentMonthPayment)

    setCreditFirstPayment(totalFirstPayment)
    setPercentageRate(loanRateNumber)
    Number(watch_loan_residual_payment) > 0 &&
      setResidual(Number(watch_loan_residual_payment))
    setLoanPeriod(watch_loan_period)

    const creditData = {
      bank_name: watch_bank_name,
      loan_amount: creditPrice,
      month_payment: currentMonthPayment,
      first_payment: totalFirstPayment,
      loan_payment: creditPrice - totalFirstPayment,
      loan_rate: loanRateNumber,
      loan_period: watch_loan_period,
    }

    if (!isNaN(Number(watch_loan_residual_payment))) {
      creditData.residual = Number(watch_loan_residual_payment)
    }

    setCreditData(creditData)
    setIsCreditError(false)
  }

  useEffect(() => {
    if (isChangeFirsPayment) {
      setIsChangeFirsPayment(false)
      setValue(
        'first_payment_percent',
        Math.round(watch_first_payment_value / (price / 100))
      )
    } else {
      setIsChangeFirsPayment(true)
    }
  }, [watch_first_payment_value])

  useEffect(() => {
    if (useRef.current) {
      if (isChangeFirsPayment) {
        setIsChangeFirsPayment(false)
        setValue(
          'first_payment_value',
          Math.round((price / 100) * watch_first_payment_percent)
        )
      } else {
        setIsChangeFirsPayment(true)
      }
    } else {
      useRef.current = true
    }
  }, [watch_first_payment_percent])

  useEffect(() => {
    setCreditPrice(price)
    setValue('loan_amount', price)
  }, [price])

  useEffect(() => {
    if (
      !!watch_first_payment_value &&
      !!watch_first_payment_percent &&
      !!watch_loan_period &&
      !!watch_loan_rate
    ) {
      onSubmit()
    }
  }, [creditPrice])

  useEffect(() => {
    setValue('bank_name', 'Авто Финас Банк')
  }, [])

  return (
    <div className={style.background}>
      <Container className={style.container}>
        <div className={style.titleWithButton}>
          <h2 className={style.title}>Кредитная программа</h2>
        </div>
        <div className={style.formBlock}>
          <Switcher
            selections={['Классический кредит', 'Кредит с остаточным платежом']}
            value={creditType}
            setter={handleCreditType}
            style={{ margin: '10px 0' }}
            gray
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={style.form}>
            <SelectWithInput
              label="Наименование банка"
              name="bank_name"
              required
              control={control}
              options={banksList}
              error={errors['bank_name']}
              className={style.select}
              isDisabled
            />
            <div className={clsx(style.row)}>
              <Input
                label="Итоговая стоимость"
                name="loan_amount"
                control={control}
                type="number"
                error={errors['loan_amount']}
                disabled={true}
              />
              <Input
                label="Первый взнос руб"
                name="first_payment_value"
                required
                rules={{ required: 'Это поле обязательно' }}
                onChangeDecorator={(e) =>
                  e.target.value > price ? price : e.target.value
                }
                control={control}
                type="number"
                error={errors['first_payment_value']}
                className={style.input}
              />
              <Input
                label="Первый взнос %"
                name="first_payment_percent"
                required
                rules={{
                  required: 'Это поле обязательно',
                  validate: (v) =>
                    v && Number(v) < 100 ? true : 'Некорректное значение',
                }}
                onChangeDecorator={(e) =>
                  e.target.value > 100 ? 100 : e.target.value
                }
                control={control}
                type="number"
                error={errors['first_payment_percent']}
                className={style.input}
              />
              <Input
                label="Процентная ставка"
                name="loan_rate"
                mask="99,99%"
                required
                rules={{
                  required: 'Это поле обязательно',
                  pattern: {
                    value: /([0-9]{2}),([0-9]{2})%/,
                    message: 'Необходимо заполнить поле',
                  },
                }}
                control={control}
                error={errors['loan_rate']}
              />
              <Input
                label="Срок кредита, мес"
                name="loan_period"
                required
                rules={{ required: 'Это поле обязательно' }}
                control={control}
                type="number"
                error={errors['loan_period']}
              />
              {creditType === CREDIT_TYPES.residualPayment && (
                <Input
                  label="Остаточный платеж"
                  name="residual"
                  required
                  rules={{ required: 'Это поле обязательно' }}
                  control={control}
                  type="number"
                  error={errors['loan_payment']}
                />
              )}
            </div>
          </div>

          <div className={style.creditInfo}>
            <div className={style.creditOption}>
              <span className={style.title}>Итоговая стоимость автомобиля</span>
              {creditPrice && (
                <span className={style.infoMonth}>
                  {getFormattedPrice(creditPrice)} ₽
                </span>
              )}
            </div>

            <div className={style.creditOption}>
              <span className={style.title}>Ежемесячный платеж</span>
              {monthPayment && (
                <span className={style.infoMonth}>
                  {getFormattedPrice(monthPayment)} ₽
                </span>
              )}
            </div>

            {creditType === CREDIT_TYPES.residualPayment && (
              <div className={style.creditOption}>
                <span className={style.title}>Остаточный платеж</span>
                {residual && (
                  <span className={style.info}>
                    {getFormattedPrice(residual)} ₽
                  </span>
                )}
              </div>
            )}

            <div className={style.creditOption}>
              <span className={style.title}>Первый взнос</span>
              {creditFirstPayment && (
                <span className={style.info}>
                  {getFormattedPrice(creditFirstPayment)} ₽
                </span>
              )}
            </div>

            <div className={style.creditOption}>
              <span className={style.title}>Процентная ставка</span>
              {percentageRate && (
                <span className={style.info}>{percentageRate}%*</span>
              )}
            </div>

            <div className={style.creditOption}>
              <span className={style.title}>Срок кредита</span>
              {loanPeriod && (
                <span className={style.info}>{loanPeriod} мес</span>
              )}
            </div>
          </div>

          <div className={style.action}>
            {isCreditError && <p>необходимо заполнить данные по кредиту</p>}
            <Button.Outlined type="submit">Рассчитать</Button.Outlined>
          </div>
        </form>
      </Container>
    </div>
  )
}
