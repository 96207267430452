import { Font, StyleSheet } from '@react-pdf/renderer'

import f_300 from 'assets/fonts/PragmaticaLightC.woff'
import f_400 from 'assets/fonts/LADAPragmatica.woff'
import f_700 from 'assets/fonts/LADAPragmatica-Bold.otf'
import rouble from 'assets/fonts/rouble.otf'

const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size)
  const chunks = new Array(numChunks)

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size)
  }

  return chunks
}

Font.registerHyphenationCallback((word) => {
  if (word.length > 20) {
    return chunkSubstr(word, 10)
  } else {
    return [word]
  }
})

Font.register({
  family: 'LADAPragmatica',
  src: f_300,
})

Font.register({
  family: 'LADAPragmaticaNormal',
  src: f_400,
})

Font.register({
  family: 'LADAPragmaticaBold',
  src: f_700,
})

Font.register({
  family: 'rouble',
  src: rouble,
})

const styles = StyleSheet.create({
  page: {
    position: 'relative',
    fontFamily: 'LADAPragmatica',
    height: '100vh',
    paddingTop: 20,
    paddingBottom: 20,
    // flexDirection: 'column',
    // justifyContent: 'space-between',
  },
  rouble: {
    display: 'flex',
    marginLeft: 10,
    fontFamily: 'rouble',
  },
  section_1Wrapper: {
    marginBottom: 5,
    paddingTop: 25,
    paddingBottom: 5,
    paddingHorizontal: 25,
  },
  header: {
    position: 'relative',
    height: 115,
    zIndex: 10,
  },
  headerBg: {
    width: '100%',
    position: 'absolute',
    height: 115,
  },
  headerGradient: {
    backgroundColor: '#000',
    position: 'absolute',
    opacity: 0.3,
    left: 0,
    right: 0,
    height: 115,
  },
  headerContent: {
    width: '100%',
    padding: 25,
  },
  headerImg: {
    display: 'inline-block',
    width: 90,
    marginBottom: 15,
  },
  headerTitle: {
    width: '70%',
    fontSize: 18,
    textTransform: 'uppercase',
    color: 'white',
    fontFamily: 'LADAPragmaticaNormal',
  },
  headerDate: {
    fontSize: 9,
    fontFamily: 'LADAPragmaticaBold',
    color: 'white',
  },
  headerCar: {
    width: 250,
    position: 'absolute',
    top: '-100',
    right: 0,
    zIndex: 1000,
  },
  section_1: {
    position: 'relative',
    backgroundColor: '#F4F4F4',
    zIndex: 0,
    padding: '15px 25px',
  },
  complInfoBlock: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  complImage: {
    width: 30,
    marginRight: 10,
  },
  complValue: {
    maxWidth: '120px',
    color: '#4C5865',
    fontSize: 9,
  },
  complInfoItem: {
    width: '33%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  complTitle: {
    width: '50%',
    fontFamily: 'LADAPragmaticaBold',
    color: '#4C5865',
    fontSize: '9px',
    marginRight: '20px',
    textTransform: 'uppercase',
  },
  complName: {
    width: '60%',
    fontFamily: 'LADAPragmaticaNormal',
    color: '#4C5865',
    fontSize: '16px',
    textTransform: 'uppercase',
  },
  modelTitle: {
    display: 'flex',
    width: '55%',
    fontSize: 25,
    textTransform: 'uppercase',
    fontFamily: 'LADAPragmaticaNormal',
    marginBottom: 20,
    color: '#4C5865',
  },
  title: {
    fontSize: 35,
    textTransform: 'uppercase',
    fontFamily: 'LADAPragmaticaNormal',
    marginBottom: 10,
    color: 'white',
  },
  welcomeSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px 25px',
  },
  welcomeLeft: {
    width: '33.3%',
    marginRight: 30,
    color: '#4C5865',
    fontFamily: 'LADAPragmaticaNormal',
    fontSize: 21,
    fontWeight: 400,
  },
  welcomeRight: {
    width: '66%',
    fontFamily: 'LADAPragmaticaNormal',
    fontSize: 9,
    fontWeight: 400,
  },
  welcomeElem: {
    fontFamily: 'LADAPragmaticaNormal',
    marginBottom: 10,
  },
  welcomeElemBold: {
    display: 'flex',
    flexDirection: 'row',
    textOverflow: 'ellipsis',
    flexWrap: 'wrap',
    fontFamily: 'LADAPragmaticaBold',
    fontWeight: 700,
  },
  managingSection: {
    padding: '20px 25px',
  },
  managingWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  managingItem: {
    width: '33.3%',
  },
  managingTitle: {
    width: '100%',
    color: '#4C5865',
    fontSize: 9,
    fontFamily: 'LADAPragmaticaBold',
    textTransform: 'uppercase',
    paddingBottom: 3,
  },
  managingTitleBorder: {
    width: '50%',
    borderBottom: '2px solid #E37639',
  },
  managingTitleBorderTop: {
    width: '50%',
    paddingTop: 11,
    borderBottom: '2px solid #E37639',
  },
  managingBox: {
    border: '1px solid #D2DEE3',
    padding: 10,
  },
  managingBoxText: {
    fontSize: 9,
    color: '#4C5865',
    marginBottom: 5,
  },
  managingBoxTitle: {
    fontSize: 9,
    fontFamily: 'LADAPragmaticaBold',
    color: '#4C5865',
  },
  optionsSection: {
    padding: '0 25px',
  },
  optionsTite: {
    width: '30%',
    fontSize: 9,
    fontFamily: 'LADAPragmaticaBold',
    color: '#4C5865',
    paddingBottom: 3,
    borderBottom: '2px solid #E37639',
    textTransform: 'uppercase',
  },
  optionsBox: {
    border: '1px solid #D2DEE3',
    padding: '10px 10px 0 10px',
  },
  optionsBoxItem: {
    marginBottom: 10,
  },
  optionsItemTitle: {
    fontSize: 7,
    fontFamily: 'LADAPragmaticaBold',
    color: '#4C5865',
    marginBottom: 5,
    textTransform: 'uppercase',
  },
  optionsItems: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  optionsItem: {
    width: '32.3%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 3,
    marginRight: '5px',
  },
  optionsItemDot: {
    position: 'relative',
    top: 2,
    backgroundColor: '#E37639',
    width: 2,
    height: 2,
    borderRadius: '50%',
    marginRight: 8,
  },
  optionsItemText: {
    width: 'calc(100% - 25px)',
    fontSize: 8,
    color: '#4C5865',
  },

  addsSection: {
    paddingHorizontal: 25,
    paddingBottom: 20,
  },
  creditSection: {
    paddingHorizontal: 25,
  },
  addsBlock: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  addsTitles: {
    flexDirection: 'row',
  },
  addsTitleWrapper: {
    width: '50%',
  },
  addsTitle: {
    width: '70%',
    fontFamily: 'LADAPragmaticaBold',
    fontSize: 8,
    textTransform: 'uppercase',
    color: '#4C5865',
    // borderBottom: '3px solid #E37639',
  },
  addsTitle1: {
    width: '70%',
    fontFamily: 'LADAPragmaticaBold',
    fontSize: 8,
    textTransform: 'uppercase',
    paddingBottom: 5,
    color: '#4C5865',
    // borderBottom: '3px solid #E37639',
  },
  addsItems: {
    width: '50%',
    border: '1px solid #D2DEE3',
    padding: 10,
  },
  addsItems2: {
    width: '50%',
    borderTop: '1px solid #D2DEE3',
    borderRight: '1px solid #D2DEE3',
    borderBottom: '1px solid #D2DEE3',
    padding: 10,
  },
  addsItemsGray: {
    width: '50%',
    backgroundColor: '#F4F4F4',
    border: '1px solid #4D5964',
    padding: 10,
  },
  addsItems2Gray: {
    width: '50%',
    backgroundColor: '#F4F4F4',
    borderTop: '1px solid #4D5964',
    borderRight: '1px solid #4D5964',
    borderBottom: '1px solid #4D5964',
    padding: 10,
  },
  addsItemstextBlock: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  addsText: {
    color: '#66727D',
    fontSize: 9,
    marginBottom: 5,
  },
  pgText: {
    width: '100%',
    color: '#66727D',
    fontSize: 9,
    marginBottom: 5,
    paddingLeft: 20,
  },
  addsTextGray: {
    color: '#4C5865',
    fontSize: 9,
    marginBottom: 5,
    textAlign: 'right',
  },
  addsTextGrayName: {
    color: '#4C5865',
    fontSize: 9,
    marginBottom: 5,
    paddingRight: 45,
  },
  addsTextGrayAcc: {
    maxWidth: '15%',
    color: '#4C5865',
    fontSize: 9,
    marginBottom: 5,
    textAlign: 'right',
  },
  addsTextGrayNameAcc: {
    maxWidth: '85%',
    color: '#4C5865',
    fontSize: 9,
    marginBottom: 5,
    paddingRight: 45,
  },
  sectionTradein: {
    padding: '0 25px 20px 25px',
  },
  tradeinTitle: {
    width: 136,
    fontSize: 9,
    fontFamily: 'LADAPragmaticaBold',
    color: '#4C5865',
    paddingBottom: 3,
    borderBottom: '2px solid #E37639',
    textTransform: 'uppercase',
  },
  tradeinBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: '1px solid #D2DEE3',
    padding: 10,
  },
  tradeinText: {
    fontSize: 9,
    color: '#4C5865',
  },
  totalPrice: {
    backgroundColor: '#F4F4F4',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRight: '1px solid #4C5865',
    borderBottom: '1px solid #4C5865',
    borderLeft: '1px solid #4C5865',
    padding: 10,
    paddingBottom: 5,
  },
  totalPriceText: {
    maxWidth: 247,
    color: '#4C5865',
    fontSize: 9,
    fontFamily: 'LADAPragmaticaBold',
    letterSpacing: 0.42,
    textTransform: 'uppercase',
  },
  totalPriceValue: {
    color: '#4C5865',
    fontSize: 30,
    fontFamily: 'LADAPragmaticaBold',
  },
  additionally: {
    backgroundColor: '#F4F4F4',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRight: '1px solid #4C5865',
    borderBottom: '1px solid #4C5865',
    borderLeft: '1px solid #4C5865',
    padding: 10,
    paddingBottom: 5,
  },
  additionallyText: {
    maxWidth: 247,
    color: '#4C5865',
    fontSize: 9,
    fontFamily: 'LADAPragmaticaNormal',
    letterSpacing: 0.42,
  },
  creditTitle: {
    fontFamily: 'LADAPragmaticaBold',
    color: '#66727D',
    fontSize: 9,
    marginBottom: 8,
    textTransform: 'uppercase',
  },
  creditLine: {
    width: '36%',
    borderBottom: '2px solid #E37639',
  },
  creditTable: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 10,
    paddingBottom: 0,
    border: '1px solid #C3C4C4',
    marginBottom: 20,
  },
  creditImage: {
    position: 'absolute',
    left: 5,
    top: 0,
    width: 145,
  },
  creditName: {
    width: '20%',
    marginRight: 15,

    flexDirection: 'row',
    alignItems: 'center',
    color: '#4C5865',
    fontFamily: 'LADAPragmaticaBold',
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: 0.42,
    textTransform: 'uppercase',
  },
  creditItem1: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: 10,
    paddingBottom: 10,
  },
  creditItem2: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  creditItemTitleBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: '10px',
    paddingBottom: 10,
  },
  creditItemTitle: {
    color: '#4C5865',
    fontSize: 9,
    textAlign: 'right',
  },
  creditItemText: {
    fontFamily: 'LADAPragmaticaBold',
    color: '#4C5865',
    fontSize: 20,
    marginRight: 10,
  },
  creditTextSection: {
    padding: '0 25px 0 25px',
  },
  creditTextDisclamer: {
    padding: '25px 0 25px 0',
  },
  creditTextTitle: {
    fontFamily: 'LADAPragmaticaBold',
    color: '#4C5865',
    fontSize: 8,
  },
  creditText: {
    color: '#4C5865',
    fontSize: 8,
    marginBottom: 10,
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    padding: 25,
  },
  footerLeft: {
    width: '40%',
  },
  footerLeftTitle: {
    color: '#4C5865',
    fontSize: 15,
  },
  footerLeftText: {
    color: '#4C5865',
    fontFamily: 'LADAPragmaticaBold',
    fontSize: 10,
    paddingRight: 15,
  },
  footerRight: {
    width: '60%',
  },
  footerRightText: {
    color: '#4C5865',
    fontSize: 10,
    marginBottom: 10,
  },
  footerRightBox: {
    border: '1px solid #D2DEE3',
    padding: '10px 10px 45px 10px',
  },
  footerRightBoxText: {
    color: '#4C5865',
    fontSize: 10,
  },
  pageNumbers: {
    position: 'absolute',
    bottom: 5,
    left: '50%',
    fontSize: 10,
  },
})

export default styles
