import { createAsyncThunk } from '@reduxjs/toolkit'

import { getCars } from 'api/cars'

export const loadAllCars = createAsyncThunk(
  'cars/allCars',
  async (_, thunkAPI) => {
    const res = await getCars()
    if (res.data.error) {
      return { items: [], total: res.data.length }
    }
    return { items: res.data, total: res.data.length }
  }
)
