export const IconArrowRight = ({ width, height, color }) => {
  return (
    <svg
      width={width || 9}
      height={height || 9}
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.799805 4.5L7.7998 4.5M7.7998 4.5L4.2998 1M7.7998 4.5L4.2998 8"
        stroke={color || '#E37639'}
      />
    </svg>
  )
}
