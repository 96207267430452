// export function deleteCookie(name) {
//   let domain = location.hostname,
//     path = '/' // root path
//
//   document.cookie = [
//     name,
//     '=',
//     '; expires=' + new Date(0).toUTCString(),
//     '; path=' + path,
//     '; domain=' + domain,
//   ].join('')
// }

export function getCookie(name, cookie) {
  const currentCookie = typeof window !== 'undefined' ? document.cookie : cookie
  let matches =
    currentCookie &&
    currentCookie.match(
      new RegExp(
        '(?:^|; )' +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
      )
    )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export function setCookie(name, value, days) {
  let expires = ''
  if (days) {
    let date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}
