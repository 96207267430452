import styles from './styles.module.scss'
import Switch from 'components/Fields/Switch'
import Input from 'components/Fields/Input'
import Button from 'components/Button/index'
import { useState } from 'react'
import { getPrice } from 'utils/getPrice'
import clsx from 'clsx'

export const ItemBrand = ({
  control,
  watch,
  label,
  priceBenefit,
  gosBenefit,
  brand_action_id,
  id,
  prefix,
  addDiscount,
  deleteDiscount,
  is_dealer,
  price,
  disabled,
  toggleSelectBrandDiscount,
  isInputsActive,
  access,
}) => {
  const watchSwitch = watch(prefix + '_switch')
  const [isActive, setIsActive] = useState(watchSwitch)
  const [disable, setDisable] = useState(false)

  const formattedBenefit =
    gosBenefit !== null
      ? getPrice(gosBenefit)
      : prefix === 'gos'
      ? priceBenefit + ' %'
      : getPrice(priceBenefit)

  const handleChange = async (e) => {
    setDisable(true)

    if (e) {
      try {
        let val = {
          brand_action_id: brand_action_id,
          discount: prefix.includes('gos')
            ? (price / 100) * priceBenefit
            : priceBenefit,
          is_dealer: is_dealer,
          name: label,
          prefix: prefix,
        }
        await addDiscount(val).then((res) => {
          setDisable(false)
          toggleSelectBrandDiscount(brand_action_id)
          setIsActive((prev) => !prev)
        })
      } catch (e) {
        console.log(e)
      }
    } else {
      try {
        await deleteDiscount({ id: id }).then((res) => {
          setDisable(false)
          toggleSelectBrandDiscount(brand_action_id)
          setIsActive((prev) => !prev)
        })
      } catch (e) {
        console.log(e)
      }
    }
  }

  const disableSwitcher = disabled || disable || !isInputsActive || !access

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <Switch
          id={brand_action_id}
          name={prefix + '_switch'}
          control={control}
          change={handleChange}
          disabled={disableSwitcher}
        />
        <p>{label}</p>
      </div>

      <div className={styles.right}>
        <span
          className={clsx(styles.benefit, {
            [styles.active]: (isActive && !disabled) || !disable,
          })}
        >
          {formattedBenefit}
        </span>
      </div>
    </div>
  )
}
