const scrollToElement = (scrollLayer, destination, duration) => {
  if (duration <= 0) {
    return
  }
  const difference = destination - scrollLayer.scrollTop
  const perTick = (difference / duration) * 10

  setTimeout(() => {
    scrollLayer.scrollTop = scrollLayer.scrollTop + perTick
    if (scrollLayer.scrollTop === destination) {
      return
    }
    scrollToElement(scrollLayer, destination, duration - 10)
  }, 10)
}

export const scrollTo = (element, scrollLayer) => {
  if (/iPad|iPhone|iPod|Safari/.test(navigator.userAgent)) {
    if (element.offsetTop !== scrollLayer.scrollTop) {
      scrollToElement(scrollLayer, element.offsetTop, 500)
    }
  } else {
    // just use native function
    element.scrollIntoView({ behavior: 'smooth' })
  }
}
