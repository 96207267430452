import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import SelectDefault from 'components/Fields/Select'
import Table from 'components/Table'
import Input from 'components/Fields/Input'
import { StatsItem } from 'components/StatsItem'
import { Container } from 'components/Container'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { statuses } from 'containers/TableManager/constats'
import { UserInfo } from 'components/UserInfo'

import { loadOrdersThunk } from 'store/orders/thunks'
import {
  getActiveOrders,
  getCompletedOrders,
  getOrdersCount,
} from 'store/orders/selectors'
import { getUserDealerId } from 'store/auth/selectors'
import { loadAuthToken } from 'store/auth/slice'

import { insertUrlParam } from 'utils/insertUrlParam'

import { getDealerData } from 'api/dealers'

import style from './styles.module.scss'
import { getAllCars } from 'store/cars/selectors'
import { useMemo } from 'react'
import { useCallback } from 'react'
import { setCarInShowroom } from 'api/cars'
import { loadAllCars } from 'store/cars/thunks'
import clsx from 'clsx'

export default function TableManager() {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const currentStatus = query.get('status')
  const dispatch = useDispatch()
  const [currentDealer, setCurrentDealer] = useState('')
  const [isFlag, setIsFlag] = useState(true)
  const [isOpenNotifications, setIsOpenNotifications] = useState({
    isOpen: false,
    currentTitle: '',
  })
  const [isSent, setIsSent] = useState(false)

  const { activeOrders, completedOrders, dealerId, cars, ordersAmount } =
    useSelector((state) => ({
      activeOrders: getActiveOrders(state),
      completedOrders: getCompletedOrders(state),
      dealerId: getUserDealerId(state),
      cars: getAllCars(state),
      ordersAmount: getOrdersCount(state),
    }))
  const requireAttention = 0

  const correctStatus = (queryStatus) => {
    if (queryStatus === null) {
      return 0
    } else {
      if (queryStatus >= 0 && queryStatus < 7) {
        return parseInt(queryStatus)
      } else {
        return 0
      }
    }
  }

  const {
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      status: correctStatus(currentStatus),
      searchModels: 0,
    },
  })

  const watchStatus = watch('status')
  const watchSearch = watch('search')
  const watchSearchModels = watch('searchModels') || ''

  const searchFields = [
    'family_slug',
    'id',
    'client_info.name',
    'model_name',
    'model_slug',
    'vin',
  ]
  const searchFieldsCar = [
    ['family_name', 'model_name'],
    'car_id',
    'modification.title',
    'vin',
  ]

  useEffect(() => setValue('search', ''), [currentStatus])

  function delayedRequest() {
    setTimeout(() => {
      setIsFlag((prev) => !prev)
    }, 300000)
  }

  useEffect(() => {
    delayedRequest()
  }, [isFlag])

  useEffect(() => {
    if (isFlag) {
      dispatch(loadOrdersThunk())
      dispatch(loadAllCars())
    }
  }, [isFlag, isSent])

  useEffect(() => {
    insertUrlParam('status', watchStatus)
    setValue('search', '')
  }, [watchStatus])

  useEffect(() => {
    async function fetchDealer() {
      try {
        const res = await getDealerData(dealerId, { showroom: false })
        setCurrentDealer(res.data.dealerName)
      } catch (e) {
        console.warn(e)
      }
    }

    if (dealerId) {
      fetchDealer()
    }
  }, [dealerId])

  useEffect(() => {
    if (dealerId === null) {
      dispatch(loadAuthToken())
    }
  }, [])

  const checkData =
    (Array.isArray(ordersAmount) && ordersAmount?.length > 0) ||
    (Array.isArray(cars) && cars?.length > 0)

  const CarsForPlacing = useMemo(
    () =>
      Array.isArray(cars) && checkData
        ? cars?.filter((el) => {
            return el.showroom !== 'in'
          })
        : [],
    [cars]
  )
  const CarsPlaced = useMemo(
    () =>
      Array.isArray(cars) && checkData
        ? cars?.filter((el) => {
            return el.showroom === 'in'
          })
        : [],
    [cars]
  )
  const CarsWithBadge = useMemo(
    () =>
      Array.isArray(cars) && checkData
        ? cars?.filter((el) => {
            return el.in_bage === true
          })
        : [],
    [cars]
  )
  const CarsWithNoBadge = useMemo(
    () =>
      Array.isArray(cars) && checkData
        ? cars?.filter((el) => {
            return el.in_bage === false
          })
        : [],
    [cars]
  )

  const withBadgeModelsList = useMemo(
    () =>
      CarsWithBadge.reduce(
        (acc, el) => {
          if (
            acc.some((e) => e.label === el.family_name + ' ' + el.model_name)
          ) {
            return [...acc]
          }

          const elem = {
            label: el.family_name + ' ' + el.model_name,
            value: el.family_name + ' ' + el.model_name,
          }

          return [...acc, elem]
        },
        [{ value: 0, label: 'Все модели' }]
      ),
    [CarsWithBadge]
  )

  const withNoBadgeModelsList = useMemo(
    () =>
      CarsWithNoBadge.reduce(
        (acc, el) => {
          if (
            acc.some((e) => e.label === el.family_name + ' ' + el.model_name)
          ) {
            return [...acc]
          }

          const elem = {
            label: el.family_name + ' ' + el.model_name,
            value: el.family_name + ' ' + el.model_name,
          }

          return [...acc, elem]
        },
        [{ value: 0, label: 'Все модели' }]
      ),
    [CarsWithNoBadge]
  )

  const deleteCarFromShowroom = useCallback(async function (carId) {
    try {
      await setCarInShowroom(carId, { showroom: false })
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isTakeOff' })
      setIsSent((prev) => !prev)
    } catch {
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isError' })
    }
  }, [])

  const addCarToShowroom = useCallback(async function (carId) {
    try {
      await setCarInShowroom(carId, { showroom: true })
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isPosted' })
      setIsSent((prev) => !prev)
    } catch {
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isError' })
    }
  }, [])

  return (
    <>
      <>
        <div className={style.background}>
          <Container className={style.container}>
            <h2 className={style.title} id="request">
              Реестр автомобилей
            </h2>
            <div className={style.userInfo}>
              <div className={style.selectType}>
                <SelectDefault
                  name="status"
                  control={control}
                  orders
                  bold
                  hiddenLabel
                  isSearchable={false}
                  options={statuses}
                  bage
                />
              </div>
              <UserInfo currentDealer={currentDealer} />
            </div>
            <div className={style.contentInfo}>
              <div className={style.textInfo}>
                {watchStatus == 0 && (
                  <StatsItem
                    amount={activeOrders?.length || 0}
                    text="Всего заказов"
                  />
                )}
                {watchStatus == 0 && (
                  <StatsItem
                    bright
                    amount={requireAttention}
                    text="Требуют внимания"
                  />
                )}
                {watchStatus == 1 && (
                  <StatsItem
                    amount={completedOrders?.length || 0}
                    text="Всего заказов"
                  />
                )}
                {watchStatus === 2 && (
                  <StatsItem amount={cars.length} text="Всего автомобилей" />
                )}
                {watchStatus === 2 && (
                  <StatsItem
                    amount={CarsPlaced.length}
                    text="Всего размещено в онлайн шоуруме"
                  />
                )}

                {watchStatus === 3 && (
                  <StatsItem
                    amount={CarsPlaced.length}
                    text="Всего размещено"
                  />
                )}
              </div>
              <div
                className={clsx(style.actionsInfo, {
                  [style.actionsInfoSpaceBetween]: watchStatus === 4,
                })}
              >
                {(watchStatus == 5 || watchStatus == 6) && (
                  <Button.Outlined to="/analytics">
                    <Icon.Analytics />
                    Аналитика
                  </Button.Outlined>
                )}
                {watchStatus === 4 && (
                  <SelectDefault
                    name="searchModels"
                    control={control}
                    orders
                    bold
                    hiddenLabel
                    isSearchable={false}
                    options={withBadgeModelsList}
                    className={style.searchModelField}
                  />
                )}

                <div className={style.search}>
                  <Input
                    label="Что найти?"
                    name="search"
                    control={control}
                    error={errors['search']}
                  />
                </div>
              </div>
            </div>

            {watchStatus == 0 && (
              <Table.ActiveOrdersManager
                data={activeOrders}
                watch={watch}
                searchFields={searchFields}
              />
            )}
            {watchStatus == 1 && (
              <Table.CompletedOrdersManager
                data={completedOrders}
                watch={watch}
                searchFields={searchFields}
              />
            )}
            {watchStatus === 2 && (
              <Table.CarsForPlacingChief
                data={CarsForPlacing}
                addCar={addCarToShowroom}
                watchSearch={watchSearch}
                searchFieldsCar={searchFieldsCar}
                watchStatus={watchStatus}
              />
            )}
            {watchStatus === 3 && (
              <Table.CarsPlacedChief
                data={CarsPlaced}
                deleteCar={deleteCarFromShowroom}
                watchSearch={watchSearch}
                searchFieldsCar={searchFieldsCar}
                watchStatus={watchStatus}
              />
            )}
            {watchStatus === 4 && (
              <Table.CarsForBadgeChief
                data={CarsWithBadge}
                noBadgeCars={CarsWithNoBadge}
                deleteCar={deleteCarFromShowroom}
                watchSearch={watchSearch}
                searchFieldsCar={searchFieldsCar}
                watchStatus={watchStatus}
                watchSearchModels={watchSearchModels}
                withNoBadgeModelsList={withNoBadgeModelsList}
              />
            )}
            {watchStatus === 5 && (
              <Table.PersonalOffer watchSearch={watchSearch} />
            )}
            {watchStatus === 6 && (
              <Table.CoporateOffer watchSearch={watchSearch} />
            )}
          </Container>
        </div>
      </>
    </>
  )
}
