export const IconTradeIn = () => {
  return (
    <svg
      width="31"
      height="25"
      viewBox="0 0 31 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2262 6.74815C22.4597 7.02087 22.87 7.0527 23.1427 6.81925C23.4154 6.58581 23.4472 6.17548 23.2138 5.90277L22.2262 6.74815ZM8.77379 18.2518C8.54035 17.9791 8.13002 17.9473 7.85731 18.1807C7.58459 18.4142 7.55276 18.8245 7.78621 19.0972L8.77379 18.2518ZM6.20832 16.1225C6.53001 16.2818 6.91995 16.1502 7.07928 15.8285L9.6757 10.5862C9.83503 10.2646 9.70341 9.87461 9.38172 9.71529C9.06003 9.55596 8.67008 9.68758 8.51075 10.0093L6.20283 14.669L1.54306 12.3611C1.22137 12.2018 0.831423 12.3334 0.672094 12.6551C0.512765 12.9768 0.644384 13.3667 0.966074 13.5261L6.20832 16.1225ZM24.7917 8.87753C24.47 8.7182 24.08 8.84982 23.9207 9.17151L21.3243 14.4138C21.165 14.7354 21.2966 15.1254 21.6183 15.2847C21.94 15.444 22.3299 15.3124 22.4892 14.9907L24.7972 10.331L29.4569 12.6389C29.7786 12.7982 30.1686 12.6666 30.3279 12.3449C30.4872 12.0232 30.3556 11.6333 30.0339 11.4739L24.7917 8.87753ZM24.35 12.5C24.35 17.3877 20.3877 21.35 15.5 21.35V22.65C21.1057 22.65 25.65 18.1057 25.65 12.5H24.35ZM6.65 12.5C6.65 7.61228 10.6123 3.65 15.5 3.65V2.35C9.89431 2.35 5.35 6.89431 5.35 12.5H6.65ZM15.5 3.65C18.1916 3.65 20.602 4.85078 22.2262 6.74815L23.2138 5.90277C21.3532 3.72918 18.5873 2.35 15.5 2.35V3.65ZM15.5 21.35C12.8084 21.35 10.398 20.1492 8.77379 18.2518L7.78621 19.0972C9.64681 21.2708 12.4127 22.65 15.5 22.65V21.35ZM7.11267 15.3321C6.81282 14.4438 6.65 13.4916 6.65 12.5H5.35C5.35 13.6348 5.53647 14.7273 5.88094 15.7479L7.11267 15.3321ZM23.8873 9.66788C24.1872 10.5562 24.35 11.5084 24.35 12.5H25.65C25.65 11.3652 25.4635 10.2727 25.1191 9.25212L23.8873 9.66788Z"
        fill="white"
      />
    </svg>
  )
}
