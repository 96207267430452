import clsx from 'clsx'

import { useNavigate } from 'react-router-dom'

import Button from 'components/Button'

import DirectSale from 'containers/Analytics/Reports/DirectSale'
import AllDealers from 'containers/Analytics/Reports/AllDealers'
import Dealer from 'containers/Analytics/Reports/Dealer'

import { ROLE } from 'constants/role'

import { useViewport } from 'hooks/useViewport'

import style from './styles.module.scss'

export default function Reports({ currentDealer, role }) {
  const navigate = useNavigate()
  const { isMobile } = useViewport()

  function goBack() {
    if (role === ROLE.admin || role === ROLE.root) {
      navigate('/supervisor', { replace: true })
    } else if (role === ROLE.chief || role === ROLE.chief2) {
      navigate('/chief')
    } else if (role === ROLE.manager) {
      navigate('/manager')
    }
  }

  return (
    <div className={style.container}>
      <h2 className={style.title}>Отчеты</h2>
      <div
        className={clsx(style.wrapper, {
          [style.wrapper_width]:
            role === ROLE.chief ||
            role === ROLE.chief2 ||
            role === ROLE.manager ||
            currentDealer === null,
        })}
      >
        {(role === ROLE.admin || role === ROLE.root) && (
          <DirectSale isMobile={isMobile} />
        )}
        {(role === ROLE.admin || role === ROLE.root) && (
          <AllDealers isMobile={isMobile} />
        )}
        {currentDealer !== null && (
          <Dealer currentDealer={currentDealer} isMobile={isMobile} />
        )}
      </div>

      <div
        className={clsx(style.action, {
          [style.position]:
            currentDealer === null ||
            (role !== ROLE.admin && role !== ROLE.root),
        })}
      >
        <Button.Default onClick={() => goBack()} className={style.btn}>
          Перейти к списку
        </Button.Default>
      </div>
    </div>
  )
}
