const MAIN_ROUTE = { path: '/', exact: false }
const AUTH_ROUTE = { path: '/auth', exact: true }
const CHIEF_TABLE_ROUTE = { path: '/chief', exact: true }
const MANAGER_TABLE_ROUTE = { path: '/manager', exact: true }
const SUPERVISOR_TABLE_ROUTE = { path: '/supervisor', exact: true }
const ORDER_ROUTE = { path: '/order/:id', exact: true }
const CAR_ROUTE = { path: '/car/:id', exact: true }
const ANALYTICS_ROUTE = { path: '/analytics', exact: true }
const PDF_ROUTE = { path: '/create-pdf', exact: true }
const CAR_FOR_BADGE = { path: '/carForBadge/:id', exact: true }
const CREATE_PERSONAL_OFFER = { path: '/personalOffer/new', exact: true }
const EDIT_PERSONAL_OFFER = { path: '/personalOffer/edit/:id', exact: true }
const CREATE_CORPORATE_OFFER = { path: '/corporateOffer/new', exact: true }
const EDIT_CORPORATE_OFFER = { path: '/corporateOffer/edit/:id', exact: true }
const PO_VIEWER = { path: '/personalOffer/view/:id', exact: true }
const CO_VIEWER = { path: '/corporateOffer/view/:id', exact: true }

export {
  MAIN_ROUTE,
  AUTH_ROUTE,
  CHIEF_TABLE_ROUTE,
  MANAGER_TABLE_ROUTE,
  SUPERVISOR_TABLE_ROUTE,
  ORDER_ROUTE,
  CAR_ROUTE,
  ANALYTICS_ROUTE,
  PDF_ROUTE,
  CAR_FOR_BADGE,
  CREATE_PERSONAL_OFFER,
  EDIT_PERSONAL_OFFER,
  CREATE_CORPORATE_OFFER,
  EDIT_CORPORATE_OFFER,
  PO_VIEWER,
  CO_VIEWER,
}
