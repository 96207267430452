export const IconCarOfTheYear = ({ width, height, color, className }) => {
  return (
    <svg
      width="197"
      height="44"
      viewBox="0 0 197 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clip-path="url(#clip0)">
        <path
          d="M154.9 10.4951L159.719 6.79957C160.933 5.87609 161.375 5.24679 161.375 4.47119C161.375 3.65784 160.805 3.15912 159.94 3.15912C159.075 3.15912 158.396 3.65784 157.384 4.82202L154.974 2.80829C156.346 1.01639 157.707 0.0378418 160.208 0.0378418C163.027 0.0378418 164.953 1.71805 164.953 4.19431C164.953 6.2631 163.906 7.35334 161.978 8.72048L159.769 10.2733H165.08V13.1916H154.9V10.4951Z"
          fill="white"
        />
        <path
          d="M166.518 6.72554C166.518 3.06622 168.839 0 172.496 0C176.153 0 178.437 3.03003 178.437 6.72554C178.437 10.3833 176.138 13.4511 172.46 13.4511C168.783 13.4511 166.518 10.4195 166.518 6.72554ZM174.85 6.72554C174.85 4.68035 173.876 3.17792 172.46 3.17792C171.044 3.17792 170.111 4.61899 170.111 6.72554C170.111 8.79434 171.066 10.2732 172.501 10.2732C173.936 10.2732 174.85 8.81322 174.85 6.72554Z"
          fill="white"
        />
        <path
          d="M179.616 10.4951L184.435 6.79957C185.649 5.87609 186.091 5.24679 186.091 4.47119C186.091 3.65784 185.521 3.15912 184.656 3.15912C183.792 3.15912 183.11 3.65784 182.1 4.82202L179.69 2.80829C181.07 1.01639 182.431 0.0378418 184.932 0.0378418C187.751 0.0378418 189.678 1.71805 189.678 4.19431C189.678 6.2631 188.63 7.35334 186.702 8.72048L184.493 10.2733H189.809V13.1916H179.619L179.616 10.4951Z"
          fill="white"
        />
        <path
          d="M193.487 3.50985L191.318 4.00857L190.636 1.23812L194.406 0.166748H197.001V13.1915H193.487V3.50985Z"
          fill="white"
        />
        <path
          d="M4.83024 30.2405H7.86246L12.6927 41.725H9.32061L8.49365 39.6893H4.11761L3.3063 41.725H0L4.83024 30.2405ZM7.5868 37.2256L6.32286 33.9816L5.04168 37.2225L7.5868 37.2256Z"
          fill="white"
        />
        <path
          d="M13.7954 30.3208H19.6155C21.0423 30.3208 22.0479 30.6795 22.7119 31.3481C22.9549 31.5932 23.1449 31.8859 23.2702 32.208C23.3955 32.5301 23.4534 32.8747 23.4402 33.2203C23.4402 34.5386 22.6947 35.3205 21.6908 35.7595C23.0675 36.2157 23.943 36.9976 23.943 38.5771C23.943 40.5311 22.3548 41.7236 19.6484 41.7236H13.7954V30.3208ZM18.8371 34.8643C19.7941 34.8643 20.3281 34.5386 20.3281 33.8385C20.3281 33.2203 19.8426 32.8616 18.9013 32.8616H16.8652V34.8643H18.8371ZM19.2913 39.1796C20.2467 39.1796 20.798 38.8052 20.798 38.1051C20.798 37.4869 20.3125 37.0794 19.2584 37.0794H16.8589V39.1734L19.2913 39.1796Z"
          fill="white"
        />
        <path
          d="M27.8352 33.0897H24.4302V30.3208H34.3835V33.0897H30.9786V41.7204H27.8352V33.0897Z"
          fill="white"
        />
        <path
          d="M34.8857 36.0269C34.8857 32.7546 37.5123 30.1006 41.0128 30.1006C44.5133 30.1006 47.1086 32.7232 47.1086 36.0269C47.1086 39.2993 44.482 41.9549 40.9815 41.9549C37.481 41.9549 34.8857 39.3307 34.8857 36.0269ZM43.8994 36.0269C43.8994 34.3829 42.7153 32.9497 40.9815 32.9497C39.2477 32.9497 38.1122 34.3499 38.1122 36.0269C38.1122 37.671 39.2947 39.1042 41.0128 39.1042C42.731 39.1042 43.8994 37.704 43.8994 36.0269Z"
          fill="white"
        />
        <path
          d="M48.7456 30.3208H52.0691L54.7771 34.7337L57.4836 30.3208H60.8071V41.7204H57.6778V35.1742L54.7771 39.6359H54.7114L51.8264 35.2072V41.7204H48.7456V30.3208Z"
          fill="white"
        />
        <path
          d="M62.4282 36.0269C62.4282 32.7546 65.0548 30.1006 68.5569 30.1006C72.0589 30.1006 74.651 32.7232 74.651 36.0269C74.651 39.2993 72.026 41.9549 68.524 41.9549C65.0219 41.9549 62.4282 39.3307 62.4282 36.0269ZM71.4418 36.0269C71.4418 34.3829 70.2578 32.9497 68.524 32.9497C66.7902 32.9497 65.6546 34.3499 65.6546 36.0269C65.6546 37.671 66.8371 39.1042 68.5569 39.1042C70.2766 39.1042 71.4418 37.704 71.4418 36.0269Z"
          fill="white"
        />
        <path
          d="M76.2896 30.3208H85.416V33.0079H79.433V34.3278H81.6257C84.2522 34.3278 86.1803 35.5329 86.1803 37.9903C86.1803 40.3501 84.4574 41.7204 81.787 41.7204H76.2896V30.3208ZM81.5411 39.0664C82.4808 39.0664 83.0322 38.6432 83.0322 37.8613C83.0322 37.1612 82.4808 36.705 81.5568 36.705H79.433V39.0648L81.5411 39.0664Z"
          fill="white"
        />
        <path
          d="M87.6367 30.3208H90.7488V36.7364L95.1421 30.3208H98.0756V41.7204H94.9635V35.3048L90.5703 41.7204H87.6367V30.3208Z"
          fill="white"
        />
        <path
          d="M98.854 41.6229L99.0655 39.1954C99.3065 39.2338 99.5498 39.2559 99.7937 39.2615C100.686 39.2615 101.529 38.8053 101.529 31.9004V30.3271H110.25V41.7267H107.105V33.0897H104.467V33.5302C104.467 40.4823 102.943 41.8825 100.74 41.8825C100.105 41.8793 99.4727 41.792 98.8603 41.6229"
          fill="white"
        />
        <path
          d="M112.261 30.3208H115.406V33.9046H117.129C119.852 33.9046 121.846 35.2072 121.846 37.7795C121.846 40.2384 120.062 41.7204 117.29 41.7204H112.261V30.3208ZM117.042 39.0664C118.081 39.0664 118.696 38.5944 118.696 37.6977C118.696 36.9 118.081 36.3793 117.06 36.3793H115.406V39.0664H117.042Z"
          fill="white"
        />
        <path
          d="M127.662 30.3208H135.831V33.0897H130.805V41.7204H127.662V30.3208Z"
          fill="white"
        />
        <path
          d="M136.123 36.0269C136.123 32.7546 138.749 30.1006 142.251 30.1006C145.753 30.1006 148.347 32.7232 148.347 36.0269C148.347 39.2993 145.72 41.9549 142.218 41.9549C138.716 41.9549 136.123 39.3307 136.123 36.0269ZM145.136 36.0269C145.136 34.3829 143.954 32.9497 142.218 32.9497C140.483 32.9497 139.349 34.3499 139.349 36.0269C139.349 37.671 140.533 39.1042 142.251 39.1042C143.969 39.1042 145.136 37.704 145.136 36.0269Z"
          fill="white"
        />
        <path
          d="M148.816 38.9514H149.822C150.583 36.8842 150.972 34.8972 150.972 30.6133V30.3191H159.612V38.9498H160.894L160.538 43.9999H157.875V41.7203H151.847L151.637 43.9999H148.818L148.816 38.9514ZM156.467 38.9514V33.0895H154.004V33.2028C154.004 35.72 153.631 37.3876 153.113 38.9514H156.467Z"
          fill="white"
        />
        <path
          d="M166.064 30.2405H169.096L173.926 41.725H170.562L169.736 39.6893H165.351L164.54 41.725H161.233L166.064 30.2405ZM168.82 37.2256L167.556 33.9848L166.275 37.2256H168.82Z"
          fill="white"
        />
        <path
          d="M105.291 11.0127C72.7526 11.0127 64.8823 24.7816 31.8678 24.7816C18.9402 24.7816 10.4246 27.0958 5.21846 29.3235L5.01172 29.8033C10.1583 27.8966 18.4938 25.8876 30.7746 25.8876C66.3937 25.8876 73.3337 13.6998 105.874 13.6998C136.512 13.6998 153.116 22.8245 171.322 21.0153L171.415 21.0027L171.514 20.9885L171.605 20.9759L171.701 20.9618L171.801 20.9476L171.89 20.935L171.986 20.9193L172.084 20.9036L172.183 20.8894L172.282 20.8737L172.38 20.8579L172.479 20.8406C154.336 22.148 136.672 11.0127 105.291 11.0127Z"
          fill="#006DB6"
        />
        <path
          d="M104.707 8.3208C72.1688 8.3208 63.3775 23.6692 32.9599 23.6692C19.4214 23.6692 10.71 26.2729 5.43499 28.8042L5.21729 29.3202C10.425 27.0925 18.9406 24.7783 31.8682 24.7783C64.8874 24.7783 72.7467 11.0094 105.291 11.0094C136.671 11.0094 154.336 22.1479 172.508 20.8358L172.608 20.8201L172.708 20.8044L172.805 20.7886L172.907 20.7713L173.007 20.754L173.109 20.7367L173.211 20.7194L173.311 20.7005L173.41 20.6832L173.51 20.6643L173.61 20.647L173.709 20.6297C155.526 21.377 136.824 8.3208 104.707 8.3208Z"
          fill="white"
        />
        <path
          d="M105.877 13.6997C73.3369 13.6997 66.3969 25.8875 30.7778 25.8875C18.497 25.8875 10.1615 27.8965 5.01491 29.8033L4.82227 30.2611C9.9172 28.6878 18.0725 26.9966 29.6861 26.9966C67.9021 26.9966 73.9211 16.3899 106.461 16.3899C136.376 16.3899 151.882 23.3892 170.206 21.1631H170.253L170.387 21.1474H170.399L170.457 21.1395L170.56 21.1269L170.648 21.1159H170.656L170.747 21.1049L170.844 21.0923L170.943 21.0797L171.027 21.0671L171.12 21.0545L171.21 21.042L171.296 21.0294C153.119 22.8197 136.515 13.6997 105.877 13.6997Z"
          fill="#D1232A"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="197" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
