import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { LOADING_STATUS } from 'constants/loadingStatus'
import { loadAllCars } from 'store/cars/thunks'

export const adapter = createEntityAdapter()

export const cars = createSlice({
  name: 'cars',
  initialState: adapter.getInitialState({
    items: [],
    total: 0,
    loadingStatus: LOADING_STATUS.LOADING,
  }),
  reducers: {},
  extraReducers: {
    [loadAllCars.pending]: (state) => {
      state.loadingStatus = LOADING_STATUS.LOADING
    },
    [loadAllCars.rejected]: (state) => {
      state.loadingStatus = LOADING_STATUS.IDLE
      state.items = []
    },
    [loadAllCars.fulfilled]: (state, { payload }) => {
      if (Array.isArray(payload.items) && payload.items.length > 0) {
        state.items = payload.items.sort((a, b) => a.id - b.id)
      }
      state.loadingStatus = LOADING_STATUS.IDLE
    },
  },
})

export default cars.reducer
