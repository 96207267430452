import React, { useEffect, useState } from 'react'

import Button from 'components/Button'
import { Container } from 'components/Container'
import Icon from 'components/Icon'

import { colors } from 'constants/inlineColorConfig'

import { ROLE } from 'constants/role'
import { fullAccess, partialAccess } from 'components/Table/buttons'

import style from './styles.module.scss'
import clsx from 'clsx'

export const FooterActions = ({
  handleOpenModalCancel,
  role,
  status,
  payment_info,
  isFinishedOrder,
  goBack,
  handleSendEmail,
}) => {
  const { active } = status

  const isDisabled = active.id === 5 || active.id === 6

  const buttons = role === ROLE.admin ? partialAccess : fullAccess

  return (
    <div className={style.background}>
      <Container className={style.container}>
        <div className={style.links}>
          {isFinishedOrder && (
            <div
              className={clsx(style.itemEmail, {
                [style.itemEmailDisabled]: role === ROLE.admin,
              })}
              onClick={role === ROLE.admin ? () => {} : handleSendEmail}
            >
              <Icon.Email color={colors.darkGray} />
              <span>Отправить на почту клиенту</span>
            </div>
          )}
          {/*<div className={style.itemPdf}>*/}
          {/*  <Icon.DownloadCircle color={colors.darkGray} />*/}
          {/*  <span>Скачать pdf</span>*/}
          {/*</div>*/}
        </div>
        <div className={style.buttons}>
          {!isDisabled && buttons.access && (
            <Button.Outlined
              onClick={handleOpenModalCancel}
              type="submit"
              className={style.actionBtn}
            >
              Отменить заказ
            </Button.Outlined>
          )}
          {!isDisabled && !payment_info.status && buttons.access && (
            <Button.Default
              onClick={goBack}
              type="submit"
              className={style.actionBtn}
            >
              {buttons.order[0]}
            </Button.Default>
          )}
        </div>
      </Container>
    </div>
  )
}
