export const IconAutomatic = ({ width, height, color }) => {
  return (
    <svg
      width={width || '34'}
      height={height || '34'}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" stroke={color || '#E37639'} />
      <rect
        x="17"
        y="9"
        width="16"
        height="1"
        rx="0.5"
        transform="rotate(90 17 9)"
        fill={color || '#E37639'}
      />
      <path
        d="M21 9V25"
        stroke={color || '#E37639'}
        strokeMiterlimit="11.4737"
        strokeDasharray="1 1"
      />
      <rect
        x="9.5"
        y="16.5"
        width="14"
        height="5"
        rx="2"
        fill="white"
        stroke={color || '#E37639'}
      />
    </svg>
  )
}
