import { useState, useRef, useEffect } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import clsx from 'clsx'
import { Controller } from 'react-hook-form'
import { components } from 'react-select'
import Creatable, { useCreatable } from 'react-select/creatable'

import styles from './styles.module.scss'

/**
 * @param isHiddenInputValue {boolean}  скрывает значение slect при открытом меню
 */

const MenuList = (props) => (
  <components.MenuList {...props}>
    <Scrollbars
      autoHeight={true}
      autoHide={false}
      universal={true}
      renderThumbVertical={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: '4px',
            cursor: 'pointer',
            zIndex: '10',
          }}
        />
      )}
    >
      {props.children}
    </Scrollbars>
  </components.MenuList>
)

const SelectWithInput = ({
  label,
  name,
  control,
  change,
  isDisabled,
  options = [],
  isMulti,
  isClearable,
  isSearchable = true,
  fullwidth,
  isSpecSeries,
  defaultValue,
  isDarkStyled,
  rules = {},
  error,
  className,
  isHiddenInputValue,
  closeMenuOnSelect = true,
  hiddenLabel,
  bold,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [isOtherBank, setIsOtherBank] = useState(false)
  const creatableRef = useRef(null)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const MultiValue = (props) => (
    <components.MultiValueContainer {...props}>
      {props.index === 0 && (
        <span className={clsx({ [options.id + '_' + className]: className })}>
          {label}: {props.selectProps.value.length}
        </span>
      )}
    </components.MultiValueContainer>
  )

  useEffect(() => {
    if (isOtherBank) {
      handleBlur()
      setIsOtherBank(false)
    }
  }, [isOtherBank])

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || null}
      rules={rules}
      render={({ onChange, value }) => {
        return (
          <div className={styles.select__wrap} data-test-name={name}>
            <span
              className={clsx({
                [styles.select__label]: true,
                [styles.select__label_focused]:
                  isFocused ||
                  (value &&
                    ((typeof value === 'object' &&
                      Object.keys(value).length > 0) ||
                      (typeof value === 'string' && value.length > 0) ||
                      typeof value === 'number')),
                [styles.hideLabel]: isMulti && value && value?.length !== 0,
              })}
            >
              {label}
            </span>
            <Creatable
              ref={creatableRef}
              value={
                // typeof value === 'string' || typeof value === 'number'
                //   ? options.reduce(
                //       (acc, option) => (option.value === value ? option : acc),
                //       {}
                //     )
                //   : value
                value === '0' || value === ''
                  ? null
                  : options.find((obj) => obj.label === value)
              }
              className={clsx(
                isMulti
                  ? 'react-multi-select-container'
                  : `react-select-container${
                      isSpecSeries ? '-spec' : isDarkStyled ? '-dark' : ''
                    }`,
                { [className]: className }
              )}
              classNamePrefix={isMulti ? 'react-multi-select' : 'react-select'}
              onFocus={handleFocus}
              onBlur={() => {
                if (value === '0') {
                  creatableRef.current.setValue(null)
                }
              }}
              onClick={handleBlur}
              onChange={(e) => {
                change && change(e)
                onChange(e && !isMulti ? e.value : e)
                if (e.value === '0') {
                  setTimeout(() => {
                    creatableRef.current.focus()
                  }, 100)
                  setIsOtherBank(true)
                }
              }}
              formatCreateLabel={(inputValue) => `Добавить ${inputValue}`}
              name={name}
              closeMenuOnScroll={true}
              isMulti={isMulti}
              options={options}
              isSearchable={isSearchable}
              isClearable={isClearable}
              menuPlacement="auto"
              placeholder={<></>}
              isDisabled={isDisabled}
              backspaceRemovesValue={false}
              hideSelectedOptions={false}
              components={{ MultiValue, MenuList }}
              captureMenuScroll={false}
              instanceId={name}
              closeMenuOnSelect={closeMenuOnSelect}
              styles={{
                control: (style, { hasValue }) => ({
                  ...style,
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  borderColor:
                    isMulti && hasValue
                      ? '#E37639!important'
                      : '#d9dcdc!important',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  width: fullwidth ? '100vw' : undefined,
                  marginTop: hiddenLabel && 10,
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 50,
                  borderRadius: 0,
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  transition: '.5s transform',
                  transform: isFocused ? 'rotate(180deg)' : undefined,
                  color: isFocused ? '#E37639' : '#848E98',
                }),
                singleValue: (provided, { selectProps }) => {
                  return {
                    ...provided,
                    color: '#66727D',
                    fontSize: '16px',
                    lineHeight: '21px',
                    fontWeight: bold && 'bold',
                    opacity:
                      isHiddenInputValue && selectProps.menuIsOpen ? 0 : 1,
                  }
                },
              }}
            />
            {error && <div className={styles.error}> {error.message} </div>}
          </div>
        )
      }}
    />
  )
}

export default SelectWithInput
