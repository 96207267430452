export const optGroups = [
  {
    id: 13,
    name: 'Безопасность',
  },
  {
    id: 15,
    name: 'Интерьер',
  },
  {
    id: 16,
    name: 'Комфорт',
  },
  {
    id: 19161,
    name: 'Мультимедиа',
  },
  {
    id: 17,
    name: 'Экстерьер',
  },
]

export const insuranceNames = {
  kasko: 'КАСКО',
  osago: 'ОСАГО',
  osagoKasko: 'ОСАГО + КАСКО',
  pusk: 'ПУСК',
  start: 'СТАРТ',
  razgon: 'РАЗГОН',
  forsage: 'ФОРСАЖ',
  granpri: 'ГРАН-ПРИ',
}
