import { request, requestLada, requestLadaFile } from 'utils/request'

export async function getBadgeById(id, creditData) {
  return requestLada.post(`/api/os-car-bages`, {
    car_id: id,
    credit_data: creditData,
  })
}

export async function deleteBadgeById(id) {
  return requestLada.delete(`/api/os-car-bages`, {
    data: { car_id: id },
  })
}

export async function sendBage(file) {
  return requestLada.post(`/api/os-car-bages/send-file`, file)
}
