export const IconHelpRating = ({ width, height, color }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00004 4.33334C6.26366 4.33334 5.66671 4.93029 5.66671 5.66667C5.66671 6.03486 5.36823 6.33334 5.00004 6.33334C4.63185 6.33334 4.33337 6.03486 4.33337 5.66667C4.33337 4.19391 5.52728 3 7.00004 3C8.4728 3 9.66671 4.19391 9.66671 5.66667C9.66671 6.90923 8.81686 7.9533 7.66671 8.24932V8.33334C7.66671 8.70153 7.36823 9 7.00004 9C6.63185 9 6.33337 8.70153 6.33337 8.33334V7.66667C6.33337 7.29848 6.63185 7 7.00004 7C7.73642 7 8.33337 6.40305 8.33337 5.66667C8.33337 4.93029 7.73642 4.33334 7.00004 4.33334Z"
        fill="currentColor"
      />
      <path
        d="M7.00004 11C7.36823 11 7.66671 10.7015 7.66671 10.3333C7.66671 9.96515 7.36823 9.66667 7.00004 9.66667C6.63185 9.66667 6.33337 9.96515 6.33337 10.3333C6.33337 10.7015 6.63185 11 7.00004 11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00004 13.6667C10.6819 13.6667 13.6667 10.6819 13.6667 7C13.6667 3.3181 10.6819 0.333336 7.00004 0.333336C3.31814 0.333336 0.333374 3.3181 0.333374 7C0.333374 10.6819 3.31814 13.6667 7.00004 13.6667ZM7.00004 12.3333C9.94556 12.3333 12.3334 9.94552 12.3334 7C12.3334 4.05448 9.94556 1.66667 7.00004 1.66667C4.05452 1.66667 1.66671 4.05448 1.66671 7C1.66671 9.94552 4.05452 12.3333 7.00004 12.3333Z"
        fill="currentColor"
      />
    </svg>
  )
}
