import { request } from 'utils/request'

/**
 * получение данных по автомобилю для нового КП
 * @param compl_id
 * @param color_code
 * @returns {Promise<AxiosResponse<any>>}
 */

export async function getCarDataForPO(compl_id, color_code) {
  return request.get(
    `/api/os-car-pers-offer/${compl_id}/compl-id/${color_code}/color-code`
  )
}

/**
 * получение списка всех КП
 * @returns {Promise<AxiosResponse<any>>}
 */

export async function getAllPO() {
  return request.get('/api/os-car-pers-offer')
}

/**
 * получение данных КП по ID
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */

export async function getPOById(id) {
  return request.get(`/api/os-car-pers-offer/${id}/offer-id`)
}

/**
 * получение с формы и отдача данных для КП
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */

export async function setDataPO(data) {
  return request.post('/api/os-car-pers-offer', {
    ...data,
  })
}

/**
 * Обновление данных КП
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */

export async function updateDataPO(data, offer_id) {
  return request.put(`/api/os-car-pers-offer/${offer_id}`, {
    ...data,
  })
}
