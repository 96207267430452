import { request } from 'utils/request'

export async function addCreditData(data) {
  return request.post(`/api/os-order-credit`, {
    ...data,
  })
}

export async function updateCreditData(data) {
  return request.put(`/api/os-order-credit`, {
    ...data,
  })
}

export async function updateInsuranceToCredit(orderId, data) {
  return request.put(`/api/os-manager-orders/${orderId}`, {
    casco_credit: data,
  })
}

export async function updateTradeinToCredit(orderId, data) {
  return request.put(`/api/os-manager-orders/${orderId}`, {
    tradein_credit: data,
  })
}
