import clsx from 'clsx'

import styles from './styles.module.scss'

export const ButtonWithIcon = ({
  title,
  children,
  onClick,
  className,
  to,
  ...other
}) => {
  if (to) {
    return (
      <a
        className={clsx(styles.btn, className)}
        href={to}
        target="_blank"
        {...other}
      >
        <span>{title}</span>
        {children}
      </a>
    )
  }

  return (
    <button
      type="button"
      className={clsx(styles.btn, className)}
      onClick={onClick}
      {...other}
    >
      <span>{title}</span>
      {children}
    </button>
  )
}
