import { useState, Fragment } from 'react'
import clsx from 'clsx'

import Icon from 'components/Icon'

import styles from './styles.module.scss'

export const AccordionTable = ({
  title,
  content,
  withoutTitleCell,
  isDefaultOpen,
  classForItem,
  disclaimer,
  technical,
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen)

  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  return (
    <div
      className={clsx({
        [styles.container]: true,
        [styles.opened]: isOpen,
      })}
    >
      <div className={styles.header} onClick={toggleOpen}>
        {title}
        <Icon.ArrowDown color={isOpen ? '#E47639' : '#4B5865'} />
      </div>
      <div className={styles.body}>
        {content.map(({ title, data }, i) => (
          <Fragment key={i}>
            <div className={styles.rowTitle}>{title}</div>
            <div
              className={clsx({
                [styles.row]: true,
                [styles.withoutTitleCell]: withoutTitleCell,
                [styles.technical]: technical,
              })}
            >
              {data.map(({ label, value }, j) => (
                <div className={styles.cell} key={j}>
                  {label && (
                    <div
                      className={clsx(styles.label, {
                        [styles.technical__label]: technical,
                      })}
                    >
                      {label}
                    </div>
                  )}
                  <div
                    className={clsx(styles.value, classForItem, {
                      [styles.technical__value]: technical,
                    })}
                  >
                    {typeof value !== 'string'
                      ? value.map((item) => (
                          <p key={item}>
                            <span className={styles.circle}>
                              <span>•</span> {item}
                            </span>
                          </p>
                        ))
                      : value}
                  </div>
                </div>
              ))}
            </div>
          </Fragment>
        ))}
        {disclaimer && (
          <div className={styles.disclaimer}>
            * Данное наименование исключается из состава базового оборудования
            при отсутствии какого-либо из элементов, входящих в его состав, или
            в случае отказа покупателя от набора в целом, заявленного до
            заключения договора купли-продажи автомобиля. В этих случаях цена
            базового оборудования соответствующей комплектации уменьшается на
            указанную стоимость такого набора.
          </div>
        )}
      </div>
    </div>
  )
}
