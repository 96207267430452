import { request } from 'utils/request'

export async function getTradeInCar(orderId) {
  return request.get(`/api/os-order-tradein/${orderId}`)
}

export async function updateTradeInCar(data) {
  return request.put(`/api/os-order-tradein`, {
    ...data,
  })
}

export async function addTradeInCar(data) {
  return request.post(`/api/os-order-tradein`, {
    ...data,
  })
}

export async function deleteTradeInCar(data) {
  return request.delete(`/api/os-order-tradein`, {
    data,
  })
}
