import { getCarDataForCO, getCOById } from 'api/corporateOffer'

export async function getCarDataCO(color, compl) {
  try {
    const res = await getCarDataForCO(color, compl)

    return res.data
  } catch (e) {
    throw e
  }
}

export async function getOfferDataCO(id) {
  try {
    const res = await getCOById(id)

    return res.data
  } catch (e) {
    throw e
  }
}
