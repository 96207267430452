export const getValuesWithSelect = (data = [], fields = ['', ''], isItem) => {
  return data.map((item, index) => ({
    value: isItem
      ? item
      : item[fields[0]]
      ? item[fields[0]]
      : index === 0
      ? 0
      : index,
    label: isItem ? item : item[fields[1]],
  }))
}
