export const IconGift = ({ width, height, color }) => {
  return (
    <svg
      width={width || 22}
      height={height || 22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0003 11.0317V20.2717C19.0057 20.4663 18.9342 20.6552 18.8013 20.7975C18.6684 20.9398 18.4848 21.0239 18.2903 21.0317H3.71029C3.51576 21.0239 3.33216 20.9398 3.19926 20.7975C3.06636 20.6552 2.99487 20.4663 3.00029 20.2717V11.0317H19.0003Z"
        stroke="#E37639"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.24 6.76174C20.4416 6.76174 20.6349 6.84181 20.7774 6.98434C20.9199 7.12686 21 7.32017 21 7.52174V10.0317C21 10.297 20.8946 10.5513 20.7071 10.7388C20.5196 10.9264 20.2652 11.0317 20 11.0317H2C1.73478 11.0317 1.48043 10.9264 1.29289 10.7388C1.10536 10.5513 1 10.297 1 10.0317V7.52174C1 7.32017 1.08007 7.12686 1.2226 6.98434C1.36513 6.84181 1.55844 6.76174 1.76 6.76174H9.76C8.3579 6.58784 7.04423 5.98342 6 5.03174C5.55709 4.58882 5.30826 3.98811 5.30826 3.36174C5.30826 2.73537 5.55709 2.13465 6 1.69174C6.44291 1.24883 7.04363 1 7.67 1C8.29637 1 8.89709 1.24883 9.34 1.69174C10.2798 2.77147 10.86 4.11712 11 5.54174C11.1545 4.1242 11.745 2.78951 12.69 1.72174C13.1442 1.37155 13.7103 1.19872 14.2826 1.23556C14.855 1.2724 15.3943 1.51639 15.7998 1.92192C16.2054 2.32744 16.4493 2.86677 16.4862 3.43909C16.523 4.01141 16.3502 4.57756 16 5.03174C14.9357 5.98381 13.5997 6.57834 12.18 6.73174L20.24 6.76174Z"
        stroke="#E37639"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3 6.45175C11.1926 6.32725 11.1109 6.18279 11.0594 6.02663C11.0079 5.87048 10.9877 5.70571 11 5.54175C11.0123 5.70571 10.9922 5.87048 10.9407 6.02663C10.8892 6.18279 10.8074 6.32725 10.7 6.45175C10.447 6.66579 10.1208 6.77333 9.79004 6.75175H12.21C11.8793 6.77333 11.5531 6.66579 11.3 6.45175V6.45175Z"
        stroke="#E37639"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 11.2817V21.0317"
        stroke={color || '#E37639'}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
