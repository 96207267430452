import React, { useState } from 'react'
import clsx from 'clsx'

import Icon from 'components/Icon'

import style from './styles.module.scss'

export const StatusHistory = ({ isDefaultOpen, history }) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen)

  const formattedData = history.map(({ date, name, id }) => {
    const formattedDate = date.split(' ')[0]
    return { date: formattedDate, name: name, id: id }
  })

  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  return (
    <div
      className={clsx(style.container, {
        [style.opened]: isOpen,
      })}
    >
      <div className={style.header} onClick={toggleOpen}>
        <div className={style.label}>История статусов</div>
        <Icon.ArrowDown color={isOpen ? '#E47639' : '#4B5865'} />
      </div>
      <div className={style.body}>
        {formattedData &&
          formattedData.map(({ date, name, id }, i) => (
            <div className={style.row} key={`${id}_${i}`}>
              {date} / {name}
            </div>
          ))}
      </div>
    </div>
  )
}
