export function percentCalculation(price, carPrice) {
  let result = price / (carPrice / 100)

  return `${result.toFixed(2) + '%'}`
}

export function checkAccessoriesAvailable(
  price,
  carPrice,
  currentPercent,
  mode
) {
  let percent = percentCalculation(price, carPrice).replace('%', '')
  let replaceCurrentPercent = currentPercent.replace('%', '')
  let totalPercent = Number(percent) + Number(replaceCurrentPercent)

  switch (mode) {
    case 'isFull':
      return totalPercent <= 130
      break
    case 'isAcc':
      return totalPercent <= 20 ? true : false
      break
    case 'isDiscountAdd':
      let result = Number(replaceCurrentPercent) - Number(percent)
      return result >= 94 ? true : false
      break
    case 'isDiscountRemove':
      let res = Number(percent) + Number(replaceCurrentPercent)
      return res <= 130 ? true : false
      break
    case 'isServices':
      return totalPercent <= 25 ? true : false
      break
    default:
      return false
  }
}
