import Slider, { SliderTooltip } from 'rc-slider'
import { Controller } from 'react-hook-form'
import clsx from 'clsx'
import { IconFlatArrowLeft } from 'components/Icon/IconFlatArrowLeft'
import { IconFlatArrowRight } from 'components/Icon/IconFlatArrowRight'

import 'rc-slider/assets/index.css'
import styles from './styles.module.scss'

const Handle = (props) => {
  const handleStyle = Object.assign({
    left: `${props.offset}%`,
    transform: `translateX(-${props.offset}%)`,
  })
  return (
    <div className={styles.tooltip} style={handleStyle}>
      <IconFlatArrowLeft width={6} color="#fff" />
      {props.value}%
      <IconFlatArrowRight width={6} color="#fff" />
    </div>
  )
}

const Tooltip = (props) => {
  const { value, dragging, index, ...restProps } = props
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value} %`}
      visible={false}
      placement="top"
      key={index}
      className={styles.tooltip}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  )
}

const Ranger = ({ name, control, min = 0, max = 100, step = 10, putClass }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ value, onChange }) => (
        <Slider
          min={min}
          max={max}
          step={step}
          defaultValue={3}
          handle={Tooltip}
          value={value}
          onChange={onChange}
          className={clsx([styles.slider], 'ui-slider', {
            [putClass]: putClass,
          })}
        />
      )}
    />
  )
}

export default Ranger
