import * as React from 'react'

import { Router } from 'routes'

import { scrollTo } from 'utils/scroll'

import './index.css'
import './assets/styles/select.css'

export default function App() {
  const scroll = (e) => {
    const target = e.target
    const href = target && target.href && target.href.match(/#..*/gi)
    if (href && target.tagName === 'A') {
      e.preventDefault()
      const element = document.querySelector(href[0])
      const scrollLayer = document.scrollingElement

      if (element && scrollLayer) {
        scrollTo(element, scrollLayer)
      }
    }
  }
  return (
    <div className="App" onClick={scroll}>
      <Router />
    </div>
  )
}
