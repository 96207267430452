import { ButtonCircle } from './ButtonCircle'
import { DefaultButton } from './DefaultButton'
import { ButtonFullWidth } from './ButtonFullWidth'
import { ButtonInverted } from './ButtonInverted'
import { ButtonOutlined } from './ButtonOutlined'
import { ButtonWithIcon } from './ButtonWithIcon'

const Button = {
  Circle: ButtonCircle,
  Default: DefaultButton,
  FullWidth: ButtonFullWidth,
  Inverted: ButtonInverted,
  Outlined: ButtonOutlined,
  WithIcon: ButtonWithIcon,
}

export default Button
