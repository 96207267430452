import { request, requestLada } from 'utils/request'

export async function getAccessories() {
  return requestLada.get(`/api/os-accessories`)
}

export async function getAccessoriesByKomplId(komplId) {
  return requestLada.get(`/api/os-accessories/${komplId}`)
}

export async function getCarAccessories(carId) {
  return request.get(`/api/os-car-accessories/${carId}`)
}

export async function addCarAccessories(data) {
  return request.post(`/api/os-car-accessories`, {
    ...data,
  })
}

export async function deleteCarAccessories(data) {
  return request.delete(`/api/os-car-accessories`, {
    data: { ...data },
  })
}
