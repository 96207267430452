import React from 'react'

import {
  Page,
  Image,
  Text,
  View,
  Document,
  Svg,
  Path,
} from '@react-pdf/renderer'

import { getPrice } from 'utils/getPrice'
import {
  accessoriesFormatter,
  getAccessoriesPrice,
} from 'components/PdfGenerator/PdfList/utils'

import engineImg from '../../../assets/img/pdfImages/engine.png'
import colorImg from '../../../assets/img/pdfImages/color.png'
import logo from '../../../assets/img/pdfImages/logoLADA.png'
import gift from '../../../assets/img/pdfImages/gift.png'
import roadhelp from '../../../assets/img/pdfImages/roadhelp.png'
import bankLogo from '../../../assets/img/pdfImages/bankLogo.jpg'

import styles from './styles'

export const PdfList = ({ carInfo, formattedSvg }) => {
  const {
    car_id,
    family_name,
    model_name,
    complectation,
    color_name,
    color_price,
    modification,
    accessories,
    features,
    complectation_code,
    price,
    total_price,
    dealer_actions,
    special_offers,
    additional_services,
    credit_data,
    gifts,
    PG_list,
    hideBankLogo,
  } = carInfo

  const pdfName = `${family_name}_${complectation}_${car_id}`.toLowerCase()

  const formattedAccessories =
    accessories?.base || accessories?.added
      ? accessoriesFormatter(accessories)
      : []

  const leastAccessories =
    formattedAccessories.length > 3
      ? formattedAccessories.reduce(
          (acc, el, i) => (i > 1 ? acc + el.price : acc),
          0
        )
      : false

  const accessorriesAndServicePrice =
    getAccessoriesPrice(accessories) +
    additional_services.reduce((acc, { price }) => acc + price, 0)

  const allActions = [...special_offers, ...dealer_actions]

  const offersTotalBenefit = allActions.reduce(
    (acc, { benefit }) => acc + benefit,
    0
  )

  return (
    <Document language="ru" title={pdfName}>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.section_1}>
            <Text style={styles.title}>
              Новая {family_name.toLowerCase().replace('новая ', '')}{' '}
              {model_name}
            </Text>

            <View style={styles.flexSpaceBetween}>
              <View style={styles.compl}>
                <Text style={styles.complTitle}>КОМПЛЕКТАЦИЯ АВТОМОБИЛЯ</Text>
                <Text
                  style={
                    complectation.length > 15
                      ? { ...styles.complName, fontSize: '12' }
                      : styles.complName
                  }
                >
                  {complectation}
                </Text>
              </View>
              <View style={styles.engineData}>
                <View style={styles.engineElem}>
                  <Image style={styles.engineimg} src={engineImg} />
                  <View style={styles.engineContent}>
                    <Text style={styles.engineTitle}>двигатель</Text>
                    <Text style={styles.engineText}>{modification.title}</Text>
                  </View>
                </View>
                <View style={styles.engineElem}>
                  <Image style={styles.engineimg} src={colorImg} />
                  <View style={styles.engineContent}>
                    <Text style={styles.engineTitle}>цвет</Text>
                    {color_name ? (
                      <Text style={styles.engineText}>{color_name}</Text>
                    ) : (
                      <Text style={styles.engineText}>нет данных цвета</Text>
                    )}
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.headTable}>
              {features.length &&
                // features.map((el, i) => {
                features.map((el, i) => {
                  if (i < 4) {
                    let itemStyle = null

                    if (features.length >= 4) {
                      if (i === 0) itemStyle = styles.headTableItem1
                      if (i % 4 === 0 && !itemStyle)
                        itemStyle = styles.headTableItem3
                      if (i > 3 && !itemStyle) itemStyle = styles.headTableItem4
                      if (!itemStyle) itemStyle = styles.headTableItem2
                    }

                    if (features.length === 3) {
                      if (i === 0) itemStyle = styles.headTableItem31
                      if (i === 1) itemStyle = styles.headTableItem32
                      if (i === 2) itemStyle = styles.headTableItem33
                    }

                    if (features.length === 2) {
                      if (i === 0) itemStyle = styles.headTableItem21
                      if (i === 1) itemStyle = styles.headTableItem22
                    }

                    if (features.length === 1) {
                      if (i === 0) itemStyle = styles.headTableItem11
                    }

                    return (
                      <View style={itemStyle}>
                        <Text style={styles.headTableText}>{el}</Text>
                      </View>
                    )
                  }
                })}
            </View>
            {modification.packages.map((el) => (
              <View
                style={
                  features.length > 0
                    ? styles.packageItem
                    : styles.packageItemFull
                }
              >
                <Text style={styles.packageText}>
                  Включен пакет опций: {el.title}
                </Text>
              </View>
            ))}

            <View style={styles.qrSection}>
              <View style={styles.priceBlock}>
                <Text style={styles.priceTitle}>стоимость комплектации от</Text>
                <View style={styles.priceWrapper}>
                  <Text style={styles.priceValue}>{getPrice(price)}</Text>
                  <Text
                    style={{
                      ...styles.rouble,
                      fontSize: 24,
                      lineHeight: '100%',
                      position: 'relative',
                      top: '-8px',
                    }}
                  >
                    д
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.addsSection}>
            <View style={styles.addsTitles}>
              <View style={styles.addsTitleWrapper}>
                <Text style={styles.addsTitle}>установленные аксессуары</Text>
              </View>
              <View style={styles.addsTitleWrapper}>
                <Text style={styles.addsTitle}>дополнительные услуги</Text>
              </View>
            </View>
            <View style={styles.addsBlock}>
              <View style={styles.addsItems}>
                {formattedAccessories.map((el, i) => {
                  const numShowAccessories = leastAccessories ? 2 : 3

                  if (numShowAccessories > i)
                    return (
                      <>
                        <View style={styles.addsItemstextBlock}>
                          <Text style={styles.addsTextGrayNameAcc}>
                            {el.name}
                          </Text>
                          <Text style={styles.addsTextGrayAcc}>
                            {getPrice(el.price)}
                            <Text style={styles.rouble}>й</Text>
                          </Text>
                        </View>
                        {el.isPg && (
                          <Text style={styles.pgText}>
                            {PG_list.replaceAll('</b>', '')
                              .replaceAll('<b>', '')
                              .replaceAll('<br/>\r\n', ', ')
                              .replaceAll('<br/>', '')}
                          </Text>
                        )}
                      </>
                    )
                })}

                {leastAccessories && (
                  <View style={styles.addsItemstextBlock}>
                    <Text style={styles.addsTextGrayNameAcc}>
                      Другие аксессуары
                    </Text>
                    <Text style={styles.addsTextGrayAcc}>
                      {getPrice(leastAccessories)}
                      <Text style={styles.rouble}>й</Text>
                    </Text>
                  </View>
                )}

                {formattedAccessories.length === 0 && (
                  <Text style={styles.addsText}>
                    Без дополнительных аксессуаров
                  </Text>
                )}
              </View>

              <View style={styles.addsItems2}>
                {additional_services.map((el) => (
                  <View style={styles.addsItemstextBlock}>
                    <Text style={styles.addsTextGrayName}>{el.name}</Text>
                    <Text style={styles.addsTextGray}>
                      {getPrice(el.price)}
                      <Text style={styles.rouble}>й</Text>
                    </Text>
                  </View>
                ))}

                {additional_services.length === 0 && (
                  <Text style={styles.addsText}>Без дополнительных услуг</Text>
                )}
              </View>
            </View>
          </View>

          <View style={styles.giftSection}>
            <View
              style={
                gifts.length === 0
                  ? { ...styles.giftBlock, border: '0' }
                  : styles.giftBlock
              }
            >
              {gifts.length > 0 && (
                <>
                  <Image style={styles.giftImg} src={gift} />
                  <View>
                    <Text style={styles.giftTitle}>подарок дилера</Text>
                    {gifts.map((el) => (
                      <Text style={styles.giftItem}>{el.name}</Text>
                    ))}
                  </View>
                </>
              )}
            </View>

            <View
              style={
                gifts.length > 0 ? styles.giftBlockSecond : styles.giftBlock
              }
            >
              <Image style={styles.giftImg} src={roadhelp} />
              <View>
                <Text style={styles.giftTitle}>
                  LADA Помощь на дороге в подарок
                </Text>
                <Text style={styles.giftItem}>
                  Экстренная техническая помощь и эвакуация транспортного
                  средства на период гарантийного срока автомобиля
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.addsSection}>
            <View style={styles.addsTitles}>
              {allActions.length > 0 && (
                <View style={styles.addsTitleWrapper}>
                  <Text style={{ ...styles.addsTitle, fontSize: '9px' }}>
                    Максимальная выгода
                  </Text>
                </View>
              )}

              <View style={styles.addsTitleWrapper}>
                <Text style={{ ...styles.addsTitle, fontSize: '9px' }}>
                  Стоимость автомобиля
                </Text>
              </View>
            </View>
            <View style={styles.addsBlock}>
              {allActions.length > 0 && (
                <View style={styles.addsItemsGray}>
                  {allActions.map((el) => (
                    <View style={styles.addsItemstextBlock}>
                      <Text style={styles.addsTextGray}>{el.title}</Text>
                      <Text style={styles.addsTextGray}>
                        {getPrice(el.benefit)}
                        <Text style={styles.rouble}>й</Text>
                      </Text>
                    </View>
                  ))}
                  {allActions.length > 0 && (
                    <View
                      style={{
                        ...styles.addsItemstextBlock,
                        borderTop: '1px dashed #4C5865',
                        paddingTop: '5px',
                      }}
                    >
                      <Text style={{ ...styles.addsTextGray, ...styles.bold }}>
                        Итоговая выгода
                      </Text>
                      <Text style={{ ...styles.addsTextGray, ...styles.bold }}>
                        {getPrice(offersTotalBenefit)}
                        <Text style={styles.rouble}>д</Text>
                      </Text>
                    </View>
                  )}
                </View>
              )}

              <View
                style={
                  allActions.length > 0
                    ? styles.addsItems2Gray
                    : { ...styles.addsItemsGray, width: '100%' }
                }
              >
                <View style={styles.addsItemstextBlock}>
                  <Text style={styles.addsTextGray}>Цена автомобиля</Text>
                  <Text style={styles.addsTextGray}>
                    {getPrice(price)}
                    <Text style={styles.rouble}>й</Text>
                  </Text>
                </View>
                {accessorriesAndServicePrice > 0 && (
                  <View style={styles.addsItemstextBlock}>
                    <Text style={styles.addsTextGray}>
                      Стоимость аксессуаров и услуг
                    </Text>
                    <Text style={styles.addsTextGray}>
                      {getPrice(accessorriesAndServicePrice)}
                      <Text style={styles.rouble}>й</Text>
                    </Text>
                  </View>
                )}

                {offersTotalBenefit > 0 && (
                  <View style={styles.addsItemstextBlock}>
                    <Text style={styles.addsTextGray}>Максимальная выгода</Text>
                    <Text style={styles.addsTextGray}>
                      -{getPrice(offersTotalBenefit)}
                      <Text style={styles.rouble}>й</Text>
                    </Text>
                  </View>
                )}

                {Number(color_price) > 0 && (
                  <View style={styles.addsItemstextBlock}>
                    <Text style={styles.addsTextGray}>Доплата за цвет</Text>
                    <Text style={styles.addsTextGray}>
                      {getPrice(color_price)}
                      <Text style={styles.rouble}>й</Text>
                    </Text>
                  </View>
                )}
              </View>
            </View>
            <View style={styles.totalPrice}>
              <Text style={styles.totalPriceText}>
                стоимость представленного автомобиля с учётом аксессуаров, услуг
                и выгод
              </Text>
              <Text style={styles.totalPriceValue}>
                {getPrice(total_price)}
                <Text
                  style={{ ...styles.rouble, lineHeight: '100%', fontSize: 24 }}
                >
                  д
                </Text>
              </Text>
            </View>
          </View>

          {credit_data?.month_payment > 0 && (
            <>
              <View style={styles.creditSection}>
                <Text style={styles.creditTitle}>
                  Кредитное предложение LADA finanСe
                </Text>
                <View style={styles.creditLine}></View>
                <View style={styles.creditTable}>
                  {!hideBankLogo && (
                    <Image style={styles.creditImage} src={bankLogo} />
                  )}

                  <View style={styles.creditItem1}>
                    <View style={styles.creditItemTitleBlock}>
                      <Text style={styles.creditItemTitle}>Ставка</Text>
                      <Text style={styles.creditItemTitle}>по кредиту</Text>
                    </View>
                    <Text style={styles.creditItemText}>
                      {credit_data.loan_rate} %
                    </Text>
                  </View>

                  <View style={styles.creditItem2}>
                    <View style={styles.creditItemTitleBlock}>
                      <Text style={styles.creditItemTitle}>Ежемесячный</Text>
                      <Text style={styles.creditItemTitle}>платёж</Text>
                    </View>
                    <Text
                      style={{
                        ...styles.creditItemText,
                        position: 'relative',
                        top: '-8px',
                      }}
                    >
                      {getPrice(credit_data.month_payment)}{' '}
                      <Text style={styles.rouble}>д</Text>
                    </Text>
                  </View>
                </View>
              </View>
            </>
          )}
        </View>
        <View>
          <View style={styles.footerSection}>
            <View style={styles.footer}>
              <View style={styles.footerRight}>
                {formattedSvg && (
                  <View style={styles.svg}>
                    <Svg
                      width={formattedSvg.width}
                      height={formattedSvg.height}
                      viewBox={formattedSvg.viewBox}
                    >
                      {formattedSvg.list.length > 0 &&
                        formattedSvg.list.map(({ fill, d, shapeRendering }) => (
                          <Path
                            fill={fill}
                            d={d}
                            shapeRendering={shapeRendering}
                            key={d.slice(1, 5)}
                          />
                        ))}
                    </Svg>
                  </View>
                )}
                <View style={styles.qrTextBlock}>
                  <Text style={styles.qrTitle}>
                    отсканируйте QR код смартфоном и узнайте подробности об
                    автомобиле
                  </Text>
                  {complectation_code && (
                    <View>
                      <Text style={styles.qrText}>Код комплектации</Text>
                      <Text style={styles.qrValue}>{complectation_code}</Text>
                    </View>
                  )}
                </View>
              </View>

              <View style={styles.footerLeft}>
                <Image style={styles.footerImg} src={logo} />
                <Text style={styles.footerText}>
                  Детальная информация об автомобиле, специальных предложениях и
                  кредитных предложениях доступна по ссылке при переходе по
                  QR-коду, а также у менеджеров отдела продаж и кредитных
                  специалистов дилерского центра.
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
