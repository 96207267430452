export const IconRub = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
    >
      <path
        d="M22.2 19.2V33.2"
        stroke="#E37639"
        strokeWidth="2.2"
        strokeLinecap="round"
      />
      <path
        d="M30.2 29.2L18.2 29.2"
        stroke="#E37639"
        strokeWidth="2.2"
        strokeLinecap="round"
      />
      <path
        d="M21.2 16.2H28.2C29.7 16.2 32.7 17 32.7 20.2C32.7 23.4 29.7 24.2 28.2 24.2H22.2"
        stroke="#E37639"
        strokeWidth="2.2"
        strokeLinecap="round"
      />
      <path
        d="M11.7 8.7C13.7 15.1 5.53333 16.3667 1.2 16.2C9.6 21.4 7.36666 26.0333 5.2 27.7C11.6 28.1 8.86666 36.2 6.7 40.2C16.3 36.2 18.7 40.2 18.7 42.7C22.7 37.5 28.3667 44.5333 30.7 48.7C31.1 37.9 35.5333 37.5333 37.7 38.7C37.7 32.7 44.7 31.2 48.2 31.2C39.8 25.6 42.0333 21.2 44.2 19.7C37.8 17.7 43.2 8.53333 46.7 4.2C34.3 11 31.2 7.03333 31.2 4.2C26.4 9 21.2 4.2 19.2 1.2C20.8 8 14.8667 9.03333 11.7 8.7Z"
        stroke="#E37639"
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinecap="round"
      />
    </svg>
  )
}
