import { request } from 'utils/request'

export async function getFinancialServices(orderId) {
  return request.get(`/api/os-order-finance-service/${orderId}`)
}

export async function addFinancialServices(data) {
  return request.post(`/api/os-order-finance-service`, {
    ...data,
  })
}

export async function updateFinancialServices(data) {
  return request.put(`/api/os-order-finance-service`, {
    ...data,
  })
}

export async function deleteFinancialServices(data) {
  return request.delete(`/api/os-order-finance-service`, { data })
}
