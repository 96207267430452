import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { MainPage } from 'components/MainPage'

import { loadAuthToken } from 'store/auth/slice'
import { getUserRole } from 'store/auth/selectors'

import { MAIN_ROUTE } from 'routes/routes'

import { newUrlParam } from 'utils/insertUrlParam'
import { setTokenFromLs } from 'utils/token'

import styles from './styles.module.scss'

export default function Auth() {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const token = query.get('token')
  const dispatch = useDispatch()

  const { role } = useSelector((state) => ({ role: getUserRole(state) }))

  useEffect(() => {
    if (token) {
      setTokenFromLs(token)
      newUrlParam(MAIN_ROUTE.path, token)
    }
  }, [token])

  useEffect(() => {
    dispatch(loadAuthToken())
  }, [])

  return (
    <>
      {role !== null ? (
        <MainPage />
      ) : (
        <div className={styles.errorMessage}>
          Ошибка авторизации. Попробуйте заново авторизоваться.
        </div>
      )}
    </>
  )
}
