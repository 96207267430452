import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useForm } from 'react-hook-form'
import { Container } from 'components/Container'

import { ItemDealer } from './ItemDealer/ItemDealer'

import style from './styles.module.scss'

export const Discounts = ({
  discountList,
  discountsData = [],
  setDiscountsData,
  setTotalPrice = () => {},
  mode = 'create',
}) => {
  const {
    control,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    setValue,
  } = useForm()

  return (
    <div>
      <Container className={style.container}>
        <h2 className={style.title}>Специальные предложения</h2>
        <form className={style.wrapper}>
          <div className={style.list}>
            {discountList.length > 0 &&
              discountList.map((el) => {
                let discount = discountsData.filter(({ id }) => id === el.id)
                let discountBenefit = !!discount.length
                  ? discount[0].benefit
                  : 0
                let isEdit = mode === 'edit'

                return (
                  <ItemDealer
                    key={el.prefix}
                    control={control}
                    watch={watch}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    id={el.id}
                    discountsData={discountsData}
                    setDiscountsData={setDiscountsData}
                    setTotalPrice={setTotalPrice}
                    active={isEdit && !!discount.length}
                    discountBenefit={discountBenefit}
                    setValue={setValue}
                    {...el}
                  />
                )
              })}
          </div>
        </form>
      </Container>
    </div>
  )
}
