import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Reports from 'containers/Analytics/Reports'

import { Container } from 'components/Container'
import Button from 'components/Button'

import { getCars } from 'api/cars'
import { getOrders } from 'api/orders'

import { ROLE } from 'constants/role'

import {
  getLoadingStatus,
  getUserDealerId,
  getUserRole,
} from 'store/auth/selectors'
import { loadAuthToken } from 'store/auth/slice'

import style from './styles.module.scss'
import { Preloader } from 'components/Preloader'
import { getCityIdToLs, getDealerIdToLs } from 'utils/localStorage'
import { loadDealersById } from 'store/dealers/thunks'
import { getDealersById } from 'store/dealers/selectors'
import { getDealerData } from 'api/dealers'

export default function Analytics({ isMobile, isTablet }) {
  const [cars, setCars] = useState([])
  const [orders, setOrders] = useState([])
  const [currentDealer, setCurrentDealer] = useState(null)
  const [cityId, setCityId] = useState(null)
  const [amountOrders, setAmountOrders] = useState({
    active: 0,
    new: 0,
  })
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { role, loadingStatus, dealerId } = useSelector((state) => ({
    role: getUserRole(state),
    loadingStatus: getLoadingStatus(state),
    dealerId: getUserDealerId(state),
  }))

  function goBack() {
    if (role === ROLE.admin || role === ROLE.root) {
      navigate('/supervisor', { replace: true })
    } else if (role === ROLE.chief || role === ROLE.chief2) {
      navigate('/chief')
    } else if (role === ROLE.manager) {
      navigate('/manager')
    }
  }

  useEffect(() => {
    async function fetchDealer() {
      try {
        if (role === ROLE.admin || role === ROLE.root) {
          const id = getDealerIdToLs()

          const res = await getDealerData(id, { showroom: false })
          setCurrentDealer(res.data.dealerCode)
          setCityId(res.data.dealerCityId)
        } else {
          const res = await getDealerData(dealerId, { showroom: false })
          setCurrentDealer(res.data.dealerCode)
          setCityId(res.data.dealerCityId)
        }
      } catch (e) {
        console.warn(e)
      }
    }

    if (dealerId !== null) fetchDealer()
  }, [dealerId])

  useEffect(() => {
    dispatch(loadAuthToken())

    if (cityId !== null) {
      dispatch(loadDealersById(cityId))

      async function fetchData() {
        try {
          const fetchedCars = await getCars()
          const fetchedOrders = await getOrders()
          setCars(fetchedCars.data)
          setOrders(fetchedOrders.data)
        } catch (e) {
          console.warn(e)
        }
      }

      fetchData()
    }
  }, [cityId])

  const amountCars = cars?.length
  const placedCars = cars?.filter((el) => el.showroom === 'in').length

  useEffect(() => {
    if (Array.isArray(orders.list) && orders.list.length > 0) {
      const active = orders.list.filter(
        ({ status }) => status.id !== 5 && status.id !== 6
      )
      const newOrders = orders.list.filter(
        ({ status, manager }) =>
          manager.id === null && status.id !== 5 && status.id !== 6
      )

      setAmountOrders({
        active: active.length,
        new: newOrders.length,
      })
    }
  }, [orders])

  return (
    <div className={style.background}>
      <Container className={style.container}>
        {loadingStatus === 'loading' ? (
          <div className={style.preloader}>
            <Preloader.Spinner />
          </div>
        ) : role === ROLE.admin || role === ROLE.root ? (
          <Reports currentDealer={currentDealer} role={role} />
        ) : (
          <>
            <h2 className={style.title}>Онлайн продажи</h2>
            <div className={style.row}>
              <div className={style.item}>
                <span className={style.titleItem}>Всего автомобилей</span>
                <span className={style.amount}>{amountCars}</span>
              </div>
              <div className={style.item}>
                <span className={style.titleItem}>Размещено автомобилей</span>
                <span className={style.amount}>{placedCars}</span>
              </div>
              <div className={style.item}>
                <span className={clsx(style.titleItem, style.bright)}>
                  Новые заказы
                </span>
                <span className={clsx(style.amount, style.bright)}>
                  {amountOrders.new}
                </span>
              </div>
            </div>
            <div className={style.row}>
              <div className={style.item}>
                <span className={style.titleItem}>Активные заказы</span>
                <span className={style.amount}>{amountOrders.active}</span>
              </div>
              {currentDealer === null && (
                <div className={style.actions}>
                  <Button.Default onClick={() => goBack()}>
                    Перейти к списку
                  </Button.Default>
                </div>
              )}
            </div>
            {currentDealer !== null && (
              <Reports currentDealer={currentDealer} role={role} />
            )}
          </>
        )}
      </Container>
    </div>
  )
}
