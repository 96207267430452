import { requestLada } from 'utils/request'

export async function getDealerData(dealerId) {
  return requestLada.get(`/api/dealers/${dealerId}/dealer-code`)
}

export async function getAllDealersRequest() {
  return requestLada.get(`/api/dealers`)
}

export async function getDealersById(cityId) {
  return requestLada.get(`/api/dealers/${cityId}`)
}
