import { request } from 'utils/request'

export async function getAllStatuses() {
  return request.get(`/api/os-statuses`)
}

export async function addOrderStatus(data) {
  return request.post(`/api/os-order-statuses`, {
    ...data,
  })
}
