export const IconCheck = ({ width, height, color }) => {
  return (
    <svg
      width={width || 9}
      height={height || 9}
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 4L5 8L12 1" stroke={color || '#FFFFFF'} strokeWidth="2" />
    </svg>
  )
}
