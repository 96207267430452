import { request } from 'utils/request'

export async function getCarDiscount(carId) {
  return request.get(`/api/os-car-discounts/${carId}`)
}

export async function addCarDiscount(data) {
  return request.post(`/api/os-car-discounts`, {
    ...data,
  })
}

export async function editCarDiscount(data) {
  return request.put(`/api/os-car-discounts`, {
    ...data,
  })
}

export async function deleteCarDiscount(data) {
  return request.delete(`/api/os-car-discounts`, {
    data: { ...data },
  })
}

export async function getAllDiscount() {
  return request.get(`/api/os-car-dealer-actions`)
}

// Эндпоинты для управления дилерскими акцими
// на карточке авто, кроме prefix === 'dd_d',

export async function addCarDealerDiscount(data) {
  return request.post(`/api/os-car-dealer-actions`, {
    ...data,
  })
}

export async function editCarDealerDiscount(data) {
  return request.put(`/api/os-car-dealer-actions`, {
    ...data,
  })
}

export async function deleteCarDealerDiscount(data) {
  return request.delete(`/api/os-car-dealer-actions`, {
    data: { ...data },
  })
}
