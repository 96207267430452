import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import SelectDefault from 'components/Fields/Select'
import Button from 'components/Button'
import Input from 'components/Fields/Input'
import { StatusHistory } from './StatusHistory'

import { ROLE } from 'constants/role'

import { getAllStatuses } from 'api/statuses'
import { getValuesWithSelect } from 'utils/getValuesWithSelect'
import { getPrice } from 'utils/getPrice'
import { formatPhoneNumber } from 'utils/formatPhoneNumber'

import style from './styles.module.scss'

export const OrderInfoForm = ({
  status,
  client_info,
  order_id,
  created,
  comment,
  changeStatus,
  changeComment,
  setPayment,
  isPaymentError,
  paymentInfo,
  isInputsActive,
  access,
  role,
}) => {
  const [statuses, setStatuses] = useState([])
  const { value, status: paymentStatus } = paymentInfo

  const paymentText =
    paymentStatus && value !== 0
      ? `Внесено: ${getPrice(value)}`
      : `Запрошено: ${getPrice(value)}`
  const disablePayment = paymentStatus

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch,
  } = useForm({})

  const statusesForSelect = getValuesWithSelect(statuses, ['id', 'name'])

  const { name, phone, email } = client_info
  const { active, history } = status
  const isDisabled =
    active.id === 5 || active.id === 6 || active.id === 9 ? true : false

  useEffect(async () => {
    try {
      const res = await getAllStatuses(order_id)
      setStatuses(res.data)
    } catch (e) {
      console.warn(e)
    }
  }, [])

  const isPrePayActive =
    role === ROLE.chief || role === ROLE.chief2 || role === ROLE.manager

  return (
    <div className={style.order}>
      <div className={style.orderName}>
        Заказ № {order_id} от {created?.date} {created?.time}
      </div>

      <div className={style.currentStatus}>
        <div className={style.label}>Текущий статус</div>
        <div className={style.info}>
          {active?.date && active?.date.split(' ')[0]} / {active?.name}
        </div>

        {!isDisabled && (
          <form onSubmit={handleSubmit(changeStatus)}>
            <div className={style.change}>
              <div className={style.select}>
                <SelectDefault
                  label="Изменить статус"
                  name="status"
                  control={control}
                  options={statusesForSelect}
                  error={errors['status']}
                  isDisabled={!access}
                />
              </div>
              <Button.Outlined disabled={!access} type="submit">
                Изменить
              </Button.Outlined>
            </div>
          </form>
        )}
        <StatusHistory history={history} />
      </div>
      <form onSubmit={handleSubmit(changeComment)}>
        <div className={style.comment}>
          <div className={style.label}>КОММЕНТАРИЙ</div>
          <div className={style.info}>
            {comment && comment.length > 0 ? comment : ''}
          </div>
          <Input
            label="Новый комментарий"
            name="comment"
            control={control}
            error={errors['comment']}
            disabled={!isInputsActive || !access}
          />
          {isInputsActive && access && (
            <span className={style.commentText}>
              У клиента комментарий не отображается
            </span>
          )}
          <div className={style.action}>
            <Button.Outlined
              disabled={!isInputsActive || !access}
              type="submit"
            >
              Сохранить
            </Button.Outlined>
          </div>
        </div>
      </form>
      <div className={style.client}>
        <div className={style.label}>Клиент</div>
        <div className={style.info}>{name}</div>
      </div>
      <div className={style.client}>
        <div className={style.label}>КОНТАКТЫ клиента</div>
        <div className={style.info}>
          {phone && <p>{formatPhoneNumber(phone)}</p>}
          {email && (
            <p>
              <a href={'mailto:' + email}>{email}</a>
            </p>
          )}
        </div>
      </div>
      {/*<div className={style.contact}>*/}
      {/*  <div className={style.label}>Контакты клиента</div>*/}
      {/*  <div className={style.info}>*/}
      {/*    {phone}, {email}*/}
      {/*  </div>*/}
      {/*  <div className={style.change}>*/}
      {/*    <div className={style.select}>*/}
      {/*      <Input*/}
      {/*        label="Новый контакт"*/}
      {/*        name="contact"*/}
      {/*        control={control}*/}
      {/*        error={errors['contact']}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className={style.action}>*/}
      {/*      <Button.Outlined type="submit">Добавить</Button.Outlined>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className={style.payment}>
        <div className={style.label}>ПРЕДОПЛАТА</div>
        <div className={style.info}>
          {value === 0 && !paymentStatus ? 'Внесено: 0 ₽' : paymentText}
        </div>
        <div className={style.change}>
          {!disablePayment && (
            <>
              <div className={style.select}>
                <Input
                  label="Сумма, ₽"
                  name="payment"
                  control={control}
                  error={
                    isPaymentError
                      ? { message: 'Введите сумму больше 0' }
                      : errors['payment']
                  }
                  required
                  disabled={!isInputsActive || !isPrePayActive}
                />
              </div>
              <div className={style.action}>
                <Button.Outlined
                  disabled={!isInputsActive || !isPrePayActive}
                  onClick={() => setPayment(watch('payment'))}
                >
                  Запросить
                </Button.Outlined>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
