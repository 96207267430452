export const IconWarranty = ({ width, height, color }) => {
  return (
    <svg
      width={width || 59}
      height={height || 49}
      viewBox="0 0 59 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M15.3926 48.0016H7.10467C6.47395 47.981 5.87622 47.7085 5.43971 47.2426C5.00319 46.7766 4.76265 46.1543 4.76968 45.5091V40.2617C4.76968 39.9486 4.89133 39.6483 5.10788 39.4269C5.32442 39.2054 5.61811 39.0811 5.92435 39.0811C6.23059 39.0811 6.52428 39.2054 6.74082 39.4269C6.95736 39.6483 7.07902 39.9486 7.07902 40.2617V45.5091L15.3926 45.6403C15.3923 45.3221 15.5123 45.0162 15.7275 44.7863C15.9427 44.5565 16.2364 44.4207 16.5473 44.4071C16.6964 44.3964 16.8461 44.4168 16.9872 44.4671C17.1284 44.5173 17.2581 44.5964 17.3685 44.6994C17.4788 44.8024 17.5676 44.9274 17.6293 45.0665C17.6911 45.2057 17.7245 45.3563 17.7276 45.5091C17.7346 46.1543 17.4941 46.7766 17.0576 47.2426C16.6211 47.7085 16.0233 47.981 15.3926 48.0016Z"
          fill={color || '#E37639'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.91272 40.0005C5.80231 40.9544 7.02515 41.5092 8.31374 41.5436H25.2869C25.9607 41.5436 26.5069 40.9973 26.5069 40.3235C26.5069 39.6497 25.9607 39.1035 25.2869 39.1035H8.54468C7.86415 39.1035 7.2115 38.8271 6.73029 38.3351C6.24909 37.843 5.97875 37.1757 5.97875 36.4798V29.737C5.97464 28.4216 6.33013 27.1312 7.00512 26.0113L8.18545 24.0698C8.26654 23.9073 8.30882 23.7275 8.30882 23.5451C8.30882 23.3627 8.26654 23.1828 8.18545 23.0203C8.12778 22.8444 8.02659 22.6867 7.89171 22.5626C7.75683 22.4385 7.59283 22.3521 7.41567 22.3119L2.69436 20.8427C2.59173 20.8164 2.36079 20.6853 2.36079 20.6853V18.0616H7.10776C7.27205 18.0868 7.43975 18.0755 7.59938 18.0285C7.75902 17.9814 7.90684 17.8996 8.03275 17.7888C8.15866 17.678 8.2597 17.5406 8.32896 17.3862C8.39822 17.2318 8.43407 17.0639 8.43407 16.894C8.43407 16.7242 8.39822 16.5563 8.32896 16.4019C8.2597 16.2474 8.15866 16.1101 8.03275 15.9993C7.90684 15.8884 7.75902 15.8067 7.59938 15.7596C7.43975 15.7125 7.27205 15.7012 7.10776 15.7265H2.36079C2.04741 15.7299 1.73776 15.7964 1.44952 15.9222C1.16128 16.048 0.9001 16.2307 0.680889 16.4597C0.461679 16.6887 0.288734 16.9596 0.17193 17.257C0.0551263 17.5543 -0.00324836 17.8723 0.000139439 18.1928V20.8164C-0.00262388 21.4316 0.219554 22.0256 0.623071 22.4819C1.02659 22.9382 1.58231 23.2238 2.18118 23.2827L5.31161 24.1485L4.95238 24.7257C4.04626 26.2234 3.56643 27.9497 3.56678 29.7107V36.4536C3.5392 37.7714 4.02314 39.0466 4.91272 40.0005Z"
          fill={color || '#E37639'}
        />
        <path
          d="M13.8293 23.2845C13.9735 23.3116 14.1213 23.3116 14.2655 23.2845H18.3301C18.9822 23.2845 19.5108 22.7559 19.5108 22.1038C19.5108 21.4518 18.9822 20.9232 18.3301 20.9232H14.4964L10.9298 19.4539C12.2384 16.1743 13.5983 12.8685 13.9063 12.3962C14.2832 11.9371 14.8137 11.6378 15.3945 11.5566H18.0875C18.7396 11.5566 19.2682 11.028 19.2682 10.376C19.2682 9.72391 18.7396 9.19531 18.0875 9.19531H15.3945C14.7658 9.24284 14.1536 9.42368 13.597 9.72632C13.0404 10.029 12.5515 10.4468 12.1614 10.9532C10.772 13.7966 9.53006 16.7129 8.44083 19.6901C8.33712 19.9726 8.34314 20.285 8.45767 20.5631C8.5722 20.8412 8.78654 21.064 9.05666 21.1855L13.8293 23.2845Z"
          fill={color || '#E37639'}
        />
      </g>
      <path
        d="M36.8828 30.1783C37.0108 30.1792 37.1371 30.1513 37.253 30.0967C37.3687 30.0421 37.4709 29.9624 37.5527 29.8638C37.553 29.8634 37.5533 29.8631 37.5536 29.8627L48.6942 16.6612C48.8549 16.4883 48.9433 16.2594 48.9433 16.0227C48.9433 15.7835 48.853 15.5521 48.6891 15.3787L48.6891 15.3787L48.6883 15.3779C48.605 15.2905 48.5053 15.2206 48.3948 15.1728C48.2842 15.1249 48.1652 15.1001 48.0447 15.1001C47.9243 15.1001 47.8053 15.1249 47.6947 15.1728C47.5842 15.2206 47.4845 15.2905 47.4012 15.3779L47.3962 15.3832L47.3915 15.3887L36.8096 27.865L32.0128 22.3283L32.0071 22.3217L32.001 22.3156C31.831 22.1448 31.6017 22.0478 31.3612 22.0478C31.1207 22.0478 30.8915 22.1448 30.7215 22.3156L30.7212 22.3153L30.7125 22.3251C30.5537 22.5022 30.4668 22.7333 30.4668 22.9717C30.4668 23.2094 30.5532 23.4399 30.711 23.6167L36.1456 29.8822L36.1454 29.8824L36.1533 29.8906C36.3249 30.0712 36.5604 30.1757 36.809 30.1783L36.809 30.1783H36.8116H36.8828ZM36.8828 30.1783C36.8824 30.1783 36.8821 30.1783 36.8817 30.1783L36.8839 29.9283M36.8828 30.1783H36.8839V29.9283M36.8839 29.9283H36.8116M36.8839 29.9283C36.9744 29.9291 37.064 29.9094 37.1464 29.8705C37.2288 29.8317 37.302 29.7746 37.3609 29.7034L36.8116 29.9283M36.8116 29.9283C36.632 29.9264 36.4605 29.851 36.3345 29.7184L30.8986 23.4515C30.7818 23.3211 30.7168 23.1497 30.7168 22.9717C30.7168 22.7937 30.7818 22.6223 30.8986 22.492L36.8116 29.9283Z"
        fill={color || '#E37639'}
        stroke={color || '#E37639'}
        strokeWidth="0.5"
      />
      <path
        d="M33.3757 2.66255L33.6813 2.54422L33.6772 2.5358L38.6759 0.600824C38.6759 0.600814 38.6759 0.600804 38.676 0.600794C38.7615 0.567867 38.8495 0.543532 38.9386 0.527793L38.9386 0.527931L38.9476 0.526065C39.213 0.470816 39.4906 0.492464 39.7463 0.590904C39.7463 0.590912 39.7464 0.59092 39.7464 0.590928L51.1814 5.0174C51.2637 5.04925 51.3407 5.08757 51.4123 5.13141L51.4354 5.14554L51.4607 5.15531L57.3808 7.44697C57.3808 7.44698 57.3809 7.447 57.3809 7.44701C57.925 7.65794 58.2823 8.17905 58.2823 8.76244V20.3155V20.3526L58.2914 20.3886C58.319 20.4987 58.3338 20.6143 58.3338 20.7337V22.8561C58.3338 32.1948 54.6721 38.1348 50.5881 41.8649C46.4949 45.6034 41.9632 47.1338 40.2259 47.6135L40.2039 47.6196L40.183 47.629C40.0888 47.6712 39.9882 47.7038 39.8822 47.7251C39.8814 47.7253 39.8807 47.7254 39.8799 47.7256L39.465 47.8014L39.4602 47.8023L39.4402 47.8055L39.4402 47.8054L39.4318 47.8071C39.3538 47.8223 39.2712 47.8291 39.1843 47.8291C39.1058 47.8291 39.0323 47.8234 38.9607 47.8109C38.9604 47.8109 38.9601 47.8108 38.9598 47.8108L38.5423 47.7345C38.5416 47.7343 38.5408 47.7342 38.5401 47.734C38.4006 47.706 38.2705 47.6584 38.1518 47.5949L38.1234 47.5797L38.0925 47.5709C36.2245 47.0378 31.732 45.4516 27.7106 41.7044C23.6979 37.9654 20.1399 32.0627 20.1399 22.8571V19.1115V19.1038L20.1395 19.0961C20.1383 19.0716 20.1376 19.047 20.1376 19.0221V8.75255C20.1376 8.1693 20.4948 7.64821 21.0411 7.4372L21.0414 7.43711L33.3757 2.66255ZM39.1603 44.9462L39.2302 44.9628L39.3 44.946C40.5689 44.6402 44.6039 43.4664 48.3262 40.1936C52.0589 36.9118 55.4591 31.534 55.4591 22.866V9.93546V9.72988L55.2674 9.65568L39.3079 3.47935L39.1996 3.43744L39.0914 3.47935L23.1549 9.64677L22.9631 9.72097V9.92655V22.8571C22.9631 31.5694 26.3982 36.9576 30.153 40.2347C33.8973 43.5025 37.9417 44.6565 39.1603 44.9462Z"
        fill={color || '#E37639'}
        stroke={color || '#FFFFFF'}
        strokeWidth="0.6"
      />
      <defs>
        <clipPath id="clip0">
          <rect
            width="31"
            height="39"
            fill="white"
            transform="translate(0 9)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
