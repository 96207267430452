import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Input from 'components/Fields/Input'
import { Container } from 'components/Container'
import Icon from 'components/Icon'
import Button from 'components/Button'
import { ModalActionDelete, ModalActionSave } from 'containers/Order/Modals'

import { getFormattedPrice } from 'utils/getPrice'
import { getValuesWithSelect } from 'utils/getValuesWithSelect'

import { colors } from 'constants/inlineColorConfig'

import style from './styles.module.scss'

export const FinancialServices = ({
  isInputsActive = true,
  access,
  financialServices,
  updateFinancialServices,
  deleteFinancialService,
  addService,
  fetchFinancialServices,
  isSent,
}) => {
  const [isShowModalDelete, setIsShowModalDelete] = useState(false)
  const [isShowModalSave, setIsShowModalSave] = useState(false)

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm()

  useEffect(() => {
    fetchFinancialServices()
  }, [])

  useEffect(() => {
    updateFinancialServices()
  }, [isSent])

  return (
    <div>
      {isShowModalDelete && (
        <ModalActionDelete
          handleCloseModal={() => setIsShowModalDelete(false)}
        />
      )}
      {isShowModalSave && (
        <ModalActionSave handleCloseModal={() => setIsShowModalSave(false)} />
      )}
      <Container className={style.container}>
        <h2 className={style.title}>Дополнительные финансовые услуги</h2>
        <div className={style.list}>
          {!!financialServices?.length &&
            financialServices.map(({ name, price }) => (
              <div className={style.item} key={name}>
                <div className={style.itemName}>
                  <span className={style.itemTitle}>{name}</span>
                  <div onClick={() => deleteFinancialService(name, price)}>
                    <Icon.Close
                      width={16}
                      height={16}
                      color={colors.blackPearl}
                    />
                  </div>
                </div>
                {price && (
                  <div className={style.itemPrice}>
                    {getFormattedPrice(price)} ₽
                  </div>
                )}
              </div>
            ))}
        </div>
        <form onSubmit={handleSubmit(addService)}>
          <div className={style.form}>
            <div className={style.select}>
              <Input
                label="Услуги"
                name="service"
                control={control}
                error={errors['service']}
                required
                rules={{ required: 'Это поле необходимо заполнить' }}
                disabled={!isInputsActive || !access}
              />
            </div>
            <div className={style.input}>
              <Input
                label="Сумма, ₽"
                name="price"
                control={control}
                error={errors['price']}
                required
                rules={{ required: 'Это поле необходимо заполнить' }}
                disabled={!isInputsActive || !access}
              />
            </div>
            <div className={style.action}>
              {access && (
                <Button.Outlined disabled={!isInputsActive} type="submit">
                  Добавить
                </Button.Outlined>
              )}
            </div>
          </div>
        </form>
      </Container>
    </div>
  )
}
