import { createAsyncThunk } from '@reduxjs/toolkit'

import { getOrders } from 'api/orders'

export const loadOrdersThunk = createAsyncThunk(
  'orders/allOrders',
  async (_, thunkAPI) => {
    const res = await getOrders()
    if (res.data.error) {
      return { items: [], total: res.data.length }
    }
    return { items: res.data, total: res.data.length }
  }
)
