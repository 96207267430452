export const IconPDF = ({ width, height, color }) => {
  return (
    <svg
      width={width || '46'}
      height={height || '46'}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.125 25.875V23H34.5V37.375H37.375V31.625H41.6875V28.75H37.375V25.875H43.125Z"
        fill="#E37639"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.975 31.225H41.2875V29.15H36.975V25.475H42.725V23.4H34.9V36.975H36.975V31.225ZM37.375 37.375H34.5V23H43.125V25.875H37.375V28.75H41.6875V31.625H37.375V37.375Z"
        fill="#F7F8F8"
      />
      <path
        d="M27.3125 37.375H21.5625V23H27.3125C28.4559 23.0011 29.5521 23.4559 30.3606 24.2644C31.1691 25.0729 31.6239 26.1691 31.625 27.3125V33.0625C31.6239 34.2059 31.1691 35.3021 30.3606 36.1106C29.5521 36.9191 28.4559 37.3739 27.3125 37.375ZM24.4375 34.5H27.3125C27.6936 34.4996 28.059 34.348 28.3285 34.0785C28.598 33.809 28.7496 33.4436 28.75 33.0625V27.3125C28.7496 26.9314 28.598 26.566 28.3285 26.2965C28.059 26.027 27.6936 25.8754 27.3125 25.875H24.4375V34.5Z"
        fill="#E37639"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0375 34.9V25.475H27.3125C27.7996 25.4755 28.267 25.6692 28.6114 26.0136C28.9558 26.358 29.1495 26.825 29.15 27.3121L29.15 33.0625C29.1495 33.5496 28.9558 34.017 28.6114 34.3614C28.267 34.7058 27.8 34.8995 27.3129 34.9H24.0375ZM21.9625 36.975H27.3121C28.3495 36.974 29.3442 36.5614 30.0778 35.8278C30.8114 35.0942 31.224 34.0995 31.225 33.0621V27.3129C31.224 26.2755 30.8114 25.2808 30.0778 24.5472C29.3443 23.8137 28.3497 23.4011 27.3123 23.4H21.9625V36.975ZM31.625 27.3125C31.6239 26.1691 31.1691 25.0729 30.3606 24.2644C29.5521 23.4559 28.4559 23.0011 27.3125 23H21.5625V37.375H27.3125C28.4559 37.3739 29.5521 36.9191 30.3606 36.1106C31.1691 35.3021 31.6239 34.2059 31.625 33.0625V27.3125ZM28.75 33.0625C28.7496 33.4436 28.598 33.809 28.3285 34.0785C28.059 34.348 27.6936 34.4996 27.3125 34.5H24.4375V25.875H27.3125C27.6936 25.8754 28.059 26.027 28.3285 26.2965C28.598 26.566 28.7496 26.9314 28.75 27.3125V33.0625Z"
        fill="#F7F8F8"
      />
      <path
        d="M15.8125 23H8.625V37.375H11.5V33.0625H15.8125C16.5746 33.0614 17.3053 32.7581 17.8442 32.2192C18.3831 31.6803 18.6864 30.9496 18.6875 30.1875V25.875C18.6867 25.1127 18.3836 24.3819 17.8446 23.8429C17.3056 23.3039 16.5748 23.0008 15.8125 23ZM11.5 30.1875V25.875H15.8125L15.8139 30.1875H11.5Z"
        fill="#E37639"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8125 23H8.625V37.375H11.5V33.0625H15.8125C16.5746 33.0614 17.3053 32.7581 17.8442 32.2192C18.3831 31.6803 18.6864 30.9496 18.6875 30.1875V25.875C18.6867 25.1127 18.3836 24.3819 17.8446 23.8429C17.3056 23.3039 16.5748 23.0008 15.8125 23ZM18.2875 25.8754C18.2868 25.2191 18.0258 24.5898 17.5617 24.1258C17.0977 23.6617 16.4685 23.4007 15.8123 23.4H9.025V36.975H11.1V32.6625H15.8119C16.4682 32.6615 17.0973 32.4004 17.5613 31.9363C18.0254 31.4723 18.2865 30.8432 18.2875 30.1869V25.8754ZM11.1 30.5875V25.475H16.2124L16.2141 30.5875H11.1ZM11.5 30.1875V25.875H15.8125L15.8139 30.1875H11.5Z"
        fill="#F7F8F8"
      />
      <path
        d="M31.625 20.125V14.375C31.6301 14.1861 31.5942 13.9983 31.5197 13.8246C31.4453 13.6509 31.3341 13.4954 31.1938 13.3688L21.1313 3.30625C21.0047 3.16587 20.8492 3.05462 20.6755 2.98016C20.5017 2.90571 20.3139 2.86983 20.125 2.875H5.75C4.9882 2.87728 4.25826 3.18091 3.71958 3.71958C3.18091 4.25826 2.87728 4.9882 2.875 5.75V40.25C2.875 41.0125 3.1779 41.7438 3.71707 42.2829C4.25623 42.8221 4.9875 43.125 5.75 43.125H28.75V40.25H5.75V5.75H17.25V14.375C17.2523 15.1368 17.5559 15.8667 18.0946 16.4054C18.6333 16.9441 19.3632 17.2477 20.125 17.25H28.75V20.125H31.625ZM20.125 14.375V6.325L28.175 14.375H20.125Z"
        fill="#E37639"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2251 14.3643C31.2287 14.2331 31.2037 14.1028 31.152 13.9822C31.1004 13.8616 31.0232 13.7536 30.9258 13.6657L30.9182 13.6588L20.8411 3.58177L20.8342 3.57409C20.7463 3.47668 20.6384 3.39948 20.5179 3.34782C20.3974 3.29616 20.267 3.27126 20.1359 3.27485L20.125 3.27515L5.75062 3.275C5.0947 3.27711 4.46624 3.53861 4.00242 4.00242C3.53861 4.46624 3.27711 5.0947 3.275 5.75062V40.25C3.275 40.9064 3.53576 41.5359 3.99991 42.0001C4.46406 42.4642 5.09359 42.725 5.75 42.725H28.35V40.65H5.35V5.35H17.65L17.65 14.3738C17.65 14.3739 17.65 14.3737 17.65 14.3738C17.652 15.0298 17.9135 15.6587 18.3774 16.1226C18.8412 16.5864 19.4697 16.8479 20.1256 16.85H29.15V19.725H31.225L31.2251 14.3643ZM31.625 20.125H28.75V17.25H20.125C19.3632 17.2477 18.6333 16.9441 18.0946 16.4054C17.5559 15.8667 17.2523 15.1368 17.25 14.375V5.75H5.75V40.25H28.75V43.125H5.75C4.9875 43.125 4.25623 42.8221 3.71707 42.2829C3.1779 41.7438 2.875 41.0125 2.875 40.25V5.75C2.87728 4.9882 3.18091 4.25826 3.71958 3.71958C4.25826 3.18091 4.9882 2.87728 5.75 2.875H20.125C20.3139 2.86983 20.5017 2.90571 20.6755 2.98016C20.8492 3.05462 21.0047 3.16587 21.1313 3.30625L31.1938 13.3688C31.3341 13.4954 31.4453 13.6509 31.5197 13.8246C31.5942 13.9983 31.6301 14.1861 31.625 14.375V20.125ZM19.725 14.775V5.35932L29.1407 14.775H19.725ZM20.125 14.375V6.325L28.175 14.375H20.125Z"
        fill="#F7F8F8"
      />
    </svg>
  )
}
