import { useState, useEffect } from 'react'
import clsx from 'clsx'

import SelectDefault from 'components/Fields/Select'
import Calendar from '../Calendar'
import Icon from 'components/Icon'

import {
  removeDateToLs,
  setCityIdToLs,
  setDealerIdToLs,
} from 'utils/localStorage'

import styles from './styles.module.scss'

export default function Filter({
  control,
  cityId,
  setCityId,
  dealerId,
  setDealerId,
  dealersByCityId,
  citiesWithSelect,
  setValue,
  selectedDate,
  setSelectedDate,
  currentStatus,
  actualStatus,
}) {
  const [isOpenCalendar, setIsOpenCalendar] = useState(false)

  const handleChangeCity = (e) => {
    if (e.value) {
      setCityId(e.value)
      setCityIdToLs(e.value)
    }
  }

  const handleChangeDealer = (e) => {
    if (e.value) {
      setDealerIdToLs(e.value)
      setDealerId(e.value)
    }
  }

  const handleOpenCalendar = () => {
    setIsOpenCalendar(true)
  }

  const handleResetDate = (e) => {
    e.stopPropagation()
    removeDateToLs()
    setSelectedDate(null)
  }

  useEffect(() => {
    if (cityId !== null) {
      const currentCity =
        citiesWithSelect.length && cityId !== null
          ? citiesWithSelect.filter(({ value }) => value == cityId)
          : null

      setValue('city', currentCity)
    } else if (dealerId !== null) {
      const currentDealer =
        dealersByCityId.length && dealerId !== null
          ? dealersByCityId.filter(({ value }) => value == dealerId)
          : null

      setValue('dealer', currentDealer)
    }
  }, [citiesWithSelect])

  useEffect(() => {
    if (dealerId !== null) {
      const currentDealer =
        dealersByCityId.length && dealerId !== null
          ? dealersByCityId.filter(({ value }) => value == dealerId)
          : null

      setValue('dealer', currentDealer)
    }
  }, [dealersByCityId])

  return (
    <div
      className={clsx(styles.container, {
        [styles.flex]: cityId !== null,
      })}
    >
      {isOpenCalendar && (
        <Calendar
          setSelectedDate={setSelectedDate}
          setIsOpenCalendar={setIsOpenCalendar}
        />
      )}
      <SelectDefault
        name="city"
        label="Выберите город"
        control={control}
        className={styles.select}
        orders
        bold
        hiddenLabel
        options={citiesWithSelect}
        isSearchable
        change={handleChangeCity}
      />
      {cityId !== null && (
        <SelectDefault
          name="dealer"
          label="Выберите дилера"
          control={control}
          className={styles.select}
          orders
          bold
          hiddenLabel
          options={dealersByCityId}
          isSearchable
          change={handleChangeDealer}
        />
      )}
      {cityId !== null && dealerId !== null && actualStatus == 1 && (
        <div className={styles.wrapperCalendar} onClick={handleOpenCalendar}>
          {selectedDate !== null ? (
            <div className={styles.selectedDate}>
              <div className={styles.icon}>
                <Icon.Calendar />
                <span>
                  {'c ' +
                    selectedDate.startDate +
                    ' по ' +
                    selectedDate.endDate}
                </span>
              </div>
              <div
                className={styles.resetBtn}
                onClick={(e) => handleResetDate(e)}
              >
                <Icon.Close />
              </div>
            </div>
          ) : (
            <div className={styles.input}>
              {selectedDate === null && <span>Выберите дату</span>}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
