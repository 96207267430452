import axios from 'axios'

import { getTokenFromLs } from 'utils/token'
import { getDateFromLs, getDealerIdToLs } from './localStorage'

const LADA_TOKEN = 'Basic c2VvdGx0OnBnSTZjS1pNKk90cQ=='

export const request = axios.create({
  ...(process.env.NODE_ENV === 'development'
    ? {}
    : { baseUrl: 'https://v6.lada.ru' }),
  headers: {
    Accept: '*/*',
    Authorization: `Bearer ${getTokenFromLs()}`,
    'content-type': 'application/json',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
  },
})

request.interceptors.request.use(
  async (config) => {
    if (getTokenFromLs()) {
      config.headers['Authorization'] = `Bearer ${getTokenFromLs()}`
      config.headers['entid'] = getDealerIdToLs()

      if (getDateFromLs()) {
        const date = getDateFromLs()
        config.headers['startdate'] = date.startDate
        config.headers['enddate'] = date.endDate
      }
    }
    return config
  },
  (error) => {
    console.warn(error)
    throw error
  }
)

request.interceptors.response.use(
  (response) => {
    if (response && response.data && response.data.error) {
      console.warn({
        status: response.status,
        url: response.config.url,
        data: response.data,
      })
    }
    return response
  },
  (error) => {
    // whatever you want to do with the error
    console.warn(error)
    throw error
  }
)

export const requestLada = axios.create({
  headers: {
    Accept: '*/*',
    Authorization: LADA_TOKEN,
    'content-type': 'application/json',
  },
})

request.interceptors.response.use(
  (response) => {
    if (response && response.data && response.data.error) {
      console.warn({
        status: response.status,
        url: response.config.url,
        data: response.data,
      })
    }
    return response
  },
  (error) => {
    // whatever you want to do with the error
    console.warn(error)
    throw error
  }
)

export const requestLadaFile = axios.create({
  headers: {
    Accept: '*/*',
    Authorization: LADA_TOKEN,
    processData: false,
    contentType: false,
  },
})

requestLadaFile.interceptors.response.use(
  (response) => {
    if (response && response.data && response.data.error) {
      console.warn({
        status: response.status,
        url: response.config.url,
        data: response.data,
      })
    }
    return response
  },
  (error) => {
    // whatever you want to do with the error
    console.warn(error)
    throw error
  }
)
