export const IconSupport = ({ width, height, color }) => {
  return (
    <svg
      width={width || 48}
      height={height || 48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.1742 46.6113C7.53208 46.6113 7.15008 46.33 6.94151 46.0957C6.59701 45.702 6.17987 44.8091 7.26492 43.2155C9.68577 39.6698 10.5248 37.7856 12.1394 33.6891C12.3527 33.1477 12.9644 32.8829 13.5034 33.0938C14.0447 33.3071 14.3119 33.9188 14.101 34.4578C12.5074 38.505 11.5934 40.5697 9.23347 44.0709C13.2198 41.9805 16.5804 39.8104 19.2262 37.6051C19.4559 37.4129 19.7512 37.3262 20.0465 37.3708C21.4432 37.5653 22.7345 37.659 24 37.659C36.0715 37.659 45.8908 30.1316 45.8908 20.8841C45.8908 11.6319 36.0715 4.10448 24 4.10448C11.9285 4.10448 2.10917 11.6319 2.10917 20.8841C2.10917 25.5618 4.71516 30.0707 9.25925 33.2509C9.73499 33.586 9.84982 34.2422 9.51704 34.7156C9.18426 35.1936 8.52807 35.3108 8.05 34.978C2.93643 31.3971 0 26.2601 0 20.8841C0 10.4671 10.7661 2 24 2C37.2339 2 48 10.4671 48 20.8841C48 31.2964 37.2339 39.7682 24 39.7682C22.779 39.7682 21.5346 39.6862 20.2176 39.5174C17.4358 41.7813 13.9557 43.9959 9.86623 46.1168C9.16317 46.48 8.60775 46.6113 8.1742 46.6113Z"
        fill={color || '#E37639'}
      />
      <path
        d="M30.7113 31.908H20.6435C18.7546 31.908 17.9461 31.0667 17.4094 30.5112C17.0251 30.1082 16.9079 30.0027 16.5095 30.0027H13.8238C13.2497 30.0027 12.7786 29.541 12.7716 28.9645L12.5958 17.5281C12.5912 17.2469 12.7036 16.9751 12.8982 16.7712C13.0974 16.572 13.3692 16.4571 13.6504 16.4571H18.0164C18.1781 16.3751 18.9398 15.651 19.4952 14.9503L20.0904 14.2917C21.3161 12.9442 22.1269 12.056 22.1269 10.4132V9.57656C22.1269 8.72587 22.6261 8.02984 23.4299 7.75565C24.4377 7.40881 25.6164 7.81658 26.3523 8.76805C27.3038 9.9984 27.7303 10.9217 27.7303 13.1387C27.7303 14.6292 27.3858 15.5502 27.1819 16.1009C27.1421 16.2111 27.1046 16.3001 27.0858 16.3751L27.0952 18.8053C27.0952 22.1331 25.0751 25.0415 21.9535 26.2109C21.4169 26.4148 20.8005 26.1406 20.599 25.5945C20.3927 25.0485 20.6693 24.4415 21.2153 24.2376C23.5049 23.3799 24.9884 21.2473 24.9884 18.8053V16.2861C24.9884 15.9603 25.0962 15.6721 25.2087 15.3674C25.3821 14.8964 25.6235 14.2495 25.6235 13.1387C25.6235 11.4326 25.3657 10.9381 24.6861 10.057C24.5267 9.8531 24.3533 9.77342 24.2338 9.74998V10.4132C24.2338 12.8716 22.9776 14.2495 21.6465 15.7096L21.1099 16.3048C20.3131 17.3125 19.0921 18.564 18.0164 18.564H14.7191L14.8644 27.8959H16.5095C17.8219 27.8959 18.464 28.5638 18.9304 29.0512C19.3663 29.5012 19.6498 29.8012 20.6435 29.8012H30.7113C31.0581 29.8012 31.6604 29.2082 31.6604 28.8708C31.6604 28.8122 31.6627 28.7536 31.6744 28.695L33.0056 20.7552C33.097 20.2068 32.9446 19.6514 32.5884 19.2295C32.2275 18.81 31.7073 18.564 31.1518 18.564H29.5231C28.9419 18.564 28.4685 18.0929 28.4685 17.5141C28.4685 16.9305 28.9419 16.4571 29.5231 16.4571H31.1518C32.3283 16.4571 33.4368 16.9727 34.1937 17.8703C34.953 18.7679 35.2764 19.9443 35.0843 21.1043L33.7625 28.9715C33.6969 30.4409 32.1877 31.908 30.7113 31.908Z"
        fill={color || '#E37639'}
      />
    </svg>
  )
}
