export const statuses = [
  { value: 0, label: 'Активные заказы' },
  // { value: 1, label: 'Завершенные заказы' },
  { value: 2, label: 'Автомобили для размещения' },
  { value: 3, label: 'Размещенные в онлайн-шоуруме' },
  // { value: 4, label: 'Автомобили для бейджа' },
]

export const statusesRoot = [
  { value: 0, label: 'Тип: активные заказы' },
  // { value: 1, label: 'Тип: завершенные заказы' },
  { value: 2, label: 'Тип: автомобили для размещения' },
  { value: 3, label: 'Тип: размещенные в онлайн-шоуруме' },
  // { value: 4, label: 'Автомобили для бейджа' },
]
