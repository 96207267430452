export const IconInstallment = ({ width, height, color }) => {
  return (
    <svg
      width="29"
      height="23"
      viewBox="0 0 29 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.969238 1.64286C0.969238 0.735533 1.74078 0 2.69252 0H26.5052C27.457 0 28.2285 0.735532 28.2285 1.64286V21.3571C28.2285 22.2645 27.457 23 26.5052 23H2.69252C1.74078 23 0.969238 22.2645 0.969238 21.3571V1.64286ZM2.69252 0.896104C2.25991 0.896104 1.90921 1.23044 1.90921 1.64286V21.3571C1.90921 21.7696 2.25991 22.1039 2.69252 22.1039H26.5052C26.9378 22.1039 27.2885 21.7696 27.2885 21.3571V1.64286C27.2885 1.23044 26.9378 0.896104 26.5052 0.896104H2.69252Z"
        fill="white"
      />
      <path
        d="M6.72129 16.9473C6.39457 16.9473 6.10542 16.8883 5.85383 16.7701C5.60338 16.6519 5.39562 16.4835 5.23056 16.2648C5.06549 16.045 4.9414 15.781 4.8583 15.4729C4.77633 15.1647 4.73535 14.819 4.73535 14.4358C4.73535 14.0537 4.77633 13.7085 4.8583 13.4004C4.9414 13.0922 5.06549 12.8282 5.23056 12.6085C5.39562 12.3876 5.60281 12.2186 5.85212 12.1015C6.10143 11.9844 6.39115 11.9259 6.72129 11.9259C7.12997 11.9259 7.46865 12.0098 7.73731 12.1777C8.00711 12.3456 8.21032 12.5582 8.34692 12.8155C8.48467 13.0718 8.57916 13.3402 8.63038 13.6207C8.68161 13.9001 8.70723 14.1718 8.70723 14.4358C8.70723 14.6998 8.68161 14.972 8.63038 15.2525C8.57916 15.532 8.4841 15.8009 8.34522 16.0594C8.20747 16.3167 8.00427 16.5293 7.7356 16.6972C7.46808 16.864 7.12997 16.9473 6.72129 16.9473ZM6.72129 16.119C7.09582 16.119 7.33602 15.9566 7.4419 15.6319C7.54891 15.3061 7.60241 14.9074 7.60241 14.4358C7.60241 13.9642 7.54891 13.566 7.4419 13.2413C7.33602 12.9166 7.09582 12.7543 6.72129 12.7543C6.357 12.7543 6.11908 12.9216 6.00751 13.2562C5.89595 13.5909 5.84017 13.9841 5.84017 14.4358C5.84017 14.8875 5.89595 15.2813 6.00751 15.617C6.11908 15.9517 6.357 16.119 6.72129 16.119Z"
        fill="white"
      />
      <path
        d="M9.56444 15.4199V14.5716H11.5845V15.4199H9.56444Z"
        fill="white"
      />
      <path
        d="M14.4465 16.9473C14.1197 16.9473 13.8306 16.8883 13.579 16.7701C13.3286 16.6519 13.1208 16.4835 12.9557 16.2648C12.7907 16.045 12.6666 15.781 12.5835 15.4729C12.5015 15.1647 12.4605 14.819 12.4605 14.4358C12.4605 14.0537 12.5015 13.7085 12.5835 13.4004C12.6666 13.0922 12.7907 12.8282 12.9557 12.6085C13.1208 12.3876 13.328 12.2186 13.5773 12.1015C13.8266 11.9844 14.1163 11.9259 14.4465 11.9259C14.8552 11.9259 15.1938 12.0098 15.4625 12.1777C15.7323 12.3456 15.9355 12.5582 16.0721 12.8155C16.2098 13.0718 16.3043 13.3402 16.3556 13.6207C16.4068 13.9001 16.4324 14.1718 16.4324 14.4358C16.4324 14.6998 16.4068 14.972 16.3556 15.2525C16.3043 15.532 16.2093 15.8009 16.0704 16.0594C15.9326 16.3167 15.7294 16.5293 15.4608 16.6972C15.1933 16.864 14.8552 16.9473 14.4465 16.9473ZM14.4465 16.119C14.821 16.119 15.0612 15.9566 15.1671 15.6319C15.2741 15.3061 15.3276 14.9074 15.3276 14.4358C15.3276 13.9642 15.2741 13.566 15.1671 13.2413C15.0612 12.9166 14.821 12.7543 14.4465 12.7543C14.0822 12.7543 13.8443 12.9216 13.7327 13.2562C13.6211 13.5909 13.5653 13.9841 13.5653 14.4358C13.5653 14.8875 13.6211 15.2813 13.7327 15.617C13.8443 15.9517 14.0822 16.119 14.4465 16.119Z"
        fill="white"
      />
      <path
        d="M17.2896 15.4199V14.5716H19.3097V15.4199H17.2896Z"
        fill="white"
      />
      <path
        d="M22.4449 15.7396H20.1294V14.8914L22.438 12.0618H23.5992V14.9179H24.1934V15.7396H23.5992V16.8115H22.4449V15.7396ZM21.0036 14.9179H22.4449V13.127L21.0036 14.9179Z"
        fill="white"
      />
      <path
        d="M5.36301 6.9724H6.3368C6.55564 6.9724 6.72365 7.01437 6.84085 7.09831C6.95804 7.18225 7.03488 7.28055 7.07135 7.3932C7.10782 7.50586 7.12606 7.621 7.12606 7.73863C7.12606 8.26601 6.85908 8.5297 6.32513 8.5297H5.85611V9.34728H5.36301V6.9724ZM5.85611 7.43379V8.06831H6.24125C6.35455 8.06831 6.4416 8.04373 6.50239 7.99459C6.56366 7.94488 6.5943 7.86095 6.5943 7.74277C6.5943 7.61355 6.56171 7.52988 6.49655 7.49178C6.43139 7.45312 6.34629 7.43379 6.24125 7.43379H5.85611Z"
        fill="white"
      />
      <path
        d="M7.96637 6.9724H8.4653L9.25163 9.34728H8.72863L8.59368 8.90245H7.83507L7.70377 9.34728H7.18368L7.96637 6.9724ZM7.94302 8.49905H8.48645L8.21729 7.59284L7.94302 8.49905Z"
        fill="white"
      />
      <path
        d="M10.8316 8.50899H11.321C11.3084 8.64373 11.2748 8.77848 11.2204 8.91322C11.1659 9.04741 11.0701 9.16476 10.933 9.26527C10.7959 9.36522 10.6086 9.4152 10.3713 9.4152C10.2405 9.4152 10.1155 9.39698 9.99639 9.36053C9.87773 9.32464 9.76905 9.26168 9.67033 9.17167C9.57161 9.08165 9.49283 8.95712 9.43399 8.79808C9.37564 8.63849 9.34646 8.44051 9.34646 8.20416C9.34646 7.97664 9.37685 7.76569 9.43764 7.5713C9.49843 7.37636 9.60371 7.21649 9.75349 7.09169C9.90326 6.96688 10.1031 6.90448 10.3531 6.90448C10.568 6.90448 10.745 6.94617 10.8841 7.02956C11.0237 7.11295 11.1258 7.22091 11.1905 7.35344C11.2556 7.48543 11.2914 7.62763 11.2977 7.78004H10.8134C10.7822 7.50393 10.6368 7.36587 10.3772 7.36587C10.1807 7.36587 10.0484 7.45091 9.98034 7.621C9.91226 7.79109 9.87822 7.97857 9.87822 8.18345C9.87822 8.32427 9.89646 8.45294 9.93293 8.56946C9.9694 8.68598 10.0263 8.77931 10.1036 8.84944C10.1809 8.91902 10.2772 8.95381 10.3925 8.95381C10.5398 8.95381 10.6466 8.90742 10.7127 8.81465C10.7788 8.72132 10.8185 8.61944 10.8316 8.50899Z"
        fill="white"
      />
      <path
        d="M13.017 8.50899H13.5064C13.4938 8.64373 13.4602 8.77848 13.4058 8.91322C13.3513 9.04741 13.2555 9.16476 13.1184 9.26527C12.9812 9.36522 12.794 9.4152 12.5567 9.4152C12.4259 9.4152 12.3009 9.39698 12.1818 9.36053C12.0631 9.32464 11.9544 9.26168 11.8557 9.17167C11.757 9.08165 11.6782 8.95712 11.6194 8.79808C11.561 8.63849 11.5319 8.44051 11.5319 8.20416C11.5319 7.97664 11.5622 7.76569 11.623 7.5713C11.6838 7.37636 11.7891 7.21649 11.9389 7.09169C12.0887 6.96688 12.2885 6.90448 12.5385 6.90448C12.7534 6.90448 12.9304 6.94617 13.0695 7.02956C13.2091 7.11295 13.3112 7.22091 13.3759 7.35344C13.441 7.48543 13.4768 7.62763 13.4831 7.78004H12.9987C12.9676 7.50393 12.8222 7.36587 12.5625 7.36587C12.3661 7.36587 12.2338 7.45091 12.1657 7.621C12.0977 7.79109 12.0636 7.97857 12.0636 8.18345C12.0636 8.32427 12.0818 8.45294 12.1183 8.56946C12.1548 8.68598 12.2117 8.77931 12.289 8.84944C12.3663 8.91902 12.4626 8.95381 12.5779 8.95381C12.7252 8.95381 12.832 8.90742 12.8981 8.81465C12.9642 8.72132 13.0039 8.61944 13.017 8.50899Z"
        fill="white"
      />
      <path
        d="M13.8157 6.9724H14.7895C15.0083 6.9724 15.1764 7.01437 15.2936 7.09831C15.4108 7.18225 15.4876 7.28055 15.5241 7.3932C15.5605 7.50586 15.5788 7.621 15.5788 7.73863C15.5788 8.26601 15.3118 8.5297 14.7778 8.5297H14.3088V9.34728H13.8157V6.9724ZM14.3088 7.43379V8.06831H14.694C14.8073 8.06831 14.8943 8.04373 14.9551 7.99459C15.0164 7.94488 15.047 7.86095 15.047 7.74277C15.047 7.61355 15.0144 7.52988 14.9493 7.49178C14.8841 7.45312 14.799 7.43379 14.694 7.43379H14.3088Z"
        fill="white"
      />
      <path
        d="M16.8465 9.4152C16.6413 9.4152 16.4687 9.37516 16.3286 9.29509C16.1886 9.21446 16.0792 9.10982 16.0004 8.98115C15.9216 8.85192 15.8667 8.7169 15.8355 8.57609C15.8044 8.43527 15.7888 8.29638 15.7888 8.15943C15.7888 8.02247 15.8044 7.88331 15.8355 7.74194C15.8671 7.60057 15.9221 7.46582 16.0004 7.33771C16.0787 7.20904 16.1876 7.10466 16.3272 7.02459C16.4672 6.94452 16.6403 6.90448 16.8465 6.90448C17.0318 6.90448 17.1923 6.93817 17.328 7.00554C17.4641 7.07236 17.5745 7.1643 17.6591 7.28138C17.7442 7.3979 17.8062 7.53099 17.8451 7.68064C17.8845 7.82974 17.9042 7.98934 17.9042 8.15943C17.9042 8.32951 17.8845 8.48911 17.8451 8.63821C17.8062 8.78731 17.7442 8.9204 17.6591 9.03747C17.5745 9.15455 17.4641 9.24677 17.328 9.31414C17.1923 9.38152 17.0318 9.4152 16.8465 9.4152ZM16.8465 8.95381C16.9773 8.95381 17.0977 8.90107 17.2076 8.7956C17.3175 8.69012 17.3725 8.47806 17.3725 8.15943C17.3725 7.95344 17.3469 7.79164 17.2959 7.67401C17.2448 7.55639 17.1799 7.47576 17.1011 7.43214C17.0228 7.38796 16.938 7.36587 16.8465 7.36587C16.6836 7.36587 16.5557 7.42882 16.4628 7.55473C16.37 7.68009 16.3235 7.88165 16.3235 8.15943C16.3235 8.3643 16.3488 8.52556 16.3994 8.64318C16.45 8.76081 16.5141 8.84198 16.592 8.88672C16.6702 8.93145 16.7551 8.95381 16.8465 8.95381Z"
        fill="white"
      />
      <path
        d="M18.1821 6.9724H18.6745V7.54562C18.6745 7.67484 18.6978 7.77397 18.7445 7.843C18.7912 7.91147 18.8904 7.94571 19.0421 7.94571C19.1399 7.94571 19.2544 7.93688 19.3857 7.91921V6.9724H19.8788V9.34728H19.3857V8.3806C19.2233 8.39827 19.066 8.4071 18.9138 8.4071C18.728 8.4071 18.5816 8.38198 18.4746 8.33172C18.3681 8.28092 18.2928 8.20443 18.2485 8.10227C18.2043 7.99956 18.1821 7.86481 18.1821 7.69804V6.9724Z"
        fill="white"
      />
      <path
        d="M20.8387 7.96973L21.5558 6.9724H22.1357L21.2961 8.05837L22.1831 9.34728H21.5798L20.8387 8.18676H20.8329V9.34728H20.3398V6.9724H20.8329V7.96973H20.8387Z"
        fill="white"
      />
      <path
        d="M22.9081 6.9724H23.4071L24.1934 9.34728H23.6704L23.5355 8.90245H22.7768L22.6455 9.34728H22.1255L22.9081 6.9724ZM22.8848 8.49905H23.4282L23.1591 7.59284L22.8848 8.49905Z"
        fill="white"
      />
    </svg>
  )
}
