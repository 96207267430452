import clsx from 'clsx'

import style from './styles.module.scss'

const Heading_1 = ({ title, className }) => {
  return <h1 className={clsx(style.h1, className)}>{title}</h1>
}

const Heading_2 = ({ title, className, children }) => {
  return <h2 className={clsx(style.h2, className)}>{title || children}</h2>
}

const Heading_3 = ({ title, className }) => {
  return <h3 className={clsx(style.h3, className)}>{title}</h3>
}

const Heading_4 = ({ title, className, children }) => {
  return <h4 className={clsx(style.h4, className)}>{title || children}</h4>
}

const Paragraph = ({ title, className, children }) => {
  return <p className={clsx(style.p, className)}>{title || children}</p>
}

const Text = {
  H1: Heading_1,
  H2: Heading_2,
  H3: Heading_3,
  H4: Heading_4,
  P: Paragraph,
}

export default Text
