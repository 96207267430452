import React, { useEffect, useState, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import { FooterActions } from './FooterActions'
import { CarInfo } from 'containers/NewCar/CarInfo'
import { Accessories } from 'containers/Order/Accessories'
import { Discounts } from 'containers/Order/Discounts'
import { Services } from 'containers/Order/Services'
import { Gifts } from 'containers/Order/Gifts'
import { MemoizedDiscounts } from 'components/DiscountsTetsing'
import { Notifications } from 'components/Notifications'

import { loadAuthToken } from 'store/auth/slice'
import { getUserRole } from 'store/auth/selectors'

import { getCarById, getCarForBageById, setCarInShowroom } from 'api/cars'
import { getAccessoriesByKomplId } from 'api/accessories'
import { updateFullPayment } from 'api/payment'

import { ROLE } from 'constants/role'

import { percentCalculation } from './utils'
import { getCookie } from 'utils/cookie'

import style from './styles.module.scss'
import { BageCarHeader } from './BageCarHeader'
import { CreditProgram } from './CreditProgram'

export default function BadgeCar({ isMobile, isTablet }) {
  const [isLoading, setIsLoading] = useState(true)
  const [isSent, setIsSent] = useState(false)
  const [carInfo, setCarInfo] = useState([])
  const [accessories, setAccessories] = useState(null)
  const [isOpenNotifications, setIsOpenNotifications] = useState({
    isOpen: false,
    currentTitle: '',
  })
  const [totalPriceWithPercent, setTotalPriceWithPercent] = useState(0)
  const [creditData, setCreditData] = useState([])
  const [isCreditError, setIsCreditError] = useState(false)

  const dispatch = useDispatch()

  const { id } = useParams()

  const checkData = !!carInfo && !!accessories

  const { role } = useSelector((state) => ({
    role: getUserRole(state),
  }))
  const access = role === ROLE.admin ? false : true

  async function fetchCarInfo() {
    try {
      // const res = await getCarById(id)
      const res = await getCarForBageById(id)
      setCarInfo(res.data)
      setIsLoading(false)
    } catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    fetchCarInfo()
  }, [id, isSent])

  const updatePayment = async (val) => {
    try {
      await updateFullPayment({
        carId: carInfo.car_id,
        payment: val,
      })
      setIsSent((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (Object.keys(carInfo).length) {
      async function fetchAccessories() {
        try {
          const res = await getAccessoriesByKomplId(carInfo.complectation_id)
          setAccessories(res.data)
          setIsLoading(false)
        } catch (e) {
          console.warn(e)
        }
      }

      fetchAccessories()
    }
  }, [carInfo])

  useEffect(() => {
    if (carInfo.modification !== undefined) {
      setTotalPriceWithPercent({
        totalCarPricePercent: percentCalculation(
          carInfo.total_price
            ? carInfo.total_price
            : carInfo.modification.total_price,
          carInfo.price ? carInfo.price : carInfo.modification.price
        ),
        totalCarPrice: carInfo.total_price
          ? carInfo.total_price
          : carInfo.modification.total_price,
        initialCarPrice: carInfo.price
          ? carInfo.price
          : carInfo.modification.price,
      })
    }
  }, [carInfo])

  useEffect(() => {
    dispatch(loadAuthToken())
  }, [])

  return (
    <>
      <Notifications
        {...isOpenNotifications}
        setIsOpenNotifications={setIsOpenNotifications}
      />
      {!isLoading && checkData && (
        <>
          {/* <CarInfo
            {...carInfo}
            updatePayment={updatePayment}
            goBack={goBack}
            access={access}
          /> */}
          <BageCarHeader {...carInfo} />
          {carInfo?.accessories && (
            <Accessories
              {...accessories}
              refetchOrderInfo={fetchCarInfo}
              data={carInfo.accessories}
              carId={carInfo.car_id}
              access={access}
              background
              {...totalPriceWithPercent}
            />
          )}
          <MemoizedDiscounts
            refetchOrderInfo={fetchCarInfo}
            activeDealerOffers={carInfo?.dealer_actions}
            activeOffers={carInfo?.special_offers}
            carId={carInfo.car_id}
            access={access}
            bage
            {...totalPriceWithPercent}
          />
          <Services
            refetchOrderInfo={fetchCarInfo}
            data={carInfo.additional_services}
            carId={carInfo.car_id}
            access={access}
            background
            {...totalPriceWithPercent}
          />
          <Gifts data={carInfo.gifts} carId={carInfo.car_id} access={access} />
          <CreditProgram
            price={
              carInfo.total_price
                ? Number(carInfo.total_price)
                : Number(carInfo.modification.total_price)
            }
            setCreditData={setCreditData}
            carInfo={carInfo}
            credit_data={carInfo.credit_data || []}
            isCreditError={isCreditError}
            setIsCreditError={setIsCreditError}
          />
          <FooterActions
            carId={carInfo.car_id}
            creditData={creditData}
            setIsCreditError={setIsCreditError}
          />
        </>
      )}
    </>
  )
}
