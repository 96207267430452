import ActiveOrdersChief from './ActiveOrdersChief'
import ActiveOrdersManager from './ActiveOrdersManager'
import CompletedOrdersChief from './CompletedOrdersChief'
import CarsForPlacingChief from './CarsForPlacingChief'
import CarsPlacedChief from './CarsPlacedChief'
import CompletedOrdersManager from './CompletedOrdersManager'
import CarsForBadgeChief from './CarsForBadgeChief'
import PersonalOffer from './PersonalOffer'
import CoporateOffer from './CoporateOffer'

const Table = {
  ActiveOrdersChief: ActiveOrdersChief,
  ActiveOrdersManager: ActiveOrdersManager,
  CompletedOrdersChief: CompletedOrdersChief,
  CarsForPlacingChief: CarsForPlacingChief,
  CarsPlacedChief: CarsPlacedChief,
  CompletedOrdersManager: CompletedOrdersManager,
  CarsForBadgeChief: CarsForBadgeChief,
  PersonalOffer: PersonalOffer,
  CoporateOffer: CoporateOffer,
}

export default Table
