import { PDFViewer } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { PdfList } from 'components/Pdf/PerfonaKPCreator/PdfList'

import { getOfferData } from 'containers/PersonalOffer/actions'
import { getOfferDataCO } from 'containers/CorporateOffer/actions'

export default function PDFViewerForTest() {
  const { id } = useParams()

  const [type, setType] = useState(null)

  const [val, setVal] = useState({})

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let path = window.location.pathname

      if (path.includes('personal')) {
        getOfferData(id).then((res) => {
          setVal(res)
        })

        setType('personal')
      } else if (path.includes('corporate')) {
        getOfferDataCO(id).then((res) => {
          setVal(res)
        })

        setType('corporate')
      }
    }
  }, [])

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      {!!Object.keys(val).length && !!type && (
        <PdfList data={val} type={type} />
      )}
    </PDFViewer>
  )
}
