import clsx from 'clsx'

import styles from './styles.module.scss'

export const TabItem = ({
  isActive,
  title,
  id,
  setActive,
  disabled,
  isMenu,
  putIdWithIndex,
  putId,
  putClass = '',
  additionalIndexForClass,
  classItem,
  metricEvent = () => {},
  onChange = () => {},
}) => {
  const handleSetActiveTab = disabled ? () => {} : () => setActive(id)
  const computedId = putId ? `${putId}${putIdWithIndex ? id : ''}` : ''

  let putClassWithId = putId ? putId + '_' + putClass : '0_' + putClass

  if (additionalIndexForClass) {
    putClassWithId = putId
      ? putId + '_' + putClass + '_' + additionalIndexForClass
      : '0_' + putClass + '_' + additionalIndexForClass
  }

  const handleClick = () => {
    handleSetActiveTab()
    onChange(id)
  }

  return (
    <li
      data-test-name={title.toString().toLowerCase().replace(/\s/g, '')}
      id={computedId}
      className={clsx(classItem, {
        [styles.selected]: isActive,
        [styles.disabled]: disabled,
        'burger-menu__base-name': isMenu,
        [putClassWithId]: putClass,
      })}
      onClick={handleClick}
      onMouseDown={() => metricEvent(title)}
    >
      <span className="faq_category">
        {title.replace('niva legend bronto', 'niva bronto')}
      </span>
    </li>
  )
}
