import clsx from 'clsx'

import styles from './styles.module.scss'

export const StatsItem = ({ bright, amount, text = 'Всего' }) => {
  return (
    <div className={styles.container}>
      <span className={styles.text}>{text}</span>
      <span
        className={clsx(styles.amount, {
          [styles.bright]: bright,
        })}
      >
        {amount}
      </span>
    </div>
  )
}
