import { requestLada, request } from '../utils/request'

export async function getInsurance() {
  return requestLada.get(`/api/static-data/insurance`)
}

export async function getInsuranceByOrder(orderId) {
  return request.get(`/api/os-order-insurance/${orderId}`)
}

export async function setInsuranceToOrder(data) {
  return request.post(`/api/os-order-insurance`, { ...data })
}

export async function setInsuranceToOrderUpdate(data) {
  return request.put(`/api/os-order-insurance`, { ...data })
}

export async function deleteInsuranceOrder(data) {
  return request.delete(`/api/os-order-insurance`, { data })
}
