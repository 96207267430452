import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import { Container } from 'components/Container'
import Tables from './Tables'
import Button from 'components/Button'
import Icon from 'components/Icon'
import Filter from './Filter'

import { loadDealersById } from 'store/dealers/thunks'
import { getDealersById } from 'store/dealers/selectors'
import { getCitiesWithId } from 'store/city/selectors'
import { loadCitiesWithId } from 'store/city/thunks'
import { loadAuthToken } from 'store/auth/slice'

import { getValuesWithSelect } from 'utils/getValuesWithSelect'
import {
  getCityIdToLs,
  getDateFromLs,
  getDealerIdToLs,
} from 'utils/localStorage'

import styles from './styles.module.scss'

export default function TableSupervisor() {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const currentStatus = query.get('status')

  const [actualStatus, setActualStatus] = useState(currentStatus)

  const [cityId, setCityId] = useState(getCityIdToLs() || null)
  const [dealerId, setDealerId] = useState(getDealerIdToLs() || null)
  const [selectedDate, setSelectedDate] = useState(getDateFromLs() || null)
  const [citiesWithSelect, setCitiesWithSelect] = useState([])
  const [dealersByCityId, setDealersByCityId] = useState([])

  const dispatch = useDispatch()

  const {
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {},
  })

  const { cities, dealers } = useSelector((state) => ({
    cities: getCitiesWithId(state),
    dealers: getDealersById(state),
  }))

  useEffect(() => {
    dispatch(loadCitiesWithId())

    if (cityId !== null) {
      dispatch(loadDealersById(cityId))
    }
  }, [cityId])

  useEffect(() => {
    if (Array.isArray(cities) && !!cities.length) {
      setCitiesWithSelect(getValuesWithSelect(cities, ['id', 'name']))
    }
  }, [cities])

  useEffect(() => {
    if (Array.isArray(dealers) && !!dealers.length) {
      setDealersByCityId(
        getValuesWithSelect(dealers, ['dealerCode', 'dealerName'])
      )
    }
  }, [dealers])

  useEffect(() => {
    dispatch(loadAuthToken())
  }, [])

  return (
    <>
      <div className={styles.background}>
        <Container className={styles.container}>
          <div className={styles.topWrap}>
            <h2 className={styles.title} id="request">
              Реестр автомобилей
            </h2>
            {cityId !== null && dealerId !== null && (
              <Button.Outlined to="/analytics" className={styles.analytics}>
                <Icon.Analytics />
                Аналитика
              </Button.Outlined>
            )}
          </div>
          <Filter
            control={control}
            cityId={cityId}
            setCityId={setCityId}
            dealerId={dealerId}
            setDealerId={setDealerId}
            citiesWithSelect={citiesWithSelect}
            dealersByCityId={dealersByCityId}
            setValue={setValue}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            currentStatus={currentStatus}
            actualStatus={actualStatus}
          />
          {cityId !== null && dealerId !== null && (
            <Tables
              control={control}
              watch={watch}
              dealerId={dealerId}
              selectedDate={selectedDate}
              setActualStatus={setActualStatus}
            />
          )}
        </Container>
      </div>
    </>
  )
}
