export const IconTrash = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6.6665H5.77778H20"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.4445 6.66688V4.88911C8.4445 4.41761 8.6318 3.96543 8.9652 3.63203C9.2986 3.29863 9.75078 3.11133 10.2223 3.11133H13.7778C14.2493 3.11133 14.7015 3.29863 15.0349 3.63203C15.3683 3.96543 15.5556 4.41761 15.5556 4.88911V6.66688M18.2223 6.66688V19.1113C18.2223 19.5828 18.035 20.035 17.7016 20.3684C17.3682 20.7018 16.916 20.8891 16.4445 20.8891H7.55561C7.08411 20.8891 6.63193 20.7018 6.29853 20.3684C5.96513 20.035 5.77783 19.5828 5.77783 19.1113V6.66688H18.2223Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2222 11.1113V16.4447"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7778 11.1113V16.4447"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
