import React from 'react'
import { useForm } from 'react-hook-form'

import { ModalAction } from 'components/ModalAction'
import Input from 'components/Fields/Input'
import Button from 'components/Button'

import style from './styles.module.scss'

export const ModalActionDelete = ({ handleCloseModal }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useForm({})

  return (
    <ModalAction title="Удалить?" handleClose={handleCloseModal}>
      <div className={style.modal}>
        <Button.Outlined onClick={handleCloseModal}>
          Да, удалить
        </Button.Outlined>
      </div>
    </ModalAction>
  )
}

export const ModalActionSave = ({ handleCloseModal, handleSave }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useForm({})

  return (
    <ModalAction title="Сохранить изменения?" handleClose={handleCloseModal}>
      <div className={style.modal}>
        <Button.Outlined onClick={handleSave || handleCloseModal}>
          Да, сохранить
        </Button.Outlined>
      </div>
    </ModalAction>
  )
}

export const ModalActionCancel = ({ handleCloseModal }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useForm({})

  return (
    <ModalAction
      title="Отмена заказа"
      description="Укажите причину отмены заказа"
      handleClose={handleCloseModal}
    >
      <div className={style.modal}>
        <Input
          label="Причина"
          name="reason"
          control={control}
          error={errors['reason']}
        />
        <Button.Outlined onClick={handleCloseModal}>
          Отменить заказ
        </Button.Outlined>
      </div>
    </ModalAction>
  )
}

export const ModalActionApply = ({ handleCloseModal }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useForm({})

  return (
    <ModalAction
      title="Реализация"
      description="Передать заказ в реализацию?"
      handleClose={handleCloseModal}
    >
      <div className={style.modal}>
        <Button.Outlined onClick={handleCloseModal}>
          Да, передать
        </Button.Outlined>
      </div>
    </ModalAction>
  )
}

export const ModalWarning = ({ handleCloseModal }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useForm({})

  return (
    <ModalAction
      title="нарушено условие"
      description="Итоговая стоимость автомобиля  должна составлять не менее 94 и не более 130% РРЦ"
      handleClose={handleCloseModal}
    >
      <div className={style.modal}>
        <Button.Outlined onClick={handleCloseModal}>понятно!</Button.Outlined>
      </div>
    </ModalAction>
  )
}
