import React, { useEffect, useState } from 'react'

import 'moment/locale/ru'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DayPickerRangeController } from 'react-dates'
import Cleave from 'cleave.js/react'
import moment from 'moment'
import './react_dates_overrides.scss'

import Button from 'components/Button'
import Icon from 'components/Icon'

import { setDateReportToLs, setDateToLs } from 'utils/localStorage'

import styles from './styles.module.scss'
import clsx from 'clsx'

const LIMIT_RANGE_DAYS = 180
const cleaveOption = {
  delimiter: '.',
  blocks: [2, 2, 4],
}

const useRangeDates = (customLimitRangeDay = false, visibleDays = 1) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [focusedInput, setFocusedInput] = useState('startDate')

  const isOutsideRange = (date) =>
    !date.isBetween(
      moment().subtract(customLimitRangeDay ? 1 : LIMIT_RANGE_DAYS, 'd'),
      moment().add(visibleDays, 'd'),
      'd'
    )

  const converToMomentIfNeeded = (date) =>
    typeof date === 'string' ? moment(date, 'L', true) : date

  const updateStartDate = (start) => {
    const newStartDate = converToMomentIfNeeded(start)

    if (!newStartDate) {
      return
    }

    const validStartDateFormat =
      newStartDate.isValid() && !isOutsideRange(newStartDate)
    const isAfterCurrentEnd = endDate && newStartDate.isAfter(endDate, 'd')

    if (validStartDateFormat) {
      if (isAfterCurrentEnd) {
        setEndDate(null)
      }

      setFocusedInput('endDate')
      setStartDate(newStartDate)
    }
  }

  const updateStartEnd = (end) => {
    const newEndDate = converToMomentIfNeeded(end)

    if (!newEndDate) {
      return
    } else if (end === null && endDate) {
      setEndDate(null)
      return
    }

    const validEndDateFormat =
      newEndDate.isValid() && !isOutsideRange(newEndDate)
    const isBeforeCurrentStart =
      startDate && newEndDate.isBefore(startDate, 'd')

    if (validEndDateFormat && !isBeforeCurrentStart) {
      setEndDate(newEndDate)
    }
  }

  const onDatesChange = (newDates) => {
    updateStartDate(newDates.startDate)
    updateStartEnd(newDates.endDate)
  }

  useEffect(() => {
    if (endDate === null) {
      setEndDate(startDate)
    }
  }, [startDate])

  return {
    focusedInput,
    onDatesChange,
    isOutsideRange,
    startDate,
    endDate,
    startDateString: (startDate && startDate.format('L')) || '',
    endDateString: (endDate && endDate.format('L')) || '',
    onFocusChange: (focused) => {
      setFocusedInput(!focused ? 'startDate' : focused)
    },
  }
}

export default function Calendar({
  setSelectedDate,
  setIsOpenCalendar,
  reportType = false,
  isMobile = false,
  oneDate = false,
  visibleDays = 1,
  customLimitRangeDay = false,
  className = '',
}) {
  const {
    focusedInput,
    onDatesChange,
    isOutsideRange,
    startDateString,
    endDateString,
    startDate,
    endDate,
    onFocusChange,
  } = useRangeDates(customLimitRangeDay, visibleDays)

  const smallMode = isMobile || oneDate

  const handleClose = () => {
    setIsOpenCalendar(false)
  }

  const handleApplyChanges = () => {
    setSelectedDate({
      startDate: startDate.format('L'),
      endDate: endDate.format('L'),
    })

    if (reportType) {
      setDateReportToLs(
        JSON.stringify({
          startDate: startDate.format('L'),
          endDate: endDate.format('L'),
        }),
        reportType
      )
    } else {
      setDateToLs(
        JSON.stringify({
          startDate: startDate.format('L'),
          endDate: endDate.format('L'),
        })
      )
    }
    setIsOpenCalendar(false)
  }

  return (
    <div
      className={clsx(styles.container, {
        [styles.position]: reportType,
        [className]: className,
      })}
    >
      <div className={styles.closeBtn} onClick={handleClose}>
        <Icon.Close />
      </div>
      <DayPickerRangeController
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        isOutsideRange={isOutsideRange}
        startDate={startDate}
        endDate={oneDate ? startDate : endDate}
        numberOfMonths={smallMode ? 1 : 2}
        hideKeyboardShortcutsPanel
        initialVisibleMonth={() => {
          const test = moment().subtract(smallMode ? 0 : 1, 'months')
          return test
        }}
        small={smallMode}
      />
      <div className={styles.wrapper}>
        <div className={styles.inputs}>
          {oneDate ? (
            <div>
              <Cleave
                options={cleaveOption}
                onChange={(e) => onDatesChange({ startDate: e.target.value })}
                value={startDateString}
              />
            </div>
          ) : (
            <>
              <div>
                <span>Начало</span>
                <Cleave
                  options={cleaveOption}
                  onChange={(e) => onDatesChange({ startDate: e.target.value })}
                  value={startDateString}
                />
              </div>
              <span className={styles.line} />
              <div>
                <span>Завершение</span>
                <Cleave
                  options={cleaveOption}
                  onChange={(e) => onDatesChange({ endDate: e.target.value })}
                  value={endDateString}
                />
              </div>
            </>
          )}
        </div>
        <Button.Default
          children={'Применить'}
          onClick={handleApplyChanges}
          disabled={!startDate || endDate === null}
        />
      </div>
    </div>
  )
}
