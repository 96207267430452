export const IconLongArrowLeft = ({ width, height, color }) => {
  return (
    <svg
      width={width || 19}
      height={height || 8}
      viewBox="0 0 19 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.646446 3.50997C0.451185 3.70523 0.451185 4.02182 0.646446 4.21708L3.82843 7.39906C4.02369 7.59432 4.34027 7.59432 4.53553 7.39906C4.7308 7.2038 4.7308 6.88721 4.53553 6.69195L1.70711 3.86353L4.53553 1.0351C4.7308 0.839836 4.7308 0.523254 4.53553 0.327991C4.34027 0.132729 4.02369 0.132729 3.82843 0.327991L0.646446 3.50997ZM19 3.36353L1 3.36353V4.36353L19 4.36353V3.36353Z"
        fill={color || '#66727D'}
      />
    </svg>
  )
}
