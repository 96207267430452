export const IconFavorite = ({ width, height, color }) => {
  return (
    <svg
      width={width || '21'}
      height={height || '20'}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5023 2L13.1285 7.26577L19 8.11261L14.7534 12.2117L15.7548 18L10.5023 15.2658L5.2498 18L6.25114 12.2117L2 8.11261L7.87604 7.26577L10.5023 2Z"
        stroke={color || '#A6ADB5'}
        strokeWidth="1.6"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
