export const getSpacialOffersInfo = (data = [], fields = ['', '', '', '']) => {
  return data.map((item, index) => ({
    discount: item[fields[0]],
    name: item[fields[1]],
    id: item[fields[2]],
    isDealer: item[fields[3]],
    prefix: item[fields[4]],
  }))
}

export const formattedActions = (arr) => {
  const result = arr.map(({ id, name, title, prefix, is_dealer, benefit }) => {
    return {
      brand_action_id: id,
      label: name || title,
      is_dealer: is_dealer,
      prefix: prefix,
      priceBenefit: benefit ? benefit : null,
    }
  })

  return result
}

export function percentCalculation(price, carPrice) {
  let result = price / (carPrice / 100)

  return `${result.toFixed(2) + '%'}`
}

export function checkAvailable(price, carPrice, currentPercent, mode, family) {
  let percent = percentCalculation(price, carPrice).replace('%', '')
  let replaceCurrentPercent = currentPercent.replace('%', '')
  let totalPercent = Number(percent) + Number(replaceCurrentPercent)

  switch (mode) {
    case 'isFull':
      return totalPercent <= 130
      break
    case 'isAcc':
      return totalPercent <= 20
      break
    case 'isDiscountAdd':
      let result = Number(replaceCurrentPercent) - Number(percent)
      return family === 'vesta' ? result > 0 : result >= 94
      return result >= 94
      break
    case 'isDiscountRemove':
      let res = Number(percent) + Number(replaceCurrentPercent)
      return res <= 130
      break
    case 'isServices':
      return totalPercent
      break
    default:
      return false
  }
}
