import React from 'react'
import s from './s.module.scss'
import { useSelector } from 'react-redux'
import { getUserName, getUserRole } from '../../store/auth/selectors'
import { ROLE_ALIAS } from '../../constants/role'

export const UserInfo = ({ currentDealer }) => {
  const { name, role } = useSelector((state) => ({
    name: getUserName(state),
    role: getUserRole(state),
  }))

  return (
    <div className={s.manager}>
      {currentDealer && (
        <div className={s.dealer}>
          <span>Дилерский центр:</span>
          <span>{currentDealer}</span>
        </div>
      )}
      <span>{ROLE_ALIAS[role]}</span>
      <span className={s.name}>{name}</span>
    </div>
  )
}
