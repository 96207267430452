import React from 'react'

import styles from './styles'
import { Document, Image, Page, Text, View } from '@react-pdf/renderer'

import logo from 'assets/img/pdfImages/logoLADAWhite.png'
import engine from 'assets/img/pdfImages/engine.png'
import color from 'assets/img/pdfImages/color.png'

import { getPrice } from 'utils/getPrice'
import { wordFormatter } from 'utils/wordFormatter'
import moment from 'moment'

export const PdfList = ({ data, type }) => {
  const {
    model_name,
    background,
    image,
    complectation,
    color_name,
    modification,
    total_price,
    credit,
    city,
    dealer_address,
    dealer,
    dealer_phone,
    date,
    tradein,
    lastName,
    name,
    middleName,
    email,
    phone,
    manager_phone,
    manager_email,
    manager_name,
    accessories,
    services,
    color_price,
    price,
    discounts,
    managerFIO,
    managerPosition,
    deliverDate,
    paymentType,
    amountValue,
  } = data

  console.log(credit, '>creditcredit')
  const currentYear = moment().year()
  const replacedModelName = model_name.replace(' Legend 3 дв.', '')

  const accessorriesAndServiceMath = () => {
    let accPrice = !!accessories.length
      ? accessories.reduce((acc, { price }) => acc + price, 0)
      : 0
    let servicesPrice = !!services.length
      ? services.reduce((acc, { price }) => acc + price, 0)
      : 0

    return accPrice + servicesPrice
  }

  const accessorriesAndServicePrice = accessorriesAndServiceMath()

  const offersTotalBenefit = !!discounts.length
    ? discounts.reduce((acc, { benefit }) => acc + benefit, 0)
    : 0

  const isDealerBenefit = discounts.filter(({ prefix }) => prefix === 'dd_d')

  const totalPriceMath = () => {
    let tradeInPrice = Number(tradein?.cost) > 0 ? Number(tradein?.cost) : 0

    return Math.round(
      total_price -
        offersTotalBenefit -
        tradeInPrice +
        accessorriesAndServicePrice
    )
  }

  const totalPrice = totalPriceMath()

  const totalPriceAllCars = !!amountValue
    ? totalPrice * amountValue
    : totalPrice

  const isShowTradein = Number(tradein?.cost) > 0

  return (
    <Document language="ru" title={'lada'}>
      <Page wrap style={styles.page} pageNumber={1}>
        <View style={{ ...styles.header, marginTop: '-20px' }}>
          <Image style={styles.headerBg} src={background.desktop} />
          <View style={styles.headerContent}>
            <View style={styles.headerGradient}></View>
            <Image style={styles.headerImg} src={logo} />
            <Text style={styles.headerTitle}>Коммерческое предложение</Text>
            <Text style={styles.headerDate}>{date}</Text>
          </View>
        </View>

        <View style={styles.section_1}>
          <Image style={styles.headerCar} src={image} />

          <Text style={styles.modelTitle}>{model_name}</Text>
          <View style={styles.complInfoBlock}>
            <View style={styles.complInfoItem}>
              <Text style={{ ...styles.complTitle, maxWidth: 80 }}>
                КОМПЛЕКТАЦИЯ АВТОМОБИЛЯ
              </Text>
              <Text style={styles.complName}>{complectation}</Text>
            </View>

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                width: '55%',
              }}
            >
              <View style={{ ...styles.complInfoItem, width: '50%' }}>
                <Image style={styles.complImage} src={engine} />
                <View>
                  <Text style={styles.complTitle}>двигатель</Text>
                  <Text style={styles.complTitle}>и кпп</Text>
                  <Text style={styles.complValue}>{modification.title}</Text>
                </View>
              </View>

              <View style={{ ...styles.complInfoItem, width: '50%' }}>
                <Image style={styles.complImage} src={color} />
                <View>
                  <Text style={styles.complTitle}>Цвет кузова</Text>
                  <Text style={styles.complValue}>{color_name}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.welcomeSection}>
          <View>
            <Text style={styles.welcomeLeft}>
              Уважаемый(-ая) {wordFormatter(name)}
              {!!middleName ? ` ${wordFormatter(middleName)}` : ''}!
            </Text>
          </View>
          {type === 'corporate' ? (
            <View style={styles.welcomeRight}>
              <Text style={styles.welcomeElem}>
                Выражаем Вам благодарность за проявленный интерес к автомобилям
                марки LADA производства АО "АВТОВАЗ». В ответ на Ваш запрос
                сообщаем, что дилерский центр ''{dealer}'' готов предложить к
                поставке автомобили {model_name}, которые полностью
                удовлетворяют требованиям технической спецификации.
              </Text>

              {!!isDealerBenefit.length && (
                <Text style={styles.welcomeElem}>
                  Учитывая планируемый объём закупки, а также особый статус
                  Вашей компании, в течение {currentYear} года будет действовать
                  специальная корпоративная скидка в размере{' '}
                  {isDealerBenefit[0].benefit} руб. от рекомендованной розничной
                  цены на дату оплаты.
                </Text>
              )}

              <Text style={styles.welcomeElem}>
                Просим обратить Ваше внимание на то, что производство
                автомобилей LADA осуществляется в течение 60 календарных дней с
                момента размещения заказа в производство.
              </Text>

              <Text style={styles.welcomeElemBold}>
                {managerPosition} официального дилера {dealer}
              </Text>
              <Text style={styles.welcomeElemBold}>{managerFIO}</Text>
            </View>
          ) : (
            <View style={styles.welcomeRight}>
              <Text style={styles.welcomeElem}>
                Благодарим Вас за интерес к автомобилям {replacedModelName}!
              </Text>
              <Text style={{ marginTop: '-10px' }}>
                Мы предлагаем широкую гамму модификаций {replacedModelName},
                которые созданы для того, чтобы дарить радость от поездок,
                открывать новые интересные места, сопровождать Вас во всех
                важных делах.
              </Text>
              <Text style={styles.welcomeElem}>
                Специально для Вас мы разработали акции и предложения,
                позволяющие приобрести новый автомобиль {replacedModelName} на
                наиболее выгодных условиях. Подробную информацию Вы можете
                получить у вашего персонального менеджера в салоне бренда LADA
              </Text>
              <Text style={styles.welcomeElemBold}>
                До встречи в официальном дилерском центре {dealer}!
              </Text>
            </View>
          )}
        </View>

        <View style={styles.managingSection}>
          <View style={styles.managingWrapper}>
            <View style={styles.managingItem}>
              <Text style={styles.managingTitle}>Клиент</Text>
              <Text style={styles.managingTitleBorder} />
              <View style={styles.managingBox}>
                <Text style={{ ...styles.managingBoxText, height: '30px' }}>
                  {wordFormatter(lastName)} {wordFormatter(name)}{' '}
                  {!!middleName ? wordFormatter(middleName) : ''}
                </Text>
                <Text style={styles.managingBoxTitle}>Email</Text>
                <Text style={{ ...styles.managingBoxText, height: '30px' }}>
                  {email}
                </Text>
                <Text style={styles.managingBoxTitle}>Телефон</Text>
                <Text style={styles.managingBoxText}>{phone}</Text>
              </View>
            </View>

            <View style={styles.managingItem}>
              <Text style={styles.managingTitle}>Дилер</Text>
              <Text style={styles.managingTitleBorder} />
              <View style={styles.managingBox}>
                <Text style={{ ...styles.managingBoxText, height: '30px' }}>
                  {dealer}
                </Text>
                <Text style={styles.managingBoxTitle}>Адрес</Text>
                <Text style={{ ...styles.managingBoxText, height: '30px' }}>
                  {dealer_address}
                </Text>
                <Text style={styles.managingBoxTitle}>Телефон</Text>
                <Text style={styles.managingBoxText}>{dealer_phone}</Text>
              </View>
            </View>

            <View style={styles.managingItem}>
              <Text style={styles.managingTitle}>Ваш продавец-консультант</Text>
              <Text style={styles.managingTitleBorder} />
              <View style={styles.managingBox}>
                <Text style={{ ...styles.managingBoxText, height: '30px' }}>
                  {manager_name}
                </Text>
                <Text style={styles.managingBoxTitle}>Email</Text>
                <Text style={{ ...styles.managingBoxText, height: '30px' }}>
                  {manager_email}
                </Text>
                <Text style={styles.managingBoxTitle}>Телефон</Text>
                <Text style={styles.managingBoxText}>{manager_phone}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.addsSection} wrap>
          <Text style={styles.optionsTite}>состав комплектации</Text>
          <View style={styles.optionsBox}>
            {modification.options.map(({ title, items }) => (
              <View style={styles.optionsBoxItem} key={title} wrap>
                <Text style={styles.optionsItemTitle}>{title}</Text>
                <View style={styles.optionsItems}>
                  {items.map((el) => (
                    <View style={styles.optionsItem}>
                      <View style={styles.optionsItemDot}></View>
                      <Text style={styles.optionsItemText}>{el}</Text>
                    </View>
                  ))}
                </View>
              </View>
            ))}
          </View>
        </View>

        <View style={styles.addsSection}>
          <View style={styles.addsTitles}>
            <View style={styles.addsTitleWrapper}>
              <Text style={styles.addsTitle}>Дополнительные аксессуары</Text>
              <Text style={styles.managingTitleBorder} />
            </View>
            <View style={styles.addsTitleWrapper}>
              <Text style={styles.addsTitle}>дополнительные услуги</Text>
              <Text style={styles.managingTitleBorder} />
            </View>
          </View>
          <View style={styles.addsBlock}>
            <View style={styles.addsItems}>
              {accessories.map((el, i) => {
                return (
                  <>
                    <View style={styles.addsItemstextBlock}>
                      <Text style={styles.addsTextGrayNameAcc}>{el.name}</Text>
                      <Text style={styles.addsTextGrayAcc}>
                        {getPrice(el.price)}
                        <Text style={styles.rouble}>й</Text>
                      </Text>
                    </View>
                    {/* {el.isPg && (
                        <Text style={styles.pgText}>
                          {PG_list.replaceAll('</b>', '')
                            .replaceAll('<b>', '')
                            .replaceAll('<br/>\r\n', ', ')
                            .replaceAll('<br/>', '')}
                        </Text>
                      )} */}
                  </>
                )
              })}

              {accessories.length === 0 && (
                <Text style={styles.addsText}>
                  Без дополнительных аксессуаров
                </Text>
              )}
            </View>

            <View style={styles.addsItems2}>
              {services.map((el) => (
                <View style={styles.addsItemstextBlock}>
                  <Text style={styles.addsTextGrayName}>{el.name}</Text>
                  <Text style={styles.addsTextGray}>
                    {getPrice(el.price)}
                    <Text style={styles.rouble}>й</Text>
                  </Text>
                </View>
              ))}

              {services.length === 0 && (
                <Text style={styles.addsText}>Без дополнительных услуг</Text>
              )}
            </View>
          </View>
        </View>

        {isShowTradein && (
          <View style={styles.sectionTradein}>
            <Text style={styles.tradeinTitle}>Трейд-ин</Text>
            <View style={styles.tradeinBox}>
              <Text style={styles.tradeinText}>
                {tradein.model}, {tradein.year}, {tradein.run} км
              </Text>
              <Text style={styles.tradeinText}>
                {getPrice(tradein.cost)} <Text style={styles.rouble}>й</Text>
              </Text>
            </View>
          </View>
        )}

        <View style={styles.addsSection} wrap={type !== 'corporate'}>
          <View style={styles.addsTitles}>
            {discounts.length > 0 && (
              <View style={styles.addsTitleWrapper}>
                <Text style={{ ...styles.addsTitle, fontSize: '9px' }}>
                  Стоимость автомобиля
                </Text>
                <Text style={{ ...styles.addsTitle1, fontSize: '9px' }}>
                  в стандартной комплектации
                </Text>
                <Text style={styles.managingTitleBorder} />
              </View>
            )}

            <View style={styles.addsTitleWrapper}>
              <Text
                style={{
                  ...styles.addsTitle1,
                  paddingTop: 11,
                  fontSize: '9px',
                }}
              >
                Персональные выгоды
              </Text>
              <Text style={styles.managingTitleBorder} />
            </View>
          </View>
          <View style={styles.addsBlock}>
            <View style={styles.addsItemsGray}>
              <View style={styles.addsItemstextBlock}>
                <Text style={styles.addsTextGray}>
                  {type === 'corporate' ? 'РРЦ' : 'Цена автомобиля'}
                </Text>
                <Text style={styles.addsTextGray}>
                  {getPrice(price)}
                  <Text style={styles.rouble}>й</Text>
                </Text>
              </View>
              {Number(color_price) > 0 && (
                <View style={styles.addsItemstextBlock}>
                  <Text style={styles.addsTextGray}>Доплата за цвет</Text>
                  <Text style={styles.addsTextGray}>
                    {getPrice(color_price)}
                    <Text style={styles.rouble}>й</Text>
                  </Text>
                </View>
              )}
              {accessorriesAndServicePrice > 0 && (
                <View style={styles.addsItemstextBlock}>
                  <Text style={styles.addsTextGray}>
                    Стоимость аксессуаров и услуг
                  </Text>
                  <Text style={styles.addsTextGray}>
                    {getPrice(accessorriesAndServicePrice)}
                    <Text style={styles.rouble}>й</Text>
                  </Text>
                </View>
              )}
            </View>
            <View
              style={
                discounts.length > 0
                  ? styles.addsItems2Gray
                  : { ...styles.addsItemsGray, width: '100%' }
              }
            >
              {discounts.length > 0 &&
                discounts.map((el) => (
                  <View>
                    <View style={styles.addsItemstextBlock}>
                      <Text style={styles.addsTextGray}>
                        {el.prefix === 'dd_d'
                          ? el.label.replace('скидка', 'выгода')
                          : `Выгода LADA "${el.label}"`}
                      </Text>
                      <Text style={styles.addsTextGray}>
                        - {getPrice(el.benefit)}
                        <Text style={styles.rouble}>й</Text>
                      </Text>
                    </View>
                  </View>
                ))}

              {/*{discounts.length > 0 && (*/}
              {/*  <View*/}
              {/*    style={{*/}
              {/*      ...styles.addsItemstextBlock,*/}
              {/*      borderTop: '1px dashed #4C5865',*/}
              {/*      paddingTop: '5px',*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <Text style={{ ...styles.addsTextGray, ...styles.bold }}>*/}
              {/*      Итоговая выгода*/}
              {/*    </Text>*/}
              {/*    <Text style={{ ...styles.addsTextGray, ...styles.bold }}>*/}
              {/*      {getPrice(offersTotalBenefit)}*/}
              {/*      <Text style={styles.rouble}>й</Text>*/}
              {/*    </Text>*/}
              {/*  </View>*/}
              {/*)}*/}

              {/*<View style={styles.addsItemstextBlock}>*/}
              {/*  <Text style={styles.addsTextGray}>*/}
              {/*    {type === 'corporate' ? 'РРЦ' : 'Цена автомобиля'}*/}
              {/*  </Text>*/}
              {/*  <Text style={styles.addsTextGray}>*/}
              {/*    {getPrice(price)}*/}
              {/*    <Text style={styles.rouble}>й</Text>*/}
              {/*  </Text>*/}
              {/*</View>*/}

              {/*{offersTotalBenefit > 0 && (*/}
              {/*  <View style={styles.addsItemstextBlock}>*/}
              {/*    <Text style={styles.addsTextGray}>Выгода</Text>*/}
              {/*    <Text style={styles.addsTextGray}>*/}
              {/*      -{getPrice(offersTotalBenefit)}*/}
              {/*      <Text style={styles.rouble}>й</Text>*/}
              {/*    </Text>*/}
              {/*  </View>*/}
              {/*)}*/}

              {/*{Number(color_price) > 0 && (*/}
              {/*  <View style={styles.addsItemstextBlock}>*/}
              {/*    <Text style={styles.addsTextGray}>Доплата за цвет</Text>*/}
              {/*    <Text style={styles.addsTextGray}>*/}
              {/*      {getPrice(color_price)}*/}
              {/*      <Text style={styles.rouble}>й</Text>*/}
              {/*    </Text>*/}
              {/*  </View>*/}
              {/*)}*/}

              {isShowTradein && (
                <View style={styles.addsItemstextBlock}>
                  <Text style={styles.addsTextGray}>Трейд-ин автомобиль</Text>
                  <Text style={styles.addsTextGray}>
                    -{getPrice(tradein.cost)}
                    <Text style={styles.rouble}>й</Text>
                  </Text>
                </View>
              )}
            </View>
          </View>
          <View style={styles.totalPrice}>
            <Text style={styles.totalPriceText}>
              Итоговая стоимость выбранного автомобиля с учётом аксессуаров,
              дополнительных услуг
              {isShowTradein ? ', трейд-ин' : ' '} и персональных выгод*
            </Text>
            <Text style={styles.totalPriceValue}>
              {getPrice(totalPrice)}
              <Text
                style={{ ...styles.rouble, lineHeight: '100%', fontSize: 24 }}
              >
                д
              </Text>
            </Text>
          </View>
          {!!amountValue && Number(amountValue) > 1 && (
            <View style={styles.totalPrice}>
              <Text style={styles.totalPriceText}>
                Итоговая стоимость автомобилей количество {amountValue}
              </Text>
              <Text style={styles.totalPriceValue}>
                {getPrice(totalPriceAllCars)}
                <Text
                  style={{ ...styles.rouble, lineHeight: '100%', fontSize: 24 }}
                >
                  д
                </Text>
              </Text>
            </View>
          )}
          {!!deliverDate && !!paymentType && (
            <View style={styles.additionally}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-beetween',
                }}
              >
                <Text style={styles.additionallyText}>Срок поставки</Text>
                <Text style={styles.additionallyText}>Тип оплаты</Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-beetween',
                  alignItems: 'flex-end',
                }}
              >
                <Text style={styles.additionallyText}>до {deliverDate}</Text>
                <Text style={styles.additionallyText}>
                  {paymentType.toLowerCase()}
                </Text>
              </View>
            </View>
          )}

          <View style={styles.creditTextDisclamer}>
            <View>
              <Text style={styles.creditText}>
                * Информация о технических характеристиках, составе
                комплектаций, цветовой гамме и рекомендованных розничных ценах
                носит справочный характер и ни при каких обстоятельствах не
                является публичной офертой, определяемой положениями Статьи 437
                ч.2 Гражданского кодекса Российской Федерации. Для получения
                подробной информации необходимо обратиться к продавцу-
                консультанту.
              </Text>
            </View>
          </View>
        </View>
        {/*{credit?.month_payment > 0 && type === 'corporate' && (*/}
        {/*  <View wrap={type !== 'corporate'}>*/}
        {/*    <View style={styles.creditSection}>*/}
        {/*      <Text style={styles.creditTitle}>*/}
        {/*        Кредитное предложение LADA finanсe*/}
        {/*      </Text>*/}
        {/*      <View style={styles.creditLine}></View>*/}
        {/*      <View style={styles.creditTable}>*/}
        {/*        /!*<Image style={styles.creditImage} src={bankLogo} />*!/*/}
        {/*        <Text style={styles.creditName}>{credit.bank_name}</Text>*/}

        {/*        <View*/}
        {/*          style={{*/}
        {/*            display: 'flex',*/}
        {/*            flexDirection: 'row',*/}
        {/*            justifyContent: 'flex-end',*/}
        {/*            width: '80%',*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <View style={styles.creditItem1}>*/}
        {/*            <View style={styles.creditItemTitleBlock}>*/}
        {/*              <Text style={styles.creditItemTitle}>Ставка</Text>*/}
        {/*              <Text style={styles.creditItemTitle}>по кредиту</Text>*/}
        {/*            </View>*/}
        {/*            <Text style={styles.creditItemText}>*/}
        {/*              {credit.loan_rate}%*/}
        {/*            </Text>*/}
        {/*          </View>*/}

        {/*          <View style={styles.creditItem1}>*/}
        {/*            <View style={styles.creditItemTitleBlock}>*/}
        {/*              <Text style={styles.creditItemTitle}>Срок</Text>*/}
        {/*              <Text style={styles.creditItemTitle}>кредита, мес</Text>*/}
        {/*            </View>*/}
        {/*            <Text style={styles.creditItemText}>*/}
        {/*              {credit.loan_period}*/}
        {/*            </Text>*/}
        {/*          </View>*/}

        {/*          <View style={styles.creditItem2}>*/}
        {/*            <View style={styles.creditItemTitleBlock}>*/}
        {/*              <Text style={styles.creditItemTitle}>Ежемесячный</Text>*/}
        {/*              <Text style={styles.creditItemTitle}>платёж</Text>*/}
        {/*            </View>*/}
        {/*            <Text*/}
        {/*              style={{*/}
        {/*                ...styles.creditItemText,*/}
        {/*                position: 'relative',*/}
        {/*                top: '-8px',*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              {getPrice(credit.month_payment)}*/}
        {/*              <Text style={styles.rouble}>д</Text>*/}
        {/*            </Text>*/}
        {/*          </View>*/}
        {/*        </View>*/}
        {/*      </View>*/}
        {/*    </View>*/}

        {/*    <View style={styles.creditTextSection}>*/}
        {/*      <View>*/}
        {/*        <Text style={styles.creditTextTitle}>*/}
        {/*          Требования к заемщику*/}
        {/*        </Text>*/}
        {/*        <Text style={styles.creditText}>*/}
        {/*          гражданство РФ; наличие регистрации, в регионе присутствия*/}
        {/*          банка; возраст от 22 до 65 лет (срок погашения кредита по*/}
        {/*          договору не должен наступить позже дня 65-летия клиента); стаж*/}
        {/*          работы заемщика на последнем месте работы – не менее 3*/}
        {/*          месяцев.*/}
        {/*        </Text>*/}
        {/*      </View>*/}
        {/*      <View>*/}
        {/*        <Text style={styles.creditTextTitle}>*/}
        {/*          Необходимые документы*/}
        {/*        </Text>*/}
        {/*        <Text style={styles.creditText}>*/}
        {/*          Обязательно паспорт РФ. На выбор: загранпаспорт, водительское*/}
        {/*          удостоверение, страховое пенсионное свидетельство,*/}
        {/*          свидетельство ИНН, военный билет (обязателен для мужчин до 27*/}
        {/*          лет), оригинал справки о доходах по форме 2-НДФЛ заемщика либо*/}
        {/*          справка в свободной форме по форме банка и копия трудовой*/}
        {/*          книжки заемщика, заверенная работодателем.*/}
        {/*        </Text>*/}
        {/*      </View>*/}
        {/*      <View>*/}
        {/*        <Text style={styles.creditTextTitle}>Страхование</Text>*/}
        {/*        <Text style={styles.creditText}>*/}
        {/*          обязательное страхование КАСКО. Страховые компании-партнеры*/}
        {/*          банка: ЗАО «ГУТА-Страхование», ЗАО «Страховая группа*/}
        {/*          «УралСиб», ЗАСО «ЭРГО РУСЬ», ОАО «АльфаСтрахование», СОАО*/}
        {/*          «ВСК», ООО «Группа Ренессанс Страхование», ООО «СК «ОРАНТА»,*/}
        {/*          ОСАО «РЕСО-Гарантия», ООО «Росгосстрах», ООО «СК «Согласие»,*/}
        {/*          ОСАО «ИНГОССТРАХ», ОАО «КапиталЪ Страхование», ООО СК*/}
        {/*          «Московия», ОАО СК «Альянс», ОАО «СОГАЗ», ООО СК «Цюрих», ЗАО*/}
        {/*          «Объединенная страховая компания», ОАО «МСЦ», ОАО СК «Пари»,*/}
        {/*          ООО «Первая страховая компания» (ООО «БИН Страхование»), ОАО*/}
        {/*          «ГСК «Югория», ОСАО «Национальная страховая группа», ООО*/}
        {/*          «Промышленная Страховая Группа «Основа», ОАО «САК*/}
        {/*          «Энергогарант», ООО «СГ «Компаньон», ЗАО «АИГ» (ЗАО «ЧАРТИС»),*/}
        {/*          ООО «Страховая компания Екатеринбург», ОАО «Либерти*/}
        {/*          Страхование» (КИТ Финанс Страхование (ОАО), ООО СО «Геополис»,*/}
        {/*          ОАО «СГ МСК», ООО «СК АЛРОСА», ЗАО «ИФ», СЗАО «Медэкспресс».*/}
        {/*          При оплате КАСКО за наличный расчет - ограничений по страховым*/}
        {/*          компаниям нет.*/}
        {/*        </Text>*/}
        {/*      </View>*/}
        {/*    </View>*/}
        {/*  </View>*/}
        {/*)}*/}
        <Text
          style={styles.pageNumbers}
          render={({ pageNumber }) => `${pageNumber} `}
          fixed
        />
      </Page>
      {credit?.month_payment > 0 && type !== 'corporate' && (
        <Page wrap style={styles.page}>
          <View style={styles.creditSection}>
            <Text style={styles.creditTitle}>
              Кредитное предложение LADA finanсe
            </Text>
            <View style={styles.creditLine}></View>
            <View style={styles.creditTable}>
              {/*<Image style={styles.creditImage} src={bankLogo} />*/}
              <Text style={styles.creditName}>{credit.bank_name}</Text>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '80%',
                }}
              >
                <View style={styles.creditItem1}>
                  <View style={styles.creditItemTitleBlock}>
                    <Text style={styles.creditItemTitle}>Первоначальный</Text>
                    <Text style={styles.creditItemTitle}>взнос</Text>
                  </View>
                  <Text
                    style={{
                      ...styles.creditItemText,
                      position: 'relative',
                      top: '-8px',
                    }}
                  >
                    {getPrice(credit.first_payment)}
                    <Text style={styles.rouble}>д</Text>
                  </Text>
                </View>

                <View style={styles.creditItem1}>
                  <View style={styles.creditItemTitleBlock}>
                    <Text style={styles.creditItemTitle}>Ставка</Text>
                    <Text style={styles.creditItemTitle}>по кредиту</Text>
                  </View>
                  <Text style={styles.creditItemText}>{credit.loan_rate}%</Text>
                </View>

                <View style={styles.creditItem1}>
                  <View style={styles.creditItemTitleBlock}>
                    <Text style={styles.creditItemTitle}>Срок</Text>
                    <Text style={styles.creditItemTitle}>кредита, мес</Text>
                  </View>
                  <Text style={styles.creditItemText}>
                    {credit.loan_period}
                  </Text>
                </View>

                <View style={styles.creditItem2}>
                  <View style={styles.creditItemTitleBlock}>
                    <Text style={styles.creditItemTitle}>Ежемесячный</Text>
                    <Text style={styles.creditItemTitle}>платёж</Text>
                  </View>
                  <Text
                    style={{
                      ...styles.creditItemText,
                      position: 'relative',
                      top: '-8px',
                    }}
                  >
                    {getPrice(credit.month_payment)}
                    <Text style={styles.rouble}>д</Text>
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.creditTextSection}>
            <View>
              <Text style={styles.creditTextTitle}>Требования к заемщику</Text>
              <Text style={styles.creditText}>
                гражданство РФ; наличие регистрации, в регионе присутствия
                банка; возраст от 22 до 65 лет (срок погашения кредита по
                договору не должен наступить позже дня 65-летия клиента); стаж
                работы заемщика на последнем месте работы – не менее 3 месяцев.
              </Text>
            </View>
            <View>
              <Text style={styles.creditTextTitle}>Необходимые документы</Text>
              <Text style={styles.creditText}>
                Обязательно паспорт РФ. На выбор: загранпаспорт, водительское
                удостоверение, страховое пенсионное свидетельство, свидетельство
                ИНН, военный билет (обязателен для мужчин до 27 лет), оригинал
                справки о доходах по форме 2-НДФЛ заемщика либо справка в
                свободной форме по форме банка и копия трудовой книжки заемщика,
                заверенная работодателем.
              </Text>
            </View>
            <View>
              <Text style={styles.creditTextTitle}>Страхование</Text>
              <Text style={styles.creditText}>
                обязательное страхование КАСКО. Страховые компании-партнеры
                банка: ЗАО «ГУТА-Страхование», ЗАО «Страховая группа «УралСиб»,
                ЗАСО «ЭРГО РУСЬ», ОАО «АльфаСтрахование», СОАО «ВСК», ООО
                «Группа Ренессанс Страхование», ООО «СК «ОРАНТА», ОСАО
                «РЕСО-Гарантия», ООО «Росгосстрах», ООО «СК «Согласие», ОСАО
                «ИНГОССТРАХ», ОАО «КапиталЪ Страхование», ООО СК «Московия», ОАО
                СК «Альянс», ОАО «СОГАЗ», ООО СК «Цюрих», ЗАО «Объединенная
                страховая компания», ОАО «МСЦ», ОАО СК «Пари», ООО «Первая
                страховая компания» (ООО «БИН Страхование»), ОАО «ГСК «Югория»,
                ОСАО «Национальная страховая группа», ООО «Промышленная
                Страховая Группа «Основа», ОАО «САК «Энергогарант», ООО «СГ
                «Компаньон», ЗАО «АИГ» (ЗАО «ЧАРТИС»), ООО «Страховая компания
                Екатеринбург», ОАО «Либерти Страхование» (КИТ Финанс Страхование
                (ОАО), ООО СО «Геополис», ОАО «СГ МСК», ООО «СК АЛРОСА», ЗАО
                «ИФ», СЗАО «Медэкспресс». При оплате КАСКО за наличный расчет -
                ограничений по страховым компаниям нет.
              </Text>
            </View>
          </View>
          <Text
            style={styles.pageNumbers}
            render={({ pageNumber }) => `${pageNumber} `}
            fixed
          />
        </Page>
      )}

      <Page wrap style={styles.page}>
        <View style={styles.footer}>
          <View style={styles.footerLeft}>
            <Text style={styles.footerLeftTitle}>Согласие потребителя</Text>
            <Text style={styles.footerLeftText}>
              на предоставление и обработку персональных данных при получении
              коммерческого предложения у официального дилера LADA
            </Text>
          </View>

          <View style={styles.footerRight}>
            <Text style={styles.footerRightText}>
              Настоящим даю согласие официальному дилеру LADA ''{dealer}'',{' '}
              {dealer_address}, на обработку: сбор, запись, систематизацию,
              накопление, хранение, уточнение, обновление, изменение,
              извлечение, использование, передачу (АО «АВТОВАЗ», место
              нахождения: 445024, РФ Самарская область, г. Тольятти, Южное шоссе
              36), обезличивание, блокирование, удаление, уничтожение, с
              использованием или без средств автоматизации, следующих моих
              персональных данных:
            </Text>
            <Text style={styles.footerRightText}>
              фамилия, имя, отчество, контактный телефон; адрес электронной
              почты, данные коммерческого предложения; для получения
              дополнительной информации о продукции и услугах LADA путём
              осуществления со стороны официального дилера LADA ''{dealer}''
              прямых контактов с помощью средств связи в целях направления мне
              наилучших персональных предложений с учётом оптимальных практик АО
              «АВТОВАЗ» и улучшения обслуживания.
            </Text>
            <Text style={styles.footerRightText}>
              Согласие действует до даты его отзыва мною путём направления в
              адрес официального дилера LADA ''{dealer}'' письменного сообщения
              об указанном отзыве в произвольной форме.
            </Text>

            <View style={styles.footerRightBox}>
              <Text style={styles.footerRightBoxText}>Подпись Клиента</Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumbers}
          render={({ pageNumber }) => `${pageNumber} `}
          fixed
        />
      </Page>
    </Document>
  )
}
