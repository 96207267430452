import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import Button from 'components/Button'
import { Pagination } from 'components/Pagination'
import { EmptyMessage } from 'components/EmptyMessage'
import { Preloader } from 'components/Preloader'

import { getOrdersLoadingStatus } from 'store/orders/selectors'

import { LOADING_STATUS, SORT_DIRECTION } from 'constants/loadingStatus'

import { formatPhoneNumber } from 'utils/formatPhoneNumber'
import { handleSort } from 'utils/sortTableData'

import { useSort } from 'hooks/useSort'
import { useSearch } from 'hooks/useSearch'

import style from './styles.module.scss'

export default function CompletedOrdersManager({ data, watch, searchFields }) {
  const [currentData, setCurrentData] = useState([])
  const [currentPage, setCurrentPage] = useState({ current: 1, amount: 10 })
  const [isReady, setIsReady] = useState(false)

  const { loadingStatus } = useSelector((state) => ({
    loadingStatus: getOrdersLoadingStatus(state),
  }))

  const sortConfig = [
    { id: 0, path: 'created.date', type: 'date' },
    { id: 1, path: 'id', type: 'number' },
    { id: 2, path: 'family_name.model_name', type: 'multiField' },
    { id: 3, path: 'client_info.name', type: 'string' },
    { id: 4, path: 'status.name', type: 'string' },
  ]

  const watchSearch = watch('search')

  const filteredActiveOrders = useSearch(data, watchSearch, searchFields)

  const [
    sortedData,
    sortedIndex,
    sortDirection,
    handleSortedIndex,
    handleSortDirection,
  ] = useSort(filteredActiveOrders.filteredData, sortConfig)

  useEffect(() => {
    if (!!sortedData.length) {
      const startIndex = 0
      const endIndex = currentPage.amount * currentPage.current
      const formattedData = sortedData.slice(startIndex, endIndex)

      setCurrentData(formattedData)
    }
  }, [currentPage, sortedData])

  useEffect(() => {
    setIsReady(true)
  }, [currentData])

  return (
    <>
      {loadingStatus === LOADING_STATUS.LOADING ? (
        <div className={style.preloader}>
          <Preloader.Spinner />
        </div>
      ) : currentData.length > 0 ? (
        <>
          <table className={clsx(style.table, style.completeOrders)}>
            <thead>
              <tr>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        0,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 0,
                      [style.activeASC]:
                        sortedIndex === 0 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Дата создания
                  </span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        1,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 1,
                      [style.activeASC]:
                        sortedIndex === 1 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Номер заказа
                  </span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        2,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 2,
                      [style.activeASC]:
                        sortedIndex === 2 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Автомобиль
                  </span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        3,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 3,
                      [style.activeASC]:
                        sortedIndex === 3 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Клиент
                  </span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        4,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 4,
                      [style.activeASC]:
                        sortedIndex === 4 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Статус
                  </span>
                </th>
              </tr>
            </thead>

            <tbody>
              {currentData.map(
                ({
                  id,
                  created,
                  reserve,
                  vin,
                  family_name,
                  model_name,
                  complectation,
                  modification,
                  status,
                  client_info,
                  type,
                }) => {
                  return (
                    <Fragment key={id}>
                      <tr className={style.info}>
                        <td>
                          <span>{created.date}</span>
                          <span className={style.tableAction}>
                            {reserve &&
                              reserve.slice(0, 2) -
                                created.date.slice(0, 2)}{' '}
                            {reserve && 'дня до конца брони'}
                          </span>
                        </td>
                        <td>
                          <span>{id}</span>
                        </td>
                        <td>
                          <span className={style.bold}>
                            LADA {family_name} {model_name}
                          </span>
                          <span>{complectation}</span>
                          <span>{modification.title}</span>
                          <span className={style.vin}>VIN {vin}</span>
                          {type === 'direct_sale' && (
                            <div className={style.manufactureCarLabel}>
                              Авто от производителя
                            </div>
                          )}
                        </td>
                        <td>
                          <span className={style.bold}>{client_info.name}</span>
                          {client_info.phone && (
                            <span className={style.phone}>
                              {formatPhoneNumber(client_info.phone)}
                            </span>
                          )}
                          {client_info.email && (
                            <span>{client_info.email}</span>
                          )}
                        </td>
                        <td>
                          <span>{status.date}</span>
                          <span>{status.name}</span>
                        </td>
                      </tr>
                      <tr className={style.actions}>
                        <td colSpan={5} className={style.button}>
                          <Button.Default to={`/order/${id}`}>
                            Подробнее
                          </Button.Default>
                        </td>
                      </tr>
                    </Fragment>
                  )
                }
              )}
            </tbody>
          </table>
          <Pagination
            className={style.tableFooter}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            steps={[10, 25, 50]}
          />
        </>
      ) : (
        isReady && <EmptyMessage />
      )}
    </>
  )
}
