import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import { Container } from 'components/Container'
import { ModalWarning } from 'containers/Order/Modals'

import {
  addCarDealerDiscount,
  addCarDiscount,
  deleteCarDealerDiscount,
  deleteCarDiscount,
  editCarDealerDiscount,
  editCarDiscount,
  getAllDiscount,
  getCarDiscount,
  testActions,
} from 'api/discounts'
import { getActions } from 'api/actions'
import { getDisabledIds } from 'utils/getDisabledIds'
import { checkAvailable, formattedActions, getSpacialOffersInfo } from './utils'
import {
  checkAccessoriesAvailable,
  percentCalculation,
} from 'containers/NewCar/utils'

import style from './styles.module.scss'
import { ItemDealer } from 'components/DiscountsTetsing/Item/itemDealer'
import { ItemBrand } from 'components/DiscountsTetsing/Item/itemBrand'
import { TotalInfo } from 'components/DiscountsTetsing/TotalInfo'

export const DiscountsTetsing = ({
  activeOffers = [],
  activeDealerOffers = [],
  data,
  background,
  carId,
  isInputsActive = true,
  refetchOrderInfo,
  access,
  brandDiscountsList = [],
  totalCarPricePercent,
  initialCarPrice,
  isOrder = false,
  price,
  bage,
  isDirectSaleCar,
  family,
}) => {
  const [dealerActions, setDealerActions] = useState([])
  const [brandActions, setBrandActions] = useState([])
  const [choosenBrandIds, setChoosenBrandIds] = useState([])
  const [isMountedSwitch, setIsMountedSwitch] = useState(false)
  const [warning, setWarning] = useState(false)
  const [disableSwitcher, setDisableSwitcher] = useState(false)

  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm()

  const totalActiveOffersPrice = [
    ...activeOffers,
    ...activeDealerOffers,
  ].reduce((acc, { benefit, prefix }) => {
    return prefix.includes('gos')
      ? (price / 100) * benefit + acc
      : acc + benefit
  }, 0)

  const handlerDisabledDiscounts = () => {
    if (isOrder) {
      const { discount_schema: schema, discount_program: program } =
        brandDiscountsList
      if (Array.isArray(program) && program.length > 0) {
        const currentDiscount = choosenBrandIds
        const allBrandDiscountIds = program.map(({ id }) => id)

        return getDisabledIds(currentDiscount, schema, allBrandDiscountIds)
      }
    }
  }
  const disabledDiscounts = handlerDisabledDiscounts()

  const toggleSelectBrandDiscount = (id) => {
    setChoosenBrandIds((discount) =>
      discount.includes(id)
        ? discount.filter((it) => it !== id)
        : [...discount, id]
    )
  }

  const addDiscount = useCallback(
    async (val) => {
      const discountsPrice = !isOrder && totalActiveOffersPrice + val.discount
      const isCarDealerActions =
        !isOrder && Object.keys(val).includes('dealer_action_id')

      const isCheck = isOrder
        ? true
        : checkAvailable(
            discountsPrice,
            initialCarPrice,
            '100%',
            'isDiscountAdd',
            family
          )

      if (isCheck) {
        setDisableSwitcher(true)

        try {
          isCarDealerActions
            ? await addCarDealerDiscount({
                car_id: carId,
                ...val,
              })
            : await addCarDiscount({
                car_id: carId,
                ...val,
              })

          refetchOrderInfo()
        } catch (e) {
          console.log(e)
        }

        setDisableSwitcher(false)
      } else {
        setWarning(true)
      }
    },
    [activeOffers, activeDealerOffers]
  )

  const editDiscount = useCallback(
    async (val) => {
      const isCarDealerActions =
        !isOrder && Object.keys(val).includes('dealer_action_id')
      const discountsPrice = !isOrder && totalActiveOffersPrice + val.discount

      const isCheck = isOrder
        ? true
        : checkAvailable(
            discountsPrice,
            initialCarPrice,
            '100%',
            'isDiscountAdd',
            family
          )

      if (isCheck) {
        try {
          isCarDealerActions
            ? await editCarDealerDiscount({
                car_id: carId,
                ...val,
              })
            : await editCarDiscount({
                car_id: carId,
                ...val,
              })

          refetchOrderInfo()
        } catch (e) {
          console.log(e)
        }
      } else {
        setWarning(true)
      }
    },
    [activeOffers, activeDealerOffers]
  )

  const deleteDiscount = async (val) => {
    setDisableSwitcher(true)

    const isCarDealerActions =
      !isOrder && Object.keys(val).includes('dealer_action_id')

    try {
      isCarDealerActions
        ? await deleteCarDealerDiscount({
            car_id: carId,
            ...val,
          })
        : await deleteCarDiscount({
            car_id: carId,
            ...val,
          })

      refetchOrderInfo()
    } catch (e) {
      console.log(e)
    }
    setDisableSwitcher(false)
  }

  useEffect(() => {
    if (activeOffers.length > 0) {
      activeOffers.forEach((el, { is_dealer, brand_action_id, prefix }) => {
        !is_dealer &&
          setChoosenBrandIds((prev) =>
            prev.includes(brand_action_id) ? prev : [...prev, brand_action_id]
          )
      })
    }

    const fetchAction = async () => {
      try {
        //TODO: поменять тестовый эндпоинт на боевой
        const res = await getAllDiscount()
        const filteredRes = res.data.filter(({ is_dealer }) => is_dealer)

        const formattedDealer = formattedActions(filteredRes)
        setDealerActions(formattedDealer)

        if (Object.keys(brandDiscountsList).length > 0) {
          const { discount_program: program } = brandDiscountsList
          const formattedBrand = isOrder && formattedActions(program)

          setBrandActions(formattedBrand)
        }
      } catch (e) {
        console.log(e)
      }
    }

    fetchAction()
  }, [activeOffers, activeDealerOffers])

  const mountingRef = useRef()

  useEffect(() => {
    if (mountingRef.current === undefined) {
      if (isMountedSwitch) {
        activeOffers.length > 0 &&
          activeOffers.forEach((el) => {
            setValue(el.prefix + '_switch', { value: true })
          })

        activeDealerOffers.length > 0 &&
          !isOrder &&
          activeDealerOffers.forEach((el) =>
            setValue(el.prefix + '_switch', { value: true })
          )

        mountingRef.current = false
      }
    }
  }, [mountingRef, isMountedSwitch, activeOffers, activeDealerOffers])

  return (
    <div
      className={clsx({
        [style.background]: background,
      })}
    >
      {warning && <ModalWarning handleCloseModal={() => setWarning(false)} />}
      <Container className={style.container}>
        <h2 className={style.title}>
          {!bage ? 'Специальные предложения' : 'максимальная выгода'}
        </h2>
        {totalActiveOffersPrice > 0 &&
          !isDirectSaleCar &&
          !isOrder &&
          family !== 'vesta' && (
            <div className={style.message}>
              <span>
                Внимание! Итоговая стоимость автомобиля с аксессуарами и
                допуслугами должна составлять не менее 94% РРЦ
              </span>
            </div>
          )}
        {!isDirectSaleCar && (
          <form className={style.wrapper}>
            {!bage && <h3>Выгоды от дилера</h3>}
            <div className={style.list}>
              {dealerActions.length > 0 &&
                dealerActions.map((el) => {
                  let offer = activeOffers.find(
                    ({ prefix }) => prefix === el.prefix || prefix.length === 0
                  )

                  let currentOffer =
                    activeDealerOffers.length > 0 &&
                    typeof offer === 'undefined'
                      ? activeDealerOffers?.find(
                          ({ prefix }) => prefix === el.prefix
                        )
                      : offer
                  let currentId =
                    currentOffer?.id || currentOffer?.dealer_action_id || null

                  return (
                    <ItemDealer
                      key={el.prefix}
                      control={control}
                      watch={watch}
                      errors={errors}
                      setError={setError}
                      id={currentId}
                      benefit={currentOffer?.benefit || 0}
                      clearErrors={clearErrors}
                      addDiscount={addDiscount}
                      editDiscount={editDiscount}
                      deleteDiscount={deleteDiscount}
                      setIsMountedSwitch={setIsMountedSwitch}
                      isInputsActive={isInputsActive}
                      access={access}
                      isOrder={isOrder}
                      {...el}
                    />
                  )
                })}
            </div>
          </form>
        )}
        {isOrder && brandActions.length > 0 && (
          <form className={style.wrapper}>
            <h3>Выгоды от бренда</h3>
            <div className={style.list}>
              {brandActions.length > 0 &&
                brandActions.map((el) => {
                  let offer = activeOffers.find(
                    ({ prefix }) => prefix === el.prefix
                  )

                  return (
                    <ItemBrand
                      key={el.prefix}
                      control={control}
                      watch={watch}
                      errors={errors}
                      id={offer?.id || null}
                      gosBenefit={offer?.benefit || null}
                      addDiscount={addDiscount}
                      deleteDiscount={deleteDiscount}
                      price={price}
                      disabled={
                        disableSwitcher
                          ? true
                          : disabledDiscounts.includes(el.brand_action_id)
                      }
                      toggleSelectBrandDiscount={toggleSelectBrandDiscount}
                      isInputsActive={isInputsActive}
                      access={access}
                      {...el}
                    />
                  )
                })}
            </div>
          </form>
        )}

        <TotalInfo
          activeOffers={activeOffers}
          activeDealerOffers={activeDealerOffers}
        />
      </Container>
    </div>
  )
}

function areEqual(prevProps, nextProps) {
  const isCheck =
    JSON.stringify(prevProps.activeOffers) ===
      JSON.stringify(nextProps.activeOffers) &&
    JSON.stringify(prevProps.activeDealerOffers) ===
      JSON.stringify(nextProps.activeDealerOffers)

  return isCheck
}

export const MemoizedDiscounts = React.memo(DiscountsTetsing, areEqual)
