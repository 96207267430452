import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'

import { Container } from 'components/Container'
import Icon from 'components/Icon'
import Button from 'components/Button'
import { ModalActionDelete, ModalActionSave } from 'containers/Order/Modals'

import { addCarGifts, deleteCarGifts, getCarGifts } from 'api/gifts'

import { colors } from 'constants/inlineColorConfig'
import Input from 'components/Fields/Input'

import style from './styles.module.scss'

export const Gifts = ({
  data,
  background,
  carId,
  isInputsActive = true,
  access,
}) => {
  const [isShowModalDelete, setIsShowModalDelete] = useState(false)
  const [isShowModalSave, setIsShowModalSave] = useState(false)
  const [gifts, setGifts] = useState(data)
  const [isSent, setIsSent] = useState(false)
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const addGift = async ({ gift }) => {
    const currentGift = gifts.find((el) => el.value === gift)
    const name = currentGift ? currentGift.label : gift

    try {
      await addCarGifts({
        car_id: carId,
        name: name,
      })
      setIsSent((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }

  const deleteGift = async (giftId) => {
    try {
      await deleteCarGifts({
        car_id: carId,
        id: giftId,
      })
      setIsSent((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const fetchCarGifts = async () => {
      try {
        const res = await getCarGifts(carId)
        setGifts(res.data)
      } catch (e) {
        console.warn(e)
      }
    }

    fetchCarGifts()
  }, [isSent])

  return (
    <div
      className={clsx({
        [style.background]: background,
      })}
    >
      {isShowModalDelete && (
        <ModalActionDelete
          handleCloseModal={() => setIsShowModalDelete(false)}
        />
      )}
      {isShowModalSave && (
        <ModalActionSave handleCloseModal={() => setIsShowModalSave(false)} />
      )}
      <Container className={style.container}>
        <h2 className={style.title}>Подарок дилера</h2>
        <div className={style.list}>
          {gifts &&
            gifts.map(({ name, id }) => (
              <div className={style.item} key={id}>
                <div className={style.itemName}>
                  <span className={style.itemTitle}>{name}</span>
                  {access && (
                    <div onClick={() => isInputsActive && deleteGift(id)}>
                      <Icon.Close
                        width={16}
                        height={16}
                        color={colors.blackPearl}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
        <form onSubmit={handleSubmit(addGift)}>
          <div className={style.form}>
            <div className={style.select}>
              <Input
                label="Добавить подарок"
                name="gift"
                control={control}
                error={errors['gift']}
                disabled={!isInputsActive || !access}
              />
            </div>
            <div className={style.action}>
              <Button.Outlined
                disabled={!isInputsActive || !access}
                type="submit"
              >
                Добавить
              </Button.Outlined>
            </div>
          </div>
        </form>
      </Container>
    </div>
  )
}
