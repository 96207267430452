import clsx from 'clsx'

import styles from './styles.module.scss'
import Button from 'components/Button'

export const EmptyMessage = ({
  message = 'Заказы с данным статусом отсутствуют.',
  className,
  openModal = () => {},
  bage,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <p className={styles.message}>{message}</p>
      {bage && (
        <Button.Outlined className={styles.addCarButton} onClick={openModal}>
          добавить автомобиль
        </Button.Outlined>
      )}
    </div>
  )
}
