export const IconMenu = ({ width, height, color }) => {
  return (
    <svg
      width="24"
      height="19"
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="8" width="24" height="3" fill="white" />
      <rect y="16" width="24" height="3" fill="white" />
      <rect width="24" height="3" fill="white" />
    </svg>
  )
}
