/**
 * @param phoneNumberString
 * description приводим номер телефона к виду +0 0000 000 000
 * @returns {string}
 */
export const formatPhoneNumber = (phoneNumberString) => {
  return String(phoneNumberString).replace(
    /(\d{1})(\d{4})(\d{3})(\d{3})/,
    '+$1 $2 $3 $4'
  )
}
