import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getAccessoriesByKomplId } from 'api/accessories'

import { ModalAction } from 'components/ModalAction'
import { Container } from 'components/Container'
import { Notifications } from 'components/Notifications'

import { Header } from './Header'
import { Accessories } from './Accessories'
import { ClientForms } from './ClientForms'
import { FooterActions } from './FooterActions'
import { Discounts } from 'containers/PersonalOffer/Discounts'
import { FullCreditProgram } from 'containers/PersonalOffer/FullCreditProgramm'
import { Services } from 'containers/PersonalOffer/Services'
import { TradeIn } from 'containers/PersonalOffer/TradeIn'

import { getUserRole } from 'store/auth/selectors'

import { getOfferData } from 'containers/PersonalOffer/actions'
import { discountList } from './constants'
import { ROLE } from 'constants/role'
import { updateDataPO } from 'api/personalOffer'

import style from './styles.module.scss'

export default function EditPersonalOffer() {
  const { id } = useParams()
  const navigate = useNavigate()

  const { role } = useSelector((state) => ({
    role: getUserRole(state),
  }))

  const redirectLink =
    role === ROLE.chief || role === ROLE.chief2
      ? '/chief?status=5'
      : '/manager?status=5'

  const [carData, setCarData] = useState({})
  const [clientData, setClientData] = useState(null)

  const [accessoriesData, setAccessoriesData] = useState([])
  const [servicesData, setServicesData] = useState([])
  const [tradeinData, setTradeinData] = useState({})
  const [creditData, setCreditData] = useState({})
  const [discountsData, setDiscountsData] = useState([])

  const [totalPrice, setTotalPrice] = useState(0)

  const [sended, setSended] = useState(false)

  const setDataHandler = async () => {
    let validation = () => {
      try {
        let { lastName, name, phone, email } = clientData

        return (
          !!(lastName && /[a-zа-яё]/i.test(lastName)) &&
          !!(name && /[a-zа-яё]/i.test(name)) &&
          !!(phone && phone.replace(/[^+\d]/g, '').length === 12) &&
          !!(email && /\S+@\S+\.\S+/.test(email))
        )
      } catch (e) {
        return false
      }
    }

    let isRequired = validation()

    if (isRequired) {
      try {
        let data = {
          compl_id: carData.compl_id,
          color_code: carData.color_code,
          ...clientData,
          ...(!!accessoriesData.length && { accessories: accessoriesData }),
          ...(!!discountsData.length && { discounts: discountsData }),
          ...(!!servicesData.length && { services: servicesData }),
          ...(!!Object.keys(tradeinData).length && { tradein: tradeinData }),
          ...(!!Object.keys(creditData).length && { credit: creditData }),
        }

        await updateDataPO(data, id).then((res) => {
          setIsOpenNotifications({
            isOpen: true,
            currentTitle: 'isSave',
          })
          setSended(true)
        })
      } catch (e) {
        setIsOpenNotifications({
          isOpen: true,
          currentTitle: 'isError',
        })
      }
    } else {
      setIsOpenNotifications({
        isOpen: true,
        currentTitle: 'isValidation',
        children: 'ФИО, телефон, email',
      })
    }
  }

  const [isLoading, setIsLoading] = useState(true)
  const [accessories, setAccessories] = useState(null)
  const [isOpenNotifications, setIsOpenNotifications] = useState({
    isOpen: false,
    currentTitle: '',
  })

  const [isActiveTradeIn, setIsActiveTradeIn] = useState(false)
  const [isShowRules, setIsShowRules] = useState(false)

  const checkData = !!carData && !!accessories

  const getSumValues = (arr) => {
    let res = arr.reduce(
      (acc, item) =>
        item.price
          ? acc + item.price
          : item.benefit
          ? acc + item.benefit
          : item.cost
          ? acc + Number(item.cost)
          : acc + 0,
      0
    )

    return res
  }

  useEffect(() => {
    if (Object.keys(carData).length) {
      async function fetchAccessories() {
        try {
          const res = await getAccessoriesByKomplId(carData.compl_id)
          setAccessories(res.data)
          setIsLoading(false)
        } catch (e) {
          console.warn(e)
        }
      }

      fetchAccessories()
    }
  }, [carData])

  useEffect(() => {
    if (!!id) {
      getOfferData(id)
        .then((res) => {
          setCarData(res)
          setClientData({
            lastName: res.lastName,
            name: res.name,
            middleName: res.middleName,
            phone: res.phone,
            email: res.email,
          })

          setAccessoriesData(res.accessories)
          setServicesData(res.services)

          setDiscountsData(res.discounts)
          setTradeinData(res.tradein)

          setIsActiveTradeIn(!!Object.keys(res.tradein).length)

          setCreditData(res.credit)

          let addPrice = getSumValues([...res.accessories, ...res.services])
          let removePrice = getSumValues([...res.discounts, res.tradein])

          setTotalPrice(res.total_price - removePrice + addPrice)
        })
        .catch((err) => setCarData({}))
    } else {
      navigate(redirectLink, { replace: true })
    }
  }, [])

  return (
    <div className={style.container}>
      <Notifications
        {...isOpenNotifications}
        setIsOpenNotifications={setIsOpenNotifications}
      />
      {isShowRules && (
        <ModalAction
          modalClass={style.rulesModal}
          textClass={style.rulesText}
          titleClass={style.rulesTitle}
          handleClose={() => setIsShowRules(false)}
          title="Для корректной работы с персональным коммерческим предложением используйте браузеры <a href='https://www.mozilla.org/ru/firefox/'>Mozilla Firefox</a>, <a href='https://www.google.ru/chrome/'>Google Chrome</a>."
          description={
            '1. Выберите комплектацию автомобиля, для которой печатается персональное коммерческое предложение.</br></br>2.  Выберите нужный цвет автомобиля.</br></br>3. Укажите ФИО, телефон,  email клиента (обязательно).</br></br>4. Укажите (при необходимости) выгоду автосалона.</br></br>5. Добавьте дополнительное оборудование/аксессуары из реестра или свои собственные. Укажите (при необходимости) свою стоимость аксессуара.</br></br>6. Если клиент выбирает программу Трейд-ин - укажите данные автомобиля и оценочную стоимость.</br></br>7. Если клиент выбирает программу ЛАДА Финанс - укажите банк, выберите одну из представленных программ кредитования и укажите величину первоначального взноса. Если ранее была выбрана программа Трейд-ин - оценочная стоимость сдаваемого автомобиля будет являться первоначальным взносом автоматически. Стоимость дополнительного оборудования/аксессуаров включается в сумму кредита автоматически.</br></br>8. Сформируйте печатную версию персонального коммерческого предложения или отправьте его на почту клиента.'
          }
        />
      )}
      {!isLoading && checkData && (
        <>
          <Header {...carData} />
          <Container>
            <p
              className={style.showRulesText}
              onClick={() => setIsShowRules(true)}
            >
              Правила пользования
            </p>
          </Container>
          <ClientForms
            clientData={clientData}
            setClientData={setClientData}
            mode={'edit'}
          />
          <Accessories
            {...accessories}
            currentAccessories={accessoriesData}
            setCurrentAccessories={setAccessoriesData}
            setTotalPrice={setTotalPrice}
            mode={'edit'}
            background
          />
          <Discounts
            discountList={discountList}
            discountsData={discountsData}
            setDiscountsData={setDiscountsData}
            setTotalPrice={setTotalPrice}
            mode={'edit'}
          />
          <Services
            servicesData={servicesData}
            setServicesData={setServicesData}
            setTotalPrice={setTotalPrice}
            background
          />
          <TradeIn
            setIsActiveTradeIn={setIsActiveTradeIn}
            isActiveTradeIn={isActiveTradeIn}
            tradeinData={tradeinData}
            setTradeinData={setTradeinData}
            setTotalPrice={setTotalPrice}
            mode={'edit'}
          />
          <FullCreditProgram
            setCreditData={setCreditData}
            creditData={creditData}
            price={totalPrice}
            mode={'edit'}
          />
          <FooterActions
            setDataHandler={setDataHandler}
            id={id}
            mode={'edit'}
            sended={sended}
          />
        </>
      )}
    </div>
  )
}
