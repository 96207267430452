import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import Button from 'components/Button'
import { Pagination } from 'components/Pagination'
import { EmptyMessage } from 'components/EmptyMessage'
import { Preloader } from 'components/Preloader'

import { getCarsLoadingStatus } from 'store/cars/selectors'

import { LOADING_STATUS } from 'constants/loadingStatus'

import { useSort } from 'hooks/useSort'
import { useSearch } from 'hooks/useSearch'

import style from './styles.module.scss'

import { AddCarModal } from './AddCarModal'
import { deleteBadgeById } from 'api/badges'

export default function CarsForBadgeChief({
  data,
  watchSearch,
  searchFieldsCar,
  watchSearchModels,
  noBadgeCars,
  withNoBadgeModelsList,
}) {
  const [currentData, setCurrentData] = useState([])
  const [currentPage, setCurrentPage] = useState({ current: 1, amount: 10 })
  const [isReady, setIsReady] = useState(false)
  const [isOpenAddCarModal, setIsOpenAddCarModal] = useState(false)

  const { loadingStatus } = useSelector((state) => ({
    loadingStatus: getCarsLoadingStatus(state),
  }))

  const filteredCarsPlacing = useSearch(
    data,
    watchSearch,
    searchFieldsCar,
    300,
    false
  )

  const [sortedData] = useSort(filteredCarsPlacing.filteredData, [])

  useEffect(() => {
    if (!!sortedData.length) {
      const startIndex = 0
      const endIndex = currentPage.amount * currentPage.current
      const formattedData = sortedData.slice(startIndex, endIndex)

      setCurrentData(
        formattedData.filter((el) =>
          !watchSearchModels
            ? true
            : el.family_name + ' ' + el.model_name === watchSearchModels
        )
      )
    }
  }, [currentPage, sortedData, watchSearchModels])

  useEffect(() => {
    setIsReady(true)
  }, [currentData])

  const deleteHandler = async (id) => {
    await deleteBadgeById(id)

    setCurrentData((prev) => prev.filter((el) => el.car_id !== id))
  }

  return (
    <>
      {isOpenAddCarModal && (
        <AddCarModal
          withNoBadgeModelsList={withNoBadgeModelsList}
          data={noBadgeCars}
          searchFieldsCar={searchFieldsCar}
          handleClose={() => setIsOpenAddCarModal(false)}
        />
      )}
      {loadingStatus === LOADING_STATUS.LOADING ? (
        <div className={style.preloader}>
          <Preloader.Spinner />
        </div>
      ) : currentData.length > 0 ? (
        <>
          <h2 className={style.title}>Сформированные бейджи</h2>
          <Button.Outlined
            className={style.addCarButton}
            onClick={() => setIsOpenAddCarModal(true)}
          >
            Сформировать новый
          </Button.Outlined>
          <table className={clsx(style.table, style.carsPlaced)}>
            <tbody>
              {currentData.map((carInfo) => {
                return (
                  <Fragment key={carInfo.car_id}>
                    <tr className={style.info}>
                      <td>
                        <span className={style.bold}>
                          {carInfo.family_name} {carInfo.model_name}
                        </span>
                        <span className={style.vin}>VIN {carInfo.vin}</span>
                      </td>
                      <td>
                        <span>{carInfo.complectation}</span>
                        <span>{carInfo.modification.title}</span>
                      </td>
                      <td align="right">
                        <Button.Outlined
                          className={style.deleteButton}
                          onClick={() => deleteHandler(carInfo.car_id)}
                        >
                          удалить
                        </Button.Outlined>
                        <Button.Default to={`/carForBadge/${carInfo.car_id}`}>
                          редактировать и распечатать бейдж
                        </Button.Default>
                      </td>
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          </table>
          <Pagination
            className={style.tableFooter}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            steps={[10, 25, 50]}
          />
        </>
      ) : (
        isReady && (
          <>
            <EmptyMessage
              message="пока нет автомобилей"
              className={style.emptyBage}
              bage
              openModal={() => setIsOpenAddCarModal(true)}
            />
          </>
        )
      )}
    </>
  )
}
