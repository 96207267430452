import styles from './styles.module.scss'
import Switch from 'components/Fields/Switch'
import Input from 'components/Fields/Input'
import Button from 'components/Button/index'
import { useEffect, useRef, useState } from 'react'
import { getPrice } from 'utils/getPrice'

export const ItemDealer = ({
  control,
  watch,
  errors,
  setError,
  clearErrors,
  label = 'Программа',
  benefit = 0,
  id,
  brand_action_id,
  prefix,
  is_dealer,
  addDiscount,
  editDiscount,
  deleteDiscount,
  setIsMountedSwitch,
  isInputsActive,
  access,
  isOrder,
}) => {
  const watchInput = watch(prefix + '_input')

  const [isActive, setIsActive] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [disable, setDisable] = useState(false)

  const { activeMode, editedMode, inactiveMode } = {
    activeMode: isActive && benefit > 0,
    editedMode: isEdit || (isActive && benefit === 0),
    inactiveMode: !isActive && !isEdit,
  }

  const handlerEdit = () => {
    setIsEdit(true)
    setIsActive(false)
  }

  const handlerSave = async () => {
    if (typeof watchInput === 'string' && !!watchInput.match(/^[0-9]+$/)) {
      setDisable(true)

      try {
        if (benefit > 0) {
          let val = {
            discount: Number(watchInput.replaceAll('+', '')),
            [prefix === 'dd_d' || isOrder ? 'id' : 'dealer_action_id']: id,
          }

          await editDiscount(val).then((res) => {
            setDisable(false)
            setIsEdit(false)
            setIsActive(true)
          })
        } else {
          let val = {
            name: label,
            is_dealer: Number(is_dealer),
            discount: Number(watchInput.replaceAll('+', '')),
            prefix: prefix,
            [prefix === 'dd_d' || isOrder
              ? 'brand_action_id'
              : 'dealer_action_id']: brand_action_id,
          }

          addDiscount(val).then((res) => {
            setDisable(false)
            setIsEdit(false)
            setIsActive(true)
          })
        }
        clearErrors()
      } catch (e) {
        console.log(e)
      }
    } else {
      setError(prefix + '_input', {
        type: prefix + '_input',
        message: 'Некорректный ввод',
      })
    }
  }

  const handlerSwitch = async (e) => {
    if (!e && id && benefit > 0) {
      setDisable(true)

      let val = {
        [prefix === 'dd_d' || isOrder ? 'id' : 'dealer_action_id']: id,
      }

      try {
        await deleteDiscount(val).then((res) => {
          setDisable(false)
          setIsActive(false)
          setIsEdit(false)
          clearErrors()
        })
      } catch (e) {
        console.log(e)
      }
    } else if (!e && benefit === 0) {
      setIsEdit(false)
    } else {
      setIsEdit(true)
    }
  }

  useEffect(() => {
    setIsMountedSwitch(true)
  }, [])

  useEffect(() => {
    benefit > 0 && setIsActive(true)
  }, [benefit])

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <Switch
          id={brand_action_id}
          name={prefix + '_switch'}
          control={control}
          change={handlerSwitch}
          disabled={disable || !isInputsActive || !access}
        />
        <p>{label}</p>
      </div>

      <div className={styles.right}>
        {activeMode && (
          <div>
            <span>{getPrice(benefit)}</span>
            <Button.Outlined
              onClick={handlerEdit}
              children="Редактировать"
              className={styles.editBtn}
              disabled={!isInputsActive || !access}
            />
          </div>
        )}
        {editedMode && (
          <div>
            <Input
              control={control}
              type="number"
              name={prefix + '_input'}
              error={errors[prefix + '_input']}
              rules={{
                required: 'Это поле необходимо заполнить',
                validate: (v) => (v && v > 0 ? true : 'Это поле обязательно'),
              }}
              placeholder="Стоимость, ₽"
              className={styles.input}
            />
            <Button.Outlined
              onClick={handlerSave}
              children="сохранить"
              disabled={!isInputsActive || !access}
            />
          </div>
        )}
        {inactiveMode && isInputsActive && access && (
          <span>Включите выгоду чтобы добавить стоимость</span>
        )}
      </div>
    </div>
  )
}
