export const colors = {
  white: '#FFFFFF',
  darkGray: '#4C5865',
  aubergine: '#221F20',
  sandStone: '#848E98',
  azure: '#F7F8F8',
  nero: '#D9DCDC',
  brightOrange: '#E37639',
  orange: '#E6873B',
  grayishBlue: '#A6ADB5',
  blackPearl: '#66727D',
  grayLight: '#EAEFEF',
  black: '#000000',
  сyanBlue: '#66727D',
  green: '#74b974',
  red: '#fc1313',
  blackCoral: '#54616c',
}
