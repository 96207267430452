import { createSelector } from '@reduxjs/toolkit'

export const getAuth = (state) => state.auth

export const getAuthToken = createSelector(getAuth, (auth) => auth.token)

export const getUserRole = createSelector(getAuth, (auth) => auth.role)

export const getUserDealerId = createSelector(getAuth, (auth) => auth.dealerId)

export const getLoadingStatus = createSelector(
  getAuth,
  (auth) => auth.loadingStatus
)

export const getUserName = createSelector(getAuth, (auth) => auth.name)
