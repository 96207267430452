export const IconLocation = ({ width, height, color }) => {
  return (
    <svg
      width={width || 11}
      height={height || 15}
      viewBox="0 0 11 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 5.5C10.5 7.84851 9.21017 10.1124 7.85724 11.8297C7.18717 12.6802 6.51602 13.3785 6.01236 13.8641C5.8105 14.0587 5.63605 14.2186 5.5 14.3399C5.36395 14.2186 5.1895 14.0587 4.98764 13.8641C4.48398 13.3785 3.81283 12.6802 3.14276 11.8297C1.78983 10.1124 0.5 7.84851 0.5 5.5C0.5 2.73858 2.73858 0.5 5.5 0.5C8.26142 0.5 10.5 2.73858 10.5 5.5Z"
        stroke={color || '#E37639'}
      />
      <circle cx="5.5" cy="5.5" r="1.5" fill={color || '#E37639'} />
    </svg>
  )
}
