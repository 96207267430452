import { configureStore } from '@reduxjs/toolkit'

import auth from 'store/auth/slice'
import orders from 'store/orders/slice'
import cars from 'store/cars/slice'
import dealers from 'store/dealers/slice'
import cities from 'store/city/slice'

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  preloadedState: {},
  reducer: {
    auth,
    orders,
    cars,
    dealers,
    cities,
  },
})
