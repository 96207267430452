import clsx from 'clsx'

import Button from 'components/Button'

import { getPrice } from 'utils/getPrice'

import style from './styles.module.scss'

export const CreditPayment = ({
  fullPrice,
  firstPrice,
  monthPrice,
  period,
  percent,
  toBank,
  putClass,
  isShowButton,
  setNewCreditData,
  isDisabled,
}) => {
  return (
    <div className={style.resultWrapper}>
      <div>
        <div className={style.result}>
          <div className={style.item}>
            <h5>Стоимость автомобиля</h5>
            <p>{getPrice(fullPrice)}</p>
          </div>
          <div className={style.item}>
            <h5>Первый взнос</h5>
            <p>{getPrice(firstPrice)}</p>
          </div>
          <div className={style.item}>
            <h5>Процентная ставка</h5>
            <p>{percent}%*</p>
          </div>
          <div className={style.item}>
            <h5>Срок кредита</h5>
            <p>{period}</p>
          </div>
        </div>

        <div className={style.creditPayment}>
          <p className={style.total}>Предварительный расчет</p>
          <p className={style.amount}>
            {monthPrice >= 0 ? getPrice(monthPrice) : 0} / мес
          </p>
          {isShowButton && (
            <div className={style.actions}>
              <Button.Default
                className={clsx({
                  [style.getCreditBtn]: true,
                })}
                onClick={setNewCreditData}
                disabled={isDisabled}
              >
                Сохранить
              </Button.Default>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
