import { pdf, renderToString } from '@react-pdf/renderer'
import React, { useRef, useState } from 'react'
import { saveAs } from 'file-saver'

import Button from 'components/Button'
import { getBadgeById, sendBage } from 'api/badges'
import { PdfList } from 'components/PdfGenerator/PdfList'
import { QR } from 'components/QR'
import { svgFormatter } from 'components/PdfGenerator/PdfList/utils'
import { useEffect } from 'react'

export const PdfCreator = ({ id, creditData, setIsCreditError }) => {
  const [carInfo, setCarInfo] = useState(null)

  const useQr = useRef(null)

  const getBage = async () => {
    if (creditData?.month_payment) {
      const res = await getBadgeById(id, creditData)

      setCarInfo(res.data)
    } else {
      setIsCreditError(true)
    }
  }

  useEffect(() => {
    const getPdf = async () => {
      if (carInfo) {
        const doc = (
          <PdfList carInfo={carInfo} formattedSvg={svgFormatter(useQr)} />
        )
        const asPdf = pdf([])
        asPdf.updateContainer(doc)
        const blob = await asPdf.toBlob()
        saveAs(blob, 'document.pdf')

        // var formData = new FormData()
        // formData.append('file', blob, 'test.pdf')
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ', ' + pair[1])
        // }
        // await sendBage(formData)
      }
    }

    getPdf()
  }, [carInfo])

  return (
    <>
      <Button.Outlined onClick={getBage}>Сформировать бейдж</Button.Outlined>

      <QR
        value={`https://www.lada.ru/cars/bage/${carInfo?.hash}`}
        size={90}
        ref={useQr}
      />
    </>
  )
}
