export const IconFlatArrowLeft = ({ width, height, color, className }) => (
  <svg
    width={width || 14}
    height={height || 24}
    viewBox="0 0 13 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12.375 22.1254L2.62431 12.0002L12.375 1.87512"
      stroke={color || '#4C5865'}
      strokeWidth="3"
    />
  </svg>
)
