import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { LOADING_STATUS } from 'constants/loadingStatus'
import { parseJWT } from 'utils/parseJWT'
import { getTokenFromLs } from 'utils/token'

export const adapter = createEntityAdapter()

export const auth = createSlice({
  name: 'auth',
  initialState: adapter.getInitialState({
    token: null,
    role: null,
    loadingStatus: LOADING_STATUS.LOADING,
    name: '',
    dealerId: null,
  }),
  reducers: {
    loadAuthToken: (state) => {
      if (window) {
        const token = getTokenFromLs('t_kn')
        const userData = parseJWT(token.replace(/"/g, ''))

        if (userData) {
          state.token = token
          state.role = userData.role
          state.name = userData.fio
          state.dealerId = userData.entid
          state.loadingStatus = LOADING_STATUS.IDLE
        }
      }
    },
    setAuthToken: (state, { payload }) => {
      state.token = payload
    },
    removeAuthToken: (state) => {
      state.token = null
    },
  },
})

export const { loadAuthToken } = auth.actions
export default auth.reducer
