export const LOADING_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
}

/**
 * @type {{ASC: string, DESC: string}}
 */
export const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
}
