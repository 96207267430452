export const IconArrowDownWithTail = ({ width, height, color }) => {
  return (
    <svg
      width={width || '9'}
      height={height || '12'}
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 0.600098V10.3999M4.5 10.3999L8 6.8999M4.5 10.3999L1 6.8999"
        stroke={color || '#E37639'}
        strokeWidth="1.5"
      />
    </svg>
  )
}
