import { Font, StyleSheet } from '@react-pdf/renderer'

import f_300 from 'assets/fonts/PragmaticaLightC.woff'
import f_400 from 'assets/fonts/LADAPragmatica.woff'
import f_700 from 'assets/fonts/LADAPragmatica-Bold.otf'
import rouble from 'assets/fonts/rouble.otf'

Font.register({
  family: 'LADAPragmatica',
  src: f_300,
})

Font.register({
  family: 'LADAPragmaticaNormal',
  src: f_400,
})

Font.register({
  family: 'LADAPragmaticaBold',
  src: f_700,
})

Font.register({
  family: 'rouble',
  src: rouble,
})

const styles = StyleSheet.create({
  page: {
    position: 'relative',
    fontFamily: 'LADAPragmatica',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bold: {
    fontFamily: 'LADAPragmaticaBold',
  },
  section_1: {
    marginBottom: 25,
    backgroundColor: '#D2DEE3',
    paddingTop: 25,
    paddingBottom: 15,
    paddingHorizontal: 25,
  },
  title: {
    fontSize: 35,
    textTransform: 'uppercase',
    fontFamily: 'LADAPragmaticaNormal',
    marginBottom: 10,
    color: 'white',
  },
  flexSpaceBetween: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  compl: {
    flexDirection: 'row',
    maxWidth: '50%',
    alignItems: 'center',
  },
  complTitle: {
    width: '80px',
    fontSize: 8,
    fontFamily: 'LADAPragmaticaBold',
    textTransform: 'uppercase',
    color: '#4C5865',
    fontWeight: 'bold',
  },
  complName: {
    fontSize: 16,
    textTransform: 'uppercase',
    fontFamily: 'LADAPragmaticaNormal',
    color: '#4C5865',
    textAlign: 'left',
    paddingRight: 70,
    paddingLeft: 10,
  },
  engineData: {
    width: '50%',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  engineElem: {
    width: '50%',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  engineimg: {
    width: 35,
    height: 25,
    marginRight: 10,
    objectFit: 'contain',
  },
  engineContent: {
    width: 'calc(100% - 45px)',
    paddingRight: 10,
  },
  engineTitle: {
    fontSize: 8,
    color: '#4C5865',
    fontFamily: 'LADAPragmaticaBold',
    textTransform: 'uppercase',
    fontWeight: '700',
    marginBottom: 2,
  },
  engineText: {
    fontSize: 8,
  },
  headTable: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    marginTop: 10,
  },
  headTableItem1: {
    width: '25%',
    height: 'auto',
    padding: 10,
    margin: 0,
    borderLeft: '1px solid #4C5865',
    borderTop: '1px solid #4C5865',
    borderBottom: '1px solid #4C5865',
    borderRight: '1px solid #4C5865',
  },
  headTableItem2: {
    width: '25%',
    height: 'auto',
    padding: 10,
    margin: 0,
    borderTop: '1px solid #4C5865',
    borderBottom: '1px solid #4C5865',
    borderRight: '1px solid #4C5865',
  },
  headTableItem3: {
    width: '25%',
    height: 'auto',
    padding: 10,
    margin: 0,
    borderLeft: '1px solid #4C5865',
    borderBottom: '1px solid #4C5865',
    borderRight: '1px solid #4C5865',
  },
  headTableItem4: {
    width: '25%',
    height: 'auto',
    padding: 10,
    margin: 0,
    borderBottom: '1px solid #4C5865',
    borderRight: '1px solid #4C5865',
  },
  headTableItem31: {
    width: '33.33%',
    height: 'auto',
    padding: 10,
    margin: 0,
    borderLeft: '1px solid #4C5865',
    borderTop: '1px solid #4C5865',
    borderBottom: '1px solid #4C5865',
    borderRight: '1px solid #4C5865',
  },
  headTableItem32: {
    width: '33.33%',
    height: 'auto',
    padding: 10,
    margin: 0,
    borderTop: '1px solid #4C5865',
    borderBottom: '1px solid #4C5865',
    borderRight: '1px solid #4C5865',
  },
  headTableItem33: {
    width: '33.33%',
    height: 'auto',
    padding: 10,
    margin: 0,
    borderTop: '1px solid #4C5865',
    borderBottom: '1px solid #4C5865',
    borderRight: '1px solid #4C5865',
  },
  headTableItem21: {
    width: '50%',
    height: 'auto',
    padding: 10,
    margin: 0,
    borderLeft: '1px solid #4C5865',
    borderTop: '1px solid #4C5865',
    borderBottom: '1px solid #4C5865',
    borderRight: '1px solid #4C5865',
  },
  headTableItem22: {
    width: '50%',
    height: 'auto',
    padding: 10,
    margin: 0,
    borderTop: '1px solid #4C5865',
    borderBottom: '1px solid #4C5865',
    borderRight: '1px solid #4C5865',
  },
  headTableItem11: {
    width: '100%',
    height: 'auto',
    padding: 10,
    margin: 0,
    borderLeft: '1px solid #4C5865',
    borderTop: '1px solid #4C5865',
    borderBottom: '1px solid #4C5865',
    borderRight: '1px solid #4C5865',
  },
  headTableText: {
    color: '#4C5865',
    fontFamily: 'LADAPragmaticaBold',
    fontSize: 8,
  },
  qrSection: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginTop: 25,
  },
  qrTitle: {
    color: '#4C5865',
    fontSize: 7,
    fontFamily: 'LADAPragmaticaBold',
    textTransform: 'uppercase',
  },
  qrText: {
    color: '#4C5865',
    fontSize: 9,
  },
  qrValue: {
    color: '#4C5865',
    fontSize: 9,
    fontFamily: 'LADAPragmaticaBold',
  },
  priceBlock: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  priceTitle: {
    width: 100,
    color: '#4C5865',
    fontSize: 9,
    fontFamily: 'LADAPragmaticaBold',
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  priceWrapper: {
    flexDirection: 'row',
    // justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  priceValue: {
    color: '#4C5865',
    fontSize: 26,
    fontFamily: 'LADAPragmaticaBold',
    textTransform: 'uppercase',
    textAlign: 'right',
  },
  addsSection: {
    paddingHorizontal: 25,
  },
  creditSection: {
    paddingHorizontal: 25,
  },
  addsBlock: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  addsTitles: {
    flexDirection: 'row',
  },
  addsTitleWrapper: {
    width: '50%',
  },
  addsTitle: {
    width: '50%',
    fontFamily: 'LADAPragmaticaBold',
    fontSize: 8,
    textTransform: 'uppercase',
    paddingBottom: 5,
    color: '#4C5865',
    borderBottom: '3px solid #E37639',
  },
  addsItems: {
    width: '50%',
    border: '1px solid #D2DEE3',
    padding: 10,
  },
  addsItems2: {
    width: '50%',
    borderTop: '1px solid #D2DEE3',
    borderRight: '1px solid #D2DEE3',
    borderBottom: '1px solid #D2DEE3',
    padding: 10,
  },
  addsItemsGray: {
    width: '50%',
    backgroundColor: '#D2DEE3',
    border: '1px solid #4D5964',
    padding: 10,
  },
  addsItems2Gray: {
    width: '50%',
    backgroundColor: '#D2DEE3',
    borderTop: '1px solid #4D5964',
    borderRight: '1px solid #4D5964',
    borderBottom: '1px solid #4D5964',
    padding: 10,
  },
  addsItemstextBlock: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  addsText: {
    color: '#66727D',
    fontSize: 9,
    marginBottom: 5,
  },
  pgText: {
    width: '100%',
    color: '#66727D',
    fontSize: 9,
    marginBottom: 5,
    paddingLeft: 20,
  },
  addsTextGray: {
    color: '#4C5865',
    fontSize: 9,
    marginBottom: 5,
    textAlign: 'right',
  },
  addsTextGrayName: {
    color: '#4C5865',
    fontSize: 9,
    marginBottom: 5,
    paddingRight: 45,
  },
  addsTextGrayAcc: {
    maxWidth: '15%',
    color: '#4C5865',
    fontSize: 9,
    marginBottom: 5,
    textAlign: 'right',
  },
  addsTextGrayNameAcc: {
    maxWidth: '85%',
    color: '#4C5865',
    fontSize: 9,
    marginBottom: 5,
    paddingRight: 45,
  },
  totalPrice: {
    backgroundColor: '#D2DEE3',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRight: '1px solid #4D5964',
    borderBottom: '1px solid #4D5964',
    borderLeft: '1px solid #4D5964',
    padding: 10,
    paddingBottom: 5,
    marginBottom: 10,
  },
  totalPriceText: {
    maxWidth: 247,
    color: '#4C5865',
    fontSize: 9,
    fontFamily: 'LADAPragmaticaBold',
    letterSpacing: 0.42,
    textTransform: 'uppercase',
  },
  totalPriceValue: {
    color: '#4C5865',
    fontSize: 30,
    fontFamily: 'LADAPragmaticaBold',
  },
  creditTitle: {
    fontFamily: 'LADAPragmaticaBold',
    color: '#66727D',
    fontSize: 9,
    marginBottom: 8,
    textTransform: 'uppercase',
  },
  creditLine: {
    width: '20%',
    borderBottom: '2px solid #E37639',
  },
  creditTable: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 10,
    paddingBottom: 0,
    border: '1px solid #C3C4C4',
    marginBottom: 20,
  },
  creditImage: {
    position: 'absolute',
    left: 5,
    top: 0,
    width: 145,
  },
  creditItem1: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginRight: 20,
  },
  creditItem2: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  creditItemTitleBlock: {
    marginRight: 10,
    paddingBottom: 10,
  },
  creditItemTitle: {
    color: '#66727D',
    fontSize: 9,
  },
  creditItemText: {
    fontFamily: 'LADAPragmaticaBold',
    color: '#66727D',
    fontSize: 22,
  },
  footerSection: {
    paddingHorizontal: 25,
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: 25,
  },
  footerLeft: {
    width: '50%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingBottom: 10,
  },
  footerRight: {
    width: '50%',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  qrTextBlock: {
    // height: 55,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingLeft: 25,
    paddingRight: 80,
  },
  footerImg: {
    width: 90,
    marginRight: 20,
  },
  footerText: {
    maxWidth: 240,
    color: '#66727D',
    fontSize: 6,
    paddingRight: 70,
  },
  svg: {
    width: 90,
    marginRight: 10,
  },
  packageItem: {
    borderRight: '1px solid #4C5865',
    borderBottom: '1px solid #4C5865',
    borderLeft: '1px solid #4C5865',
    padding: 10,
  },
  packageItemFull: {
    borderTop: '1px solid #4C5865',
    borderRight: '1px solid #4C5865',
    borderBottom: '1px solid #4C5865',
    borderLeft: '1px solid #4C5865',
    padding: 10,
  },
  packageText: {
    color: '#4C5865',
    fontSize: 9,
    fontFamily: 'LADAPragmaticaBold',
    textAlign: 'center',
  },
  giftSection: {
    paddingHorizontal: 25,
    flexDirection: 'row',
  },
  giftBlock: {
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
    border: '2px solid #E37639',
    margin: '10px 0 10px',
  },
  giftBlockSecond: {
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
    borderTop: '2px solid #E37639',
    borderRight: '2px solid #E37639',
    borderBottom: '2px solid #E37639',
    margin: '10px 0 10px',
  },
  giftImg: {
    width: 30,
    marginRight: 10,
  },
  giftTitle: {
    color: '#E37639',
    fontSize: 9,
    fontFamily: 'LADAPragmaticaBold',
    textTransform: 'uppercase',
  },
  giftItem: {
    display: 'block',
    color: '#66727D',
    fontSize: 9,
    paddingRight: 40,
  },
  rouble: {
    display: 'inline',
    fontFamily: 'rouble',
    color: '#4C5865',
  },
})

export default styles
