export function getDealerIdToLs() {
  if (window) {
    const id = localStorage.getItem('e_')
    return id
  }
  return null
}

export function setDealerIdToLs(id) {
  if (window) {
    const dealer = localStorage.getItem('e_')

    if (dealer !== null) {
      localStorage.removeItem('e_')
    }
    localStorage.setItem('e_', id)
  }
}

export function getCityIdToLs() {
  if (window) {
    const id = localStorage.getItem('c_')
    return id
  }
  return null
}

export function setCityIdToLs(id) {
  if (window) {
    const city = localStorage.getItem('c_')

    if (city !== null) {
      localStorage.removeItem('c_')
    }
    localStorage.setItem('c_', id)
    localStorage.removeItem('e_')
  }
}

export function getDateFromLs() {
  if (window) {
    const date = localStorage.getItem('d_')
    return JSON.parse(date)
  }
  return null
}

export function setDateToLs(val) {
  if (window) {
    const date = localStorage.getItem('d_')

    if (date !== null) {
      localStorage.removeItem('d_')
    }
    localStorage.setItem('d_', val)
  }
}

export function removeDateToLs() {
  if (window) {
    localStorage.removeItem('d_')
  }
}

export function getDateReportFromLs(name) {
  if (window) {
    const date = localStorage.getItem(name)
    return JSON.parse(date)
  }
}

export function setDateReportToLs(val, name) {
  if (window) {
    const date = localStorage.getItem(name)

    if (date !== null) {
      localStorage.removeItem(name)
    }
    localStorage.setItem(name, val)
  }
}

export function removeDateReportToLs(name) {
  if (window) {
    localStorage.removeItem(name)
  }
}
