export const IconClose = ({ width, height, color }) => {
  return (
    <svg
      width={width || '14'}
      height={height || '14'}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.955404 11.9554L2.3583 13.3583L7.31371 8.4029L12.2691 13.3583L13.672 11.9554L8.71661 7L13.672 2.0446L12.2691 0.641696L7.31371 5.5971L2.3583 0.641696L0.955405 2.0446L5.91081 7L0.955404 11.9554Z"
        fill={color || '#E37639'}
      />
    </svg>
  )
}
