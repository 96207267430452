import clsx from 'clsx'
import { useForm } from 'react-hook-form'

import React, { useEffect, useState } from 'react'

import { Container } from 'components/Container'
import Input from 'components/Fields/Input'
import SelectDefault from 'components/Fields/Select'
import Icon from 'components/Icon'
import Calendar from 'containers/TableSupervisor/Calendar'

import { phoneValidation } from 'utils/phoneValidation'
import { paymentTypes } from 'containers/CorporateOffer/ClientForms/constants'

import style from './styles.module.scss'

export const ClientForms = ({ clientData, setClientData, mode = 'create' }) => {
  const [isOpenCalendar, setIsOpenCalendar] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)

  const calendarHandler = (val) => {
    setSelectedDate(val)
    setClientData((prev) => ({ ...prev, deliverDate: val.startDate }))
  }

  const selectHandler = (paymentType) => {
    setClientData((prev) => ({ ...prev, paymentType: paymentType.value }))
  }

  const {
    control,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      ...(mode === 'edit'
        ? {
            lastName: clientData.lastName,
            name: clientData.name,
            ...(!!clientData.middleName && {
              middleName: clientData.middleName,
            }),
            phone: clientData.phone,
            email: clientData.email,
            company: clientData.company,
            inn: clientData.inn,
            address: clientData.address,
            amountValue: clientData.amountValue,
            paymentType: clientData.paymentType,
            managerPosition: clientData.managerPosition,
            managerFIO: clientData.managerFIO,
          }
        : {}),
    },
  })
  const inputHandler = (e, name) => {
    const { value } = e.target

    setClientData((prev) => ({ ...prev, [name]: value }))
    if (name !== 'middleName') {
      if (!value) {
        setError(name, { type: name, message: 'Это поле обязательно' })
      } else {
        if (name === 'amountValue' && Number(value) === 0) {
          setError(name, {
            type: name,
            message: 'Введите корректное количество больше 0',
          })
        } else if (name === 'inn' && (value.length < 10 || value.length > 12)) {
          setError(name, {
            type: name,
            message: 'Введите корректный ИНН (10-12 цифр)',
          })
        } else {
          clearErrors(name)
        }
      }
    }
  }

  useEffect(() => {
    if (mode === 'edit' && !selectedDate) {
      setSelectedDate({
        startDate: clientData.deliverDate,
        endDate: clientData.deliverDate,
      })
    }
  }, [])

  return (
    <Container>
      <form>
        <h2 className={style.title}>Введите данные клиента</h2>
        <div className={style.formLine}>
          <Input
            label="Фамилия *"
            name="lastName"
            required
            rules={{ required: 'Это поле обязательно' }}
            control={control}
            type="text"
            error={errors['lastName']}
            className={style.input}
            onBlur={inputHandler}
          />
          <Input
            label="Имя *"
            name="name"
            required
            rules={{ required: 'Это поле обязательно' }}
            control={control}
            type="text"
            error={errors['name']}
            className={style.input}
            onBlur={inputHandler}
          />
        </div>

        <div className={clsx(style.formLine, style.full)}>
          <Input
            label="Отчество"
            name="middleName"
            required
            control={control}
            type="text"
            error={errors['middleName']}
            className={style.input}
            onBlur={inputHandler}
          />
        </div>

        <div className={style.formLine}>
          <Input
            dataName="phone-number"
            control={control}
            name="phone"
            inputmode="tel"
            label="Телефон *"
            required
            rules={{
              required: 'Это поле необходимо заполнить',
              validate: (v) =>
                v && v.replace(/[^+\d]/g, '').length === 10
                  ? true
                  : 'Это поле необходимо заполнить',
            }}
            error={errors['phone']}
            mask={'+7 (999) 999 99 99'}
            beforeMaskedValueChange={phoneValidation}
            onBlur={inputHandler}
          />
          <Input
            control={control}
            name="email"
            inputmode="email"
            label="E-mail *"
            required
            rules={{
              required: 'Это поле необходимо заполнить',
              validate: (v) =>
                v && /\S+@\S+\.\S+/.test(v) ? true : 'Некорректный email',
            }}
            error={errors['email']}
            onBlur={inputHandler}
          />
        </div>
        <div className={style.formLine}>
          <Input
            control={control}
            name="company"
            inputmode="company"
            label="Компания *"
            required
            rules={{ required: 'Это поле необходимо заполнить' }}
            error={errors['company']}
            onBlur={inputHandler}
          />
          <Input
            control={control}
            name="inn"
            inputmode="inn"
            label="ИНН *"
            required
            rules={{
              required: 'Это поле необходимо заполнить',
              validate: (v) =>
                v && /^\d{12}$/.test(v) ? true : 'Некорректный ИНН',
            }}
            error={errors['inn']}
            type={'number'}
            onBlur={inputHandler}
          />
        </div>
        <div className={clsx(style.formLine, style.formLine_margin)}>
          <Input
            control={control}
            name="address"
            inputmode="address"
            label="Адрес *"
            required
            rules={{ required: 'Это поле необходимо заполнить' }}
            error={errors['address']}
            onBlur={inputHandler}
          />
        </div>

        <div className={clsx(style.formLine, style.formLine_fields)}>
          {isOpenCalendar && (
            <Calendar
              setSelectedDate={calendarHandler}
              setIsOpenCalendar={setIsOpenCalendar}
              visibleDays={365}
              className={style.calendar}
              customLimitRangeDay
              oneDate
            />
          )}
          <div className={style.date} onClick={() => setIsOpenCalendar(true)}>
            {selectedDate !== null ? (
              <div className={style.selectedDate}>
                <Icon.Calendar />
                <span>{selectedDate.startDate}</span>
              </div>
            ) : (
              <>
                <div className={style.emtyDate}>
                  <Icon.Calendar />
                  <span>Дата поставки *</span>
                </div>
              </>
            )}
          </div>
          <Input
            label="Количество *"
            name="amountValue"
            required
            rules={{ required: 'Это поле обязательно' }}
            control={control}
            type="number"
            error={errors['amountValue']}
            className={style.input}
            onBlur={inputHandler}
          />
          <SelectDefault
            label="Тип оплаты *"
            name="paymentType"
            control={control}
            options={paymentTypes}
            error={errors['paymentType']}
            wrapClass={style.select}
            change={selectHandler}
            labelWithInputs
          />
        </div>

        <div className={clsx(style.formLine)}>
          <Input
            label="Должность руководителя ДЦ *"
            name="managerPosition"
            required
            rules={{ required: 'Это поле обязательно' }}
            control={control}
            error={errors['managerPosition']}
            className={style.input}
            onBlur={inputHandler}
          />
          <Input
            label="ФИО руководителя ДЦ *"
            name="managerFIO"
            required
            rules={{ required: 'Это поле обязательно' }}
            control={control}
            type="text"
            error={errors['managerFIO']}
            className={style.input}
            onBlur={inputHandler}
          />
        </div>

        <div className={style.footer}>
          <p className={style.requiredText}>
            * - поля, обязательные для заполнения
          </p>
          {/*<Button.Default type="submit">*/}
          {/*  {clientData ? 'Изменить' : 'Сохранить'}*/}
          {/*</Button.Default>*/}
        </div>
      </form>
    </Container>
  )
}
