import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import Button from 'components/Button'
import { EmptyMessage } from 'components/EmptyMessage'
import { Pagination } from 'components/Pagination'
import { Preloader } from 'components/Preloader'
import { LOADING_STATUS } from 'constants/loadingStatus'

import { SORT_DIRECTION } from 'constants/loadingStatus'
import { handleSort } from 'utils/sortTableData'
import { useSort } from 'hooks/useSort'
import { useSearch } from 'hooks/useSearch'
import { formatPhoneNumber } from 'utils/formatPhoneNumber'
import { diffDates } from 'utils/date'
import { declOfNum } from 'utils/declOfNum'

import { getUserRole } from 'store/auth/selectors'
import { getOrdersLoadingStatus } from 'store/orders/selectors'

import { fullAccess, partialAccess } from '../buttons'
import { ROLE } from 'constants/role'

import style from './styles.module.scss'

export default function ActiveOrdersChief({
  openModal,
  data,
  watchSearch,
  searchFieldsOrder,
  watchStatus,
}) {
  const [currentData, setCurrentData] = useState([])
  const [currentPage, setCurrentPage] = useState({ current: 1, amount: 10 })
  const [isReady, setIsReady] = useState(false)

  const words = [
    ['день', 'дня', 'дней'],
    ['остался', 'осталось', 'осталось'],
  ]

  const { loadingStatus } = useSelector((state) => ({
    loadingStatus: getOrdersLoadingStatus(state),
  }))

  const sortConfig = [
    { id: 0, path: 'created.date', type: 'date' },
    { id: 1, path: 'id', type: 'number' },
    { id: 2, path: 'family_name.model_name', type: 'multiField' },
    { id: 3, path: 'client_info.name', type: 'string' },
    { id: 4, path: 'manager.fullname', type: 'string' },
    { id: 5, path: 'status.name', type: 'string' },
  ]

  const filteredActiveOrders = useSearch(
    data,
    watchSearch,
    searchFieldsOrder,
    300,
    watchStatus > 1
  )

  const [
    sortedData,
    sortedIndex,
    sortDirection,
    handleSortedIndex,
    handleSortDirection,
  ] = useSort(filteredActiveOrders.filteredData, sortConfig)

  const { role } = useSelector((state) => ({ role: getUserRole(state) }))
  const buttons = role === ROLE.admin ? partialAccess : fullAccess

  useEffect(() => {
    if (!!sortedData.length) {
      const startIndex = 0
      const endIndex = currentPage.amount * currentPage.current
      const formattedData = sortedData.slice(startIndex, endIndex)

      setCurrentData(formattedData)
    }
  }, [currentPage, sortedData])

  useEffect(() => {
    setIsReady(true)
  }, [currentData])

  return (
    <>
      {loadingStatus === LOADING_STATUS.LOADING ? (
        <div className={style.preloader}>
          <Preloader.Spinner />
        </div>
      ) : currentData.length > 0 ? (
        <>
          <table className={clsx(style.table, style.activeOrdersChief)}>
            <thead>
              <tr>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        0,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 0,
                      [style.activeASC]:
                        sortedIndex === 0 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Дата создания
                  </span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        1,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 1,
                      [style.activeASC]:
                        sortedIndex === 1 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Номер заказа
                  </span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        2,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 2,
                      [style.activeASC]:
                        sortedIndex === 2 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Автомобиль
                  </span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        3,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 3,
                      [style.activeASC]:
                        sortedIndex === 3 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Клиент
                  </span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        4,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 4,
                      [style.activeASC]:
                        sortedIndex === 4 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Менеджер
                  </span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        5,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 5,
                      [style.activeASC]:
                        sortedIndex === 5 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Статус
                  </span>
                </th>
              </tr>
            </thead>

            <tbody>
              {currentData.map(
                ({
                  id,
                  created,
                  vin,
                  family_name,
                  model_name,
                  complectation,
                  modification,
                  status,
                  client_info,
                  manager,
                  type,
                }) => {
                  let days = diffDates(
                    new Date(status.date),
                    new Date(status.expired)
                  )

                  return (
                    <Fragment key={id}>
                      <tr className={style.info}>
                        <td>
                          <span>{created.date}</span>
                          <span
                            className={clsx(style.tableAction, {
                              [style.redText]: days === 0,
                            })}
                          >
                            {'До окончания срока ' +
                              declOfNum(Number(days), words[1]) +
                              ' ' +
                              days +
                              ' ' +
                              declOfNum(Number(days), words[0])}
                          </span>
                        </td>
                        <td>
                          <span>{id}</span>
                        </td>
                        <td>
                          <span className={style.bold}>
                            LADA {family_name} {model_name}
                          </span>
                          <span>{complectation}</span>
                          <span>{modification.title}</span>
                          <span className={style.vin}>VIN {vin}</span>
                          {type === 'direct_sale' && (
                            <div className={style.manufactureCarLabel}>
                              Авто от производителя
                            </div>
                          )}
                        </td>
                        <td>
                          <span className={style.bold}>{client_info.name}</span>
                          {client_info.phone && (
                            <span className={style.phone}>
                              {formatPhoneNumber(client_info.phone)}
                            </span>
                          )}
                          {client_info.email && (
                            <span>{client_info.email}</span>
                          )}
                        </td>
                        <td>
                          {manager.id ? (
                            <span className={style.bold}>
                              {manager.fullname}
                            </span>
                          ) : (
                            <span
                              className={clsx(style.bold, style.tableAction)}
                            >
                              Не назначен
                            </span>
                          )}
                        </td>
                        <td>
                          <span>{status.date}</span>
                          <span>{status.name}</span>
                        </td>
                      </tr>
                      <tr className={style.actions}>
                        <td colSpan={6} className={style.button}>
                          {manager.id ? (
                            <Fragment>
                              {buttons.access && (
                                <Button.Outlined onClick={() => openModal(id)}>
                                  {buttons.activeOrders[1]}
                                </Button.Outlined>
                              )}
                              <Button.Default to={`/order/${id}`}>
                                {buttons.activeOrders[0]}
                              </Button.Default>
                            </Fragment>
                          ) : (
                            <Fragment>
                              {buttons.access && (
                                <Button.Outlined onClick={() => openModal(id)}>
                                  {buttons.activeOrders[2]}
                                </Button.Outlined>
                              )}
                              <Button.Default to={`/order/${id}`}>
                                {buttons.activeOrders[0]}
                              </Button.Default>
                            </Fragment>
                          )}
                        </td>
                      </tr>
                    </Fragment>
                  )
                }
              )}
            </tbody>
          </table>
          <Pagination
            className={style.tableFooter}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            steps={[10, 25, 50]}
          />
        </>
      ) : (
        isReady && <EmptyMessage />
      )}
    </>
  )
}
