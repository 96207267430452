export const IconAccessories = ({ width, height, color }) => {
  return (
    <svg
      width={width || 49}
      height={height || 48}
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.0031 5.66779C30.3715 5.53072 29.7695 5.93924 29.6378 6.56276C29.5088 7.18628 29.9092 7.79905 30.5301 7.92805C39.3212 9.7583 45.6988 17.6168 45.6988 26.6095C45.6988 37.1287 37.1362 45.6914 26.6169 45.6914C17.6565 45.6914 9.80608 39.3406 7.95164 30.5898C7.81995 29.9636 7.19105 29.5631 6.58366 29.7002C5.95745 29.8346 5.55969 30.4447 5.69407 31.0682C7.77157 40.8806 16.5707 48 26.6169 48C38.4128 48 48.0075 38.4053 48.0075 26.6095C48.0075 16.531 40.8558 7.72111 31.0031 5.66779Z"
        fill={color || '#E37639'}
      />
      <path
        d="M2.55411 25.1358C2.4493 25.0229 2.27998 24.7756 2.31492 24.4289C3.39802 12.9019 12.9067 3.39585 24.4284 2.31275C24.4606 2.31006 24.4875 2.31006 24.5171 2.31006C24.8208 2.31006 25.0331 2.45788 25.1379 2.55463C25.3449 2.74545 25.4658 3.01152 25.4658 3.29103V10.8593C25.4658 11.3108 25.1084 11.6897 24.6192 11.7543C18.083 12.6653 12.6729 18.0647 11.7564 24.5929C11.6892 25.0954 11.2968 25.4636 10.8292 25.4636H3.2932C3.01369 25.4636 2.74493 25.3454 2.55411 25.1358ZM14.0462 24.91C14.8203 19.387 19.4026 14.8154 24.9417 14.0414C26.5785 13.8103 27.7744 12.4718 27.7744 10.8593V3.29103C27.7744 2.36382 27.3794 1.47422 26.6887 0.848015C26.0168 0.232557 25.1191 -0.065765 24.2107 0.012175C11.6059 1.20009 1.20494 11.6011 0.0143414 24.2112C-0.0716614 25.1116 0.232036 26.0119 0.847494 26.6892C1.4737 27.3799 2.36329 27.7723 3.2932 27.7723H10.8292C12.4606 27.7723 13.8124 26.5682 14.0462 24.91Z"
        fill={color || '#E37639'}
      />
      <path
        d="M32.9186 26.6121C32.9186 30.0872 30.0912 32.9118 26.6188 32.9118C23.1438 32.9118 20.3165 30.0872 20.3165 26.6121C20.3165 23.1371 23.1438 20.3124 26.6188 20.3124C30.0912 20.3124 32.9186 23.1371 32.9186 26.6121ZM18.0078 26.6121C18.0078 31.3611 21.8726 35.2205 26.6188 35.2205C31.3624 35.2205 35.2272 31.3611 35.2272 26.6121C35.2272 21.8632 31.3624 18.0038 26.6188 18.0038C21.8726 18.0038 18.0078 21.8632 18.0078 26.6121Z"
        fill={color || '#E37639'}
      />
      <path
        d="M25.4631 23.4204C25.4631 24.0574 25.9818 24.5761 26.6188 24.5761C27.7449 24.5761 28.6587 25.4898 28.6587 26.6159C28.6587 27.742 27.7449 28.6558 26.6188 28.6558C25.49 28.6558 24.5762 27.742 24.5762 26.6159C24.5762 25.979 24.0602 25.463 23.4232 25.463C22.7863 25.463 22.2676 25.979 22.2676 26.6159C22.2676 29.016 24.2188 30.9645 26.6188 30.9645C29.0161 30.9645 30.9673 29.016 30.9673 26.6159C30.9673 24.2159 29.0161 22.2674 26.6188 22.2674C25.9818 22.2674 25.4631 22.7834 25.4631 23.4204Z"
        fill={color || '#E37639'}
      />
      <path
        d="M22.3568 9.65959C22.9938 9.65959 23.5125 9.14357 23.5125 8.50661V6.37804C23.5125 5.7384 22.9938 5.22238 22.3568 5.22238C21.7199 5.22238 21.2012 5.7384 21.2012 6.37804V8.50661C21.2012 9.14357 21.7199 9.65959 22.3568 9.65959Z"
        fill={color || '#E37639'}
      />
      <path
        d="M8.51275 21.1969H6.38149C5.74453 21.1969 5.22852 21.7156 5.22852 22.3525C5.22852 22.9895 5.74453 23.5055 6.38149 23.5055H8.51275C9.14971 23.5055 9.66841 22.9895 9.66841 22.3525C9.66841 21.7156 9.14971 21.1969 8.51275 21.1969Z"
        fill={color || '#E37639'}
      />
    </svg>
  )
}
