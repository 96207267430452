import { useNavigate } from 'react-router-dom'

import Button from 'components/Button'
import { Container } from 'components/Container'

import style from './styles.module.scss'

export const FooterActions = ({
  setDataHandler,
  id,
  mode = 'create',
  sended,
}) => {
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }

  return (
    <div className={style.background}>
      <Container className={style.container}>
        <div className={style.links}>
          {/*<div*/}
          {/*  className={clsx(style.itemEmail)}*/}
          {/*>*/}
          {/*  <Icon.Email color={colors.darkGray} />*/}
          {/*  <span>Отправить на почту клиенту</span>*/}
          {/*</div>*/}
        </div>
        <div className={style.buttons}>
          {id && (
            <Button.Outlined
              type="button"
              className={style.actionBtn}
              to={sended && `/corporateOffer/view/${id}`}
              disabled={!sended}
            >
              Сформировать PDF
            </Button.Outlined>
          )}
          <Button.Outlined
            onClick={goBack}
            type="button"
            className={style.actionBtn}
          >
            Вернуться
          </Button.Outlined>
          <Button.Default
            onClick={setDataHandler}
            type="submit"
            className={style.actionBtn}
          >
            {mode === 'edit' ? 'Сохранить' : 'Cформировать'}
          </Button.Default>
        </div>
      </Container>
    </div>
  )
}
