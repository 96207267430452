import { getCarDataForPO, getPOById } from 'api/personalOffer'

export async function getCarData(color, compl) {
  try {
    const res = await getCarDataForPO(color, compl)

    return res.data
  } catch (e) {
    throw e
  }
}

export async function getOfferData(id) {
  try {
    const res = await getPOById(id)

    return res.data
  } catch (e) {
    throw e
  }
}
