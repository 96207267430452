import React, { useEffect, useState, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { FooterActions } from './FooterActions'
import { CarInfo } from 'containers/NewCar/CarInfo'
import { Accessories } from 'containers/Order/Accessories'
import { Discounts } from 'containers/Order/Discounts'
import { Services } from 'containers/Order/Services'
import { Gifts } from 'containers/Order/Gifts'
import { Notifications } from 'components/Notifications'

import { loadAuthToken } from 'store/auth/slice'
import { getUserRole } from 'store/auth/selectors'

import { getCarById, setCarInShowroom } from 'api/cars'
import { getAccessoriesByKomplId } from 'api/accessories'
import { updateFullPayment } from 'api/payment'

import { ROLE } from 'constants/role'

import style from './styles.module.scss'
import { percentCalculation } from './utils'
import { useForm } from 'react-hook-form'
import { MemoizedDiscounts } from 'components/DiscountsTetsing'
import { getCookie } from 'utils/cookie'

export default function NewCar({ isMobile, isTablet }) {
  const [isLoading, setIsLoading] = useState(true)
  const [isSent, setIsSent] = useState(false)
  const [carInfo, setCarInfo] = useState([])
  const [accessories, setAccessories] = useState(null)
  const [isFullPayment, setIsFullPayment] = useState(false)
  const [isOpenNotifications, setIsOpenNotifications] = useState({
    isOpen: false,
    currentTitle: '',
  })
  const [totalPriceWithPercent, setTotalPriceWithPercent] = useState(0)

  const isDirectSaleCar = carInfo.type === 'direct_sale'

  const dispatch = useDispatch()

  const { id } = useParams()
  const navigate = useNavigate()

  const checkData = !!carInfo && !!accessories

  function goBack() {
    navigate(-1)
  }

  const { role } = useSelector((state) => ({
    role: getUserRole(state),
  }))
  const access = role === ROLE.admin ? false : true

  async function fetchCarInfo() {
    try {
      const res = await getCarById(id)
      setCarInfo(res.data)
      setIsLoading(false)
    } catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    fetchCarInfo()
  }, [id, isSent])

  const updatePayment = async (val) => {
    try {
      await updateFullPayment({
        carId: carInfo.car_id,
        payment: val,
      })
      setIsSent((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (Object.keys(carInfo).length) {
      async function fetchAccessories() {
        try {
          const res = await getAccessoriesByKomplId(carInfo.complectation_id)
          setAccessories(res.data)
          setIsLoading(false)
        } catch (e) {
          console.warn(e)
        }
      }

      fetchAccessories()
    }
  }, [carInfo])

  async function deleteCarFromShowroom() {
    try {
      await setCarInShowroom(carInfo.car_id, { showroom: false })
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isTakeOff' })
      setIsSent((prev) => !prev)
    } catch {
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isError' })
    }
  }

  async function addCarToShowroom() {
    try {
      await setCarInShowroom(carInfo.car_id, { showroom: true })
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isPosted' })
      setIsSent((prev) => !prev)
    } catch {
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isError' })
    }
  }

  useEffect(() => {
    if (carInfo.modification !== undefined) {
      setTotalPriceWithPercent({
        totalCarPricePercent: percentCalculation(
          carInfo.modification.total_price,
          carInfo.modification.price
        ),
        totalCarPrice: carInfo.modification.total_price,
        initialCarPrice: carInfo.modification.price,
      })
    }
  }, [carInfo])

  useEffect(() => {
    dispatch(loadAuthToken())
  }, [])

  return (
    <>
      <Notifications
        {...isOpenNotifications}
        setIsOpenNotifications={setIsOpenNotifications}
      />
      {!isLoading && checkData && (
        <>
          <CarInfo
            {...carInfo}
            updatePayment={updatePayment}
            goBack={goBack}
            access={access}
            isDirectSaleCar={isDirectSaleCar}
          />
          {carInfo?.accessories && !isDirectSaleCar && (
            <Accessories
              {...accessories}
              refetchOrderInfo={fetchCarInfo}
              data={carInfo.accessories}
              carId={carInfo.car_id}
              access={access}
              background
              {...totalPriceWithPercent}
            />
          )}
          <MemoizedDiscounts
            refetchOrderInfo={fetchCarInfo}
            activeDealerOffers={carInfo?.dealer_actions}
            activeOffers={carInfo?.special_offers}
            carId={carInfo.car_id}
            access={access}
            isDirectSaleCar={isDirectSaleCar}
            family={carInfo.family_slug}
            {...totalPriceWithPercent}
          />

          {!isDirectSaleCar && (
            <Services
              refetchOrderInfo={fetchCarInfo}
              data={carInfo.additional_services}
              carId={carInfo.car_id}
              access={access}
              background
              {...totalPriceWithPercent}
            />
          )}
          <Gifts data={carInfo.gifts} carId={carInfo.car_id} access={access} />
          <FooterActions
            status={carInfo.showroom}
            addCar={addCarToShowroom}
            deleteCar={deleteCarFromShowroom}
            role={role}
            carId={carInfo.car_id}
            type={carInfo.type}
          />
        </>
      )}
    </>
  )
}
