export const IconCredit = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M22.0918 0.49939H14.1177C13.9408 0.49939 13.7711 0.569673 13.646 0.694778C13.5209 0.819883 13.4506 0.989562 13.4506 1.16649C13.4464 1.25667 13.4605 1.34676 13.4922 1.43131C13.5238 1.51586 13.5723 1.59312 13.6347 1.65838C13.697 1.72365 13.772 1.77559 13.855 1.81103C13.938 1.84648 14.0274 1.86471 14.1177 1.86461H22.0918C22.2414 1.86456 22.3851 1.9229 22.4923 2.02722C22.5995 2.13153 22.6618 2.27358 22.6658 2.42311V10.3973C22.6658 10.5742 22.7361 10.7439 22.8612 10.869C22.9863 10.9941 23.156 11.0644 23.3329 11.0644C23.5099 11.0644 23.6795 10.9941 23.8046 10.869C23.9298 10.7439 24 10.5742 24 10.3973V2.42311C24.0021 2.17122 23.9542 1.92142 23.8593 1.68811C23.7643 1.4548 23.624 1.24261 23.4466 1.06376C23.2692 0.884925 23.0582 0.742978 22.8257 0.646113C22.5931 0.549248 22.3437 0.499381 22.0918 0.49939"
        fill="white"
      />
      <path
        d="M15.3429 9.17071C15.4013 9.236 15.4731 9.28785 15.5535 9.32267C15.6338 9.3575 15.7208 9.37446 15.8083 9.37239C15.8979 9.37279 15.9867 9.35514 16.0693 9.32049C16.1519 9.28584 16.2267 9.2349 16.2893 9.17071L17.67 7.80548L19.0352 9.17071C19.0977 9.2349 19.1725 9.28584 19.2552 9.32049C19.3378 9.35514 19.4265 9.37279 19.5161 9.37239C19.6037 9.37446 19.6907 9.3575 19.771 9.32267C19.8514 9.28785 19.9232 9.236 19.9816 9.17071C20.0435 9.11004 20.0927 9.03763 20.1263 8.95771C20.1598 8.87779 20.1771 8.79198 20.1771 8.70529C20.1771 8.6186 20.1598 8.53279 20.1263 8.45287C20.0927 8.37295 20.0435 8.30054 19.9816 8.23987L18.6163 6.85914L19.9971 5.4784C20.059 5.41773 20.1082 5.34532 20.1418 5.2654C20.1754 5.18548 20.1926 5.09967 20.1926 5.01298C20.1926 4.9263 20.1754 4.84048 20.1418 4.76056C20.1082 4.68065 20.059 4.60823 19.9971 4.54757C19.9351 4.48518 19.8614 4.43567 19.7802 4.40189C19.6989 4.3681 19.6119 4.35071 19.5239 4.35071C19.436 4.35071 19.3489 4.3681 19.2677 4.40189C19.1865 4.43567 19.1127 4.48518 19.0507 4.54757L17.6855 5.91279L16.3048 4.54757C16.2428 4.48518 16.169 4.43567 16.0878 4.40189C16.0066 4.3681 15.9196 4.35071 15.8316 4.35071C15.7436 4.35071 15.6566 4.3681 15.5754 4.40189C15.4941 4.43567 15.4204 4.48518 15.3584 4.54757C15.2965 4.60823 15.2473 4.68065 15.2137 4.76056C15.1801 4.84048 15.1629 4.9263 15.1629 5.01298C15.1629 5.09967 15.1801 5.18548 15.2137 5.2654C15.2473 5.34532 15.2965 5.41773 15.3584 5.4784L16.7392 6.85914L15.3429 8.25539C15.281 8.31606 15.2318 8.38847 15.1982 8.46839C15.1646 8.5483 15.1473 8.63412 15.1473 8.7208C15.1473 8.80749 15.1646 8.89331 15.1982 8.97322C15.2318 9.05314 15.281 9.12555 15.3429 9.18622"
        fill="white"
      />
      <path
        d="M6.3303 14.9102C6.15338 14.9102 5.9837 14.9804 5.85859 15.1055C5.73349 15.2306 5.6632 15.4003 5.6632 15.5773V17.563H3.72397C3.54704 17.563 3.37736 17.6333 3.25226 17.7584C3.12715 17.8835 3.05687 18.0532 3.05687 18.2301C3.05687 18.4071 3.12715 18.5767 3.25226 18.7018C3.37736 18.8269 3.54704 18.8972 3.72397 18.8972H5.6632V20.8365C5.6632 21.0134 5.73349 21.1831 5.85859 21.3082C5.9837 21.4333 6.15338 21.5036 6.3303 21.5036C6.50723 21.5036 6.67691 21.4333 6.80201 21.3082C6.92712 21.1831 6.9974 21.0134 6.9974 20.8365V18.8507H8.95215C9.12908 18.8507 9.29875 18.7804 9.42386 18.6553C9.54896 18.5302 9.61925 18.3605 9.61925 18.1836C9.61925 18.0067 9.54896 17.837 9.42386 17.7119C9.29875 17.5868 9.12908 17.5165 8.95215 17.5165H6.9974V15.5773C6.9974 15.4003 6.92712 15.2306 6.80201 15.1055C6.67691 14.9804 6.50723 14.9102 6.3303 14.9102"
        fill="white"
      />
      <path
        d="M9.88235 23.18H1.8927C1.74457 23.18 1.60252 23.1211 1.49778 23.0164C1.39304 22.9116 1.3342 22.7696 1.3342 22.6215V14.6473C1.3342 14.4704 1.26391 14.3007 1.13881 14.1756C1.0137 14.0505 0.844023 13.9802 0.667098 13.9802C0.490172 13.9802 0.320493 14.0505 0.195388 14.1756C0.0702833 14.3007 0 14.4704 0 14.6473L0 22.6215C0 23.1234 0.199408 23.6049 0.554358 23.9598C0.909307 24.3148 1.39072 24.5142 1.8927 24.5142H9.88235C10.0593 24.5142 10.229 24.4439 10.3541 24.3188C10.4792 24.1937 10.5495 24.024 10.5495 23.8471C10.5495 23.6701 10.4792 23.5005 10.3541 23.3754C10.229 23.2503 10.0593 23.18 9.88235 23.18"
        fill="white"
      />
      <path
        d="M22.1073 11.8551H1.93924C1.79111 11.8551 1.64906 11.7962 1.54432 11.6915C1.43958 11.5868 1.38074 11.4447 1.38074 11.2966V2.3916C1.38025 2.2512 1.43266 2.11576 1.52753 2.01226C1.62241 1.90876 1.75278 1.8448 1.8927 1.8331H10.7666C10.9162 1.83304 11.0599 1.89138 11.1671 1.9957C11.2744 2.10002 11.3366 2.24206 11.3407 2.3916V10.3813C11.3649 10.5387 11.4447 10.6824 11.5656 10.7861C11.6866 10.8899 11.8407 10.9469 12 10.9469C12.1593 10.9469 12.3134 10.8899 12.4344 10.7861C12.5553 10.6824 12.6351 10.5387 12.6593 10.3813V2.3916C12.6593 1.88962 12.4599 1.40821 12.105 1.05326C11.75 0.69831 11.2686 0.498901 10.7666 0.498901L1.8927 0.498901C1.39072 0.498901 0.909307 0.69831 0.554358 1.05326C0.199408 1.40821 0 1.88962 0 2.3916L0 11.2655C0 11.7675 0.199408 12.2489 0.554358 12.6039C0.909307 12.9588 1.39072 13.1582 1.8927 13.1582H22.0608C22.2104 13.1582 22.3541 13.2165 22.4613 13.3208C22.5685 13.4252 22.6307 13.5672 22.6348 13.7167V22.5907C22.6307 22.7402 22.5685 22.8823 22.4613 22.9866C22.3541 23.0909 22.2104 23.1492 22.0608 23.1492H13.2334C13.1587 23.1513 13.0844 23.1384 13.0149 23.1112C12.9453 23.0841 12.8819 23.0433 12.8284 22.9912C12.7749 22.9391 12.7323 22.8769 12.7033 22.8081C12.6743 22.7393 12.6593 22.6654 12.6593 22.5907V14.6476C12.6593 14.4706 12.5891 14.301 12.464 14.1759C12.3388 14.0508 12.1692 13.9805 11.9922 13.9805C11.8153 13.9805 11.6456 14.0508 11.5205 14.1759C11.3954 14.301 11.3251 14.4706 11.3251 14.6476V22.6217C11.3292 23.1251 11.5321 23.6065 11.8895 23.961C12.2469 24.3155 12.7299 24.5144 13.2334 24.5144H22.0918C22.5952 24.5144 23.0782 24.3155 23.4356 23.961C23.7931 23.6065 23.9959 23.1251 24 22.6217V13.7478C24 13.4992 23.951 13.2531 23.8559 13.0235C23.7608 12.7938 23.6214 12.5852 23.4456 12.4094C23.2699 12.2337 23.0612 12.0943 22.8316 11.9991C22.602 11.904 22.3559 11.8551 22.1073 11.8551"
        fill="white"
      />
      <path
        d="M15.0947 20.0169H20.1677C20.3447 20.0169 20.5143 19.9467 20.6394 19.8215C20.7645 19.6964 20.8348 19.5268 20.8348 19.3498C20.8348 19.1729 20.7645 19.0032 20.6394 18.8781C20.5143 18.753 20.3447 18.6827 20.1677 18.6827H15.0947C14.9178 18.6827 14.7481 18.753 14.623 18.8781C14.4979 19.0032 14.4276 19.1729 14.4276 19.3498C14.4276 19.5268 14.4979 19.6964 14.623 19.8215C14.7481 19.9467 14.9178 20.0169 15.0947 20.0169Z"
        fill="white"
      />
      <path
        d="M15.0947 17.6899H20.1677C20.3447 17.6899 20.5143 17.6196 20.6394 17.4945C20.7645 17.3694 20.8348 17.1997 20.8348 17.0228C20.8348 16.8459 20.7645 16.6762 20.6394 16.5511C20.5143 16.426 20.3447 16.3557 20.1677 16.3557H15.0947C14.9178 16.3557 14.7481 16.426 14.623 16.5511C14.4979 16.6762 14.4276 16.8459 14.4276 17.0228C14.4276 17.1997 14.4979 17.3694 14.623 17.4945C14.7481 17.6196 14.9178 17.6899 15.0947 17.6899Z"
        fill="white"
      />
      <path
        d="M9.89704 6.82957C9.89816 6.6653 9.83862 6.5064 9.72984 6.3833C9.62106 6.2602 9.47068 6.18157 9.30752 6.16248H3.42775C3.25206 6.16643 3.08467 6.23798 2.96041 6.36224C2.83615 6.48649 2.7646 6.65389 2.76065 6.82957C2.76065 7.0065 2.83093 7.17618 2.95604 7.30128C3.08114 7.42639 3.25082 7.49667 3.42775 7.49667H9.30752C9.48444 7.49667 9.65412 7.42639 9.77922 7.30128C9.90433 7.17618 9.97461 7.0065 9.97461 6.82957"
        fill="white"
      />
      <path
        d="M6.54721 5.15463C6.7229 5.15068 6.89029 5.07913 7.01455 4.95487C7.13881 4.83061 7.21036 4.66322 7.21431 4.48753C7.21431 4.31061 7.14403 4.14093 7.01892 4.01582C6.89382 3.89072 6.72414 3.82043 6.54721 3.82043H6.00422C5.8273 3.82043 5.65762 3.89072 5.53252 4.01582C5.40741 4.14093 5.33713 4.31061 5.33713 4.48753C5.33713 4.66446 5.40741 4.83414 5.53252 4.95924C5.65762 5.08435 5.8273 5.15463 6.00422 5.15463H6.54721Z"
        fill="white"
      />
      <path
        d="M6.00422 8.49C5.8273 8.49 5.65762 8.56028 5.53252 8.68539C5.40741 8.8105 5.33713 8.98017 5.33713 9.1571C5.34118 9.33132 5.41325 9.49704 5.53792 9.61881C5.66259 9.74058 5.82996 9.80873 6.00422 9.80868H6.54721C6.72414 9.80868 6.89382 9.7384 7.01892 9.61329C7.14403 9.48819 7.21431 9.31851 7.21431 9.14159C7.21431 8.96466 7.14403 8.79498 7.01892 8.66988C6.89382 8.54477 6.72414 8.47449 6.54721 8.47449L6.00422 8.49Z"
        fill="white"
      />
    </svg>
  )
}
