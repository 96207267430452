import { getAllPO } from 'api/personalOffer'
import { getFullModelRange } from 'api/cars'

export async function getAll() {
  try {
    const res = await getAllPO()

    return res.data
  } catch (e) {
    return []
  }
}

export async function getFamiliesData() {
  try {
    const res = await getFullModelRange()

    return res.data
  } catch (e) {
    return []
  }
}
