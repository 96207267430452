export const IconAddToCompare = ({ width, height, color }) => {
  return (
    <svg
      width={width || '16'}
      height={height || '17'}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 1H16" stroke={color || '#A6ADB5'} strokeWidth="1.6" />
      <path d="M0 7.40002H16" stroke={color || '#A6ADB5'} strokeWidth="1.6" />
      <path d="M0 13.8H6.93333" stroke={color || '#A6ADB5'} strokeWidth="1.6" />
      <path
        d="M9.6001 13.8L16.0001 13.8"
        stroke={color || '#A6ADB5'}
        strokeWidth="1.6"
      />
      <path
        d="M12.8 10.6L12.8 17"
        stroke={color || '#A6ADB5'}
        strokeWidth="1.6"
      />
    </svg>
  )
}
