export const fullAccess = {
  access: true,
  activeOrders: [
    'ПЕРЕЙТИ К ЗАКАЗУ',
    'сменить менеджера',
    'назначить менеджера',
  ],
  completedOrders: 'подробнее',
  carsForPlacing: ['редактировать', 'разместить в онлайн шоурум'],
  carsPlaced: ['редактировать', 'снять с онлайн продажи'],
  car: [
    'сохранить и вернуться',
    'РАЗМЕСТИТЬ АВТОМОБИЛЬ В ШОРУМЕ',
    'Снять с онлайн продажи',
  ],
  order: ['Сохранить изменения', 'отменить заказ'],
}

export const partialAccess = {
  access: false,
  activeOrders: ['ПЕРЕЙТИ К ЗАКАЗУ'],
  completedOrders: 'подробнее',
  carsForPlacing: ['подробнее'],
  carsPlaced: ['подробнее'],
  car: ['вернуться'],
  order: ['вернуться'],
}
