import { useEffect, useState } from 'react'
import { useDebounce } from './useDebounce'
import { getFieldByPath } from '../utils/sortTableData'

/**
 *
 * @param {[]} data - raw data
 * @param {string} searchStr - raw data filtering by this string
 * @param {[string]} searchFields -
 * what's fields be search, supporting unions,
 * use {'path_one', ['path_two', 'path_three']},
 * it means to be [object.path_one, (object.path_two + " " + object.path_three)]
 * @param {number} debounceDelay - delay to search
 * @param {boolean=} disabled - bool param for disable filtering
 * @return {{filteredData: []}} - filteredData
 */
export const useSearch = (
  data,
  searchStr,
  searchFields,
  debounceDelay = 300,
  disabled = false
) => {
  const [filteredData, setFilteredData] = useState(data)
  const debouncedVal = useDebounce(searchStr, debounceDelay)

  const _searchByFields = (pathArray) =>
    [...data].filter((object) =>
      pathArray.reduce((acc, path) => {
        if (typeof path === 'object') {
          return path
            .map((pathPart) => String(getFieldByPath(object, pathPart)))
            .join(' ')
            .toLowerCase()
            .indexOf(String(searchStr).toLowerCase()) !== -1
            ? true
            : acc
        }
        return String(getFieldByPath(object, path))
          .toLowerCase()
          .indexOf(String(searchStr).toLowerCase()) !== -1
          ? true
          : acc
      }, false)
    )

  useEffect(() => {
    if (
      data &&
      data.length &&
      debouncedVal &&
      searchStr &&
      debouncedVal === searchStr &&
      !disabled
    ) {
      setFilteredData(_searchByFields(searchFields))
    }
  }, [data, debouncedVal, searchStr])

  useEffect(() => {
    if (!searchStr || disabled) {
      setFilteredData(data)
    }
  }, [data, searchStr, disabled])

  return { filteredData }
}
