import React, { useEffect, useRef, useState } from 'react'
import { PDFViewer } from '@react-pdf/renderer'

import { PdfCreator } from 'components/PdfCreator'
import { svgFormatter } from 'components/PdfGenerator/PdfList/utils'
import { QR } from 'components/QR'

import { getCarById } from 'api/cars'

export const PdfPage = () => {
  const [carInfo, setCarInfo] = useState(false)
  const [formattedSvg, setFormattedSvg] = useState(false)
  const [carId, setCarId] = useState(false)

  const useQr = useRef(null)

  async function fetchCarInfo(id) {
    try {
      const res = await getCarById(id)
      setCarInfo(res.data)
    } catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    const id = localStorage.getItem('c_id')

    setCarId(id)

    fetchCarInfo(id)
  }, [])

  useEffect(() => {
    carId && setFormattedSvg(svgFormatter(useQr))
  }, [carId])

  return (
    <>
      {carId && (
        <>
          <PDFViewer style={{ width: '100%', height: '100vh' }}>
            {carInfo && <PdfCreator {...carInfo} formattedSvg={formattedSvg} />}
          </PDFViewer>
          <QR
            value={`https://www.lada.ru/cars/bage/${carId}`}
            size={60}
            ref={useQr}
          />
        </>
      )}
    </>
  )
}
