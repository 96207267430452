import { useState, useEffect } from 'react'

export const useViewport = () => {
  const [size, setSize] = useState({
    width: undefined,
    height: undefined,
  })
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      function handleWindowResize() {
        setSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }

      window.addEventListener('resize', handleWindowResize)
      handleWindowResize()

      return () => window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (!!size.width && size.width < 576) {
      setIsMobile(true)
    }
  }, [size])

  return {
    size,
    isMobile,
  }
}
