import clsx from 'clsx'
import { Controller } from 'react-hook-form'

import styles from './styles.module.scss'

const Radio = ({
  name,
  customStyle,
  defaultValue,
  disabled,
  control,
  loading,
  children,
  click,
  bordered,
  circle,
  putClass,
  ...attr
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <label
          className={clsx({
            [styles.radio]: true,
            [customStyle]: customStyle,
            [styles.radio_disabled]: disabled,
            [styles.radio_bordered]: bordered,
            [styles.radio_circle]: circle,
            [value + '_' + putClass]: putClass,
          })}
          onClick={(event) => event.stopPropagation()}
        >
          <input
            type="radio"
            data-test-name={`${value}-${name}`}
            name={name}
            value={value}
            onChange={(e) => {
              onChange(e.target.value)
              click && click(e)
            }}
            disabled={disabled}
            {...attr}
          />
          <span>{children}</span>
        </label>
      )}
    />
  )
}

export default Radio
