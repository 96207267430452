import { request } from 'utils/request'

export async function addOrderComment(orderId, data) {
  return request.put(
    `/api/os-manager-orders/${orderId}`,
    {
      ...data,
    },
    {
      withCredentials: true,
    }
  )
}
