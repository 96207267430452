import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Container } from 'components/Container'
import Switch from 'components/Fields/Switch'
import { colors } from 'constants/inlineColorConfig'

import style from './styles.module.scss'
import { AccordionItem } from './AccordionItem'
import Button from 'components/Button'
import { getInsurance } from 'api/insurance'
import { staticData } from './constants'

export const Insurance = ({
  setIsOpen,
  setCurrent,
  setData,
  order_id,
  insuranceInfo,
  isInputsActive = true,
  setIsActiveInsurance,
  isActiveInsurance,
  removeInsurance,
  isChange,
  setIsChange,
  defaultPrice,
  access,
}) => {
  const [insuranceList, setInsuranceList] = useState([])
  const [isInsurance, setIsInsurance] = useState(false)
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      insurance: isInsurance,
    },
  })
  const nameWatch = watch('name')
  const kaskoNameWatch = watch('kasko_name')
  const watchInsurance = watch('insurance')

  const handleChange = () => {
    if (isActiveInsurance) {
      if (isInsurance) {
        removeInsurance()
      }
      setIsActiveInsurance(false)
    } else {
      setIsActiveInsurance(true)
    }
  }

  const fetchAndSet = async () => {
    try {
      const res = await getInsurance()

      if (res.data) {
        const excludeFields = ['main', 'list', 'navigation', 'banner']
        const keys = Object.keys(res.data).filter(
          (el) => !excludeFields.includes(el)
        )
        const newData = keys.reduce(
          (acc, el, id) => [
            ...acc,
            {
              ...res.data[el],
              name: el,
              title: res.data[el].title.split(' ')[0],
            },
          ],
          []
        )
        //----
        setInsuranceList(newData.reverse())
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => fetchAndSet(), [])

  const handleClick = (val) => {
    setIsActiveInsurance(watchInsurance)
    setCurrent('insurance')
    setData(val)
    setIsOpen(true)
  }

  /**
   * @param {insurance: boolean, name: string, ...fields_params} data
   * @description формируется и передается в обработчик клика обьект с выбранным полем страховки, очищаются все не выбранные поля.
   */
  const onSubmit = async (data) => {
    const requestData = {
      order_id,
      programs: [],
    }

    if (data.kasko_name) {
      requestData.programs.push({
        name: data.kasko_name,
        price: Number(data['price_' + data.kasko_name]),
      })
    }

    if (data.name) {
      requestData.programs.push({
        name: data.name,
        price: Number(data['price_' + data.name]),
      })
    }

    const checkedCasco = requestData.programs.map(
      (insuranceItem) => insuranceItem.name
    )

    staticData.forEach((el) => {
      if (!checkedCasco.includes(el.name)) {
        setValue('price_' + el.name, '')
      }
    })

    handleClick(requestData)
  }

  useEffect(() => {
    setIsChange(false)
  }, [nameWatch, kaskoNameWatch])

  useEffect(() => {
    if (insuranceInfo !== null && insuranceInfo.length) {
      setIsInsurance(true)
      setIsActiveInsurance(true)
      setValue('insurance', 'true')
    }
  }, [insuranceInfo])

  useEffect(() => {
    if (insuranceInfo && isInsurance) {
      insuranceInfo.forEach((insuranceItem) => {
        Object.keys(insuranceItem).map((el) => {
          if (el === 'price') {
            setValue(`${el}_${insuranceItem.name}`, String(insuranceItem[el]))
            return
          }

          const staticNames = staticData.map((el) => {
            return el.name
          })

          if (staticNames.includes(insuranceItem[el])) {
            setValue(`kasko_${el}`, String(insuranceItem[el]))
          } else {
            setValue(`${el}`, String(insuranceItem[el]))
          }

          return
        })
      })
    }
  }, [insuranceInfo, isInsurance])

  return (
    <Container className={style.container}>
      <div className={style.titleWithButton}>
        <h2 className={style.title}>LADA Страхование</h2>
        <div className={style.switch}>
          <Switch
            id="insurance"
            name="insurance"
            control={control}
            activeColor={colors.brightOrange}
            disabled={!isInputsActive || !access}
            value={true}
            change={handleChange}
          />
          <div className={style.text}>
            <span>Использовать</span>
            <span>программу</span>
          </div>
        </div>
      </div>
      {isActiveInsurance && (
        <>
          {insuranceList.map((item, index) => (
            <AccordionItem
              errors={errors}
              control={control}
              disabled={!watchInsurance || !isInputsActive || !access}
              key={index}
              currentName={nameWatch}
              isInputsActive={isInputsActive}
              preSetRate={(index + 1) / 100}
              creditAvailable={index > 1 ? true : false}
              setValue={setValue}
              defaultPrice={defaultPrice}
              setIsChange={setIsChange}
              watch={watch}
              isChange={isChange}
              reset={() => setValue('name', undefined)}
              {...item}
            />
          ))}
          <div className={style.titleWithButton} style={{ marginTop: 40 }}>
            <h2 className={style.title}>Другое Страхование</h2>
          </div>
          {staticData.map((el) => (
            <AccordionItem
              errors={errors}
              {...el}
              key={el.id}
              currentName={kaskoNameWatch}
              control={control}
              disabled={!watchInsurance || !isInputsActive || !access}
              isInputsActive={isInputsActive}
              disabledOpen={true}
              setValue={setValue}
              kasko={true}
              setIsChange={setIsChange}
              watch={watch}
              isChange={isChange}
              reset={() => setValue('kasko_name', undefined)}
            />
          ))}
          <div className={style.action}>
            <Button.Default
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={isChange || !isInputsActive || !access}
              isGray={isChange}
            >
              {isChange ? 'Сохранено' : 'Сохранить'}
            </Button.Default>
          </div>
        </>
      )}
    </Container>
  )
}
