import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import Button from 'components/Button'
import { Pagination } from 'components/Pagination'
import { EmptyMessage } from 'components/EmptyMessage'
import { Preloader } from 'components/Preloader'

import { useSort } from 'hooks/useSort'
import { handleSort } from 'utils/sortTableData'
import { LOADING_STATUS, SORT_DIRECTION } from 'constants/loadingStatus'
import { useSearch } from 'hooks/useSearch'

import { getUserRole } from 'store/auth/selectors'
import { getCarsLoadingStatus } from 'store/cars/selectors'

import { getFormattedPrice, getPrice } from 'utils/getPrice'

import { fullAccess, partialAccess } from '../buttons'
import { ROLE } from 'constants/role'

import style from './styles.module.scss'

export default function CarsPlacedChief({
  data,
  deleteCar,
  watchSearch,
  searchFieldsCar,
  watchStatus,
}) {
  const [currentData, setCurrentData] = useState([])
  const [currentPage, setCurrentPage] = useState({ current: 1, amount: 10 })
  const [isReady, setIsReady] = useState(false)

  const { loadingStatus } = useSelector((state) => ({
    loadingStatus: getCarsLoadingStatus(state),
  }))

  const sortConfig = [
    { id: 0, path: 'created.date', type: 'date' },
    { id: 1, path: 'car_id', type: 'number' },
    { id: 2, path: 'family_name.model_name', type: 'multiField' },
    { id: 3, path: 'discount' },
    { id: 4, path: 'price' },
  ]

  const filteredCarsPlaced = useSearch(
    data,
    watchSearch,
    searchFieldsCar,
    300,
    !(watchStatus == 3)
  )

  const [
    sortedData,
    sortedIndex,
    sortDirection,
    handleSortedIndex,
    handleSortDirection,
  ] = useSort(filteredCarsPlaced.filteredData, sortConfig)

  const { role } = useSelector((state) => ({ role: getUserRole(state) }))
  const buttons = role === ROLE.admin ? partialAccess : fullAccess

  useEffect(() => {
    if (!!sortedData.length) {
      const startIndex = 0
      const endIndex = currentPage.amount * currentPage.current
      const formattedData = sortedData.slice(startIndex, endIndex)

      setCurrentData(formattedData)
    }
  }, [currentPage, sortedData])

  useEffect(() => {
    setIsReady(true)
  }, [currentData])

  return (
    <>
      {loadingStatus === LOADING_STATUS.LOADING ? (
        <div className={style.preloader}>
          <Preloader.Spinner />
        </div>
      ) : currentData.length > 0 ? (
        <>
          <table className={clsx(style.table, style.carsPlaced)}>
            <thead>
              <tr>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        0,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 0,
                      [style.activeASC]:
                        sortedIndex === 0 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Дата создания
                  </span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        1,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 1,
                      [style.activeASC]:
                        sortedIndex === 1 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Номер автомобиля
                  </span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        2,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 2,
                      [style.activeASC]:
                        sortedIndex === 2 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Автомобиль
                  </span>
                </th>
                <th>
                  <span>Статус</span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        3,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 3,
                      [style.activeASC]:
                        sortedIndex === 3 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Скидки
                  </span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        4,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 4,
                      [style.activeASC]:
                        sortedIndex === 4 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Стоимость
                  </span>
                </th>
                <th>
                  <span>Аксессуары</span>
                </th>
                <th>
                  <span>Подарки</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.map(
                (
                  {
                    car_id,
                    created,
                    vin,
                    family_name,
                    model_name,
                    complectation,
                    modification,
                    discount,
                    total_price,
                    delivery,
                    accessories,
                    gifts,
                    showroom,
                    type,
                  },
                  i
                ) => {
                  return (
                    <Fragment key={`${car_id}_${i}`}>
                      <tr className={style.info}>
                        <td>
                          <span>{created.date}</span>
                        </td>
                        <td>{car_id}</td>
                        <td>
                          <span className={style.bold}>
                            LADA {family_name} {model_name}
                          </span>
                          <span>{complectation}</span>
                          <span>{modification.title}</span>
                          <span className={style.vin}>VIN {vin}</span>
                          {type === 'direct_sale' && (
                            <div className={style.manufactureCarLabel}>
                              Авто от производителя
                            </div>
                          )}
                        </td>
                        <td>
                          <span>{showroom === 'in' && 'В шоуруме'}</span>
                          {delivery && (
                            <span className={style.tableAction}>
                              Дата поставки {delivery}
                            </span>
                          )}
                        </td>
                        <td>
                          <span>{getPrice(discount)}</span>
                        </td>
                        <td>
                          <span>
                            {getPrice(total_price > 0 ? total_price : 0)}
                          </span>
                        </td>
                        <td>
                          {accessories.map(({ name }) => (
                            <span>{name}</span>
                          ))}
                          {accessories.length > 0 && (
                            <span className={style.bold}>
                              На сумму:{' '}
                              {getFormattedPrice(
                                accessories.reduce(
                                  (acc, asccesory) => acc + asccesory.price,
                                  0
                                )
                              )}
                              ₽
                            </span>
                          )}
                        </td>
                        <td>
                          {gifts.map(({ name }) => (
                            <span>{name}</span>
                          ))}
                        </td>
                      </tr>
                      <tr className={style.actions}>
                        <td colSpan={8} className={style.button}>
                          {buttons.access && (
                            <Button.Outlined onClick={() => deleteCar(car_id)}>
                              Снять с онлайн продажи
                            </Button.Outlined>
                          )}
                          <Button.Default to={`/car/${car_id}`}>
                            {buttons.carsPlaced[0]}
                          </Button.Default>
                        </td>
                      </tr>
                    </Fragment>
                  )
                }
              )}
            </tbody>
          </table>
          <Pagination
            className={style.tableFooter}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            steps={[10, 25, 50]}
          />
        </>
      ) : (
        isReady && <EmptyMessage />
      )}
    </>
  )
}
