import clsx from 'clsx'

import styles from './styles.module.scss'

export const ButtonOutlined = ({
  isGray,
  children,
  disabled = false,
  type = 'button',
  to,
  onClick = () => {},
  className,
  metricId,
  target = '_self',
  ...other
}) => {
  if (to) {
    return (
      <a
        className={clsx(
          styles.btn,
          { [styles.isGray]: isGray, [styles.disabled]: disabled },
          className
        )}
        href={to}
        onClick={onClick}
        target={target}
        {...other}
      >
        {children}
      </a>
    )
  }

  return (
    <button
      type={type}
      disabled={disabled}
      className={clsx(
        styles.btn,
        { [styles.isGray]: isGray, [styles.disabled]: disabled },
        className
      )}
      onClick={onClick}
      {...other}
    >
      {children}
    </button>
  )
}
