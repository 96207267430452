export const IconFlatArrowRight = ({ width, height, color }) => (
  <svg
    width={width || 14}
    height={height || 24}
    viewBox="0 0 14 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.625 22.1254L11.3757 12.0002L1.625 1.87512"
      stroke={color || '#4C5865'}
      strokeWidth="3"
    />
  </svg>
)
