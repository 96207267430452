import { getCookie, setCookie } from 'utils/cookie'

export function getTokenFromLs() {
  if (window) {
    const token = localStorage.getItem('t_kn')
    return token
  }
  return null
}

export function setTokenFromLs(token) {
  if (window) {
    localStorage.setItem('t_kn', token)
  }
}

// export function getToken() {
//   if (window) {
//     const token = getCookie('cookie_token')
//     return token
//   }
//   return null
// }
//
// export function setToken(token) {
//   if (window) {
//     setCookie('cookie_token', token)
//   }
// }

// export function removeToken() {
//   if (window) {
//     localStorage.setItem('token', '')
//   }
// }
