import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Container } from 'components/Container'
import { AccordionTable } from 'components/AccordionTable'
import Switch from 'components/Fields/Switch'
import Icon from 'components/Icon'
import Button from 'components/Button'

import { getCharacteristics } from 'utils/constants'
import { getPrice } from 'utils/getPrice'

import { colors } from 'constants/inlineColorConfig'
import { optGroups } from './constants'

import style from './styles.module.scss'
import { getAllValues } from 'containers/Order/OrderInfo/utils'

export const CarInfo = ({
  color_name,
  image,
  family_slug,
  model_name,
  complectation,
  modification,
  updatePayment,
  goBack,
  access,
  payment,
  isDirectSaleCar,
}) => {
  const { transmission_type, specification } = modification

  const { table_titles_row: tableTitlesRow, items } = specification

  const {
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      fullPayment: payment === 'full',
      ManufactureCar: isDirectSaleCar,
    },
  })

  const { allOptions } = getAllValues(modification)
  const standartEquip = optGroups.map(({ id, name }) => {
    const data = allOptions[0]
      .filter((el) => el.group === id)
      .map(({ name }) => {
        return name
      })

    return { title: name, data: [{ value: data }] }
  })

  const characteristics = getCharacteristics(tableTitlesRow, items)

  const [additionalPackages, setAdditionalPackages] = useState([])

  const formattedEngineTitle = modification.title.split(', ')

  const transmission =
    !!transmission_type && transmission_type === 'automatic'
      ? 'Автомат'
      : transmission_type === 'mechanical'
      ? 'Механическая'
      : 'Авто-механическая'

  useEffect(() => {
    const packages = modification.packages.map((pack) => {
      return {
        title: pack.title,
        data: [{ value: pack.list }],
      }
    })

    setAdditionalPackages(packages)
  }, [modification])

  return (
    <Container className={style.container}>
      <Button.Inverted
        className={style.btnBack}
        icon={<Icon.LongArrowLeft color={colors.blackCoral} />}
        onClick={() => goBack()}
      >
        Вернуться
      </Button.Inverted>
      <div className={style.header}>
        <div className={style.image}>
          <img src={image} alt="model" />
        </div>
        <div className={style.content}>
          <div className={style.info}>
            <div className={style.label}>
              {family_slug} {model_name}
            </div>
            <div className={style.modification}>{complectation}</div>
            {isDirectSaleCar && (
              <div className={style.manufactureBlock}>
                <Switch
                  id="ManufactureCar"
                  name="ManufactureCar"
                  control={control}
                  // change={updatePayment}
                  activeColor={colors.brightOrange}
                  disabled={true}
                />
                <p>Авто от производителя</p>
              </div>
            )}
            {!!color_name && <div className={style.desc}>{color_name}</div>}
            {!!modification.title && (
              <div className={style.desc}>
                Двигатель: {formattedEngineTitle[0]}
              </div>
            )}
            {!!transmission && (
              <div className={style.desc}>Трансмиссия: {transmission}</div>
            )}
          </div>
          <div className={style.price}>
            <div className={style.label}>Цена автомобиля</div>
            <div className={style.currentPrice}>
              {getPrice(
                modification.total_price > 0 ? modification.total_price : 0
              )}
            </div>
          </div>
          <div className={style.switch}>
            <Switch
              id="fullPayment"
              name="fullPayment"
              control={control}
              change={updatePayment}
              activeColor={colors.brightOrange}
              disabled={!access}
            />
            <div className={style.text}>
              <span>Доступна полная</span>
              <span>online оплата</span>
            </div>
          </div>
        </div>
      </div>
      <div className={style.options}>
        <AccordionTable
          title="стандартное оборудование"
          content={standartEquip}
          withoutTitleCell
        />
        <AccordionTable
          title="технические характеристики"
          content={characteristics}
          technical
        />
        {modification.packages.length > 0 && (
          <AccordionTable
            title="пакеты опций"
            content={additionalPackages}
            withoutTitleCell
          />
        )}
      </div>
    </Container>
  )
}
