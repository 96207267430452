import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import clsx from 'clsx'

import { ModalActionApply, ModalActionSave } from 'containers/Order/Modals'
import { OrderInfo } from './OrderInfo'
import { CreditProgram } from './CreditProgram'
import { Accessories } from './Accessories'
import { Discounts } from './Discounts'
import { TradeIn } from './TradeIn'
import { Insurance } from './Insurance'
import { Services } from 'containers/Order/Services'
import { Gifts } from 'containers/Order/Gifts'
import { FooterActions } from './FooterActions'
import { ModalAction } from 'components/ModalAction'
import { Notifications } from 'components/Notifications'
import { MemoizedDiscounts } from 'components/DiscountsTetsing'

import Button from 'components/Button'
import Input from 'components/Fields/Input'
import Radio from 'components/Fields/Radio'

import { getAuthToken, getUserRole } from 'store/auth/selectors'

import { deleteOrderById, getOrder } from 'api/orders'
import { getAccessoriesByKomplId } from 'api/accessories'
import { addCreditData, updateCreditData } from 'api/credit'
import { addOrderComment } from 'api/comment'
import { addOrderStatus } from 'api/statuses'
import { setPaymentForOrder } from 'api/payment'
import { addTradeInCar, deleteTradeInCar, updateTradeInCar } from 'api/tradeIn'
import { getFinancialServices } from 'api/financialServices'
import { sendOrderMail } from 'api/email'
import {
  deleteInsuranceOrder,
  getInsuranceByOrder,
  setInsuranceToOrder,
  setInsuranceToOrderUpdate,
} from 'api/insurance'
import { ORDER_STATUS } from 'constants/OrderStatus'
import { ROLE } from 'constants/role'

import { loadAuthToken } from 'store/auth/slice'

import style from 'containers/Order/Modals/styles.module.scss'
import { getCookie } from 'utils/cookie'
import { CreditCalculator } from 'components/CreditCalculator'

export default function Order() {
  const [isLoading, setIsLoading] = useState(true)
  const [isSent, setIsSent] = useState(false)
  const [orderInfo, setOrderInfo] = useState(null)
  const [insuranceInfo, setInsuranceInfo] = useState(null)
  const [insuranceInfoLoading, setInsuranceInfoLoading] = useState(true)
  const [accessories, setAccessories] = useState([])
  const [specialOffersDiscount, setSpecialOffersDiscount] = useState(0)
  const [userEvaluationTradeIn, setUserEvaluationTradeIn] = useState(0)
  const [isActiveTradeIn, setIsActiveTradeIn] = useState(false)
  const [isActiveInsurance, setIsActiveInsurance] = useState(false)
  const [isChange, setIsChange] = useState(false)
  const [defaultCreditPrice, setDefaultCreditPrice] = useState(0)
  const [isShowSuccessRequestPayment, setIsShowSuccessRequestPayment] =
    useState(false)
  const [isShowDeleteOrder, setIsShowDeleteOrder] = useState(false)
  const [isShowModalApply, setIsShowModalApply] = useState(false)
  const [isOpenNotifications, setIsOpenNotifications] = useState({
    isOpen: false,
    currentTitle: '',
  })
  const [totalInsuranceValue, setTotalInsuranceValue] = useState(0)
  const [financialServices, setFinancialServices] = useState([])

  const [isFinishedOrder, setIsFinishedOrder] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [isCurrent, setCurrent] = useState('')
  const [data, setData] = useState(null)
  const [isPaymentError, setIsPaymentError] = useState(false)
  const [reason, setReason] = useState()
  const [reasonInput, setReasonInput] = useState(false)

  // кредит
  const [defaultMonthlyPayment, setDefaultMonthlyPayment] = useState(0)
  const [monthlyPayment, setMonthlyPayment] = useState(
    orderInfo?.credit_data.monthlyPayment
  )
  const [currentOfferYearsRange, setCurrentOfferYearsRange] = useState([
    36, 48, 60, 72, 84,
  ])
  const [currentDownpaymentRange, setCurrentDownpaymentRange] = useState([
    10, 95,
  ])
  const [currentCreditRate, setCurrentCreditRate] = useState(
    orderInfo?.credit_data.percentageRate
  )
  const [downpayment, setDownpayment] = useState(
    orderInfo?.credit_data.firstPayment
  )
  const [totalAmount, setTotalAmount] = useState(
    orderInfo?.credit_data.loanAmount
  )
  const [duration, setDuration] = useState(orderInfo?.credit_data.loanPeriod)
  const [creditPeriodTab, setCreditPeriodTab] = useState(
    currentOfferYearsRange.indexOf(Number(orderInfo?.credit_data.loanPeriod))
  )
  const [insuranceTab, setInsuranceTab] = useState(
    orderInfo?.credit_data.insurance === false ? 1 : 0
  )
  // /кредит

  useEffect(() => {
    setMonthlyPayment(orderInfo?.credit_data.monthlyPayment)
    setCurrentCreditRate(orderInfo?.credit_data.percentageRate)
    setDownpayment(orderInfo?.credit_data.firstPayment)
    setTotalAmount(orderInfo?.credit_data.loanAmount)
    setDuration(orderInfo?.credit_data.loanPeriod)
    setCreditPeriodTab(
      currentOfferYearsRange.indexOf(Number(orderInfo?.credit_data.loanPeriod))
    )
  }, [orderInfo])

  const reasonList = [
    { id: 1, value: 'Клиент купил другой авто в нашем ДЦ' },
    { id: 2, value: 'Клиент передумал' },
    { id: 3, value: 'Клиенту отказано в кредите' },
    { id: 4, value: 'Автомобиль продан другому клиенту' },
    { id: 5, value: 'Клиент не выходит на связь/недоступен' },
    { id: 6, value: 'Оформили на родственника/юр.лицо' },
    { id: 7, value: 'Другое' },
  ]

  const dispatch = useDispatch()

  const { role, token } = useSelector((state) => ({
    role: getUserRole(state),
    token: getAuthToken(state),
  }))

  const access = role === ROLE.admin ? false : true

  const {
    control,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm({})

  const watchReason = watch('reason')

  const { id } = useParams()
  const navigate = useNavigate()

  const checkData = !!orderInfo && !!accessories
  const isDirectSale = orderInfo?.type === 'direct_sale'
  // const isDirectSale = true

  function goBack() {
    navigate(-1)
  }

  useEffect(() => {
    fetchOrderInfo()
  }, [id, isSent, isShowSuccessRequestPayment])

  async function fetchOrderInfo() {
    try {
      const res = await getOrder(id)
      const data = res.data

      console.log(data, 'datadatadata')
      setOrderInfo(data)
      setUserEvaluationTradeIn(
        Object.keys(data.tradeIn).length > 0 && data.tradeIn.evaluation
          ? data.tradeIn.evaluation
          : 0
      )
      setIsLoading(false)
    } catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    async function fetchAccessories() {
      try {
        const res = await getAccessoriesByKomplId(orderInfo?.complectation_id)
        setAccessories(res.data)
        setIsLoading(false)
      } catch (e) {
        console.warn(e)
      }
    }

    if (orderInfo) {
      fetchAccessories()
    }
  }, [orderInfo])

  const changeOrderComment = async ({ comment }) => {
    try {
      await addOrderComment(orderInfo.order_id, { comment })
      setIsSent((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }

  const changeActiveStatus = async ({ status }) => {
    try {
      await addOrderStatus({
        order_id: orderInfo.order_id,
        status_id: status,
      })
      setIsSent((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }

  const addCredit = async (data) => {
    try {
      await addCreditData({
        order_id: orderInfo.order_id,
        loan_amount: 540000,
        ...data,
      })
      setIsSent((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }

  const updateCredit = async (data) => {
    try {
      await updateCreditData({
        order_id: orderInfo.order_id,
        loan_amount: 540000,
        ...data,
      })
      setIsSent((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }

  async function deleteOrder() {
    if (reason !== undefined) {
      if (
        (reason === 'Другое' &&
          watchReason !== undefined &&
          watchReason.length > 0 &&
          !!watchReason.trim()) ||
        reason !== 'Другое'
      ) {
        const currentReason = reason === 'Другое' ? watchReason : reason

        try {
          await deleteOrderById({
            order_id: orderInfo.order_id,
            comment: currentReason,
          })
          setIsShowDeleteOrder(false)
          setIsOpenNotifications({
            isOpen: true,
            currentTitle: 'isCancelOrder',
          })
          setIsSent((prev) => !prev)
        } catch {
          setIsShowDeleteOrder(false)
          setIsOpenNotifications({ isOpen: true, currentTitle: 'isError' })
        }
        clearErrors('reason')
      } else {
        setError('reason', { type: 'reason', message: 'Это поле обязательно' })
      }
    }
  }

  async function setPayment(value) {
    if (value > 0) {
      setIsPaymentError(false)
      const res = await setPaymentForOrder({
        orderId: orderInfo.order_id,
        payment_value: value,
      })
      if (res.data === 'success') {
        setIsShowSuccessRequestPayment(true)
        setIsSent((prev) => !prev)
      }
    } else {
      setIsPaymentError(true)
    }
  }

  const updateNewTradeInCar = async (val) => {
    try {
      await updateTradeInCar({
        order_id: orderInfo.order_id,
        brand: val.brand,
        model: val.model,
        year: val.year,
        body: val.body,
        modification: val.modification,
        run: val.run,
        evaluation: userEvaluationTradeIn,
        dealer_evaluation: val.price,
      })
      setIsSent((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (orderInfo) {
      ORDER_STATUS.finished === orderInfo?.status.active.id
        ? setIsFinishedOrder(false)
        : setIsFinishedOrder(true)
      setInsuranceInfoLoading(true)

      setInsuranceInfo(orderInfo.insurance)
    }
  }, [orderInfo])

  const removeInsurance = async () => {
    if (Object.keys(insuranceInfo).length > 0 && isActiveInsurance) {
      const programsToDelete = insuranceInfo.map((el) => {
        return {
          name: el.name,
        }
      })

      try {
        await deleteInsuranceOrder({
          order_id: orderInfo.order_id,
          programs: programsToDelete,
        })
        setTotalInsuranceValue(0)
        setIsSent((prev) => !prev)
      } catch (e) {
        console.log(e)
      }
    }
  }

  const addNewTradeInCar = async (val) => {
    try {
      await addTradeInCar({
        order_id: orderInfo.order_id,
        brand: val.brand,
        model: val.model,
        year: val.year,
        body: val.body,
        modification: val.modification,
        run: val.run,
        evaluation: userEvaluationTradeIn,
        dealer_evaluation: val.price,
      })
      setIsSent((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }

  const removeTradeIn = async (orderID) => {
    try {
      await deleteTradeInCar({
        order_id: orderID,
      })
      setIsSent((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }

  const handleSpecialOffer = (summ) => {
    setSpecialOffersDiscount(summ)
    setIsSent((prev) => !prev)
  }

  const handleSendEmail = () => {
    const totalPrice = defaultCreditPrice

    try {
      sendOrderMail(orderInfo?.order_id, totalPrice)
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isSend' })
    } catch (e) {
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isError' })
    }
  }

  const handleSave = async () => {
    if (isCurrent === 'credit') {
      if (orderInfo?.credit_data.length !== 0) {
        updateCredit(data)
      } else {
        addCredit({
          ...data,
        })
      }
    } else if (isCurrent === 'tradeIn') {
      if (Object.keys(orderInfo?.tradeIn).length > 0 && isActiveTradeIn) {
        updateNewTradeInCar(data)
        setIsSent((prev) => !prev)
      } else {
        addNewTradeInCar(data)
        setIsSent((prev) => !prev)
      }
    } else if (isCurrent === 'insurance') {
      if (Object.keys(insuranceInfo).length > 0 && isActiveInsurance) {
        const programsToDelete = insuranceInfo.map((el) => {
          return {
            name: el.name,
          }
        })

        await deleteInsuranceOrder({
          order_id: data.order_id,
          programs: programsToDelete,
        })
      }

      try {
        setInsuranceToOrder(data)
        setIsChange(true)
        setIsSent((prev) => !prev)
      } catch (e) {
        console.log(e)
      }
    }
    setCurrent('')
    setData('')
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(loadAuthToken())

    const fetchFinancialService = async () => {
      const financialServices = await getFinancialServices(orderInfo?.order_id)
      setFinancialServices(financialServices)
    }

    if (orderInfo) {
      fetchFinancialService()
    }
  }, [])

  useEffect(() => {
    if (insuranceInfo) {
      let insuranceSumm = 0

      insuranceInfo.forEach((el) => {
        if (el.name !== 'pusk' && el.name !== 'start')
          insuranceSumm = insuranceSumm + el.price
      })

      setTotalInsuranceValue(insuranceSumm)
    }
  }, [insuranceInfo])

  useEffect(() => {
    if (reason !== undefined) {
      const el = reasonList.filter(({ value }) => value === reason)

      if (el[0].id === 7) {
        setReasonInput(true)
      } else {
        setReasonInput(false)
      }
    }
  }, [reason])

  return (
    <>
      <Notifications
        {...isOpenNotifications}
        setIsOpenNotifications={setIsOpenNotifications}
      />

      {isShowModalApply && (
        <ModalActionApply handleCloseModal={() => setIsShowModalApply(false)} />
      )}

      {isShowSuccessRequestPayment && (
        <ModalAction
          title="Запрос на оплату успешно отправлен"
          handleClose={() => setIsShowSuccessRequestPayment(false)}
        >
          <div className={style.modalBody}>
            <Button.Outlined
              onClick={() => setIsShowSuccessRequestPayment(false)}
            >
              Ок
            </Button.Outlined>
          </div>
        </ModalAction>
      )}

      {isShowDeleteOrder && (
        <ModalAction
          title="Отмена заказа"
          description="Укажите причину отмены заказа"
          handleClose={() => setIsShowDeleteOrder(false)}
          className={style.cancelModal}
        >
          <div className={style.modalBody}>
            <div
              className={clsx(style.radioGroup, {
                [style.radioMargin]: reasonInput,
              })}
            >
              {reasonList.map(({ id, value }) => {
                return (
                  <Radio
                    customStyle={clsx(style.radio)}
                    value={id}
                    control={control}
                    name="radio"
                    children={value}
                    circle
                    onClick={() => setReason(value)}
                  />
                )
              })}
            </div>
            {reasonInput && (
              <Input
                label="Причина"
                name="reason"
                control={control}
                error={errors['reason']}
                required
                rules={{
                  required: 'Это поле обязательно',
                  validate: (v) =>
                    v && v.length > 0 ? true : 'Это поле обязательно',
                }}
              />
            )}
            <Button.Outlined onClick={() => deleteOrder()}>
              Отменить заказ
            </Button.Outlined>
          </div>
        </ModalAction>
      )}

      {isOpen && (
        <ModalActionSave
          handleCloseModal={() => setIsOpen(false)}
          handleSave={handleSave}
        />
      )}
      {!isLoading && checkData && (
        <>
          <OrderInfo
            {...orderInfo}
            changeStatus={changeActiveStatus}
            changeComment={changeOrderComment}
            setPayment={setPayment}
            isPaymentError={isPaymentError}
            goBack={goBack}
            specialOffersDiscount={specialOffersDiscount}
            isInputsActive={isFinishedOrder}
            insuranceInfo={insuranceInfo}
            setDefaultCreditPrice={setDefaultCreditPrice}
            access={access}
            role={role}
            isDirectSale={isDirectSale}
          />
          {isDirectSale && (
            <CreditCalculator
              car={orderInfo}
              putClass={'credit'}
              monthlyPayment={monthlyPayment}
              setMonthlyPayment={setMonthlyPayment}
              currentDownpaymentRange={currentDownpaymentRange}
              currentCreditRate={currentCreditRate}
              totalAmount={totalAmount}
              setTotalAmount={setTotalAmount}
              currentOfferYearsRange={currentOfferYearsRange}
              setCurrentCreditRate={setCurrentCreditRate}
              setDuration={setDuration}
              setDownpayment={setDownpayment}
              downpayment={downpayment}
              creditPeriodTab={creditPeriodTab}
              setCreditPeriodTab={setCreditPeriodTab}
              isShowButton={isFinishedOrder}
              duration={duration}
              insuranceTab={insuranceTab}
              setInsuranceTab={setInsuranceTab}
            />
          )}
          {accessories && !isDirectSale && (
            <Accessories
              {...accessories}
              refetchOrderInfo={fetchOrderInfo}
              data={orderInfo?.accessories}
              carId={orderInfo?.car_id}
              isInputsActive={isFinishedOrder}
              access={access}
              isOrder
            />
          )}
          <TradeIn
            {...orderInfo?.tradeIn}
            refetchOrderInfo={fetchOrderInfo}
            setIsOpen={setIsOpen}
            setCurrent={setCurrent}
            setData={setData}
            isInputsActive={isFinishedOrder}
            setIsActiveTradeIn={setIsActiveTradeIn}
            isActiveTradeIn={isActiveTradeIn}
            removeTradeIn={removeTradeIn}
            orderId={orderInfo?.order_id}
            access={access}
          />
          <Insurance
            setIsOpen={setIsOpen}
            setCurrent={setCurrent}
            setData={setData}
            order_id={orderInfo?.order_id}
            insuranceInfo={insuranceInfo}
            isInputsActive={isFinishedOrder}
            setIsActiveInsurance={setIsActiveInsurance}
            isActiveInsurance={isActiveInsurance}
            removeInsurance={removeInsurance}
            orderId={orderInfo?.order_id}
            isChange={isChange}
            setIsChange={setIsChange}
            defaultPrice={orderInfo?.modification.price}
            access={access}
          />
          {!isDirectSale && (
            <MemoizedDiscounts
              activeOffers={orderInfo.special_offers}
              activeDealerOffers={[]}
              brandDiscountsList={orderInfo?.brand_discounts}
              isInputsActive={isFinishedOrder}
              carId={orderInfo?.car_id}
              background
              handleSpecialOffer={handleSpecialOffer}
              access={access}
              price={orderInfo?.modification.price}
              tradeIn={orderInfo?.tradeIn}
              refetchOrderInfo={fetchOrderInfo}
              isOrder
            />
          )}
          {!isDirectSale && (
            <Services
              data={orderInfo?.additional_services}
              carId={orderInfo?.car_id}
              isInputsActive={isFinishedOrder}
              refetchOrderInfo={fetchOrderInfo}
              access={access}
              isOrder
            />
          )}
          <Gifts
            data={orderInfo?.gifts}
            carId={orderInfo?.car_id}
            background
            isInputsActive={isFinishedOrder}
            access={access}
          />
          {!isDirectSale && (
            <CreditProgram
              data={orderInfo?.credit_data}
              setIsOpen={setIsOpen}
              setCurrent={setCurrent}
              setData={setData}
              isInputsActive={isFinishedOrder}
              defaultPrice={orderInfo?.modification.price}
              defaultCreditPrice={defaultCreditPrice}
              isCreditActive={orderInfo?.credit}
              totalInsuranceValue={totalInsuranceValue}
              access={access}
              casco_credit={orderInfo?.casco_credit}
              tradein_credit={orderInfo?.tradein_credit}
              fetchOrderInfo={fetchOrderInfo}
              order_id={orderInfo?.order_id}
              dealerEvaluation={orderInfo?.tradeIn.dealerEvaluation}
              evaluation={orderInfo?.tradeIn.evaluation}
            />
          )}
          <FooterActions
            handleOpenModalCancel={() => setIsShowDeleteOrder(true)}
            role={role}
            isFinishedOrder={isFinishedOrder}
            goBack={goBack}
            handleSendEmail={handleSendEmail}
            {...orderInfo}
          />
        </>
      )}
    </>
  )
}
