import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { MainPage } from 'components/MainPage'
import TableChief from 'containers/TableChief'
import TableManager from 'containers/TableManager'
import TableSupervisor from 'containers/TableSupervisor'
import NewCar from 'containers/NewCar'
import Order from 'containers/Order'
import Analytics from 'containers/Analytics'
import Auth from 'containers/Auth'
import { PdfPage } from 'containers/PdfPage'
import BadgeCar from 'containers/BadgeCar'
import CreatePersonalOffer from 'containers/PersonalOffer/Create'
import PDFViewerForTest from 'containers/PDFViewerForTest'
import EditPersonalOffer from 'containers/PersonalOffer/Edit'
import CreateCorporateOffer from 'containers/CorporateOffer/Create'
import EditCorporateOffer from 'containers/CorporateOffer/Edit'

import {
  MAIN_ROUTE,
  AUTH_ROUTE,
  MANAGER_TABLE_ROUTE,
  CHIEF_TABLE_ROUTE,
  CAR_ROUTE,
  ORDER_ROUTE,
  ANALYTICS_ROUTE,
  SUPERVISOR_TABLE_ROUTE,
  PDF_ROUTE,
  CAR_FOR_BADGE,
  CREATE_PERSONAL_OFFER,
  PDF_TESTING,
  EDIT_PERSONAL_OFFER,
  CREATE_CORPORATE_OFFER,
  EDIT_CORPORATE_OFFER,
  PO_VIEWER,
  CO_VIEWER,
} from 'routes/routes'

export const Router = () => {
  return (
    <Routes>
      <Route
        exact={AUTH_ROUTE.exact}
        path={AUTH_ROUTE.path}
        element={<Auth />}
      />
      <Route
        exact={MAIN_ROUTE.exact}
        path={MAIN_ROUTE.path}
        element={<MainPage />}
      />
      <Route
        exact={ORDER_ROUTE.exact}
        path={ORDER_ROUTE.path}
        element={<Order />}
      />
      <Route
        exact={ANALYTICS_ROUTE.exact}
        path={ANALYTICS_ROUTE.path}
        element={<Analytics />}
      />
      <Route
        exact={CAR_ROUTE.exact}
        path={CAR_ROUTE.path}
        element={<NewCar />}
      />
      <Route
        exact={CAR_FOR_BADGE.exact}
        path={CAR_FOR_BADGE.path}
        element={<BadgeCar />}
      />
      <Route
        exact={CREATE_PERSONAL_OFFER.exact}
        path={CREATE_PERSONAL_OFFER.path}
        element={<CreatePersonalOffer />}
      />
      <Route
        exact={EDIT_PERSONAL_OFFER.exact}
        path={EDIT_PERSONAL_OFFER.path}
        element={<EditPersonalOffer />}
      />
      <Route
        exact={CREATE_CORPORATE_OFFER.exact}
        path={CREATE_CORPORATE_OFFER.path}
        element={<CreateCorporateOffer />}
      />
      <Route
        exact={EDIT_CORPORATE_OFFER.exact}
        path={EDIT_CORPORATE_OFFER.path}
        element={<EditCorporateOffer />}
      />
      <Route
        exact={CHIEF_TABLE_ROUTE.exact}
        path={CHIEF_TABLE_ROUTE.path}
        element={<TableChief />}
      />
      <Route
        exact={MANAGER_TABLE_ROUTE.exact}
        path={MANAGER_TABLE_ROUTE.path}
        element={<TableManager />}
      />
      <Route
        exact={SUPERVISOR_TABLE_ROUTE.exact}
        path={SUPERVISOR_TABLE_ROUTE.path}
        element={<TableSupervisor />}
      />
      <Route
        exact={PDF_ROUTE.exact}
        path={PDF_ROUTE.path}
        element={<PdfPage />}
      />
      <Route
        exact={PO_VIEWER.exact}
        path={PO_VIEWER.path}
        element={<PDFViewerForTest />}
      />
      <Route
        exact={CO_VIEWER.exact}
        path={CO_VIEWER.path}
        element={<PDFViewerForTest />}
      />
    </Routes>
  )
}
