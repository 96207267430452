import { createAsyncThunk } from '@reduxjs/toolkit'

import { getAllDealersRequest, getDealersById } from 'api/dealers'

export const loadAllDealers = createAsyncThunk(
  'dealers/allDealers',
  async (_, thunkAPI) => {
    const res = await getAllDealersRequest()
    if (res.data.error) {
      return { items: [], total: res.data.length }
    }
    return { items: res.data, total: res.data.length }
  }
)

export const loadDealersById = createAsyncThunk(
  'dealers/allDealers',
  async (cityId, _, thunkAPI) => {
    const res = await getDealersById(cityId)
    if (res.data.error) {
      return { items: [], total: res.data.length }
    }
    return { items: res.data, total: res.data.length }
  }
)
