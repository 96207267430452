import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import Button from 'components/Button'
import { Pagination } from 'components/Pagination'
import { EmptyMessage } from 'components/EmptyMessage'
import { Preloader } from 'components/Preloader'

import { getUserRole } from 'store/auth/selectors'
import { getCarsLoadingStatus } from 'store/cars/selectors'

import { LOADING_STATUS, SORT_DIRECTION } from 'constants/loadingStatus'

import { getFormattedPrice, getPrice } from 'utils/getPrice'
import { handleSort } from 'utils/sortTableData'

import { useSort } from 'hooks/useSort'
import { useSearch } from 'hooks/useSearch'

import { fullAccess, partialAccess } from '../buttons'
import { ROLE } from 'constants/role'

import style from './styles.module.scss'

export default function CarsForPlacingChief({
  data,
  addCar,
  watchSearch,
  searchFieldsCar,
  watchStatus,
}) {
  const [currentData, setCurrentData] = useState([])
  const [currentPage, setCurrentPage] = useState({ current: 1, amount: 10 })
  const [isReady, setIsReady] = useState(false)

  const { loadingStatus } = useSelector((state) => ({
    loadingStatus: getCarsLoadingStatus(state),
  }))

  const sortConfig = [
    { id: 0, path: 'created.date', type: 'date' },
    { id: 1, path: 'car_id', type: 'number' },
    { id: 2, path: 'family_name.model_name', type: 'multiField' },
    { id: 3, path: 'discount' },
    { id: 4, path: 'price' },
  ]

  const filteredCarsPlacing = useSearch(
    data,
    watchSearch,
    searchFieldsCar,
    300,
    !(watchStatus == 2)
  )

  const [
    sortedData,
    sortedIndex,
    sortDirection,
    handleSortedIndex,
    handleSortDirection,
  ] = useSort(filteredCarsPlacing.filteredData, sortConfig)

  const { role } = useSelector((state) => ({ role: getUserRole(state) }))
  const buttons = role === ROLE.admin ? partialAccess : fullAccess

  const chiefAccess = role === ROLE.chief || role === ROLE.chief2

  useEffect(() => {
    if (!!sortedData.length) {
      const startIndex = 0
      const endIndex = currentPage.amount * currentPage.current
      const formattedData = sortedData.slice(startIndex, endIndex)

      setCurrentData(formattedData)
    }
  }, [currentPage, sortedData])

  useEffect(() => {
    setIsReady(true)
  }, [currentData])

  return (
    <>
      {loadingStatus === LOADING_STATUS.LOADING ? (
        <div className={style.preloader}>
          <Preloader.Spinner />
        </div>
      ) : currentData.length > 0 ? (
        <>
          <table className={clsx(style.table, style.carsPlaced)}>
            <thead>
              <tr>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        0,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 0,
                      [style.activeASC]:
                        sortedIndex === 0 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Дата создания
                  </span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        1,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 1,
                      [style.activeASC]:
                        sortedIndex === 1 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Номер автомобиля
                  </span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        2,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 2,
                      [style.activeASC]:
                        sortedIndex === 2 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Автомобиль
                  </span>
                </th>
                <th>
                  <span>Статус</span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        3,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 3,
                      [style.activeASC]:
                        sortedIndex === 3 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Скидки
                  </span>
                </th>
                <th>
                  <span
                    onClick={() =>
                      handleSort(
                        4,
                        sortedIndex,
                        handleSortedIndex,
                        handleSortDirection
                      )
                    }
                    className={clsx(style.sort, {
                      [style.activeDESC]: sortedIndex === 4,
                      [style.activeASC]:
                        sortedIndex === 4 &&
                        sortDirection === SORT_DIRECTION.ASC,
                    })}
                  >
                    Стоимость
                  </span>
                </th>
                <th>
                  <span>Аксессуары</span>
                </th>
                <th>
                  <span>Подарки</span>
                </th>
              </tr>
            </thead>

            <tbody>
              {currentData.map((carInfo) => {
                return (
                  <Fragment key={carInfo.car_id}>
                    <tr className={style.info}>
                      <td>
                        <span>{carInfo.created.date}</span>
                      </td>
                      <td>{carInfo.car_id}</td>
                      <td>
                        <span className={style.bold}>
                          {carInfo.family_name} {carInfo.model_name}
                        </span>
                        <span>{carInfo.complectation}</span>
                        <span>{carInfo.modification.title}</span>
                        <span className={style.vin}>VIN {carInfo.vin}</span>
                        {carInfo.type === 'direct_sale' && (
                          <div className={style.manufactureCarLabel}>
                            Авто от производителя
                          </div>
                        )}
                      </td>
                      <td>
                        <span>
                          {carInfo.showroom !== 'in' && 'Не размещён'}
                        </span>
                        {carInfo.delivery && (
                          <span className={style.tableAction}>
                            Дата поставки {carInfo.delivery}
                          </span>
                        )}
                      </td>
                      <td>
                        <span>{getPrice(carInfo.discount)}</span>
                      </td>
                      <td>
                        <span>
                          {getPrice(
                            carInfo.total_price > 0 ? carInfo.total_price : 0
                          )}
                        </span>
                      </td>
                      <td>
                        {carInfo.accessories.map(({ name }) => (
                          <span key={name}>{name}</span>
                        ))}
                        {carInfo.accessories.length > 0 && (
                          <span className={style.bold}>
                            На сумму:{' '}
                            {getFormattedPrice(
                              carInfo.accessories.reduce(
                                (acc, asccesory) => acc + asccesory.price,
                                0
                              )
                            )}
                            ₽
                          </span>
                        )}
                      </td>
                      <td>
                        {carInfo.gifts.map(({ name }) => (
                          <span key={name}>{name}</span>
                        ))}
                      </td>
                    </tr>
                    <tr className={style.actions}>
                      <td colSpan={8} className={style.button}>
                        <Button.Outlined to={`/car/${carInfo.car_id}`}>
                          {buttons.carsForPlacing[0]}
                        </Button.Outlined>
                        {buttons.access && (
                          <Button.Default
                            onClick={() => addCar(carInfo.car_id)}
                          >
                            Разместить в онлайн шоурум
                          </Button.Default>
                        )}
                      </td>
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          </table>
          <Pagination
            className={style.tableFooter}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            steps={[10, 25, 50]}
          />
        </>
      ) : (
        isReady && <EmptyMessage />
      )}
    </>
  )
}
