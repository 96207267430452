import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { LOADING_STATUS } from 'constants/loadingStatus'
import { loadOrdersThunk } from 'store/orders/thunks'

export const adapter = createEntityAdapter()

export const orders = createSlice({
  name: 'orders',
  initialState: adapter.getInitialState({
    items: [],
    activeOrders: [],
    completedOrders: [],
    total: 0,
    loadingStatus: LOADING_STATUS.LOADING,
  }),
  reducers: {},
  extraReducers: {
    [loadOrdersThunk.pending]: (state) => {
      state.loadingStatus = LOADING_STATUS.LOADING
      state.activeOrders = []
      state.completedOrders = []
    },
    [loadOrdersThunk.rejected]: (state) => {
      state.items = []
    },
    [loadOrdersThunk.fulfilled]: (state, { payload }) => {
      state.loadingStatus = LOADING_STATUS.IDLE

      if (Array.isArray(payload.items.list) && payload.items.list.length > 0) {
        state.items = payload.items.list.sort((a, b) => a.id - b.id)
        state.total = state.items.length
        state.activeOrders = state.items.filter(
          ({ status }) => status.id !== 6 && status.id !== 4 && status.id !== 5
        )
        state.completedOrders = state.items.filter(
          ({ status }) => status.id === 6 || status.id === 4 || status.id === 5
        )
      }
    },
  },
})

export default orders.reducer
