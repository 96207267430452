import React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from 'components/Button'
import { Container } from 'components/Container'

import { ROLE } from 'constants/role'
import { fullAccess, partialAccess } from 'components/Table/buttons'

import style from './styles.module.scss'
import { getCookie } from 'utils/cookie'

export const FooterActions = ({
  status,
  addCar,
  deleteCar,
  role,
  carId,
  type,
}) => {
  const navigate = useNavigate()
  const buttons = role === ROLE.admin ? partialAccess : fullAccess

  const chiefAccess = role === ROLE.chief || role === ROLE.chief2

  //Переменная для тестирования
  const isTestProfile = getCookie('isTestProfile')

  const goBack = () => {
    navigate(-1)
  }

  const goToCreatePDF = () => {
    localStorage.setItem('c_id', carId)
  }

  return (
    <div className={style.background}>
      <Container className={style.container}>
        <div className={style.buttons}>
          {/* {isTestProfile && (
            <Button.Outlined
              onClick={() => goToCreatePDF()}
              to="/create-pdf"
              target="_blank"
            >
              Создать PDF
            </Button.Outlined>
          )} */}
          <Button.Outlined onClick={() => goBack()}>
            {buttons.car[0]}
          </Button.Outlined>
          {buttons.access &&
            (status === 'out' ? (
              <Button.Default onClick={() => addCar()}>
                Разместить автомобиль в шоуруме
              </Button.Default>
            ) : (
              <Button.Outlined onClick={() => deleteCar()}>
                Снять с онлайн продажи
              </Button.Outlined>
            ))}
        </div>
      </Container>
    </div>
  )
}
