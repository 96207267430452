import { useSelector } from 'react-redux'

import { getUserRole } from 'store/auth/selectors'

import TableManager from 'containers/TableManager'
import TableChief from 'containers/TableChief'
import TableSupervisor from 'containers/TableSupervisor'

import { ROLE } from 'constants/role'
import { newUrlParam } from 'utils/insertUrlParam'
import {
  CHIEF_TABLE_ROUTE,
  MANAGER_TABLE_ROUTE,
  SUPERVISOR_TABLE_ROUTE,
} from 'routes/routes'

export const MainPage = () => {
  const { role } = useSelector((state) => ({ role: getUserRole(state) }))

  const supervisor = role === ROLE.admin || role === ROLE.root

  if (role === ROLE.chief || role === ROLE.chief2) {
    newUrlParam(CHIEF_TABLE_ROUTE.path, 'status', 2, true)
  } else if (role === ROLE.manager) {
    newUrlParam(MANAGER_TABLE_ROUTE.path, 'status', 0, true)
  } else if (supervisor) {
    newUrlParam(SUPERVISOR_TABLE_ROUTE.path)
  }

  return (
    <>
      {(role === ROLE.chief || role === ROLE.chief2) && <TableChief />}
      {role === ROLE.manager && <TableManager />}
      {supervisor && <TableSupervisor />}
    </>
  )
}
