import { getPrice } from 'utils/getPrice'

import styles from './styles.module.scss'

export const TotalInfo = ({ activeOffers = [], activeDealerOffers = [] }) => {
  const allOffers = [...activeOffers, ...activeDealerOffers]

  if (allOffers.length === 0) return null

  const titles = {
    ti: 'Трейд-ин',
    util: 'Утилизации',
    ll: 'LADA Leasing',
  }

  const formattedOffers = allOffers.reduce(
    (acc, { prefix, title, benefit }) => {
      let formattedPrefix = prefix.split('_d')
      let isCheck = Object.keys(acc)
        ? Object.keys(acc).some((el) => el === formattedPrefix[0])
        : false

      if (isCheck) {
        return {
          ...acc,
          [formattedPrefix[0]]: {
            title: 'Сумма выгод по программам ' + titles[formattedPrefix[0]],
            price: Number(acc[formattedPrefix[0]].price) + Number(benefit),
          },
        }
      } else {
        return {
          ...acc,
          [formattedPrefix[0]]: {
            title: title,
            price: benefit,
          },
        }
      }
    },
    {}
  )

  const totalPrice = Object.values(formattedOffers).reduce(
    (acc, { price }) => acc + price,
    0
  )

  return (
    <>
      <ul className={styles.list}>
        {formattedOffers &&
          Object.values(formattedOffers).map((el) => (
            <li>
              <span>{el.title}</span>
              <span>{getPrice(el.price)}</span>
            </li>
          ))}
      </ul>

      <div className={styles.total}>
        <span>ИТОГОвая выгода</span>
        <span>{getPrice(totalPrice)}</span>
      </div>
    </>
  )
}
