export const IconDownload = ({ width, height, color }) => (
  <svg
    width={width || 9}
    height={height || 10}
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.80078 0.5V7.5M4.80078 7.5L8.30078 4M4.80078 7.5L1.30078 4"
      stroke={color || 'white'}
    />
    <line x1="1.30078" y1="9" x2="8.30078" y2="9" stroke={color || 'white'} />
  </svg>
)
