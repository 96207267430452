import { useState } from 'react'
import clsx from 'clsx'
import InputMask from 'react-input-mask'
import NumberFormat from 'react-number-format'

import style from './styles.module.scss'

export const InputRender = ({
  disabled,
  type,
  label,
  value = '',
  placeholder = '',
  inputmode = 'text',
  onChange,
  error,
  mask,
  className,
  isNumberFormat,
  name,
  beforeMaskedValueChange = ({ value, selection }) => ({ value, selection }),
  onBlur = () => {},
  ...other
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = (cb) => {
    setIsFocused(false)
    cb()
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      data-test-name={name}
      className={clsx(style.container, { [className]: className })}
    >
      <span
        className={clsx({
          [style.label]: true,
          [style.focused]: isFocused || String(value).length > 0,
        })}
      >
        {label}
      </span>
      {isNumberFormat ? (
        <NumberFormat
          type={type}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          placeholder={placeholder}
          inputMode={inputmode}
          {...other}
        />
      ) : (
        <InputMask
          mask={mask}
          type={type}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={(e) => handleBlur(() => onBlur(e, name))}
          disabled={disabled}
          placeholder={placeholder}
          inputMode={inputmode}
          data-name={other.dataName}
          beforeMaskedValueChange={beforeMaskedValueChange}
          {...other}
        />
      )}
      {error && <div className={style.error}> {error.message} </div>}
    </div>
  )
}
