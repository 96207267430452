export const IconRatingStar = ({ width, height, color }) => {
  return (
    <svg
      width={width || 10}
      height={height || 8}
      viewBox="0 0 5 4"
      fill={color || '#d0d3d4'}
    >
      <path d="M2.28624 0.837477C2.40598 0.468952 2.92735 0.468952 3.04709 0.837477L3.17557 1.23288C3.22912 1.39769 3.3827 1.50928 3.55599 1.50928H3.97174C4.35923 1.50928 4.52034 2.00512 4.20686 2.23288L3.8705 2.47726C3.73031 2.57912 3.67165 2.75966 3.7252 2.92447L3.85367 3.31988C3.97341 3.68841 3.55162 3.99486 3.23813 3.7671L2.90178 3.52272C2.76159 3.42086 2.57175 3.42086 2.43155 3.52272L2.0952 3.7671C1.78171 3.99486 1.35992 3.68841 1.47966 3.31988L1.60814 2.92447C1.66169 2.75966 1.60302 2.57912 1.46283 2.47726L1.12648 2.23288C0.81299 2.00512 0.9741 1.50928 1.36159 1.50928H1.77735C1.95064 1.50928 2.10422 1.39769 2.15777 1.23288L2.28624 0.837477Z" />
    </svg>
  )
}
