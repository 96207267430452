import { request } from 'utils/request'

export async function getManagers() {
  return request.get(`/api/os-managers`)
}

export async function setNewManager(orderId, data) {
  return request.put(`/api/os-manager-orders/${orderId}`, {
    ...data,
  })
}
