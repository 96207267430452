import { Container } from 'components/Container'
import { AccordionTable } from 'components/AccordionTable'
import { OrderInfoForm } from '../OrderInfoForm'
import Button from 'components/Button'
import Icon from 'components/Icon'

import { getCharacteristics } from 'utils/constants'
import { getPrice } from 'utils/getPrice'

import { getAllValues } from './utils'
import { insuranceNames, optGroups } from './constants'
import { colors } from 'constants/inlineColorConfig'

import style from './styles.module.scss'
import { totalPrice } from '../utils'
import { useEffect } from 'react'

const sumPrice = (priceArr) => priceArr.reduce((acc, el) => acc + el, 0)

export const OrderInfo = ({
  modification,
  color_name,
  image,
  complectation,
  accessories,
  family_slug,
  model_name,
  status,
  client_info,
  order_id,
  created,
  comment,
  changeComment,
  changeStatus,
  setPayment,
  isPaymentError,
  payment_info,
  goBack,
  tradeIn = [],
  isInputsActive = true,
  insuranceInfo,
  setDefaultCreditPrice,
  access,
  role,
  vin,
  isDirectSale,
}) => {
  const {
    specification,
    total_price,
    total_price_wo_discount,
    transmission_type,
  } = modification

  const { base = [] } = accessories
  const { table_titles_row: tableTitlesRow, items } = specification

  const formattedEngineTitle = modification.title.split(', ')

  const transmission =
    !!transmission_type && transmission_type === 'automatic'
      ? 'Автомат'
      : transmission_type === 'mechanical'
      ? 'Механическая'
      : 'Авто-механическая'

  const { dealerEvaluation, evaluation } = Array.isArray(tradeIn)
    ? { dealerEvaluation: 0, evaluation: 0 }
    : tradeIn

  const currentEvaluation =
    dealerEvaluation !== null ? dealerEvaluation : evaluation

  const currentPrice = totalPrice(
    total_price,
    currentEvaluation,
    insuranceInfo?.price
  )

  const { allOptions } = getAllValues(modification)
  // const allCurrentOptions = getAllOptions(allOptions, optGroups)

  const formattedOptions = optGroups.map(({ id, name }) => {
    const data = allOptions[0]
      .filter((el) => el.group === id)
      .map(({ name }) => {
        return name
      })

    return { title: name, data: [{ value: data }] }
  })

  // const standartEquip = getDefaultOptions(equipments)
  const characteristics = getCharacteristics(tableTitlesRow, items)

  useEffect(() => {
    setDefaultCreditPrice(currentPrice)
  }, [currentPrice])

  return (
    <Container className={style.container}>
      <Button.Inverted
        className={style.btnBack}
        icon={<Icon.LongArrowLeft color={colors.blackCoral} />}
        onClick={() => goBack()}
      >
        Вернуться
      </Button.Inverted>
      <div className={style.content}>
        <div className={style.model}>
          <div className={style.image}>
            <img src={image} alt="model" />
          </div>
          <div className={style.content}>
            <div className={style.info}>
              <div className={style.label}>
                {family_slug} {model_name}
              </div>
              <div className={style.modification}>{complectation}</div>
              {isDirectSale && (
                <div className={style.directSaleLabel}>
                  Авто от производителя
                </div>
              )}
              {!!vin && <div className={style.desc}>VIN: {vin}</div>}
              {!!color_name && <div className={style.desc}>{color_name}</div>}
              {!!modification.title && (
                <div className={style.desc}>
                  Двигатель: {formattedEngineTitle[0]}
                </div>
              )}
              {!!transmission && (
                <div className={style.desc}>Трансмиссия: {transmission}</div>
              )}
            </div>
            <div className={style.price}>
              {total_price_wo_discount > currentPrice && (
                <>
                  <div className={style.label}>Цена автомобиля</div>
                  <div className={style.commonPrice}>
                    {getPrice(
                      total_price_wo_discount > 0 ? total_price_wo_discount : 0
                    )}
                  </div>
                </>
              )}
              <div className={style.label}>Итоговая стоимость автомобиля</div>
              <div className={style.currentPrice}>{getPrice(currentPrice)}</div>
              {insuranceInfo?.length > 0 && (
                <>
                  <div className={style.label}>Цена страхования</div>
                  {insuranceInfo.map((el) => (
                    <div className={style.insurance}>
                      <span>{insuranceNames[el.name]}:</span> {el.price} ₽/год
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className={style.options}>
            <AccordionTable
              title="Стандартное оборудование"
              content={formattedOptions}
              withoutTitleCell
            />
            <AccordionTable
              title="Технические характеристики"
              content={characteristics}
              technical
            />
          </div>
        </div>
        <div>
          <OrderInfoForm
            status={status}
            order_id={order_id}
            client_info={client_info}
            created={created}
            comment={comment}
            changeComment={changeComment}
            changeStatus={changeStatus}
            setPayment={setPayment}
            isPaymentError={isPaymentError}
            paymentInfo={payment_info}
            isInputsActive={isInputsActive}
            access={access}
            role={role}
          />
        </div>
      </div>
    </Container>
  )
}
