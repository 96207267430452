import clsx from 'clsx'

import styles from './styles.module.scss'

export const DefaultButton = ({
  isWhite,
  children,
  disabled = false,
  type = 'button',
  to,
  onClick,
  className,
  target = '_self',
  additionalHashParam = '',
  createHashWithoutLink,
  ...other
}) => {
  if (to) {
    return (
      <a
        role="link"
        className={clsx(styles.btn, className, {
          [styles.isWhite]: isWhite,
        })}
        href={to}
        target={target}
        {...other}
      >
        {children}
      </a>
    )
  }

  return (
    <button
      aria-label={`button ${type}`}
      type={type}
      disabled={disabled}
      className={clsx(styles.btn, className, {
        [styles.isWhite]: isWhite,
      })}
      onClick={onClick}
      {...other}
    >
      {children}
    </button>
  )
}
