import React from 'react'
import { QRCodeSVG } from 'qrcode.react'

export const QR = React.forwardRef(({ value, size = 128 }, ref) => {
  return (
    <div ref={ref} style={{ display: 'none' }}>
      <QRCodeSVG
        value={value}
        size={size}
        bgColor={'#ffffff'}
        fgColor={'#4C5865'}
        level={'H'}
        includeMargin={false}
      />
    </div>
  )
})
