/**
 * Order status enum
 * @type {{accepted: number, documentRegistration: number, readyForDeliver: number, finished: number, onlinePrepaid: number}}
 */
export const ORDER_STATUS = {
  accepted: 1,
  documentRegistration: 3,
  readyForDeliver: 4,
  finished: 6,
  onlinePrepaid: 9,
}
