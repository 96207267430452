import clsx from 'clsx'
import { useForm } from 'react-hook-form'

import React from 'react'

import { Container } from 'components/Container'
import Input from 'components/Fields/Input'

import { phoneValidation } from 'utils/phoneValidation'

import style from './styles.module.scss'

export const ClientForms = ({ clientData, setClientData, mode = 'create' }) => {
  const {
    control,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      ...(mode === 'edit'
        ? {
            lastName: clientData.lastName,
            name: clientData.name,
            ...(!!clientData.middleName && {
              middleName: clientData.middleName,
            }),
            phone: clientData.phone,
            email: clientData.email,
          }
        : {}),
    },
  })
  const inputHandler = (e, name) => {
    const { value } = e.target

    setClientData((prev) => ({ ...prev, [name]: value }))

    if (name !== 'middleName') {
      if (!value) {
        setError(name, { type: name, message: 'Это поле обязательно' })
      } else {
        clearErrors(name)
      }
    }
  }

  return (
    <Container>
      <form>
        <h2 className={style.title}>Введите данные клиента</h2>
        <div className={style.formLine}>
          <Input
            label="Фамилия *"
            name="lastName"
            required
            rules={{ required: 'Это поле обязательно' }}
            control={control}
            type="text"
            error={errors['lastName']}
            className={style.input}
            onBlur={inputHandler}
          />
          <Input
            label="Имя *"
            name="name"
            required
            rules={{ required: 'Это поле обязательно' }}
            control={control}
            type="text"
            error={errors['name']}
            className={style.input}
            onBlur={inputHandler}
          />
        </div>

        <div className={clsx(style.formLine, style.full)}>
          <Input
            label="Отчество"
            name="middleName"
            required
            control={control}
            type="text"
            error={errors['middleName']}
            className={style.input}
            onBlur={inputHandler}
          />
        </div>

        <div className={style.formLine}>
          <Input
            dataName="phone-number"
            control={control}
            name="phone"
            inputmode="tel"
            label="Телефон *"
            required
            rules={{
              required: 'Это поле необходимо заполнить',
              validate: (v) =>
                v && v.replace(/[^+\d]/g, '').length === 10
                  ? true
                  : 'Это поле необходимо заполнить',
            }}
            error={errors['phone']}
            mask={'+7 (999) 999 99 99'}
            beforeMaskedValueChange={phoneValidation}
            onBlur={inputHandler}
          />
          <Input
            control={control}
            name="email"
            inputmode="email"
            label="E-mail *"
            required
            rules={{
              required: 'Это поле необходимо заполнить',
              validate: (v) =>
                v && /\S+@\S+\.\S+/.test(v) ? true : 'Некорректный email',
            }}
            error={errors['email']}
            onBlur={inputHandler}
          />
        </div>

        <div className={style.footer}>
          <p className={style.requiredText}>
            * - поля, обязательные для заполнения
          </p>
          {/*<Button.Default type="submit">*/}
          {/*  {clientData ? 'Изменить' : 'Сохранить'}*/}
          {/*</Button.Default>*/}
        </div>
      </form>
    </Container>
  )
}
