import { request } from 'utils/request'

//Все авто для размещения
export async function getAmountCarForPlace() {
  return request.get(`/api/os-reports/for-showroom/count`)
}

//Все авто для размещения по дилеру
export async function getAmountCarForPlaceByDealer(filial_code) {
  return request.get(
    `/api/os-reports/for-showroom/${filial_code}/filial-code/count`
  )
}

//Все авто для размещения по direct-sale
export async function getAmountCarForPlaceByDirectSale() {
  return request.get('/api/os-reports/for-showroom/direct_sale/type/count')
}

//Все авто на витрине
export async function getAmountCarPlaced() {
  return request.get(`/api/os-reports/in-showroom/count`)
}

//Все авто на витрине по дилеру
export async function getAmountCarPlacedByDealer(filial_code) {
  return request.get(
    `/api/os-reports/in-showroom/${filial_code}/filial-code/count`
  )
}

//Все авто на витрине по direct-sale
export async function getAmountCarPlacedByDirectSale() {
  return request.get('/api/os-reports/in-showroom/direct_sale/type/count')
}

//Кол-во активных заказов по direct-sale
export async function getAmountOrdersByDirectSale(from, to) {
  return request.get(
    `/api/os-reports/orders/${from}/date-from/${to}/date-to/direct_sale/type/active-count`
  )
}
