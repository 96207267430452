export const IconAskDealers = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.37357 24.9887C2.66925 25.0642 2.89101 25.0642 3.08321 25.0642C4.72766 25.0642 6.29146 24.4399 7.5 23.3367L6.8882 22.467C6.73473 22.4928 6.34223 22.5963 6 22.8041C5.14371 23.3798 4.13352 23.7037 3.09799 23.7037C3.91112 22.9176 4.47292 21.9048 4.73903 20.7407C4.79817 20.4535 4.67989 20.1512 4.42856 20L3.5 20.5C3.5 21.5 2.58055 22.3885 1.93004 22.9327C1.59001 23.22 1.42738 23.6584 1.53087 24.0967C1.63436 24.5351 1.94483 24.8828 2.37357 24.9887Z"
        fill="white"
      />
      <path
        d="M8.43591 13.643C7.80358 13.643 7.28474 13.1205 7.28474 12.5C7.28474 11.8631 7.80358 11.3569 8.43591 11.3569C9.06824 11.3569 9.57086 11.8795 9.57086 12.5C9.57086 13.1368 9.06824 13.643 8.43591 13.643ZM8.43591 12.1897C8.25756 12.1897 8.11164 12.3367 8.11164 12.5163C8.11164 12.6959 8.25756 12.8266 8.43591 12.8266C8.59804 12.8102 8.74396 12.6796 8.74396 12.5C8.74396 12.3204 8.59804 12.1897 8.43591 12.1897Z"
        fill="white"
      />
      <path
        d="M13.0079 13.643C12.3757 13.643 11.8569 13.1205 11.8569 12.5C11.8569 11.8631 12.3757 11.3569 13.0079 11.3569C13.6402 11.3569 14.1427 11.8795 14.1427 12.5C14.1589 13.1368 13.6402 13.643 13.0079 13.643ZM13.0079 12.1897C12.8296 12.1897 12.6837 12.3367 12.6837 12.5163C12.6837 12.6959 12.8296 12.8266 13.0079 12.8266C13.1862 12.8266 13.3159 12.6796 13.3159 12.5163C13.3159 12.353 13.1862 12.1897 13.0079 12.1897Z"
        fill="white"
      />
      <path
        d="M17.5801 13.643C16.9479 13.643 16.4292 13.1205 16.4292 12.5C16.4292 11.8631 16.9479 11.3569 17.5801 11.3569C18.2124 11.3569 18.7149 11.8795 18.7149 12.5C18.7311 13.1368 18.2124 13.643 17.5801 13.643ZM17.5801 12.1897C17.4018 12.1897 17.2559 12.3367 17.2559 12.5163C17.2559 12.6959 17.4018 12.8266 17.5801 12.8266C17.7585 12.8266 17.8881 12.6796 17.8881 12.5163C17.8881 12.353 17.7585 12.1897 17.5801 12.1897Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3 12.5C24.3 18.7408 19.2408 23.8 13 23.8C11.1046 23.8 9.32151 23.3343 7.75546 22.5121C7.15983 22.2357 6.33697 22.6273 6 22.8577C6.38449 23.118 6.78425 23.3575 7.19765 23.5746C8.93163 24.4849 10.9057 25 13 25C19.9036 25 25.5 19.4036 25.5 12.5C25.5 5.59644 19.9036 0 13 0C6.09644 0 0.5 5.59644 0.5 12.5C0.5 15.6016 1.62967 18.4394 3.5 20.6244C3.78691 20.9596 4.16006 21.2977 4.48043 21.6007C4.66798 21.0424 5.02566 20.5614 4.41162 19.844C2.72035 17.8683 1.7 15.3048 1.7 12.5C1.7 6.25918 6.75918 1.2 13 1.2C19.2408 1.2 24.3 6.25918 24.3 12.5Z"
        fill="white"
      />
    </svg>
  )
}
