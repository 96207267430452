import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { LOADING_STATUS } from 'constants/loadingStatus'
import { loadAllDealers, loadDealersById } from './thunks'

export const adapter = createEntityAdapter()

export const dealers = createSlice({
  name: 'dealers',
  initialState: adapter.getInitialState({
    items: [],
    dealersById: [],
    total: 0,
    loadingStatus: LOADING_STATUS.LOADING,
  }),
  reducers: {},
  extraReducers: {
    [loadAllDealers.pending]: (state) => {
      state.loadingStatus = LOADING_STATUS.LOADING
    },
    [loadAllDealers.rejected]: (state) => {
      state.items = []
    },
    [loadAllDealers.fulfilled]: (state, { payload }) => {
      state.loadingStatus = LOADING_STATUS.IDLE
      state.items = payload.items.sort((a, b) =>
        a.dealerName.toLowerCase() < b.dealerName.toLowerCase() ? -1 : 1
      )
    },

    [loadDealersById.pending]: (state) => {
      state.loadingStatus = LOADING_STATUS.LOADING
    },
    [loadDealersById.rejected]: (state) => {
      state.dealersById = []
    },
    [loadDealersById.fulfilled]: (state, { payload }) => {
      state.loadingStatus = LOADING_STATUS.IDLE
      state.dealersById = payload.items.sort((a, b) =>
        a.dealerName.toLowerCase() < b.dealerName.toLowerCase() ? -1 : 1
      )
    },
  },
})

export default dealers.reducer
