import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ModalAction } from 'components/ModalAction'
import { Container } from 'components/Container'
import { Notifications } from 'components/Notifications'

import { Discounts } from './Discounts'
import { FullCreditProgram } from './FullCreditProgramm'
import { Services } from './Services'
import { FooterActions } from './FooterActions'
import { Accessories } from './Accessories'
import { ClientForms } from './ClientForms'

import { getUserRole } from 'store/auth/selectors'

import { ROLE } from 'constants/role'
import { discountList } from './constants'
import { getAccessoriesByKomplId } from 'api/accessories'
import { setDataCO } from 'api/corporateOffer'
import { getCarDataCO } from 'containers/CorporateOffer/actions'

import style from './styles.module.scss'

export default function CreateCorporateOffer() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { role } = useSelector((state) => ({
    role: getUserRole(state),
  }))

  const redirectLink =
    role === ROLE.chief || role === ROLE.chief2
      ? '/chief?status=6'
      : '/manager?status=6'

  const [carData, setCarData] = useState({})
  const [clientData, setClientData] = useState(null)

  const [accessoriesData, setAccessoriesData] = useState([])
  const [servicesData, setServicesData] = useState([])
  // const [creditData, setCreditData] = useState({})
  const [discountsData, setDiscountsData] = useState([])

  const [totalPrice, setTotalPrice] = useState(0)

  const setDataHandler = async () => {
    let validation = () => {
      try {
        let {
          lastName,
          name,
          phone,
          email,
          company,
          inn,
          address,
          deliverDate,
          amountValue,
          paymentType,
          managerPosition,
          managerFIO,
        } = clientData

        return (
          !!(lastName && /[a-zа-яё]/i.test(lastName)) &&
          !!(name && /[a-zа-яё]/i.test(name)) &&
          !!(phone && phone.replace(/[^+\d]/g, '').length === 12) &&
          !!(email && /\S+@\S+\.\S+/.test(email)) &&
          !!(company && /[a-zа-яё0-9]/i.test(company)) &&
          !!(inn && /^\d{10,12}$/.test(inn)) &&
          !!(address && /[a-zа-яё0-9]/.test(address)) &&
          !!(deliverDate && /^\d{2}\.\d{2}\.\d{4}$/.test(deliverDate)) &&
          !!(amountValue && /^\d+$/.test(amountValue)) &&
          !!paymentType &&
          !!(managerPosition && /[a-zа-яё0-9]/i.test(managerPosition)) &&
          !!(managerFIO && /[a-zа-яё]/i.test(managerFIO))
        )
      } catch (e) {
        return false
      }
    }

    let isRequired = validation()

    if (isRequired) {
      try {
        let data = {
          compl_id: carData.compl_id,
          color_code: carData.color_code,
          ...clientData,
          ...(!!accessoriesData.length && { accessories: accessoriesData }),
          ...(!!discountsData.length && { discounts: discountsData }),
          ...(!!servicesData.length && { services: servicesData }),
          // ...(!!Object.keys(creditData).length && { credit: creditData }),
        }

        await setDataCO(data)

        navigate(redirectLink, { replace: true })
      } catch (e) {
        setIsOpenNotifications({
          isOpen: true,
          currentTitle: 'isError',
        })
      }
    } else {
      setIsOpenNotifications({
        isOpen: true,
        currentTitle: 'isValidation',
        children: 'ДАННЫЕ КЛИЕНТА',
      })
    }
  }

  const [isLoading, setIsLoading] = useState(true)
  const [accessories, setAccessories] = useState(null)
  const [isOpenNotifications, setIsOpenNotifications] = useState({
    isOpen: false,
    currentTitle: '',
  })

  const [isShowRules, setIsShowRules] = useState(false)

  const checkData = !!carData && !!accessories

  useEffect(() => {
    if (Object.keys(carData).length) {
      async function fetchAccessories() {
        try {
          const res = await getAccessoriesByKomplId(carData.compl_id)
          setAccessories(res.data)
          setIsLoading(false)
        } catch (e) {
          console.warn(e)
        }
      }

      fetchAccessories()
    }
  }, [carData])

  useEffect(() => {
    let color = searchParams.get('color')
    let compl = searchParams.get('compl')

    if (!!color && !!compl) {
      getCarDataCO(compl, color)
        .then((res) => {
          setCarData(res)
          setTotalPrice(res.total_price)
        })
        .catch((err) => navigate(redirectLink, { replace: true }))
    } else {
      navigate(redirectLink, { replace: true })
    }
  }, [])

  return (
    <div className={style.container}>
      <Notifications
        {...isOpenNotifications}
        setIsOpenNotifications={setIsOpenNotifications}
      />
      {isShowRules && (
        <ModalAction
          modalClass={style.rulesModal}
          textClass={style.rulesText}
          titleClass={style.rulesTitle}
          handleClose={() => setIsShowRules(false)}
          title="Для корректной работы с корпоративным коммерческим предложением используйте браузеры <a href='https://www.mozilla.org/ru/firefox/'>Mozilla Firefox</a>, <a href='https://www.google.ru/chrome/'>Google Chrome</a>."
          description={
            '1. Выберите комплектацию автомобиля, для которой печатается корпоративное коммерческое предложение. <br/><br/>2. Выберите нужный цвет автомобиля. <br/><br/>3. Заполните все поля, отмеченные *. <br/><br/>4. Укажите необходимые выгоды: дилера, программ лизинга, такси. <br/><br/>5. Добавьте аксессуары из реестра или свои собственные. Укажите (при необходимости) свою стоимость аксессуаров. <br/><br/>6. Сформируйте печатную версию корпоративного коммерческого предложения.'
          }
        />
      )}
      {!isLoading && checkData && (
        <>
          <Container>
            <p
              className={style.showRulesText}
              onClick={() => setIsShowRules(true)}
            >
              Правила пользования
            </p>
          </Container>
          <ClientForms clientData={clientData} setClientData={setClientData} />
          <Accessories
            {...accessories}
            currentAccessories={accessoriesData}
            setCurrentAccessories={setAccessoriesData}
            setTotalPrice={setTotalPrice}
            background
          />
          <Discounts
            discountList={discountList}
            setDiscountsData={setDiscountsData}
            setTotalPrice={setTotalPrice}
          />
          <Services
            servicesData={servicesData}
            setServicesData={setServicesData}
            setTotalPrice={setTotalPrice}
            background
          />
          {/*<FullCreditProgram*/}
          {/*  setCreditData={setCreditData}*/}
          {/*  creditData={creditData}*/}
          {/*  price={totalPrice}*/}
          {/*/>*/}
          <FooterActions setDataHandler={setDataHandler} />
        </>
      )}
    </div>
  )
}
