export const discountList = [
  {
    id: 0,
    prefix: 'dd_d',
    label: 'Прямая скидка от дилера',
    priceBenefit: null,
  },
  {
    id: 1,
    prefix: 'dd_leasing',
    label: 'Лизинг',
    priceBenefit: null,
  },
  {
    id: 2,
    prefix: 'dd_taxi',
    label: 'Такси',
    priceBenefit: null,
  },
]
