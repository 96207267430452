import React from 'react'
import { pdf } from '@react-pdf/renderer'
import { useNavigate } from 'react-router-dom'
import { saveAs } from 'file-saver'

import Button from 'components/Button'
import { Container } from 'components/Container'
import { PdfCreator } from 'components/PdfCreator'

import { getCookie } from 'utils/cookie'

import { getBadgeById } from 'api/badges'

import style from './styles.module.scss'
import { PdfList } from 'components/PdfGenerator/PdfList'

export const FooterActions = ({ carId, creditData, setIsCreditError }) => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  return (
    <div className={style.background}>
      <Container className={style.container}>
        <div className={style.buttons}>
          <Button.Outlined onClick={() => goBack()}>
            отменить и вернуться
          </Button.Outlined>
          <PdfCreator
            id={carId}
            creditData={creditData}
            setIsCreditError={setIsCreditError}
          />
        </div>
      </Container>
    </div>
  )
}
