import clsx from 'clsx'

import styles from './styles.module.scss'

export const ButtonCircle = ({
  disabled,
  onClick,
  children,
  className,
  ...rest
}) => {
  return (
    <button
      {...rest}
      aria-label="button-circle"
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={clsx(styles.button, className)}
    >
      {children}
    </button>
  )
}
