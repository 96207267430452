export const IconArrowDown = ({ width, height, color }) => {
  return (
    <svg
      width={width || '15'}
      height={height || '9'}
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1L7.5 7L1 0.999999"
        stroke={color || '#66727D'}
        strokeWidth="2"
      />
    </svg>
  )
}
