import { createSelector } from '@reduxjs/toolkit'

export const getCars = (state) => state.cars

export const getAllCars = createSelector(getCars, (cars) => cars.items)

export const getCarsLoadingStatus = createSelector(
  getCars,
  (cars) => cars.loadingStatus
)
