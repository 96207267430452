import { SORT_DIRECTION } from '../constants/loadingStatus'

/**
 * Get value by key recursion
 * @param {Object | string | number} value - target object
 * @param {string} path - path to val
 * @param {any} defaultVal - default return val if path not resolve
 * @returns {string|number|null}
 * @private
 */
const _getField = (value, path, defaultVal = null, multiField = false) => {
  if (!path.length) return value
  if (value === undefined && path.length) return defaultVal
  if (!multiField) {
    const newPath = path.split('.')
    return _getField(value[newPath.shift()], newPath.join('.'))
  } else {
    const newPath = path.split('.')

    return newPath.reduce((acc, el) => {
      return acc + value[el]
    }, '')
  }
}

/**
 *
 * @param {Object | string | number} value - target object
 * @param {string} path - path to val
 * @param {any=} defaultVal - path to val
 * @returns {string|number}
 * @public
 */
export const getFieldByPath = (value, path, defaultVal) =>
  _getField(value, path, defaultVal)

/**
 * Simple compare functions
 * @param a - first val
 * @param b - second val
 * @returns {number} - filter signature
 * @private
 */
const _compare = (a, b) => {
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

/**
 * Sort function for tables
 * @param {[any]} data - data array
 * @param {SORT_DIRECTION.ASC | SORT_DIRECTION.DESC | string} direction - sorting direction
 * @param {string} path - sorting field from data element
 * @param {string?} type - sorting fields type
 */
export const sortTableData = (data, direction, path, type) =>
  [...data].sort((a, b) => {
    switch (type) {
      case 'multiField':
        return direction === SORT_DIRECTION.ASC
          ? _compare(
              String(_getField(a, path, null, true)),
              String(_getField(b, path, null, true))
            )
          : _compare(
              String(_getField(b, path, null, true)),
              String(_getField(a, path, null, true))
            )
      case 'date':
        return direction === SORT_DIRECTION.ASC
          ? _compare(
              new Date(
                String(_getField(a, path)).split('.').reverse().join('-')
              ).getTime(),
              new Date(
                String(_getField(b, path)).split('.').reverse().join('-')
              ).getTime()
            )
          : _compare(
              new Date(
                String(_getField(b, path)).split('.').reverse().join('-')
              ).getTime(),
              new Date(
                String(_getField(a, path)).split('.').reverse().join('-')
              ).getTime()
            )
      case 'string':
        return direction === SORT_DIRECTION.ASC
          ? _compare(String(_getField(a, path)), String(_getField(b, path)))
          : _compare(String(_getField(b, path)), String(_getField(a, path)))
      default:
        return direction === SORT_DIRECTION.ASC
          ? _compare(_getField(a, path), _getField(b, path))
          : _compare(_getField(b, path), _getField(a, path))
    }
  })

/**
 * Sort handler
 * @param id
 * @param sortedIndex
 * @param setSortedIndex
 * @param setSortDirection
 */
export const handleSort = (
  id,
  sortedIndex,
  setSortedIndex,
  setSortDirection
) => {
  if (sortedIndex === id) {
    setSortDirection((p) =>
      p === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC
    )
  } else {
    setSortedIndex(id)
    setSortDirection(SORT_DIRECTION.DESC)
  }
}
