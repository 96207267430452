export const phoneValidation = (newState, oldState, userInput) => {
  let { value, selection } = newState

  // При автозаполнения номера обрезается +7
  if (userInput && userInput.slice(0, 2) === '+7') {
    value = userInput.slice(2)
  }

  // Валидация ввода первой цифры
  if (userInput && value === '(7__) ___-__-__') {
    value = value.slice(2)
    selection.start = 1
    selection.end = 1
  }

  return {
    value,
    selection,
  }
}
