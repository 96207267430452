import { request } from 'utils/request'

export async function updateFullPayment({ carId, payment }) {
  return request.put(`/api/os-manager-cars/${carId}`, {
    payment,
  })
}

export async function setPaymentForOrder({ orderId, payment_value }) {
  return request.put(`/api/os-manager-orders/${orderId}`, {
    payment_value,
  })
}
