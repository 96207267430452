import { Controller } from 'react-hook-form'

import { InputRender } from './input'

const Input = ({
  name,
  control,
  disabled,
  type = 'text',
  label = '',
  placeholder,
  inputmode,
  rules = {},
  pattern = {},
  onChangeDecorator = (val) => val,
  required,
  error,
  mask,
  className,
  isNumberFormat,
  onBlur = () => {},
  ...other
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      pattern={pattern}
      render={({ onChange, value }) => (
        <InputRender
          type={type}
          value={value}
          onChange={(e) => onChange(onChangeDecorator(e))}
          disabled={disabled}
          label={label}
          placeholder={placeholder}
          inputmode={inputmode}
          required={required}
          error={error}
          mask={mask}
          className={className}
          isNumberFormat={isNumberFormat}
          name={name}
          onBlur={onBlur}
          {...other}
        />
      )}
    />
  )
}

export default Input
