import React from 'react'
import s from './s.module.scss'
import clsx from 'clsx'

const Switcher = ({ selections, value, setter, style, gray = false }) => {
  return (
    <div className={s.wrapper} style={style}>
      {selections.map((selection, id) => (
        <SwitcherElement
          selection={selection}
          handle={setter}
          id={id}
          value={value}
          gray={gray}
        />
      ))}
    </div>
  )
}

export default Switcher

const SwitcherElement = ({ selection, id, value, handle, gray }) => (
  <div
    className={clsx(s.selection, {
      [s.selection_select]: id === value,
    })}
    onClick={() => handle(id)}
  >
    <span
      className={clsx({
        [s.grayText]: gray,
      })}
    >
      {selection}
    </span>
  </div>
)
