/**
 * User role enum
 * @type {{manager: string, chief: string}}
 */
export const ROLE = {
  chief: '001',
  chief2: '003',
  manager: '002',
  root: 'root',
  admin: 'admin',
}

/**
 * User role alias enum
 * @type {{ROLE: string}}
 */
export const ROLE_ALIAS = {
  [ROLE.chief]: 'Руководитель',
  [ROLE.chief2]: 'Руководитель',
  [ROLE.manager]: 'Менеджер',
  [ROLE.root]: 'Супервизор',
  [ROLE.admin]: 'Супервизор',
}
