export const accessoriesFormatter = (accessories) => {
  let { added, base } = accessories
  let arr = []

  added &&
    added.length > 0 &&
    added.forEach(({ name, price, id }) =>
      arr.push({ name: name, price: price, isPg: id.indexOf('PG') > -1 })
    )
  base &&
    base.length > 0 &&
    base.forEach(({ name, price, id }) =>
      arr.push({ name: name, price: price, isPg: id.indexOf('PG') > -1 })
    )

  return arr
}

export const getAccessoriesPrice = (accessories) => {
  let { added, base } = accessories
  let result = 0

  added &&
    added.length > 0 &&
    added.forEach((el) => (result = result + el.price))
  base && base.length > 0 && base.forEach((el) => (result = result + el.price))

  return result
}

export const specOffersFormatter = (offers) => {
  let { dealer_actions = [], special_offers = [] } = offers
  let arr = []

  dealer_actions.length > 0 &&
    dealer_actions.forEach(({ title }) => arr.push(title))
  special_offers.length > 0 &&
    special_offers.forEach(({ title }) => arr.push(title))

  return {
    title: 'Специальные предложения',
    list: arr,
  }
}

export const additionalFormatter = (additional_services) => {
  let arr = []

  additional_services.length > 0 &&
    additional_services.forEach(({ name }) => arr.push(name))

  return {
    title: 'Дополнительные услуги',
    list: arr,
  }
}

export const svgFormatter = (ref) => {
  const element = ref.current.children[0]
  const svgData = {}

  if (element.nodeName === 'svg') {
    let attributes = new Set(element.attributes)
    let elementChildren = element.children

    attributes.forEach((el) => (svgData[el.name] = el.value))

    if (elementChildren.length > 0) {
      let childes = new Set(elementChildren)
      svgData.list = []

      childes.forEach((el) => {
        let childesAttributes = new Set(el.attributes)
        let obj = {}

        childesAttributes.forEach((el) => {
          if (el.name === 'shape-rendering')
            return (obj['shapeRendering'] = el.value)
          // if (el.name === 'fill' && el.value === '#ffffff')
          //   return (obj[el.name] = '#D2DEE3')

          return (obj[el.name] = el.value)
        })

        svgData.list.push(obj)
      })
    }
  }

  return svgData
}
