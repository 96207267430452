export const parseJWT = (token) => {
  if (token.indexOf('.') !== -1) {
    const base64Url = token.split('.')[1]
    const base64 = decodeURIComponent(
      atob(base64Url)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    return JSON.parse(base64)
  }
  return false
}
