import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'

import Input from 'components/Fields/Input'
import SelectDefault from 'components/Fields/Select'
import { Container } from 'components/Container'
import Icon from 'components/Icon'
import Button from 'components/Button'

import { getPrice } from 'utils/getPrice'
import { getValuesWithSelect } from 'utils/getValuesWithSelect'

import { colors } from 'constants/inlineColorConfig'
import { getAllServices } from 'api/services'

import style from './styles.module.scss'

export const Services = ({
  servicesData,
  setServicesData,
  background,
  setTotalPrice = () => {},
}) => {
  const [allServices, setAllServices] = useState([])
  const [servicesPrice, setServicesPrice] = useState(0)

  const formattedServices = getValuesWithSelect(allServices, ['id', 'name'])

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm()

  const addService = async (val) => {
    setServicesData((prev) => {
      const currentService = allServices.find((el) => el.id === val.service)
      return [
        ...prev,
        {
          name: currentService.name,
          service_id: val.service,
          price: Number(val.price).valueOf(),
        },
      ]
    })

    setServicesPrice((prev) => prev + Number(val.price).valueOf())
    setTotalPrice((prev) => prev + Number(val.price).valueOf())

    setValue('service', '')
    setValue('price', '')
  }

  const deleteService = (id) => {
    const currentService = servicesData.find((el) => el.service_id === id)

    setServicesData((prev) => prev.filter((el) => el.service_id !== id))
    setServicesPrice((prev) => prev - Number(currentService.price).valueOf())
    setTotalPrice((prev) => prev - Number(currentService.price).valueOf())
  }

  useEffect(() => {
    const fetchAllServices = async () => {
      try {
        const res = await getAllServices()
        const filteredServices = res.data.filter(
          (el) => !servicesData.some((it) => Object.values(it).includes(el.id))
        )
        const servicesPrices = servicesData.reduce(
          (acc, el) => acc + el.price,
          0
        )

        setServicesPrice(servicesPrices)
        setAllServices(filteredServices)
      } catch (e) {
        console.warn(e)
      }
    }

    fetchAllServices()
  }, [servicesData])

  return (
    <div
      className={clsx({
        [style.background]: background,
      })}
    >
      <Container className={style.container}>
        <h2 className={style.title}>Дополнительные услуги</h2>
        <div className={style.list}>
          {!!servicesData?.length &&
            servicesData.map(({ name, price, service_id }) => (
              <div className={style.item} key={service_id}>
                <div className={style.itemName}>
                  <span className={style.itemTitle}>{name}</span>
                  <div onClick={() => deleteService(service_id)}>
                    <Icon.Close
                      width={16}
                      height={16}
                      color={colors.blackPearl}
                    />
                  </div>
                </div>
                <div>
                  <span>{getPrice(price)}</span>
                </div>
              </div>
            ))}
          {servicesPrice > 0 && (
            <div>
              <div className={style.servicesPrice}>
                <span>ИТОГО допуслуг на</span>
                <span>{getPrice(servicesPrice)}</span>
              </div>
            </div>
          )}
        </div>
        <form onSubmit={handleSubmit(addService)}>
          <div className={style.form}>
            <div className={style.select}>
              <SelectDefault
                label="Добавить услугу"
                name="service"
                control={control}
                options={formattedServices}
                error={errors['service']}
                rules={{
                  required: 'Это поле необходимо заполнить',
                }}
              />
            </div>
            <div className={style.input}>
              <Input
                label="Сумма, ₽"
                name="price"
                control={control}
                error={errors['price']}
                required
                rules={{
                  required: 'Это поле необходимо заполнить',
                  validate: (v) => {
                    return v && v >= 0 ? true : 'Введите корректную сумму'
                  },
                }}
              />
            </div>
            <div className={style.action}>
              <Button.Outlined type="submit">Добавить</Button.Outlined>
            </div>
          </div>
        </form>
      </Container>
    </div>
  )
}
