export const IconParts = ({ width, height, color }) => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.0032 5.79727C30.3716 5.66021 29.7696 6.06872 29.6379 6.69224C29.5089 7.31576 29.9093 7.92853 30.5302 8.05754C39.3213 9.88778 45.6989 17.7463 45.6989 26.739C45.6989 37.2582 37.1362 45.8208 26.617 45.8208C17.6566 45.8208 9.80615 39.4701 7.95172 30.7193C7.82003 30.0931 7.19113 29.6926 6.58373 29.8297C5.95752 29.9641 5.55976 30.5742 5.69414 31.1977C7.77165 41.0101 16.5708 48.1295 26.617 48.1295C38.4128 48.1295 48.0075 38.5348 48.0075 26.739C48.0075 16.6605 40.8559 7.85059 31.0032 5.79727Z"
        fill="#E37639"
      />
      <path
        d="M2.55411 25.2652C2.4493 25.1523 2.27998 24.905 2.31492 24.5583C3.39802 13.0312 12.9067 3.52524 24.4284 2.44215C24.4606 2.43946 24.4875 2.43946 24.5171 2.43946C24.8208 2.43946 25.0331 2.58728 25.1379 2.68403C25.3449 2.87485 25.4658 3.14092 25.4658 3.42043V10.9887C25.4658 11.4402 25.1084 11.8191 24.6192 11.8836C18.083 12.7947 12.6729 18.1941 11.7564 24.7223C11.6892 25.2248 11.2968 25.593 10.8292 25.593H3.2932C3.01369 25.593 2.74493 25.4748 2.55411 25.2652ZM14.0462 25.0394C14.8203 19.5164 19.4026 14.9448 24.9417 14.1708C26.5785 13.9397 27.7744 12.6012 27.7744 10.9887V3.42043C27.7744 2.49321 27.3794 1.60362 26.6887 0.97741C26.0168 0.361952 25.1191 0.0636295 24.2107 0.14157C11.6059 1.32948 1.20494 11.7305 0.0143414 24.3406C-0.0716614 25.241 0.232036 26.1413 0.847494 26.8186C1.4737 27.5093 2.36329 27.9017 3.2932 27.9017H10.8292C12.4606 27.9017 13.8124 26.6976 14.0462 25.0394Z"
        fill="#E37639"
      />
      <path
        d="M32.919 26.7416C32.919 30.2167 30.0917 33.0414 26.6193 33.0414C23.1442 33.0414 20.3169 30.2167 20.3169 26.7416C20.3169 23.2666 23.1442 20.4419 26.6193 20.4419C30.0917 20.4419 32.919 23.2666 32.919 26.7416ZM18.0083 26.7416C18.0083 31.4906 21.873 35.35 26.6193 35.35C31.3629 35.35 35.2276 31.4906 35.2276 26.7416C35.2276 21.9927 31.3629 18.1333 26.6193 18.1333C21.873 18.1333 18.0083 21.9927 18.0083 26.7416Z"
        fill="#E37639"
      />
      <path
        d="M25.463 23.5497C25.463 24.1867 25.9817 24.7054 26.6187 24.7054C27.7448 24.7054 28.6586 25.6191 28.6586 26.7452C28.6586 27.8713 27.7448 28.7851 26.6187 28.7851C25.4899 28.7851 24.5761 27.8713 24.5761 26.7452C24.5761 26.1083 24.0601 25.5923 23.4232 25.5923C22.7862 25.5923 22.2675 26.1083 22.2675 26.7452C22.2675 29.1453 24.2187 31.0938 26.6187 31.0938C29.016 31.0938 30.9672 29.1453 30.9672 26.7452C30.9672 24.3452 29.016 22.3967 26.6187 22.3967C25.9817 22.3967 25.463 22.9127 25.463 23.5497Z"
        fill="#E37639"
      />
      <path
        d="M22.3567 9.78902C22.9937 9.78902 23.5124 9.273 23.5124 8.63604V6.50747C23.5124 5.86782 22.9937 5.35181 22.3567 5.35181C21.7198 5.35181 21.201 5.86782 21.201 6.50747V8.63604C21.201 9.273 21.7198 9.78902 22.3567 9.78902Z"
        fill="#E37639"
      />
      <path
        d="M8.51258 21.3262H6.38132C5.74436 21.3262 5.22835 21.8449 5.22835 22.4818C5.22835 23.1188 5.74436 23.6348 6.38132 23.6348H8.51258C9.14954 23.6348 9.66824 23.1188 9.66824 22.4818C9.66824 21.8449 9.14954 21.3262 8.51258 21.3262Z"
        fill="#E37639"
      />
    </svg>
  )
}
