import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'

import Input from 'components/Fields/Input'
import { Container } from 'components/Container'
import Icon from 'components/Icon'
import Button from 'components/Button'
import { GroupSelectWithTabs } from 'components/Fields/GroupSelectWithTabs/GroupSelect'

import { getPrice } from 'utils/getPrice'

import { colors } from 'constants/inlineColorConfig'

import style from './styles.module.scss'

const formattedCurrentAccessories = (accessories) => {
  const base = accessories.base
  const added = accessories.added

  if (base && added) {
    return [...base, ...added]
  }

  if (base && !added) {
    return [...base]
  }

  if (!base && added) {
    return [...added]
  }
}

/**
 * Cut used accessories from dataList for do not use in adding
 * @param {[]} dataList - full accessories list
 * @param {[]} usedList - current accessories in use
 * @return {[]} - filtered full accessories list
 * @private
 */
const _cutUsedAccessories = (dataList, usedList) =>
  !!usedList.length
    ? [...dataList].reduce(
        (acc, optionGroup) => [
          ...acc,
          {
            ...optionGroup,
            options: [...optionGroup.options].reduce(
              (acc, option) =>
                usedList.find(
                  (usedOption) => String(usedOption.id) === String(option.value)
                )
                  ? acc
                  : [...acc, option],
              []
            ),
          },
        ],
        []
      )
    : dataList

export const Accessories = ({
  catalog,
  accessories,
  dealer_accessories,
  background,
  currentAccessories,
  setCurrentAccessories,
  setTotalPrice = () => {},
}) => {
  const [groups, setGroups] = useState(null)
  const [allAccessories, setAllAccessories] = useState([])
  const [filteredAccessories, setFilteredAccessories] = useState([])

  const [accTabs, setAccTabs] = useState(['Все'])

  const accessoriesPrice = currentAccessories?.reduce(
    (acc, { price }) => acc + price,
    0
  )

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    reset,
  } = useForm()

  const watchAccessory = watch('accessories')
  const watchPrice = watch('price')

  const addAccessory = async (val) => {
    const acc = [
      ...(Array.isArray(accessories) ? [...accessories] : []),
      ...dealer_accessories,
    ]

    const current = acc.find((el) => el.id === watchAccessory)

    try {
      setCurrentAccessories((prev) => {
        const newCurrentAccessories = structuredClone(prev)
        newCurrentAccessories.push({ ...current, price: Number(watchPrice) })

        return newCurrentAccessories
      })

      setTotalPrice((prev) => prev + Number(watchPrice))

      setValue('accessories', '')
      reset()
    } catch (e) {
      console.log(e)
    }
  }

  const deleteAccessory = async (accessoryId) => {
    try {
      setCurrentAccessories((prev) => {
        let newCurrentAccessories = structuredClone(prev)
        let deletingAcc = newCurrentAccessories.filter(
          (el) => el.id === accessoryId
        )

        newCurrentAccessories = newCurrentAccessories.filter(
          (el) => el.id !== accessoryId
        )

        setTotalPrice((prev) => prev - deletingAcc[0].price)

        return newCurrentAccessories
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (!!catalog) {
      const filteredGroups = catalog.reduce((acc, group) => {
        if (!acc['parent']) acc['parent'] = []
        if (!acc['options']) acc['options'] = []

        if (group.parent_id !== 0) {
          acc['options'].push(group)
        } else {
          acc['parent'].push(group)
        }
        return acc
      }, {})

      setGroups(filteredGroups)
    }
  }, [catalog])

  useEffect(() => {
    if ((accessories || dealer_accessories) && groups) {
      const accessoriesGroups =
        (!!accessories &&
          groups.options.map(({ id, parent_id }) => {
            const options = accessories
              .filter((el) => el.group === id)
              .map((it) => {
                return { value: it.id, label: it.name }
              })

            return { parent_id, options: options }
          })) ||
        []

      const filteredAccessories =
        (accessoriesGroups.length > 0 &&
          groups.parent.map(({ id, name }) => {
            const options = accessoriesGroups
              .filter((el) => el.parent_id === id)
              .reduce((acc, it) => {
                it.options.forEach(
                  (item) =>
                    (item.label = `${item.label + ' арт. ' + item.value}`)
                )
                acc.push(...it.options)

                return acc
              }, [])

            return { label: name, options: options }
          })) ||
        []

      const dealerAccessories =
        (!!dealer_accessories &&
          dealer_accessories.map(({ id, name }) => {
            return { value: id, label: name }
          })) ||
        []

      const mixedAccessories = [
        ...filteredAccessories,
        { label: 'Дилерские аксессуары', options: dealerAccessories },
      ]

      filteredAccessories.length > 0 &&
        setAccTabs((prev) =>
          !prev.includes('LADA Image') ? [...prev, 'LADA Image'] : prev
        )
      dealerAccessories.length > 0 &&
        setAccTabs((prev) =>
          !prev.includes('Дилер') ? [...prev, 'Дилер'] : prev
        )

      mixedAccessories.length > 0 && setAllAccessories(mixedAccessories)
    }
  }, [accessories, dealer_accessories, groups])

  useEffect(() => {
    if (accessories) {
      const acc = [...accessories, ...dealer_accessories]
      const current = acc.find((el) => el.id === watchAccessory)

      if (current && current.price) {
        setValue('price', current.price)
      }
      if (current && !current.price) {
        setValue('price', '')
      }
    }
  }, [watchAccessory])

  useEffect(() => {
    if (allAccessories.length)
      setFilteredAccessories(
        _cutUsedAccessories(allAccessories, currentAccessories)
      )
  }, [currentAccessories, allAccessories])

  return (
    <div
      className={clsx({
        [style.background]: background,
      })}
    >
      <Container className={style.container}>
        <h2 className={style.title}>Аксессуары</h2>
        <div className={style.list}>
          {!!currentAccessories.length &&
            currentAccessories.map(({ name, price, id }) => (
              <div className={style.item} key={id}>
                <div className={style.itemName}>
                  <span className={style.itemTitle}>{name}</span>
                  <div onClick={() => deleteAccessory(id)}>
                    <Icon.Close
                      width={16}
                      height={16}
                      color={colors.blackPearl}
                    />
                  </div>
                </div>
                {price && (
                  <div className={style.itemPrice}>{getPrice(price)}</div>
                )}
              </div>
            ))}
          {accessoriesPrice > 0 && (
            <div>
              <div className={style.accPrice}>
                <span>итого аксессуаров на</span>
                <span>{getPrice(accessoriesPrice)}</span>
              </div>
            </div>
          )}
        </div>
        <form onSubmit={handleSubmit(addAccessory)}>
          <div className={style.form}>
            <div className={style.select}>
              <GroupSelectWithTabs
                label="Добавить аксессуары"
                name="accessories"
                control={control}
                options={filteredAccessories}
                error={errors['accessories']}
                className={style.accessories}
                tabs={accTabs}
              />
            </div>
            <div className={style.price}>
              <Input
                label="Сумма, ₽"
                name="price"
                control={control}
                error={errors['price']}
                required
                rules={{
                  required: 'Это поле необходимо заполнить',
                  validate: (v) =>
                    v && v > 0 ? true : 'Введите корректную сумму',
                }}
              />
            </div>
            <div className={style.action}>
              <Button.Outlined type="submit">Добавить</Button.Outlined>
            </div>
          </div>
        </form>
      </Container>
    </div>
  )
}
