export const IconRegistrationCertificate = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 46}
      height={height || 32}
      viewBox="0 0 46 32"
      fill="none"
    >
      <path
        d="M38.3327 12.4568H26.2355C25.6216 12.4568 25.1216 11.9598 25.1216 11.3459C25.1216 10.732 25.6216 10.2379 26.2355 10.2379H38.3327C38.9466 10.2379 39.4466 10.7349 39.4466 11.3459C39.4466 11.9598 38.9466 12.4568 38.3327 12.4568Z"
        fill={color || '#FFFFFF'}
      />
      <path
        d="M19.7176 12.4568H7.62037C7.00644 12.4568 6.50653 11.9598 6.50653 11.3459C6.50653 10.732 7.00644 10.2379 7.62037 10.2379H19.7176C20.3315 10.2379 20.8314 10.7349 20.8314 11.3459C20.8314 11.9598 20.3315 12.4568 19.7176 12.4568Z"
        fill={color || '#FFFFFF'}
      />
      <path
        d="M38.3327 17.1109H26.2355C25.6216 17.1109 25.1216 16.6139 25.1216 16C25.1216 15.3861 25.6216 14.892 26.2355 14.892H38.3327C38.9466 14.892 39.4466 15.389 39.4466 16C39.4466 16.6139 38.9466 17.1109 38.3327 17.1109Z"
        fill={color || '#FFFFFF'}
      />
      <path
        d="M19.7176 17.1109H7.62037C7.00644 17.1109 6.50653 16.6139 6.50653 16C6.50653 15.3861 7.00644 14.892 7.62037 14.892H19.7176C20.3315 14.892 20.8314 15.389 20.8314 16C20.8314 16.6139 20.3315 17.1109 19.7176 17.1109Z"
        fill={color || '#FFFFFF'}
      />
      <path
        d="M31.8193 21.7621H26.2355C25.6216 21.7621 25.1246 21.2651 25.1246 20.6512C25.1246 20.0373 25.6245 19.5432 26.2355 19.5432H31.8164C32.4303 19.5432 32.9302 20.0402 32.9302 20.6512C32.9331 21.2651 32.4332 21.7621 31.8193 21.7621Z"
        fill={color || '#FFFFFF'}
      />
      <path
        d="M13.2042 21.7621H7.62037C7.00645 21.7621 6.50946 21.2651 6.50946 20.6512C6.50946 20.0373 7.00937 19.5432 7.62037 19.5432H13.2013C13.8152 19.5432 14.3151 20.0402 14.3151 20.6512C14.318 21.2651 13.8181 21.7621 13.2042 21.7621Z"
        fill={color || '#FFFFFF'}
      />
      <path
        d="M4.46994 32H40.5598C43.0214 32 45.0239 29.9974 45.021 27.533V4.46702C45.021 2.00256 43.0185 0 40.5569 0H33.198C32.5844 0 32.087 0.49737 32.087 1.11091C32.087 1.72445 32.5844 2.22182 33.198 2.22182H40.5598C41.7994 2.22182 42.808 3.2304 42.808 4.46702V27.533C42.808 28.7696 41.7965 29.7782 40.5598 29.7782H4.46994C3.2304 29.7782 2.22182 28.7696 2.22182 27.533V4.46702C2.22182 3.2304 3.2304 2.22182 4.46994 2.22182H27.7172C28.3307 2.22182 28.8281 1.72445 28.8281 1.11091C28.8281 0.49737 28.3307 0 27.7172 0H4.46994C2.00256 0 0 2.00256 0 4.46702V27.533C0 29.9974 2.00256 32 4.46994 32Z"
        fill={color || '#FFFFFF'}
      />
    </svg>
  )
}
