export const IconAccount = ({ width, height, color }) => {
  return (
    <svg
      width={width || '64'}
      height={height || '64'}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="32"
        cy="32"
        r="30.5"
        fill="white"
        stroke={color || '#A6ADB5'}
        strokeWidth="3"
      />
      <circle
        cx="31.9993"
        cy="20.9657"
        r="10.6379"
        stroke={color || '#A6ADB5'}
        strokeWidth="3"
      />
      <path
        d="M5.36523 46.3449C12.2946 40.8752 21.6742 37.5173 32.0003 37.5173C42.3264 37.5173 51.706 40.8752 58.6354 46.3449"
        stroke={color || '#A6ADB5'}
        strokeWidth="3"
      />
    </svg>
  )
}
