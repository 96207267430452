import { useEffect, useRef, useState } from 'react'

import Switch from 'components/Fields/Switch'
import Input from 'components/Fields/Input'
import Button from 'components/Button/index'

import { getPrice } from 'utils/getPrice'

import styles from './styles.module.scss'

export const ItemDealer = ({
  control,
  id,
  label,
  prefix,
  errors,
  setError,
  clearErrors,
  setDiscountsData,
  watch,
  setTotalPrice = () => {},
  active = false,
  discountBenefit = 0,
  setValue,
}) => {
  const [isActive, setIsActive] = useState(active)
  const [editMode, setEditMode] = useState(active)
  const [benefit, setBenefit] = useState(discountBenefit)

  const inputWatch = watch(id + '_input')

  const handlerSwitch = () => {
    if (isActive) {
      setEditMode(false)

      setDiscountsData((prev) => {
        let newState = prev.filter((el) => el.id !== id)

        return newState
      })

      setBenefit(0)
      setTotalPrice((prev) => prev + benefit)
    }

    setIsActive((prev) => !prev)
  }

  const handlerEdit = () => {
    setEditMode(false)
  }

  const handlerSave = () => {
    if (inputWatch || !!Number(inputWatch)) {
      setDiscountsData((prev) => {
        let newState = prev.filter((el) => el.id !== id)

        return [
          ...newState,
          {
            id: id,
            prefix: prefix,
            label: label,
            benefit: Number(inputWatch),
          },
        ]
      })

      setBenefit(Number(inputWatch))

      setTotalPrice((prev) => prev - Number(inputWatch))

      setEditMode(true)

      clearErrors(`${id + '_input'}`)
    } else {
      setError(`${id + '_input'}`, {
        type: `${id + '_input'}`,
        message: 'Это поле необходимо заполнить',
      })
    }
  }

  useEffect(() => {
    if (active) setValue(id + '_switch', true)
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <Switch
          id={id}
          name={id + '_switch'}
          control={control}
          change={handlerSwitch}
        />
        <p>{label}</p>
      </div>

      <div className={styles.right}>
        {isActive && editMode && (
          <div>
            <span>{getPrice(benefit)}</span>
            <Button.Outlined
              onClick={handlerEdit}
              children="Редактировать"
              className={styles.editBtn}
              disabled={!isActive}
            />
          </div>
        )}
        {isActive && !editMode && (
          <div>
            <Input
              control={control}
              type="number"
              name={id + '_input'}
              error={errors[id + '_input']}
              rules={{
                required: 'Это поле необходимо заполнить',
                validate: (v) => (v && v > 0 ? true : 'Это поле обязательно'),
              }}
              placeholder="Стоимость, ₽"
              className={styles.input}
            />
            <Button.Outlined onClick={handlerSave} children="добавить" />
          </div>
        )}
        {!isActive && <span>Включите выгоду чтобы добавить стоимость</span>}
      </div>
    </div>
  )
}
