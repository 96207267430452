import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Button from 'components/Button'
import Icon from 'components/Icon'
import Table from 'components/Table'
import SelectDefault from 'components/Fields/Select'
import Input from 'components/Fields/Input'
import { Container } from 'components/Container'
import { ModalAction } from 'components/ModalAction'
import { StatsItem } from 'components/StatsItem'
import { UserInfo } from 'components/UserInfo'
import { Notifications } from 'components/Notifications'

import { loadOrdersThunk } from 'store/orders/thunks'
import {
  getActiveOrders,
  getCompletedOrders,
  getOrdersCount,
} from 'store/orders/selectors'
import { getUserDealerId } from 'store/auth/selectors'
import { getAllCars } from 'store/cars/selectors'
import { loadAllCars } from 'store/cars/thunks'
import { loadAuthToken } from 'store/auth/slice'

import { getManagers, setNewManager } from 'api/managers'
import { setCarInShowroom } from 'api/cars'
import { getDealerData } from 'api/dealers'

import { statuses } from 'containers/TableChief/constats'
import { insertUrlParam } from 'utils/insertUrlParam'
import { getValuesWithSelect } from 'utils/getValuesWithSelect'

import style from './styles.module.scss'
import clsx from 'clsx'
import { getCookie } from 'utils/cookie'

export default function TableChief() {
  const [isShowModal, setIsShowModal] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [managers, setManagers] = useState([])
  const [currentOrderId, setCurrentOrderId] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentDealer, setCurrentDealer] = useState('')
  const [isOpenNotifications, setIsOpenNotifications] = useState({
    isOpen: false,
    currentTitle: '',
  })
  const [isFlag, setIsFlag] = useState(true)

  const dispatch = useDispatch()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const currentStatus = query.get('status')

  const { activeOrders, completedOrders, ordersAmount, cars, dealerId } =
    useSelector((state) => ({
      activeOrders: getActiveOrders(state),
      completedOrders: getCompletedOrders(state),
      ordersAmount: getOrdersCount(state),
      cars: getAllCars(state),
      dealerId: getUserDealerId(state),
    }))

  //Переменная для тестирования
  const isTestProfile = getCookie('isTestProfile')

  const correctStatus = (queryStatus) => {
    if (queryStatus === null) {
      return 2
    } else {
      if (queryStatus >= 0 && queryStatus < 7) {
        return parseInt(queryStatus)
      } else {
        return 2
      }
    }
  }

  const {
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      status: correctStatus(currentStatus),
      searchModels: 0,
    },
  })

  const checkData =
    (Array.isArray(ordersAmount) && ordersAmount?.length > 0) ||
    (Array.isArray(cars) && cars?.length > 0) ||
    (Array.isArray(managers) && managers?.length > 0)

  const watchStatus = watch('status')
  const watchManager = watch('manager')
  const watchSearch = watch('search') || ''
  const watchSearchModels = watch('searchModels') || ''

  const CarsForPlacing = useMemo(
    () =>
      Array.isArray(cars) && checkData
        ? cars?.filter((el) => {
            return el.showroom !== 'in'
          })
        : [],
    [cars]
  )

  const CarsPlaced = useMemo(
    () =>
      Array.isArray(cars) && checkData
        ? cars?.filter((el) => {
            return el.showroom === 'in'
          })
        : [],
    [cars]
  )
  const CarsWithBadge = useMemo(
    () =>
      Array.isArray(cars) && checkData
        ? cars?.filter((el) => {
            return el.in_bage === true
          })
        : [],
    [cars]
  )
  const CarsWithNoBadge = useMemo(
    () =>
      Array.isArray(cars) && checkData
        ? cars?.filter((el) => {
            return el.in_bage === false
          })
        : [],
    [cars]
  )

  const withBadgeModelsList = useMemo(
    () =>
      CarsWithBadge.reduce(
        (acc, el) => {
          if (
            acc.some((e) => e.label === el.family_name + ' ' + el.model_name)
          ) {
            return [...acc]
          }

          const elem = {
            label: el.family_name + ' ' + el.model_name,
            value: el.family_name + ' ' + el.model_name,
          }

          return [...acc, elem]
        },
        [{ value: 0, label: 'Все модели' }]
      ),
    [CarsWithBadge]
  )

  const withNoBadgeModelsList = useMemo(
    () =>
      CarsWithNoBadge.reduce(
        (acc, el) => {
          if (
            acc.some((e) => e.label === el.family_name + ' ' + el.model_name)
          ) {
            return [...acc]
          }

          const elem = {
            label: el.family_name + ' ' + el.model_name,
            value: el.family_name + ' ' + el.model_name,
          }

          return [...acc, elem]
        },
        [{ value: 0, label: 'Все модели' }]
      ),
    [CarsWithNoBadge]
  )

  //add object field path to add search by this field

  const searchFieldsOrder = [
    'family_slug',
    'id',
    'client_info.name',
    'model_name',
    'model_slug',
    'manager.fullname',
    'vin',
  ]
  const searchFieldsCar = [
    ['family_name', 'model_name'],
    'car_id',
    'modification.title',
    'vin',
    'complectation_code',
    'complectation',
  ]

  const setManagerForOrder = async () => {
    try {
      await setNewManager(currentOrderId, {
        manager_id: watchManager,
      })
      setIsSent((prev) => !prev)
      setIsShowModal(false)
    } catch (e) {
      console.warn(e)
    }
  }

  async function deleteCarFromShowroom(carId) {
    try {
      await setCarInShowroom(carId, { showroom: false })
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isTakeOff' })
      setIsSent((prev) => !prev)
    } catch {
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isError' })
    }
  }

  async function addCarToShowroom(carId) {
    try {
      await setCarInShowroom(carId, { showroom: true })
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isPosted' })
      setIsSent((prev) => !prev)
    } catch {
      setIsOpenNotifications({ isOpen: true, currentTitle: 'isError' })
    }
  }

  const requireAttention = 0
  const filteredManagers = getValuesWithSelect(managers, ['id', 'name'])

  function openModal(id) {
    setIsShowModal(true)
    setCurrentOrderId(id)
  }

  useEffect(() => {
    setValue('search', '')
  }, [watchStatus])

  useEffect(() => {
    async function fetchManagers() {
      try {
        const res = await getManagers()
        setManagers(res.data)
        setIsLoading(false)
      } catch (e) {
        console.warn(e)
      }
    }

    fetchManagers()
  }, [])

  useEffect(() => {
    async function fetchDealer() {
      try {
        const res = await getDealerData(dealerId, { showroom: false })
        setCurrentDealer(res.data.dealerName)
      } catch (e) {
        console.warn(e)
      }
    }

    if (dealerId) {
      fetchDealer()
    }
  }, [dealerId])

  function delayedRequest() {
    setTimeout(() => {
      setIsFlag((prev) => !prev)
    }, 300000)
  }

  useEffect(() => {
    delayedRequest()
  }, [isFlag])

  useEffect(() => {
    if (isFlag) {
      dispatch(loadOrdersThunk())
      dispatch(loadAllCars())
    }
  }, [isSent, isFlag])

  useEffect(() => {
    insertUrlParam('status', watchStatus)
  }, [watchStatus])

  useEffect(() => {
    if (dealerId === null) {
      dispatch(loadAuthToken())
    }
  }, [])

  return (
    <>
      <Notifications
        {...isOpenNotifications}
        setIsOpenNotifications={setIsOpenNotifications}
      />
      {!isLoading && checkData && (
        <>
          {isShowModal && (
            <ModalAction
              account
              withoutTitle
              description="Выберите менеджера на данный заказ"
              handleClose={() => setIsShowModal(false)}
            >
              <div className={style.modal}>
                <SelectDefault
                  label="Менеджер"
                  name="manager"
                  control={control}
                  options={filteredManagers}
                  error={errors['manager']}
                />
                <Button.Outlined onClick={setManagerForOrder}>
                  Назначить
                </Button.Outlined>
              </div>
            </ModalAction>
          )}

          <div className={style.background}>
            <Container className={style.container}>
              <h2 className={style.title} id="request">
                Реестр автомобилей
              </h2>
              <div className={style.userInfo}>
                <div className={style.selectType}>
                  <SelectDefault
                    name="status"
                    control={control}
                    orders
                    bold
                    hiddenLabel
                    isSearchable={false}
                    options={statuses}
                    bage
                  />
                </div>
                <UserInfo currentDealer={currentDealer} />
              </div>
              <div className={style.contentInfo}>
                <div className={style.textInfo}>
                  {watchStatus === 0 && (
                    <StatsItem
                      amount={activeOrders?.length || 0}
                      text="Всего заказов"
                    />
                  )}
                  {watchStatus === 0 && (
                    <StatsItem
                      bright
                      amount={requireAttention}
                      text="Требуют внимания"
                    />
                  )}
                  {watchStatus === 1 && (
                    <StatsItem
                      amount={completedOrders?.length || 0}
                      text="Всего заказов"
                    />
                  )}
                  {watchStatus === 2 && (
                    <StatsItem amount={cars.length} text="Всего автомобилей" />
                  )}
                  {watchStatus === 2 && (
                    <StatsItem
                      amount={CarsPlaced.length}
                      text="Всего размещено в онлайн шоуруме"
                    />
                  )}

                  {watchStatus === 3 && (
                    <StatsItem
                      amount={CarsPlaced.length}
                      text="Всего размещено"
                    />
                  )}
                </div>
                <div
                  className={clsx(style.actionsInfo, {
                    [style.actionsInfoSpaceBetween]: watchStatus === 4,
                  })}
                >
                  {watchStatus === 4 && (
                    <SelectDefault
                      name="searchModels"
                      control={control}
                      orders
                      bold
                      hiddenLabel
                      isSearchable={false}
                      options={withBadgeModelsList}
                      className={style.searchModelField}
                    />
                  )}
                  {watchStatus < 7 && (
                    <Button.Outlined to="/analytics">
                      <Icon.Analytics />
                      Аналитика
                    </Button.Outlined>
                  )}
                  <div className={style.search}>
                    <Input
                      label="Что найти?"
                      name="search"
                      control={control}
                      error={errors['search']}
                    />
                  </div>
                </div>
              </div>

              {watchStatus === 0 && (
                <Table.ActiveOrdersChief
                  data={activeOrders}
                  openModal={openModal}
                  watchSearch={watchSearch}
                  searchFieldsOrder={searchFieldsOrder}
                  watchStatus={watchStatus}
                />
              )}
              {watchStatus === 1 && (
                <Table.CompletedOrdersChief
                  data={completedOrders}
                  watchSearch={watchSearch}
                  searchFieldsOrder={searchFieldsOrder}
                  watchStatus={watchStatus}
                />
              )}
              {watchStatus === 2 && (
                <Table.CarsForPlacingChief
                  data={CarsForPlacing}
                  addCar={addCarToShowroom}
                  watchSearch={watchSearch}
                  searchFieldsCar={searchFieldsCar}
                  watchStatus={watchStatus}
                />
              )}
              {watchStatus === 3 && (
                <Table.CarsPlacedChief
                  data={CarsPlaced}
                  deleteCar={deleteCarFromShowroom}
                  watchSearch={watchSearch}
                  searchFieldsCar={searchFieldsCar}
                  watchStatus={watchStatus}
                />
              )}
              {watchStatus === 4 && (
                <Table.CarsForBadgeChief
                  data={cars}
                  noBadgeCars={cars}
                  watchSearch={watchSearch}
                  searchFieldsCar={searchFieldsCar}
                  watchStatus={watchStatus}
                  watchSearchModels={watchSearchModels}
                  withNoBadgeModelsList={withNoBadgeModelsList}
                />
              )}
              {watchStatus === 5 && (
                <Table.PersonalOffer watchSearch={watchSearch} />
              )}
              {watchStatus === 6 && (
                <Table.CoporateOffer watchSearch={watchSearch} />
              )}
            </Container>
          </div>
        </>
      )}
    </>
  )
}
