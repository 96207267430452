import clsx from 'clsx'

//import { SelectTabs } from 'components/SelectTabs'

import { TabItem } from './TabItem'

import styles from './styles.module.scss'

const Tabs = ({
  type = 'horizontal',
  tabs = [],
  active = 0,
  setActive = () => {},
  isGrey,
  className,
  classItem,
  rectangle,
  isMenu,
  mobilTabsSelect = false,
  isDarkStyled = false,
  familyName = '',
  isOrange,
  isBrightGrey,
  disabledTabs,
  overflowNone = false,
  mobilTabsSelectActive = false,
  name = 'years',
  putClass,
  additionalIndexForClass,
  classForSelect,
  disabledTabsIndex,
  valueWithIndex,
  mobilTabsFullSelectActive,
  metricEvent = () => {},
  onChange = () => {},
}) => {
  const isHorizontal = type === 'horizontal'

  const titleTabsSelect =
    mobilTabsSelect && (disabledTabs || disabledTabsIndex)
      ? tabs.map((el, index) =>
          typeof el === 'object'
            ? {
                label: el.name,
                value: el.id,
                disabled:
                  disabledTabs?.includes(el.name) ||
                  disabledTabsIndex?.includes(index),
              }
            : {
                label: el,
                value: index,
                disabled:
                  disabledTabs?.includes(el) ||
                  disabledTabsIndex?.includes(index),
              }
        )
      : tabs.map((el, index) =>
          typeof el === 'object'
            ? { label: el.name, value: el.id }
            : { label: el, value: index }
        )

  if (!tabs || tabs.length === 0) return null

  return (
    <>
      {/*{mobilTabsSelect && (*/}
      {/*  <SelectTabs*/}
      {/*    name={name}*/}
      {/*    active={active}*/}
      {/*    setActive={setActive}*/}
      {/*    titleTabsSelect={titleTabsSelect}*/}
      {/*    mobilTabsSelectActive={mobilTabsSelectActive}*/}
      {/*    isDarkStyled={isDarkStyled}*/}
      {/*    className={classForSelect}*/}
      {/*    valueWithIndex={valueWithIndex}*/}
      {/*  />*/}
      {/*)}*/}

      {overflowNone ? (
        <ul
          className={clsx({
            [styles.horizontalTabs]: isHorizontal,
            [styles.verticalTabs]: !isHorizontal,
            [styles.grey]: isGrey,
            [styles.tabsMobileActive]: mobilTabsSelectActive,
            [styles.tabsMobileFullActive]: mobilTabsFullSelectActive,
            [styles.orange]: isOrange,
            [styles.brightGrey]: isBrightGrey,
            [styles.menu]: isMenu,
            [styles.tabsMobile]: mobilTabsSelect,
            [styles.rectangledTabs]: rectangle,
            [className]: className,
          })}
        >
          {tabs.length > 0 &&
            tabs.map((el, i) => {
              let title = typeof el === 'string' ? el : el.name
              title = title && title.length > 0 ? title : familyName

              return (
                <TabItem
                  key={i}
                  setActive={setActive}
                  isActive={i === active}
                  title={title}
                  id={i}
                  disabled={disabledTabs && disabledTabs.includes(title)}
                  isMenu={isMenu}
                  putId={i}
                  putClass={putClass}
                  additionalIndexForClass={additionalIndexForClass}
                  disabledTabsIndex={disabledTabsIndex}
                  metricEvent={metricEvent}
                  onChange={onChange}
                />
              )
            })}
        </ul>
      ) : (
        <div className={styles.tabsWrapper}>
          <ul
            className={clsx({
              [styles.horizontalTabs]: isHorizontal,
              [styles.verticalTabs]: !isHorizontal,
              [styles.grey]: isGrey,
              [styles.tabsMobileActive]: mobilTabsSelectActive,
              [styles.tabsMobileFullActive]: mobilTabsFullSelectActive,
              [styles.orange]: isOrange,
              [styles.brightGrey]: isBrightGrey,
              [styles.menu]: isMenu,
              [styles.tabsMobile]: mobilTabsSelect,
              [styles.rectangledTabs]: rectangle,
              [className]: className,
            })}
          >
            {tabs.length > 0 &&
              tabs.map((el, i) => {
                let title = typeof el === 'string' ? el : el.name
                title = title && title.length > 0 ? title : familyName

                return (
                  <TabItem
                    key={i}
                    setActive={setActive}
                    isActive={i === active}
                    title={title}
                    id={i}
                    disabled={
                      (disabledTabs && disabledTabs.includes(title)) ||
                      (disabledTabsIndex && disabledTabsIndex.includes(i))
                    }
                    isMenu={isMenu}
                    putId={i}
                    putClass={putClass}
                    classItem={classItem}
                    metricEvent={metricEvent}
                    onChange={onChange}
                  />
                )
              })}
          </ul>
        </div>
      )}
    </>
  )
}

export default Tabs
