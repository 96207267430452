export const discountList = [
  {
    id: 53581,
    label: 'Программа трейд-ин',
    is_dealer: true,
    prefix: 'ti_d',
    priceBenefit: null,
  },
  {
    id: 53585,
    label: 'Прямая скидка',
    is_dealer: true,
    prefix: 'dd_d',
    priceBenefit: null,
  },
  {
    id: 53582,
    label: 'Программа кредитования',
    is_dealer: true,
    prefix: 'lf_d',
    priceBenefit: null,
  },
]
