import clsx from 'clsx'
import * as ReactDOM from 'react-dom'

import Icon from 'components/Icon'

import style from './styles.module.scss'

export const ModalAction = ({
  title,
  description,
  children,
  handleClose,
  className,
  modalClass,
  titleClass,
  textClass,
  closeClass,
  withoutTitle,
  account,
}) => {
  return ReactDOM.createPortal(
    <div className={clsx(style.container, className)}>
      <div className={style.overlay} onClick={handleClose} />
      <div
        className={clsx(style.modal, {
          [modalClass]: modalClass,
        })}
      >
        <div className={style.header}>
          <div className={clsx(style.close, closeClass)} onClick={handleClose}>
            <Icon.Close />
          </div>
          <div className={style.icon}>
            {account ? <Icon.Account /> : <Icon.Attention />}
          </div>
        </div>
        <h2
          className={clsx({
            [titleClass]: titleClass,
          })}
          dangerouslySetInnerHTML={{ __html: title }}
        ></h2>
        {description && (
          <div
            className={clsx(style.description, {
              [style.withoutTitle]: withoutTitle,
              [textClass]: textClass,
            })}
          >
            {' '}
            <p dangerouslySetInnerHTML={{ __html: description }}></p>{' '}
          </div>
        )}
        <div className={style.body}>{children}</div>
      </div>
    </div>,
    document.body
  )
}
