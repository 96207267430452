export function getAllValues({ options, specification }) {
  const technicalData = []
  const optionsData = []

  technicalData.push(specification.items)
  optionsData.push(options)

  return {
    allTechnicalValues: technicalData,
    allOptions: optionsData,
  }
}

export function getAllOptions(options, optionsGroup) {
  const data = []
  const allOptions = {}

  options.forEach((opt) => {
    if (!data.find(({ id }) => id === opt.id)) {
      data.push(opt)
    }
  })

  optionsGroup.forEach(({ id }) => {
    allOptions[id] = data
      .filter((opt) => opt.group === id)
      .map((option) => {
        const results = []
        options.forEach((value) => {
          results.push(value.find((v) => v.id === option.id) ? true : false)
        })
        return {
          ...option,
        }
      })
  })

  return allOptions
}
