export const IconEmail = ({ width, height, color }) => {
  return (
    <svg
      width={width || 25}
      height={height || 25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12" stroke={color || '#E37639'} />
      <path
        d="M16.9 16H8.1C7.49249 16 7 15.5523 7 15V8.9565C7.02563 8.42127 7.51069 7.99949 8.1 8H16.9C17.5075 8 18 8.44772 18 9V15C18 15.5523 17.5075 16 16.9 16ZM8.1 9.934V15H16.9V9.934L12.5 12.6L8.1 9.934ZM8.54 9L12.5 11.4L16.46 9H8.54Z"
        fill={color || '#E37639'}
      />
    </svg>
  )
}
