import clsx from 'clsx'

import styles from './styles.module.scss'

export const ButtonInverted = ({
  isWhite,
  children,
  disabled = false,
  type = 'button',
  to,
  onClick,
  className,
  target = '_self',
  icon,
}) => {
  if (to) {
    return (
      <a
        className={clsx(styles.btn, { [styles.isWhite]: isWhite }, className)}
        href={to}
        target={target}
      >
        <span className={styles.icon}>{icon}</span>
        {children}
      </a>
    )
  }

  return (
    <button
      type={type}
      disabled={disabled}
      className={clsx(styles.btn, { [styles.isWhite]: isWhite }, className)}
      onClick={onClick}
    >
      <span className={styles.icon}>{icon}</span>
      {children}
    </button>
  )
}
