export const IconDriverLicense = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 46}
      height={height || 32}
      viewBox="0 0 46 32"
      fill="none"
    >
      <path
        d="M5.83292 27.3458H5.80368C5.19268 27.3283 4.71032 26.8167 4.72201 26.2086C4.76001 24.7615 6.19835 23.1741 7.86471 22.7414L10.6332 22.0222C11.0074 21.9228 11.2354 21.6802 11.2354 21.5837V19.9729C11.2354 19.359 11.7353 18.862 12.3463 18.862C12.9573 18.862 13.4543 19.359 13.4543 19.9729V21.5837C13.4543 22.7589 12.5247 23.8202 11.1916 24.168L8.42016 24.8872C7.59575 25.1006 6.94675 25.9309 6.9409 26.2642C6.92921 26.8664 6.43515 27.3458 5.83292 27.3458Z"
        fill={color || '#FFFFFF'}
      />
      <path
        d="M24.4439 27.3459C23.8446 27.3459 23.3506 26.8664 23.333 26.2671C23.3243 25.9338 22.6811 25.1036 21.8567 24.8902L19.0853 24.171C17.7492 23.8231 16.8196 22.7619 16.8196 21.5867V19.9554C16.8196 19.3415 17.3195 18.8474 17.9276 18.8474C18.5415 18.8474 19.0414 19.3444 19.0414 19.9554V21.5867C19.0414 21.6832 19.2665 21.9258 19.6436 22.0252L22.4121 22.7444C24.0756 23.177 25.5168 24.7645 25.5548 26.2116C25.5665 26.8226 25.0842 27.3342 24.4702 27.3488H24.4439V27.3459Z"
        fill={color || '#FFFFFF'}
      />
      <path
        d="M15.1379 21.765C13.9159 21.765 9.37576 20.7886 9.37576 17.8593V16.4589L8.77061 15.8538C8.56304 15.6462 8.44611 15.3656 8.44611 15.0674V13.2081C8.44611 12.9128 8.56597 12.6292 8.77061 12.4217L9.37576 11.8136V10.4133C9.37576 7.5249 11.314 5.58081 14.2053 5.58081H16.0646C18.9559 5.58081 20.897 7.5249 20.897 10.4133V11.8136L21.5051 12.4217C21.7127 12.6292 21.8296 12.9099 21.8296 13.2081V15.0674C21.8296 15.3626 21.7127 15.6462 21.5051 15.8538L20.897 16.4589V17.8593C20.9 20.7886 16.3599 21.765 15.1379 21.765ZM10.665 14.6113L11.2731 15.2194C11.4806 15.424 11.5947 15.7076 11.5947 16.0058V17.8622C11.5947 18.6895 14.2258 19.5432 15.1379 19.5432C16.05 19.5432 18.6782 18.6895 18.6782 17.8622V16.0058C18.6782 15.7105 18.798 15.424 19.0027 15.2194L19.6078 14.6113V13.67L19.0027 13.0619C18.7951 12.8573 18.6782 12.5737 18.6782 12.2755V10.4162C18.6782 8.73521 17.7514 7.80263 16.0646 7.80263H14.2053C12.5243 7.80263 11.5917 8.73228 11.5917 10.4162V12.2755C11.5917 12.5708 11.4777 12.8573 11.2702 13.0619L10.6621 13.67V14.6113H10.665Z"
        fill={color || '#FFFFFF'}
      />
      <path
        d="M39.3327 12.4567H27.2354C26.6215 12.4567 26.1216 11.9598 26.1216 11.3458C26.1216 10.7319 26.6215 10.2379 27.2354 10.2379H39.3327C39.9466 10.2379 40.4465 10.7348 40.4465 11.3458C40.4465 11.9598 39.9466 12.4567 39.3327 12.4567Z"
        fill={color || '#FFFFFF'}
      />
      <path
        d="M39.3327 17.1109H27.2354C26.6215 17.1109 26.1216 16.6139 26.1216 16C26.1216 15.386 26.6215 14.892 27.2354 14.892H39.3327C39.9466 14.892 40.4465 15.389 40.4465 16C40.4465 16.6139 39.9466 17.1109 39.3327 17.1109Z"
        fill={color || '#FFFFFF'}
      />
      <path
        d="M32.8192 21.7621H27.2354C26.6215 21.7621 26.1245 21.2651 26.1245 20.6512C26.1245 20.0372 26.6244 19.5432 27.2354 19.5432H32.8163C33.4302 19.5432 33.9301 20.0402 33.9301 20.6512C33.9331 21.2651 33.4332 21.7621 32.8192 21.7621Z"
        fill={color || '#FFFFFF'}
      />
      <path
        d="M4.46994 32H40.5598C43.0214 32 45.0239 29.9974 45.021 27.533V4.46702C45.021 2.00256 43.0185 0 40.5569 0H33.198C32.5844 0 32.087 0.49737 32.087 1.11091C32.087 1.72445 32.5844 2.22182 33.198 2.22182H40.5598C41.7994 2.22182 42.808 3.2304 42.808 4.46702V27.533C42.808 28.7696 41.7965 29.7782 40.5598 29.7782H4.46994C3.2304 29.7782 2.22182 28.7696 2.22182 27.533V4.46702C2.22182 3.2304 3.2304 2.22182 4.46994 2.22182H27.7172C28.3307 2.22182 28.8281 1.72445 28.8281 1.11091C28.8281 0.49737 28.3307 0 27.7172 0H4.46994C2.00256 0 0 2.00256 0 4.46702V27.533C0 29.9974 2.00256 32 4.46994 32Z"
        fill={color || '#FFFFFF'}
      />
    </svg>
  )
}
