export const getComplectationInfo = (price, optionsPrice) => ({
  title: 'Комплектация',
  data: [
    { label: 'Базовая комплектация', price: price },
    optionsPrice > 0 ? { label: 'Сумма опций', price: optionsPrice } : {},
  ],
})

export const getColorInfo = ({ title, color, hexCode }) => ({
  title: 'Цвет',
  data: [{ label: 'Кузов', value: title, color: color || hexCode }],
})

export const getInfoPackages = (packages, carPackages) => {
  const res = carPackages.map((pack) => {
    const info = packages.find(({ id }) => id === pack)
    return {
      label: `Пакет опций ${info.title}`,
      price: info.price,
      credit: info.credit,
      deleteId: 1,
    }
  })

  return carPackages.length > 0
    ? {
        title: 'Опции комплектации',
        data: res,
      }
    : null
}

export const getCharacteristics = (rows = [], items = {}) => {
  return rows.map(({ title, fields }) => {
    const data = fields.map(({ title, name }) => ({
      label: title,
      value: items[name],
    }))
    return {
      title,
      data,
    }
  })
}

export const getEngineAndTransmissionInfo = (transmission, engine) => ({
  title: 'Двигатель и трансмиссия',
  engine_with: [1, 2, 3],
  data: [
    { label: 'Двигатель', value: engine },
    { label: 'Трансмиссия', value: transmission },
  ],
})
