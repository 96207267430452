import { useForm } from 'react-hook-form'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { useSelector } from 'react-redux'

import { Container } from 'components/Container'
import Switch from 'components/Fields/Switch'
import { colors } from 'constants/inlineColorConfig'
import Input from 'components/Fields/Input'
import Button from 'components/Button'
import Switcher from 'components/Switcher'
import SelectWithInput from 'components/Fields/SelectWithInput/index'

import { getFormattedPrice, getPrice } from 'utils/getPrice'
import { CREDIT_TYPES } from 'constants/creditTypes'

import { getUserRole } from 'store/auth/selectors'

import { ROLE } from 'constants/role'
import { fullAccess, partialAccess } from 'components/Table/buttons'

import { setOrderInfo } from 'api/orders'
import { updateInsuranceToCredit, updateTradeinToCredit } from 'api/credit'
import {
  getFinancialServices,
  addFinancialServices,
  deleteFinancialServices,
} from 'api/financialServices'

import { banksList } from './constants'
import { FinancialServices } from './FinancialServices/index'

import style from './styles.module.scss'

export const getMonthPrice = ({
  price,
  percent,
  period,
  first_payment = 0,
  residual = 0,
}) => {
  const amount = price - first_payment
  const stavka = percent / 100

  if (residual > 0) {
    const result = Math.floor(
      ((stavka / 12) *
        (amount * Math.pow(1 + stavka / 12, period - 1) -
          residual / (1 + stavka / 12))) /
        (Math.pow(1 + stavka / 12, period - 1) - 1)
    )
    return result ? result : 0
  }

  const result = Math.floor(
    ((stavka / 12) *
      (amount * Math.pow(1 + stavka / 12, period) -
        residual / (1 + stavka / 12))) /
      (Math.pow(1 + stavka / 12, period) - 1)
  )
  return result ? result : 0
}

/**
 * Check fields composition
 * @param fields
 * @return {function(*): boolean}
 */
function allFieldsIsSet(...fields) {
  return (checkCallback) =>
    fields.reduce((acc, field) => (acc ? checkCallback(field) : acc), true)
}

export const CreditProgram = ({
  data,
  order_id,
  setIsOpen,
  setCurrent,
  setData,
  isInputsActive = true,
  defaultPrice,
  defaultCreditPrice,
  isCreditActive,
  totalInsuranceValue,
  access,
  casco_credit,
  tradein_credit,
  dealerEvaluation,
  evaluation,
  fetchOrderInfo,
}) => {
  const [creditType, setCreditType] = useState(0)
  const handleCreditType = useCallback((id) => setCreditType(id), [])
  const [monthPayment, setMonthPayment] = useState(0)
  const [creditPrice, setCreditPrice] = useState(0)
  const [creditFirstPayment, setCreditFirstPayment] = useState(0)
  const [isActive, setIsActive] = useState(isCreditActive)
  const [isInsuranceToCredit, setIsInsuranceToCredit] = useState(casco_credit)
  const [isTradeitToCredit, setIsTradeinToCredit] = useState(tradein_credit)
  const [financialServices, setFinancialServices] = useState([])
  const [isSent, setIsSent] = useState(false)
  const [isInputDisabled, setIsInputDisabled] = useState(false)
  const { role } = useSelector((state) => ({ role: getUserRole(state) }))
  const buttons = role === ROLE.admin ? partialAccess : fullAccess
  const insuranceValue =
    dealerEvaluation || evaluation ? dealerEvaluation || evaluation : 0
  console.log(dealerEvaluation, 'dealerEvaluation')
  console.log(evaluation, 'evaluation')
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      creditProgram: isCreditActive,
      loan_amount: defaultCreditPrice,
      insuranceToCredit: casco_credit,
      tradeinToCredit: tradein_credit,
      price_tradein: insuranceValue,
    },
  })

  const watchCreditProgram = watch('creditProgram')
  const watchBank = watch('bank')

  const isDisabled = !watchCreditProgram || !isInputsActive || !access
  console.log(isDisabled, 'isDisabled')
  console.log(insuranceValue, 'insuranceValue')
  const watch_loan_amount = watch('loan_amount')
  const watch_loan_rate = watch('loan_rate')
  const watch_first_payment = watch('first_payment')
  const watch_loan_period = watch('loan_period')
  const watch_loan_residual_payment = watch('residual')
  const watch_insurance_switcher = watch('insuranceToCredit')
  const watch_tradein_swicther = watch('tradeinToCredit')
  const watch_tradein_price = watch('price_tradein')
  const buttonRef = useRef(null)

  useEffect(() => {
    const fetchInsuranceToCredit = async () => {
      await updateInsuranceToCredit(order_id, watch_insurance_switcher)

      setIsInsuranceToCredit(
        watch_insurance_switcher ? watch_insurance_switcher : false
      )
    }

    fetchInsuranceToCredit()
  }, [watch_insurance_switcher])

  useEffect(() => {
    const fetchTradeinToCredit = async () => {
      await updateTradeinToCredit(order_id, watch_tradein_swicther)
      setIsTradeinToCredit(watch_tradein_swicther)
    }

    fetchTradeinToCredit()
  }, [watch_tradein_swicther])

  useEffect(() => {
    if (watch_loan_rate === '__,__%') {
      setValue('loan_rate', '__,00%')
    }
  }, [watch_loan_rate])

  const onChange = async () => {
    try {
      await setOrderInfo(order_id, {
        credit: isActive ? false : true,
      })
    } catch (e) {
      console.log(e)
    }
    setIsActive((prev) => !prev)
  }

  // Credit calculation
  useEffect(() => {
    const summOfServices = financialServices.length
      ? financialServices.reduce((acc, service) => acc + service.price, 0)
      : financialServices
    const loanRateFormated = String(watch_loan_rate).replace(',', '.')
    const currentEvaluation =
      dealerEvaluation || evaluation ? dealerEvaluation || evaluation : 0
    const currentFIrstPaymentInput = watch_first_payment
      ? watch_first_payment
      : 0

    const currentTotalPrice = isInsuranceToCredit
      ? Number(defaultPrice) + Number(insuranceValue) + Number(summOfServices)
      : Number(defaultPrice) + Number(summOfServices)

    const totalFirstPayment =
      isTradeitToCredit && (evaluation || dealerEvaluation)
        ? Number(currentEvaluation) + Number(currentFIrstPaymentInput)
        : Number(currentFIrstPaymentInput)

    const loanRateNumber = Number(
      loanRateFormated.substr(0, loanRateFormated.length - 1)
    )

    totalFirstPayment
      ? setValue(
          'loan_amount',
          currentTotalPrice - totalFirstPayment < 0
            ? 0
            : currentTotalPrice - totalFirstPayment
        )
      : setValue('loan_amount', currentTotalPrice)

    setCreditPrice(
      currentTotalPrice < 0
        ? 0
        : currentTotalPrice > defaultPrice
        ? defaultPrice
        : currentTotalPrice
    )
    setCreditFirstPayment(totalFirstPayment)

    switch (creditType) {
      case CREDIT_TYPES.default:
        if (
          allFieldsIsSet(
            watch_loan_amount,
            watch_loan_period
          )((value) => !!value)
        )
          setMonthPayment(
            getMonthPrice({
              price: currentTotalPrice,
              percent: loanRateNumber,
              period: Number(watch_loan_period),
              first_payment: Number(totalFirstPayment) || 0,
              residual: Number(0),
            })
          )
        break
      case CREDIT_TYPES.residualPayment:
        if (
          allFieldsIsSet(
            watch_loan_amount,
            watch_loan_period,
            watch_loan_residual_payment
          )((value) => !!value)
        )
          setMonthPayment(
            getMonthPrice({
              price: currentTotalPrice,
              percent: loanRateNumber,
              period: Number(watch_loan_period),
              residual: Number(watch_loan_residual_payment),
              first_payment: Number(totalFirstPayment) || 0,
            })
          )
        break
      default:
    }
  }, [
    watch_loan_amount,
    watch_loan_rate,
    watch_first_payment,
    watch_loan_period,
    watch_loan_residual_payment,
    watch_tradein_price,
    isInsuranceToCredit,
    isTradeitToCredit,
    creditType,
    financialServices,
  ])

  useEffect(() => {
    if (String(watchBank) === '0') {
      setIsInputDisabled(true)
    } else {
      setIsInputDisabled(false)
    }
  }, [watchBank])

  useEffect(() => {
    if (insuranceValue === 0) {
      setValue('insuranceToCredit', false)
    }
  }, [insuranceValue])

  useEffect(() => {
    if (!casco_credit) {
      setValue('tradeinToCredit', false)
    }
  }, [casco_credit])

  useEffect(() => {
    if (defaultCreditPrice > 0) {
      setValue('loan_amount', defaultCreditPrice)
    }
  }, [defaultCreditPrice, isActive])

  useEffect(() => {
    setValue('price_tradein', dealerEvaluation || evaluation)
  }, [dealerEvaluation, evaluation])

  if (!data) {
    return null
  }

  const handleClick = (val) => {
    delete val.insuranceToCredit
    delete val.tradeinToCredit

    const data = {
      ...val,
      loan_rate: val.loan_rate.replace(',', '.').replace('%', ''),
      month_payment: monthPayment,
      loan_payment: creditPrice - creditFirstPayment,
      order_id: order_id,
      residual: val.residual ? val.residual : 0,
    }

    setCurrent('credit')
    setData(data)
    setIsOpen(true)
  }

  const getFinServices = async () => {
    try {
      const res = await getFinancialServices()
      setFinancialServices(res.data)
    } catch (e) {
      console.warn(e)
    }
  }

  const updateFinServices = async () => {
    try {
      const res = await getFinancialServices(order_id)
      setFinancialServices(res.data)
      fetchOrderInfo()
    } catch (e) {
      console.warn(e)
    }
  }

  const addService = async ({ service, price }) => {
    try {
      await addFinancialServices({
        order_id: order_id,
        'finance-services': [
          {
            name: service,
            price: price,
          },
        ],
      })
      setIsSent((prev) => !prev)
      fetchOrderInfo()
    } catch (e) {
      console.log(e)
    }
  }

  const deleteFinService = async (name, price) => {
    if (isInputsActive) {
      try {
        await deleteFinancialServices({
          order_id: order_id,
          'finance-services': [
            {
              name: name,
              price: price,
            },
          ],
        })

        setIsSent((prev) => !prev)
        fetchOrderInfo()
      } catch (e) {
        console.log(e)
      }
    }
  }

  const fetchFinServices = async () => {
    try {
      const res = await getFinancialServices()
      setFinancialServices(res.data)
    } catch (e) {
      console.warn(e)
    }
  }

  const handleFakeFormSubmit = () => {
    buttonRef.current.click()
  }

  return (
    <div className={style.background}>
      <Container className={style.container}>
        <div className={style.titleWithButton}>
          <h2 className={style.title}>Кредитная программа</h2>
          <div className={style.switch}>
            <Switch
              id="creditProgram"
              name="creditProgram"
              control={control}
              activeColor={colors.brightOrange}
              disabled={!isInputsActive || !access}
              change={onChange}
            />
            <div className={style.text}>
              <span>Использовать</span>
              <span>программу</span>
            </div>
          </div>
        </div>
        {isActive && (
          <>
            <div className={style.formBlock}>
              {/* <div className={style.formTitle}>Изменить условия</div> */}
              <Switcher
                selections={[
                  'Классический кредит',
                  'Кредит с остаточным платежом',
                ]}
                value={creditType}
                setter={handleCreditType}
                style={{ margin: '10px 0' }}
              />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <div className={style.form}>
                <div className={style.row}>
                  <div className={style.select}>
                    <SelectWithInput
                      label="Наименование банка"
                      name="bank"
                      required
                      rules={{
                        required: 'Это поле обязательно',
                        validate: (v) =>
                          v && v !== '0' ? true : 'Это поле обязательно',
                      }}
                      control={control}
                      options={banksList}
                      error={errors['bank']}
                      isDisabled={isDisabled}
                      isSearchable={isInputDisabled}
                    />
                  </div>
                  <Input
                    label="Первый взнос"
                    name="first_payment"
                    required
                    rules={{ required: 'Это поле обязательно' }}
                    control={control}
                    type="number"
                    error={errors['first_payment']}
                    disabled={isDisabled}
                    className={style.input}
                  />
                  <Input
                    label="Сумма оценки автомобиля по Trade-In"
                    name="price_tradein"
                    // required
                    // rules={{ required: 'Это поле обязательно' }}
                    control={control}
                    type="number"
                    error={errors['price_tradein']}
                    disabled={true}
                  />
                </div>
                <div className={clsx(style.row)}>
                  <Input
                    label="Итоговая стоимость автомобиля"
                    name="loan_amount"
                    // required
                    // rules={{ required: 'Это поле обязательно' }}
                    control={control}
                    type="number"
                    error={errors['loan_amount']}
                    disabled={true}
                  />
                  <Input
                    label="Процентная ставка"
                    name="loan_rate"
                    mask="99,99%"
                    required
                    rules={{
                      required: 'Это поле обязательно',
                      pattern: {
                        value: /([0-9]{2}),([0-9]{2})%/,
                        message: 'Необходимо заполнить поле',
                      },
                    }}
                    control={control}
                    error={errors['loan_rate']}
                    disabled={isDisabled}
                  />
                  <Input
                    label="Срок кредита в месяцах"
                    name="loan_period"
                    required
                    rules={{ required: 'Это поле обязательно' }}
                    control={control}
                    type="number"
                    error={errors['loan_period']}
                    disabled={isDisabled}
                  />
                  {creditType === CREDIT_TYPES.residualPayment && (
                    <Input
                      label="Остаточный платеж"
                      name="residual"
                      required
                      rules={{ required: 'Это поле обязательно' }}
                      control={control}
                      type="number"
                      error={errors['loan_payment']}
                      disabled={isDisabled}
                    />
                  )}
                </div>
              </div>
              <div className={style.switcherBlock}>
                <div className={style.switchGroup}>
                  <Switch
                    id="insuranceToCredit"
                    name="insuranceToCredit"
                    control={control}
                    activeColor={colors.brightOrange}
                    disabled={isDisabled || insuranceValue === 0}
                  />
                  <p>Добавить КАСКО в кредит </p>
                </div>
                <div className={style.switchGroup}>
                  <Switch
                    id="tradeinToCredit"
                    name="tradeinToCredit"
                    control={control}
                    activeColor={colors.brightOrange}
                    disabled={isDisabled || Number(watch_tradein_price) < 1}
                  />
                  <p>
                    Использовать сумму оценки автомобиля в Trade-in –{' '}
                    {getFormattedPrice(dealerEvaluation || evaluation)} ₽
                  </p>
                </div>
              </div>
              {!!buttons.access && (
                <button
                  type="submit"
                  style={{ display: 'none' }}
                  ref={buttonRef}
                ></button>
                // <Button.Default ref={buttonRef} disabled={isDisabled} type="submit" style={{display: 'none'}}>
                //   Рассчитать кредит
                // </Button.Default>
              )}
            </form>
            <FinancialServices
              data={order_id?.additional_services}
              isInputsActive={isInputsActive}
              access={access}
              financialServices={financialServices}
              getFinancialServices={getFinServices}
              updateFinancialServices={updateFinServices}
              addService={addService}
              deleteFinancialService={deleteFinService}
              fetchFinancialServices={fetchFinServices}
              isSent={isSent}
            />
            <div className={style.action}>
              <Button.Default
                disabled={isDisabled || !buttons.access}
                onClick={handleFakeFormSubmit}
              >
                Рассчитать кредит
              </Button.Default>
            </div>
            <div className={style.loanAmount}>Итог расчета</div>
            <div className={style.creditMainInfo}>
              <p>
                Наименование банка: <span>{data.bank}</span>
              </p>
              <p>
                Тип кредитования:{' '}
                <span>
                  {data.residual > 0 ? 'С остаточным платежом' : 'Кредит'}
                </span>
              </p>
            </div>
            <div className={style.creditInfo}>
              <div
                className={clsx(style.creditOption, {
                  [style.creditOptionResidual]: data?.residual,
                })}
              >
                <span className={style.title}>Ежемесячный платеж</span>
                <span className={style.infoMonth}>
                  {getFormattedPrice(data?.monthlyPayment)} ₽
                </span>
              </div>

              <div
                className={clsx(style.creditOption, {
                  [style.creditOptionResidual]: data?.residual,
                })}
              >
                <span className={style.title}>
                  Итоговая стоимость автомобиля
                </span>
                <span className={style.infoMonth}>
                  {getFormattedPrice(data?.loanPayment)} ₽
                </span>
              </div>

              {!!data?.residual && (
                <div
                  className={clsx(style.creditOption, {
                    [style.creditOptionResidual]: data?.residual,
                  })}
                >
                  <span className={style.title}>Остаточный платеж</span>
                  <span className={style.info}>
                    {getFormattedPrice(data?.residual)} ₽
                  </span>
                </div>
              )}

              <div
                className={clsx(style.creditOption, {
                  [style.creditOptionResidual]: data?.residual,
                })}
              >
                <span className={style.title}>Первый взнос</span>
                <span className={style.info}>
                  {getFormattedPrice(data?.firstPayment)} ₽
                </span>
              </div>

              <div
                className={clsx(style.creditOption, {
                  [style.creditOptionResidual]: data?.residual,
                })}
              >
                <span className={style.title}>Процентная ставка</span>
                <span className={style.info}>{data?.percentageRate}%*</span>
              </div>

              <div
                className={clsx(style.creditOption, {
                  [style.creditOptionResidual]: data?.residual,
                })}
              >
                <span className={style.title}>Срок кредита</span>
                <span className={style.info}>{data?.loanPeriod} мес</span>
              </div>
            </div>
          </>
        )}
      </Container>
    </div>
  )
}
