export function insertUrlParam(key, value) {
  if (window.history.pushState) {
    let searchParams = new URLSearchParams(window.location.search)
    searchParams.set(key, value)
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString()
    window.history.pushState({ path: newurl }, '', newurl)
  }
}

export function newUrlParam(pathName, key, value, withKey = false) {
  if (window.history.pushState) {
    let searchParams = new URLSearchParams(window.location.search)

    if (key !== undefined && !withKey) {
      searchParams.delete(key)
    } else {
      searchParams.set(key, value)
    }

    let getParams = withKey ? '?' + searchParams.toString() : ''

    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      pathName +
      getParams
    window.history.pushState({ path: newurl }, '', newurl)
  }
}
