export const paymentTypes = [
  {
    value: 'Лизинг',
    label: 'Лизинг',
  },
  {
    value: 'Безналичный расчёт',
    label: 'Безналичный расчёт',
  },
]
