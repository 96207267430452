import { createSelector } from '@reduxjs/toolkit'

export const getDealers = (state) => state.dealers

export const getAllDealers = createSelector(
  getDealers,
  (dealers) => dealers.items
)

export const getDealersById = createSelector(
  getDealers,
  (dealers) => dealers.dealersById
)

export const getDealersLoadingStatus = createSelector(
  getDealers,
  (dealers) => dealers.loadingStatus
)
