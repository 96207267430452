import { request } from 'utils/request'

export async function getOrders() {
  return request.get(`/api/os-manager-orders`)
}

export async function getOrder(id) {
  return request.get(`/api/os-manager-orders/${id}`)
}

export async function setOrderInfo(id, data) {
  return request.put(`/api/os-manager-orders/${id}`, {
    ...data,
  })
}

export async function deleteOrderById(data) {
  return request.delete(`/api/os-manager-orders`, {
    data: { ...data },
  })
}
