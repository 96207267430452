export const IconYt = ({ width, height, color }) => {
  return (
    <svg
      width={width || 24}
      height={height || 17}
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5199 16.7616H10.1093H8.67262C4.95018 16.6833 2.48162 16.618 1.44979 15.5861C0.417957 14.5543 0 13.8229 0 8.45473C0 3.08659 0.522447 2.28986 1.44979 1.36252C2.37713 0.435172 4.95018 0.265377 8.67262 0.134766H10.1093C11.0497 0.134766 11.9902 0.134766 12.9306 0.134766H14.3673C18.0897 0.213133 20.5452 0.278438 21.5771 1.31027C22.6089 2.3421 23.0268 3.02128 23.0268 8.40248C23.0268 13.7837 22.5044 14.5543 21.5771 15.4816C20.6497 16.409 18.0767 16.5788 14.3542 16.6571H12.9175H11.5199V16.7616ZM11.5199 1.31027H10.1355H8.69874C5.62936 1.44088 2.97795 1.44088 2.27264 2.19843C1.56734 2.95598 1.17551 3.29557 1.17551 8.45473C1.17551 13.6139 1.65877 14.0841 2.27264 14.698C2.88652 15.3118 5.62936 15.4686 8.69874 15.5339H10.1355C11.0628 15.5339 11.9771 15.5339 12.9044 15.5339H14.3412C17.4105 15.5339 20.062 15.4033 20.7673 14.698C21.4726 13.9927 21.8644 13.6008 21.8644 8.45473C21.8644 3.30863 21.3811 2.8123 20.7673 2.19843C20.1534 1.58456 17.4105 1.44088 14.3673 1.36252H12.9306H11.5199"
        fill={color || '#848E98'}
      />
      <path
        d="M11.5199 16.7616H10.1093H8.67262C4.95018 16.6833 2.48162 16.618 1.44979 15.5861C0.417957 14.5543 0 13.8229 0 8.45473C0 3.08659 0.522447 2.28986 1.44979 1.36252C2.37713 0.435172 4.95018 0.265377 8.67262 0.134766H10.1093C11.0497 0.134766 11.9902 0.134766 12.9306 0.134766H14.3673C18.0897 0.213133 20.5452 0.278438 21.5771 1.31027C22.6089 2.3421 23.0268 3.02128 23.0268 8.40248C23.0268 13.7837 22.5044 14.5543 21.5771 15.4816C20.6497 16.409 18.0767 16.5788 14.3542 16.6571H12.9175H11.5199V16.7616ZM11.5199 1.31027H10.1355H8.69874C5.62936 1.44088 2.97795 1.44088 2.27264 2.19843C1.56734 2.95598 1.17551 3.29557 1.17551 8.45473C1.17551 13.6139 1.65877 14.0841 2.27264 14.698C2.88652 15.3118 5.62936 15.4686 8.69874 15.5339H10.1355C11.0628 15.5339 11.9771 15.5339 12.9044 15.5339H14.3412C17.4105 15.5339 20.062 15.4033 20.7673 14.698C21.4726 13.9927 21.8644 13.6008 21.8644 8.45473C21.8644 3.30863 21.3811 2.8123 20.7673 2.19843C20.1534 1.58456 17.4105 1.44088 14.3673 1.36252H12.9306H11.5199"
        fill={color || '#848E98'}
      />
      <path
        d="M9.1428 12.5684C9.03708 12.5701 8.93207 12.5508 8.83389 12.5115C8.73572 12.4722 8.64634 12.4138 8.57096 12.3397C8.49559 12.2655 8.43573 12.1771 8.39488 12.0796C8.35403 11.982 8.33299 11.8774 8.33301 11.7716V5.07125C8.33299 4.96552 8.35403 4.86083 8.39488 4.76331C8.43573 4.66578 8.49559 4.57737 8.57096 4.50321C8.64634 4.42905 8.73572 4.37064 8.83389 4.33138C8.93207 4.29212 9.03708 4.27279 9.1428 4.27452C9.28001 4.27666 9.41458 4.31255 9.53464 4.37901L15.5428 7.72267C15.6693 7.79078 15.7743 7.89293 15.8458 8.01756C15.9174 8.14219 15.9527 8.28435 15.9477 8.42797C15.9474 8.56885 15.9098 8.70714 15.8387 8.82875C15.7675 8.95036 15.6654 9.05093 15.5428 9.12021L9.58688 12.4639C9.46931 12.5363 9.33307 12.5726 9.19504 12.5684H9.1428ZM9.5477 5.71125V11.1447L14.3673 8.42797L9.5477 5.71125Z"
        fill={color || '#848E98'}
      />
    </svg>
  )
}
