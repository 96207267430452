import clsx from 'clsx'

import React, { lazy, useEffect, useReducer, useState } from 'react'
import { Preloader } from 'components/Preloader'
import Button from 'components/Button'

import { useSearch } from 'hooks/useSearch'

import {
  initialState,
  reducer,
} from 'components/Table/CoporateOffer/AddCarModal/reducer'
import { Notifications } from 'components/Notifications'

import { getAll, getFamiliesData } from 'components/Table/CoporateOffer/actions'

import style from './styles.module.scss'

const searchFields = [
  'company',
  'offer_id',
  'model_name',
  'complectation_code',
  'phone',
  'lastName',
  'name',
  'middleName',
]

export default function CorporateOffer({ watchSearch }) {
  const [formState, dispatch] = useReducer(reducer, initialState)

  const [list, setList] = useState([])

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingModal, setIsLoadingModal] = useState(true)

  const [currentData, setCurrentData] = useState([])
  const [currentPage, setCurrentPage] = useState({ current: 1, amount: 10 })

  const [isOpenAddCarModal, setIsOpenAddCarModal] = useState(false)

  const [isOpenNotifications, setIsOpenNotifications] = useState({
    isOpen: false,
    currentTitle: '',
  })

  const List = lazy(() => import('./List'))
  const AddCarModal = lazy(() => import('./AddCarModal'))

  const filteredCars = useSearch(list, watchSearch, searchFields, 500, false)

  // const deleteHandler = async (id) => {
  //     await deleteBadgeById(id)
  //
  //     setCurrentData((prev) => prev.filter((el) => el.car_id !== id))
  // }

  useEffect(() => {
    getAll()
      .then((res) => setList(res))
      .catch((err) => setList([]))
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    const startIndex = 0
    const endIndex = currentPage.amount * currentPage.current
    const formattedData = filteredCars.filteredData.slice(startIndex, endIndex)

    setCurrentData(formattedData)
  }, [currentPage, filteredCars.filteredData, watchSearch])

  useEffect(() => {
    if (isOpenAddCarModal) {
      getFamiliesData()
        .then((res) => dispatch({ type: 'CHANGE_DATA', payload: res }))
        .finally(() => setIsLoadingModal(false))
    }
  }, [isOpenAddCarModal])

  return (
    <>
      <Notifications
        {...isOpenNotifications}
        setIsOpenNotifications={setIsOpenNotifications}
      />
      {isOpenAddCarModal && (
        <React.Suspense fallback={<LoadingModal />}>
          {!isLoadingModal ? (
            <AddCarModal handleClose={() => setIsOpenAddCarModal(false)} />
          ) : (
            <LoadingModal />
          )}
        </React.Suspense>
      )}
      <h2 className={style.title}>Сформированные корпоративные КП</h2>
      <Button.Outlined
        className={style.addCarButton}
        onClick={() => setIsOpenAddCarModal(true)}
      >
        {' '}
        Сформировать КП
      </Button.Outlined>
      <React.Suspense fallback={<Loading />}>
        {!isLoading ? (
          <List
            list={currentData}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setIsOpenModal={setIsOpenAddCarModal}
            setIsOpenNotifications={setIsOpenNotifications}
          />
        ) : (
          <Loading />
        )}
      </React.Suspense>
    </>
  )
}

function Loading() {
  return (
    <div className={style.preloader}>
      <Preloader.Spinner />
    </div>
  )
}

function LoadingModal() {
  return (
    <div className={style.overlay}>
      <div className={clsx(style.preloader, style.display)}>
        <Preloader.Spinner />
      </div>
    </div>
  )
}
