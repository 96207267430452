export const IconAnalytics = ({ width, height, color }) => {
  return (
    <svg
      width={width || '14'}
      height={height || '15'}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="3.41675"
        width="4.08333"
        height="11.0833"
        fill={color || '#E37639'}
      />
      <rect
        x="9.91602"
        y="8.66675"
        width="4.08333"
        height="5.83333"
        fill={color || '#E37639'}
      />
      <rect
        x="4.66602"
        y="0.5"
        width="4.66667"
        height="14"
        fill={color || '#E37639'}
      />
    </svg>
  )
}
