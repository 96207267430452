import { useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import clsx from 'clsx'
import { Controller } from 'react-hook-form'
import Select, { components } from 'react-select'

import styles from './styles.module.scss'

/**
 * @param isHiddenInputValue {boolean}  скрывает значение slect при открытом меню
 */

const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      <Scrollbars
        autoHeight={true}
        autoHide={false}
        universal={true}
        renderThumbVertical={({ style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: '4px',
              cursor: 'pointer',
              zIndex: 10,
            }}
          />
        )}
      >
        {props.children}
      </Scrollbars>
    </components.MenuList>
  )
}

const SelectDefault = ({
  label,
  name,
  control,
  change,
  isDisabled,
  options = [],
  isMulti,
  isClearable,
  isSearchable = true,
  fullwidth,
  isSpecSeries,
  defaultValue,
  isDarkStyled,
  rules = {},
  error,
  className,
  wrapClass,
  isHiddenInputValue,
  closeMenuOnSelect = true,
  hiddenLabel,
  bold,
  bage,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const MultiValue = (props) => (
    <components.MultiValueContainer {...props}>
      {props.index === 0 && (
        <span className={clsx({ [options.id + '_' + className]: className })}>
          {label}: {props.selectProps.value.length}
        </span>
      )}
    </components.MultiValueContainer>
  )

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || null}
      rules={rules}
      render={({ onChange, value }) => {
        return (
          <div
            className={clsx(styles.select__wrap, {
              [wrapClass]: wrapClass,
            })}
            data-test-name={name}
          >
            <span
              className={clsx({
                [styles.select__label]: true,
                [styles.select__label_focused]:
                  isFocused ||
                  (value &&
                    ((typeof value === 'object' &&
                      Object.keys(value).length > 0) ||
                      (typeof value === 'string' && value.length > 0) ||
                      typeof value === 'number')),
                [styles.hideLabel]: isMulti && value && value?.length !== 0,
              })}
            >
              {label}
            </span>
            <Select
              value={
                typeof value === 'string' || typeof value === 'number'
                  ? options.reduce(
                      (acc, option) => (option.value === value ? option : acc),
                      {}
                    )
                  : value
              }
              className={clsx(
                isMulti
                  ? 'react-multi-select-container'
                  : `react-select-container${
                      isSpecSeries
                        ? '-spec'
                        : isDarkStyled
                        ? '-dark'
                        : bage
                        ? '-bage'
                        : ''
                    }`,
                { [className]: className }
              )}
              classNamePrefix={isMulti ? 'react-multi-select' : 'react-select'}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onClick={handleBlur}
              onChange={(e) => {
                change && change(e)
                onChange(e && !isMulti ? e.value : e)
                setIsFocused(false)
              }}
              name={name}
              closeMenuOnScroll={true}
              isMulti={isMulti}
              options={options}
              isSearchable={isSearchable}
              isClearable={isClearable}
              menuPlacement="auto"
              placeholder={<></>}
              isDisabled={isDisabled}
              backspaceRemovesValue={false}
              hideSelectedOptions={false}
              components={{ MultiValue, MenuList }}
              captureMenuScroll={false}
              instanceId={name}
              closeMenuOnSelect={closeMenuOnSelect}
              styles={{
                control: (style, { hasValue }) => ({
                  ...style,
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  borderColor:
                    isMulti && hasValue
                      ? '#E37639!important'
                      : '#d9dcdc!important',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  width: fullwidth ? '100vw' : undefined,
                  marginTop: hiddenLabel && 10,
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 50,
                  borderRadius: 0,
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  transition: '.5s transform',
                  transform: isFocused ? 'rotate(180deg)' : undefined,
                  color: isFocused ? '#E37639' : '#848E98',
                }),
                singleValue: (provided, { selectProps }) => {
                  return {
                    ...provided,
                    color: '#66727D',
                    fontSize: '16px',
                    lineHeight: '21px',
                    fontWeight: bold && 'bold',
                    opacity:
                      isHiddenInputValue && selectProps.menuIsOpen ? 0 : 1,
                  }
                },
              }}
            />
            {error && <div className={styles.error}> {error.message} </div>}
          </div>
        )
      }}
    />
  )
}

export default SelectDefault
