import React, { useEffect, useState } from 'react'

import Calendar from 'containers/TableSupervisor/Calendar'

import Icon from 'components/Icon'

import { getDateReportFromLs, removeDateReportToLs } from 'utils/localStorage'
import {
  getAmountCarForPlaceByDirectSale,
  getAmountCarPlacedByDirectSale,
  getAmountOrdersByDirectSale,
} from 'api/reports'

import style from '../styles.module.scss'

export default function DirectSale({ isMobile }) {
  const [isOpenCalendar, setIsOpenCalendar] = useState(false)
  const [selectedDate, setSelectedDate] = useState(
    getDateReportFromLs('date_report_di-sa') || null
  )
  const [amountCarsData, setAmountCarsData] = useState({
    forPlace: '',
    placed: '',
  })
  const [activeOrderCount, setActiveOrderCount] = useState('')

  const handleOpenCalendar = () => {
    setIsOpenCalendar(true)
  }

  const handleResetDate = (e) => {
    e.stopPropagation()
    let link = document.getElementById('orders_DS')

    link.removeAttribute('href')
    removeDateReportToLs('date_report_di-sa')
    setSelectedDate(null)
    setIsOpenCalendar(false)
  }

  const handleSetReport = () => {
    if (selectedDate === null) {
      handleOpenCalendar()
    }
  }

  async function fetchAmountCars() {
    try {
      const amountCarsForPlace = await getAmountCarForPlaceByDirectSale()
      const amountCarsPlaced = await getAmountCarPlacedByDirectSale()

      setAmountCarsData({
        forPlace: amountCarsForPlace.data.success,
        placed: amountCarsPlaced.data.success,
      })
    } catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    if (selectedDate !== null) {
      async function getOrderData() {
        try {
          const count = await getAmountOrdersByDirectSale(
            selectedDate.startDate,
            selectedDate.endDate
          )

          setActiveOrderCount(count.data.success)
        } catch (e) {
          console.log(e)
        }
        let link = document.getElementById('orders_DS')
        link.setAttribute(
          'href',
          `https://v6.lada.ru/api/os-reports/orders/${selectedDate.startDate}/date-from/${selectedDate.endDate}/date-to/direct_sale/type`
        )
      }

      getOrderData()
    } else {
      setActiveOrderCount('')
    }
  }, [selectedDate])

  useEffect(() => {
    fetchAmountCars()
  }, [])

  return (
    <div>
      <div className={style.block}>
        {isOpenCalendar && (
          <Calendar
            setSelectedDate={setSelectedDate}
            setIsOpenCalendar={setIsOpenCalendar}
            reportType="date_report_di-sa"
            isMobile={isMobile}
          />
        )}
        <h3>Отчеты по прямым продажам</h3>
        <div className={style.orders}>
          <div className={style.wrapperCalendar} onClick={handleOpenCalendar}>
            {selectedDate !== null ? (
              <div className={style.selectedDate}>
                <div className={style.icon}>
                  <Icon.Calendar />
                  <span>
                    {'c ' +
                      selectedDate.startDate +
                      ' по ' +
                      selectedDate.endDate}
                  </span>
                </div>
                <div
                  className={style.resetBtn}
                  onClick={(e) => handleResetDate(e)}
                >
                  <Icon.Close />
                </div>
              </div>
            ) : (
              <div className={style.input}>
                {selectedDate === null && (
                  <div className={style.emtyDate}>
                    <Icon.Calendar />
                    <span>
                      Выберите дату для формирования отчета по заказам
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
          <a
            onClick={(e) => handleSetReport(e)}
            className={style.item}
            target="_blank"
            id="orders_DS"
          >
            <span>
              <Icon.DownloadReports />
            </span>
            <div className={style.content}>
              <span>
                {selectedDate !== null
                  ? `Заказы (с ${selectedDate.startDate} по ${selectedDate.endDate})`
                  : 'Заказы'}{' '}
                <span className={style.amount}>{activeOrderCount}</span>
              </span>
              <span>CSV</span>
            </div>
          </a>
        </div>
        <div className={style.line}></div>
        <div className={style.cars}>
          <a
            href="https://v6.lada.ru/api/os-reports/for-showroom/direct_sale/type"
            target="_blank"
            className={style.item}
          >
            <span>
              <Icon.DownloadReports />
            </span>
            <div className={style.content}>
              <p>
                Автомобили для размещения{' '}
                <span className={style.amount}>{amountCarsData.forPlace}</span>
              </p>
              <span>CSV</span>
            </div>
          </a>
          <a
            href="https://v6.lada.ru/api/os-reports/in-showroom/direct_sale/type"
            target="_blank"
            className={style.item}
          >
            <span>
              <Icon.DownloadReports />
            </span>
            <div className={style.content}>
              <p>
                Автомобили на витрине{' '}
                <span className={style.amount}>{amountCarsData.placed}</span>
              </p>
              <span>CSV</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}
