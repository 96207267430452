import React from 'react'

import { ModalAction } from '../ModalAction'

export const Notifications = ({
  currentTitle,
  isOpen,
  setIsOpenNotifications,
  children,
}) => {
  const titles = {
    isCancelOrder: 'Заказ отменен',
    isPosted: 'Автомобиль успешно размещен',
    isTakeOff: 'Автомобиль снят с онлайн-продажи',
    isSend: 'Письмо с данными заказа успешно отправлено',
    isError: 'Произошла ошибка. Повторите попытку.',
    isValidation: 'Не заполнены обязательные поля: ',
    isSended: 'Успешно отправлено',
    isSave: 'Cохранено',
  }

  return (
    <>
      {isOpen && (
        <ModalAction
          title={titles[currentTitle]}
          handleClose={() => setIsOpenNotifications(false)}
        >
          {children && (
            <span
              style={{
                display: 'block',
                textAlign: 'center',
                color: '#4c5865',
                textDecoration: 'underline',
              }}
            >
              {children}
            </span>
          )}
        </ModalAction>
      )}
    </>
  )
}
